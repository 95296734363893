<div class="stats"
     [class.value-first]="layout === LAYOUTS.TitleBig"
>
    <div>
        <label [innerHTML]="label | safe: 'html'"></label>
    </div>
    <div class="value">
        {{ value }}
    </div>
</div>
