import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { MutationResult } from 'apollo-angular'
import {
    RegisterNewsLetterSubscriptionMutation,
    RegisterNewsLetterSubscriptionMutationService,
    RegisterNewsLetterSubscriptionMutationVariables,
} from '@app-graphql/schema'

@Injectable({
    providedIn: 'root',
})
export class NewsLetterService {

    constructor(
        private readonly registerNewsLetterSubscriptionMutationService: RegisterNewsLetterSubscriptionMutationService,
    ) { }

    public registerForNewsLetter(input: RegisterNewsLetterSubscriptionMutationVariables)
        : Observable<MutationResult<RegisterNewsLetterSubscriptionMutation>> {
        return this.registerNewsLetterSubscriptionMutationService.mutate(input, {
            errorPolicy: 'all',
        })
    }
}
