import { Component, Input } from '@angular/core'

import { ButtonFragment } from '@app-graphql/schema'
import { Nil } from '@app-types/common.types'

@Component({
    selector: 'app-component-with-text',
    templateUrl: './component-with-text.component.html',
    styleUrls: ['./component-with-text.component.scss'],
})
export class ComponentWithTextComponent {
    @Input()
    public preHeader: string | Nil

    @Input()
    public header: string

    @Input()
    public content: string

    @Input()
    public buttons?: ButtonFragment[]

    @Input()
    public transparentBackground?: boolean = false

    @Input()
    public textAlign?: 'left' | 'right' = 'right'
}
