<div class="subfooter"
     *ngIf="subfooterLinks"
>
    <app-grid>
        <app-cell mobile="0:12">
            <div class="links-container">
                <div class="middle-point">
                    <img src="/assets/images/divider.svg"
                         alt="mid point icon"
                         loading="lazy"
                    />
                </div>

                <div class="block">
                    <div *ngIf="subfooterLinks.links[0] as leftLink"
                         class="content"
                    >
                        <div class="text">
                            <h2 class="subheading serif">
                                {{ leftLink.subtitle }}
                            </h2>
                            
                            <h2>
                                {{ leftLink.title }}
                            </h2>
                            
                            <div class="button-container">
                                <app-button *ngIf="leftLink.link"
                                            theme="dark"
                                            size="normal"
                                            type="link"
                                            layout="label-only"
                                            [link]="(leftLink.link.link | isExternalLink) ? '' : leftLink.link.link | translateRoute"
                                            [href]="(leftLink.link.link | isExternalLink) ? leftLink.link.link : '' | translateRoute"
                                            [cmsTarget]="leftLink.link.target"
                                >
                                    {{ leftLink.link.label }}
                                </app-button>
                            </div>
                        </div>

                        <div class="link-image">
                            <app-responsive-image *ngIf="leftLink.image"
                                                  [image]="leftLink.image"
                            ></app-responsive-image>
                        </div>
                    </div>
                </div>

                <div class="horizontal-middle-point">
                    <div class="background-image"></div>
                </div>

                <div class="block">
                    <div *ngIf="subfooterLinks.links[1] as rightLink"
                         class="content"
                    >
                        <div class="text">
                            <h2 class="subheading serif">
                                {{ rightLink.subtitle }}
                            </h2>
                            <h2>
                                {{ rightLink.title }}
                            </h2>
                            <div class="button-container">
                                <app-button *ngIf="rightLink.link"
                                            theme="dark"
                                            size="normal"
                                            layout="label-only"
                                            type="link"
                                            [link]="(rightLink.link.link | isExternalLink) ? '' : rightLink.link.link | translateRoute"
                                            [href]="(rightLink.link.link | isExternalLink) ? rightLink.link.link : '' | translateRoute"
                                            [cmsTarget]="rightLink.link.target"
                                >
                                    {{ rightLink.link.label }}
                                </app-button>
                            </div>
                        </div>

                        <div class="link-image">
                            <app-responsive-image *ngIf="rightLink.image"
                                                  [image]="rightLink.image"
                            ></app-responsive-image>
                        </div>
                    </div>
                </div>

            </div>
        </app-cell>
    </app-grid>
</div>
