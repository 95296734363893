import { Component, Input } from '@angular/core'
import { ImageComponentFragment } from '@app-graphql/schema'

@Component({
    selector: 'app-image-content',
    templateUrl: './image-content.component.html',
    styleUrls: ['./image-content.component.scss'],
})
export class ImageContentComponent {
    @Input()
    public image: ImageComponentFragment

    @Input()
    public caption?: string

    @Input()
    public lazyLoad: boolean = true
}
