import { Injectable } from '@angular/core'

import { ApiHealthCheckService } from '@app-services'

@Injectable({
    providedIn: 'root',
})
export class ApiAvailableGuard {

    constructor(
        protected readonly apiHealthCheckService: ApiHealthCheckService,
    ) {
    }

    public canActivate(): Promise<boolean> {
        return new Promise<boolean>((resolve) => resolve(true))
        return this.apiHealthCheckService.isAvailable()
    }
}
