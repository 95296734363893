<app-grid>
    <app-cell desktop="0:3"
              laptop="0:3"
              tablet="0:12"
              mobile="0:12"
    >
        <h3 class="title">
            {{ pageLinks.title }}
        </h3>
    </app-cell>

    <app-cell desktop="3:12"
              laptop="3:12"
              tablet="0:12"
              mobile="0:12"
    >
        <div class="card-container"
             *ngIf="localization.currentLocale$ | async as locale"
        >
            <div class="grid">
                <ng-container *ngFor="let card of pageLinks.pages">
                    <ng-container *ngIf="card.localizations | ppLocale: locale as localized">
                        <div class="card"
                             (click)="routeToPage(locale, localized.slug)"
                        >
                            <div class="icon-bubble">
                                <span *ngIf="card.icon" [appIconEnum]="card.icon" class="icon"></span>
                                <span *ngIf="!card.icon" appIcon="dealer-shop" class="icon"></span>
                            </div>

                            <div class="subject">
                                <h3 class="subheading">
                                    {{localized.title}}
                                </h3>

                                <span appIcon="arrow-fat-right"
                                      class="arrow"
                                ></span>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>

        </div>
    </app-cell>
</app-grid>