import { Component, Input } from '@angular/core'

export enum StatusEnum {
    Collection = 'COLLECTION',
    MadeToOrder = 'TO ORDER',
    Sale = 'SALE',
    Limited = 'LIMITED',
}

@Component({
    selector: 'app-product-availability',
    templateUrl: './product-availability.component.html',
    styleUrls: ['./product-availability.component.scss'],
})
export class ProductAvailabilityComponent {
    @Input()
    public status?: string

    @Input()
    public statusTranslation: string

    @Input()
    public deliveryTimeInWeeks: number | null | undefined

    @Input()
    public layout: 'row' | 'column' = 'column'

    @Input()
    public showTooltip: boolean = false

    @Input()
    public largeText: boolean = false

    public statusEnum = StatusEnum
}
