import { any, equals, not } from 'ramda'
import { defaultIfEmpty, startWith, Subscription } from 'rxjs'

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core'
import { FormControl } from '@angular/forms'

import { idGenerator, isBoolean } from '@app-lib/common.lib'

/**
 * # Checkbox component
 *
 * Renders a checkbox with a label.
 *
 * -
 *
 * ## Input bindings
 *
 * | Input binding                                    | Default        | Required |
 * | ------------------------------------------------ | -------------- | -------- |
 * | {@link CheckboxComponent.value [value]}          | `''`           | true     |
 * | {@link CheckboxComponent.control [control]}      | `undefined`     | true     |
 * | {@link CheckboxComponent.disabled [disabled]}    | `false`        | false    |
 * | {@link CheckboxComponent.nativeMode [disabled]}  | `false`        | false    |
 * | {@link CheckboxComponent.smallLabel}             | `false`        | false    |
 *
 * -
 *
 * ## Label content
 * The label content is projected in an `<ng-content>` slot:
 *
 * ```html
 * <app-checkbox [control]="..." [value]="...">
 *     <span>Label content here</span>
 * </app-checkbox>
 * ```
 */
@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class CheckboxComponent<T = string> implements OnChanges {

    private static readonly idGenerator = idGenerator('checkbox')

    @Input()
    public checked?: boolean

    @Output()
    public changed = new EventEmitter<boolean>()

    @Input()
    public nativeMode: boolean = false

    @Input()
    public smallLabel: boolean = false

    /**
     * Provide the value for this checkbox.
     */
    @Input()
    public value: T

    /**
     * Requires a form control to attach to the native input element. This input accepts
     * `undefined` so that the existence check for controls within form-groups can be done
     * internally. If `undefined` is bound then the checkbox will simply not render.
     */
    @Input()
    public control: FormControl<boolean | ArrayLike<T> | null> | undefined

    /**
     * Should this button be disabled? (Default false)
     * Can be either toggled with a one-time binding or with a dynamic boolean binding. Allowed syntax:
     *
     * ```html
     * <app-checkbox disabled></app-checkbox>
     * <app-checkbox disabled="disabled"></app-checkbox>
     * <app-checkbox [disabled]="booleanValue"></app-checkbox>
     * ```
     */
    @Input()
    public set disabled(disabled: '' | 'disabled' | boolean) {
        this.isDisabled = disabled === '' || disabled === 'disabled' || disabled
    }

    public readonly id = `checkbox-${CheckboxComponent.idGenerator.next().value}`

    public isDisabled: boolean = false

    private firstEmit = true

    @Input()
    private errorLogger: (message: string) => void = (message) => console.log(message)

    /**
     * Natively checkboxes work by setting a FormControl's value to either true or false, this is possible with
     * `nativeMode` but another possible use for Checkboxes could be to add or remove a value from an array based on
     * the checked status of the checkbox
     */

    public ngOnChanges(changes: SimpleChanges) {
        if (! changes.nativeMode && ! this.nativeMode) {
            this.nativeMode = false
        }
        if (changes.control) {
            const control: FormControl<T[] | null> = changes.control.currentValue
            this.internalFormControl = control

            if (control && ! this.nativeMode) {
                if (isBoolean(control.value)) {
                    this.errorLogger(
                        'CheckboxComponent initialised with a boolean FormControl without enabling nativeMode',
                    )
                } else {
                    this.setupInternalValueListener()
                }
            }
        }
    }

    public renderedFormControl: FormControl<boolean>
    private renderedFormChanges: Subscription

    public internalFormControl: FormControl<T[] | null>
    private internalFormChanges: Subscription

    /**
     * Listen to value changes on provided control and adjust the renderedFormControl's value according to whether or
     * not the {@link CheckboxComponent.value value} is in the value array
     * @private
     */
    private setupInternalValueListener() {
        if (this.internalFormChanges) {
            this.internalFormChanges.unsubscribe()
        }

        this.internalFormChanges = this.internalFormControl.valueChanges.pipe(
            startWith(this.internalFormControl.value),
            defaultIfEmpty([]),
        ).subscribe((value: T[] | null) => {
            if (value) {
                const checked = any(equals(this.value), value)
                if (! this.renderedFormControl) {
                    this.renderedFormControl = new FormControl(checked, {
                        nonNullable: true,
                    })
                    this.setupRenderedValueListener()
                } else {
                    if (checked !== this.renderedFormControl.value) {
                        if (this.firstEmit) {
                            this.firstEmit = false
                        } else {
                            this.changed.emit()
                        }
                        this.renderedFormControl.setValue(checked, {
                            emitEvent: false,
                        })
                    }
                }
            }
        })
    }

    /**
     * Listen to value changes on the rendered control and adjust the internalFormControl's value according to either
     * include or reject the {@link CheckboxComponent.value value} from the array depending on if the checkbox is
     * checked
     * @private
     */
    private setupRenderedValueListener() {
        if (this.renderedFormChanges) {
            this.renderedFormChanges.unsubscribe()
        }

        this.renderedFormChanges = this.renderedFormControl.valueChanges.pipe(
            startWith(this.renderedFormControl.value),
        ).subscribe((value: boolean) => {
            const currentValue: T[] = (this.internalFormControl.value ?? []).filter(v => not(equals(this.value, v)))
            const newValue = value
                ? [ ...currentValue, this.value ]
                : currentValue
            this.internalFormControl.setValue(newValue)
        })
    }

}
