import { Component, Input } from '@angular/core'
import { HeaderLayoutEnum, VideoFragment } from '@app-graphql/schema'

@Component({
    selector: 'app-video-header',
    templateUrl: './video-header.component.html',
    styleUrls: ['./video-header.component.scss'],
})
export class VideoHeaderComponent {
    @Input()
    public video: VideoFragment

    @Input()
    public layout: HeaderLayoutEnum = HeaderLayoutEnum.Compact
}
