import { Injectable, OnDestroy } from '@angular/core'
import { Product } from '@app-domains/content-blocks/components/product-card/product-card.types'
import {
    ProductOrderPropertiesFragment,
    ProductsOrderPropertiesQuery,
    ProductsOrderPropertiesQueryService,
    ProductsOrderPropertiesQueryVariables,
} from '@app-graphql/schema'
import { BehaviorSubject, Observable, Subscription } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client'
import { isNil, pluck } from 'ramda'

@Injectable({
    providedIn: 'root',
})
export class ProductEnhanceService implements OnDestroy {

    private enhancedProductsSubject = new BehaviorSubject<ProductOrderPropertiesFragment[] | null>(null)
    public enhancedProducts$ = this.enhancedProductsSubject.asObservable()
    private productPropSub: Subscription

    constructor(
        private productsOrderPropertiesQueryService: ProductsOrderPropertiesQueryService,
    ) {
    }

    public ngOnDestroy(): void {
        if (this.productPropSub) {
            this.productPropSub.unsubscribe()
        }
    }

    public initialize(products: Product[]): void {
        if (products.length === 0) {
            return
        }

        this.productPropSub = this.getProductsOrderProperties({ eans: pluck('ean', products) })
            .subscribe((enhancedProducts) => this.enhancedProductsSubject.next(enhancedProducts.data.productsByEan))
    }

    public getEnhancedProduct(ean: string): ProductOrderPropertiesFragment | undefined {
        const products = this.enhancedProductsSubject.getValue()

        if (isNil(products)) {
            return undefined
        }

        return products.find((product) => product.ean === ean)
    }

    public getProductsOrderProperties(
        input: ProductsOrderPropertiesQueryVariables,
    ): Observable<ApolloQueryResult<ProductsOrderPropertiesQuery>> {
        return this.productsOrderPropertiesQueryService.fetch(input)
    }
}
