<div class="text-header"
     [style]="'min-height:'+height+'px'"
     [ngClass]="extended ? 'extended' : ''"
>
    <app-grid>
        <app-cell [mobile]="textColumn?.mobile"
                  [tablet]="textColumn?.tablet"
                  [laptop]="textColumn?.laptop"
                  [desktop]="textColumn?.desktop"
        >
            <h3 class="serif first-line">{{ firstLine }}</h3>
            
            <h1 class="second-line">{{ secondLine }}</h1>
            
            <p *ngIf="smallText"
               class="small-text"
            >
                {{ smallText }}
            </p>
        </app-cell>
        
        <app-cell [mobile]="otherContentColumn?.mobile"
                  [tablet]="otherContentColumn?.tablet"
                  [laptop]="otherContentColumn?.laptop"
                  [desktop]="otherContentColumn?.desktop"
        >
            <ng-content></ng-content>
        </app-cell>
    </app-grid>
</div>
