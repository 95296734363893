import { Pipe, PipeTransform } from '@angular/core'
import { TranslatePipe } from '@ngx-translate/core'
import type { translations } from '../../../assets/i18n/en'

type FlatValue<T, K> =
    K extends `${infer I}.${infer J}` ? I extends keyof T ? FlatValue<T[I], J> : never
        : K extends keyof T ? T[K] : never
type FlatKeys<T, P extends string = ''> =
    T extends object
        ? { [K in keyof T]: FlatKeys<T[K], `${P}${P extends '' ? '' : '.'}${K & string}`> }[keyof T]
        : P
type Flatten<T> = { [K in FlatKeys<T>]: FlatValue<T, K> }
export type TranslationKey = keyof Flatten<typeof translations>

@Pipe({
    name: 'translate',
    pure: false,
})
export class TypedTranslatePipe extends TranslatePipe implements PipeTransform {
    override transform(key: TranslationKey, ...args: any[]): string {
        return super.transform(key, ...args)
    }
}
