<app-grid>
    <app-cell mobile="0:12">
        <div class="contact-form">
            <header class="header">
                <h2 class="big">
                    {{ 'data-request.title' | translate }}
                </h2>

                <p>
                    {{ 'data-request.subtitle' | translate }}
                </p>
            </header>

            <section class="info-container">
                <h3>
                    {{ 'contact.contact-us-directly' | translate }}
                </h3>

                <div class="detail">
                    <div class="icon">
                        <span appIcon="phone"></span>
                    </div>
                    <div>
                        <a href="tel:+31889666300">+31 (0)889-666300</a>
                        <p class="subtitle">
                            ({{ 'contact.days' | translate }}) 08:30 - 17:00
                        </p>
                    </div>
                </div>

                <div class="detail">
                    <div class="icon">
                        <span appIcon="mail"></span>
                    </div>
                    <div>
                        <a href="mailto:info@deeekhoorn.com">info&#64;deeekhoorn.com</a>
                    </div>
                </div>

                <div class="detail">
                    <div class="icon">
                        <span appIcon="location"></span>
                    </div>
                    <div>
                        De Eekhoorn Dutch Furniture
                        <p class="subtitle address">
                            Jan Tinbergenweg 1 <br>
                            1689 ZV Hoorn
                        </p>

                        <p class="hq">
                            <a [routerLink]="'/experience-center' | translateRoute"
                               target="_blank"
                               class="text-link"
                            >{{ 'contact.click-here'| translate }}</a>

                            {{ 'contact.more-info-experience-center'| translate }}
                        </p>
                    </div>
                </div>
            </section>

            <form [formGroup]="dataRequestForm">
                <h3>
                    {{ 'contact.let-us-contact' | translate }}
                </h3>

                <app-radio-button [control]="dataRequestForm | formChild : 'salutation':'control'"
                                  [value]="'data-request.salutation.mr' | translate"
                >
                    {{ 'data-request.salutation.mr' | translate }}
                </app-radio-button>

                <app-radio-button [control]="dataRequestForm | formChild : 'salutation':'control'"
                                  [value]="'data-request.salutation.mrs' | translate"
                >
                    {{ 'data-request.salutation.mrs' | translate }}
                </app-radio-button>

                <app-input [control]="dataRequestForm | formChild : 'firstName':'control'"
                           [errorMessages]="dataRequestFormMessages"
                           [placeholder]="'contact.enter-name'| translate"
                           autocapitalize="words"
                           autocomplete="name"
                           class="full-width"
                >
                    {{ 'data-request.first-name' | translate }}
                </app-input>

                <app-input [control]="dataRequestForm | formChild : 'lastName':'control'"
                           [errorMessages]="dataRequestFormMessages"
                           [placeholder]="'contact.enter-name'| translate"
                           autocapitalize="words"
                           autocomplete="name"
                           class="full-width-right"
                >
                    {{ 'data-request.last-name' | translate }}
                </app-input>

                <app-input [control]="dataRequestForm | formChild : 'zipcode':'control'"
                           [errorMessages]="dataRequestFormMessages"
                           autocapitalize="words"
                >
                    {{ 'order-dropshipment.postal-code' | translate }}
                </app-input>

                <app-input [control]="dataRequestForm | formChild : 'houseNumber':'control'"
                           [errorMessages]="dataRequestFormMessages"
                           [form]="dataRequestForm"
                           autocapitalize="off"
                           type="number"
                >
                    {{ 'order-dropshipment.house-number' | translate }}
                </app-input>

                <app-input [control]="dataRequestForm | formChild : 'addition':'control'"
                           [errorMessages]="dataRequestFormMessages"
                           [form]="dataRequestForm"
                           autocapitalize="off"
                >
                    {{ 'data-request.addition' | translate }}
                </app-input>

                <app-input [control]="dataRequestForm | formChild : 'street':'control'"
                           [errorMessages]="dataRequestFormMessages"
                           [form]="dataRequestForm"
                           autocapitalize="off"
                           class="full-width"
                >
                    {{ 'order-dropshipment.street-name' | translate }}
                </app-input>

                <app-input [control]="dataRequestForm | formChild : 'city':'control'"
                           [errorMessages]="dataRequestFormMessages"
                           [form]="dataRequestForm"
                           autocapitalize="off"
                           class="full-width-right"
                >
                    {{ 'enhance-debtor.city' | translate }}
                </app-input>

                <div class="fuzzy full-width">
                    <div class="label">
                        <label class="small bold">
                            {{ 'register.country' | translate }}
                        </label>
                    </div>

                    <app-fuzzy-search [dataSet]="countries"
                                      (valueUpdated)="setCountry($event)"
                    ></app-fuzzy-search>
                </div>

                <app-input [control]="dataRequestForm | formChild : 'email':'control'"
                           [errorMessages]="dataRequestFormMessages"
                           [placeholder]="'contact.enter-email'| translate"
                           [form]="dataRequestForm"
                           type="email"
                           fieldName="email"
                           autocapitalize="off"
                           autocomplete="email"
                           class="full-width"
                >
                    {{ 'order-dropshipment.email' | translate }}
                </app-input>

                <app-input [control]="dataRequestForm | formChild : 'phoneNumber':'control'"
                           [errorMessages]="dataRequestFormMessages"
                           [placeholder]="'contact.enter-phone-number' | translate"
                           type="tel"
                           autocapitalize="off"
                           autocomplete="tel"
                           class="full-width-right"
                >
                    {{ 'contact.phone-number' | translate }}
                </app-input>

                <div class="footer">
                    <label class="recaptcha">
                        {{ 'recaptcha.protected-by'| translate }}

                        <a href='https://policies.google.com/privacy'
                           class="text-link"
                           target="_blank"
                        >
                            {{ 'recaptcha.privacy-policy'| translate }}
                        </a>

                        {{ 'recaptcha.and'| translate }}

                        <a href='https://policies.google.com/terms'
                           class="text-link"
                           target="_blank">
                            {{ 'recaptcha.terms-of-service'| translate }}
                        </a>

                        {{ 'recaptcha.apply'| translate }}.
                    </label>

                    <app-button theme="dark"
                                (click)="submitDataRequestForm()"
                                class="submit"
                    >
                        {{ 'common.send' | translate }}
                    </app-button>
                </div>
            </form>
        </div>
    </app-cell>
</app-grid>
