import { not } from 'ramda'
import { combineLatestWith, firstValueFrom, Observable, startWith, Subject, Subscription } from 'rxjs'
import { debounceTime, map, tap } from 'rxjs/operators'
import { TranslateRouterService } from '@endeavour/ngx-translate-router'
import { SearchClient } from 'algoliasearch'
import { InstantSearchOptions as InstantSearchConfig } from 'instantsearch.js/es/types'
import { AlgoliaSearchHelper, PlainSearchParameters } from 'algoliasearch-helper'

import { CdkMenuTrigger } from '@angular/cdk/menu'
import {
    Component,
    ElementRef,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core'
import { FormControl } from '@angular/forms'

import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { generateDebtorExclusivityFilter } from '@app-lib/debtor.lib'
import { getIndexName } from '@app-lib/algolia.lib'
import { shareReplayOne } from '@app-lib/rxjs.lib'
import { BreakpointsService } from '@app-domains/ui/services/breakpoints/breakpoints.service'
import { LocalizationService } from '@app-domains/localization/service/localization.service'
import { ALGOLIA_SEARCH_CLIENT } from '@app-domains/algolia/algolia.module'
import {
    DebtorService,
    SearchService,
} from '@app-services'

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, OnChanges, OnDestroy {

    @ViewChild(CdkMenuTrigger, { static: false })
    private menuTrigger: CdkMenuTrigger

    @ViewChild('openCloseInput')
    private openCloseInput: ElementRef

    public productSearchParameters$: Observable<PlainSearchParameters>
    public instantSearchConfig$: Observable<InstantSearchConfig>
    public otherSearchParameters: PlainSearchParameters
    private otherLoading = new Subject<boolean>()
    private productsLoading = new Subject<boolean>()
    public loading$ = this.productsLoading.pipe(
        startWith(false),
        combineLatestWith(this.otherLoading.pipe(startWith(false))),
        map(([productsLoading, otherLoading]) => productsLoading || otherLoading),
    )

    @Input()
    public set mobileMenuIsOpen(isOpen: boolean) {
        this.internalMobileMenuIsOpen = isOpen
        if (isOpen) {
            this.onBlur()
        }
    }

    public get mobileMenuIsOpen() {
        return this.internalMobileMenuIsOpen
    }

    @Input()
    public openCloseVariant: boolean = false

    @Input()
    public navigationIsCollapsed: boolean = false

    public internalMobileMenuIsOpen: boolean

    public initialised = false
    public search = new FormControl('', {
        nonNullable: true,
    })

    public recentSearches: any

    public searchIsOpen: boolean = false

    public inputStopped: boolean = false

    private currentBreakpointSubscription: Subscription
    private routerSubscription: Subscription
    public currentBreakpoint: string

    constructor(
        @Inject(ALGOLIA_SEARCH_CLIENT)
        private readonly searchClient: SearchClient,
        private breakpointsService: BreakpointsService,
        public searchService: SearchService,
        private router: Router,
        private translateRouterService: TranslateRouterService,
        private debtorService: DebtorService,
        private localization: LocalizationService,
        public readonly route: ActivatedRoute,
    ) {
    }

    public initialise() {
        if (this.initialised) {
            return
        }

        this.initialised = true
        this.recentSearches = this.searchService.getRecentSearches()

        const queryParam = this.route.snapshot.queryParams.q
        this.search.setValue(queryParam)
    }

    public ngOnInit(): void {
        this.productSearchParameters$ = this.debtorService.debtor$.pipe(
            map((debtor) => ({
                hitsPerPage: 5,
                filters: `type:product ${ generateDebtorExclusivityFilter(debtor) }`,
            })),
        )
        this.otherSearchParameters = {
            hitsPerPage: 62,
            filters: 'NOT type:product',
        }
        this.instantSearchConfig$ = this.localization.currentLocale$.pipe(
            map((locale) => ({
                indexName: getIndexName({
                    locale,
                    sorting: null,
                }),
                searchClient: this.searchClient,
                searchFunction: (helper: AlgoliaSearchHelper) => {
                    const query = helper.getQuery()
                    if (query.query && query.query?.length >= 3) {
                        helper.search()
                    }
                },
            })),
            shareReplayOne(),
        )

        this.initialise()

        this.search.valueChanges.pipe(
            startWith(this.search.value),
            tap(() => this.inputStopped = false),
            debounceTime(300),
        ).subscribe((query) => {
            this.inputStopped = true
            this.searchService.querySubject.next(query)
        })

        this.routerSubscription = this.router.events.subscribe((x) => {
            if (x instanceof NavigationEnd) {
                this.menuTrigger?.close()
            }
        })

        this.currentBreakpointSubscription = this.breakpointsService.currentBreakpoint$.subscribe((result) => {
            this.currentBreakpoint = result
        })
    }

    public ngOnDestroy(): void {
        this.routerSubscription.unsubscribe()
        this.currentBreakpointSubscription.unsubscribe()
    }

    public async onBlur(resetQuery = false): Promise<void> {
        const onSearchPage = await firstValueFrom(this.searchService.onSearchPage$)

        if (onSearchPage) {
            return
        }

        if (resetQuery) {
            this.search.setValue('')
        }

        this.openCloseInput?.nativeElement.blur()
    }

    public resultClicked(): void {
        this.recentSearches = this.searchService.getRecentSearches()
        void this.onBlur(true)
    }

    public openCloseSearch(): void {
        this.searchIsOpen = not(this.searchIsOpen)
        this.resetSearchMenu()
    }

    public resetSearchMenu(): void {
        if (this.searchIsOpen) {
            setTimeout(() => {
                this.openCloseInput.nativeElement.focus()
                this.menuTrigger.open()
            }, 400)
        } else {
            this.menuTrigger.close()
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        const { navigationIsCollapsed } = changes

        if (navigationIsCollapsed) {
            const isCollapsed = navigationIsCollapsed.currentValue

            if (isCollapsed) {
                this.searchIsOpen = false
                this.resetSearchMenu()
            }

            void this.onBlur(true)
        }
    }

    public async navigateToSearch(): Promise<void> {
        const onSearchPage = await firstValueFrom(this.searchService.onSearchPage$)

        if (onSearchPage) {
            return
        }

        await this.router.navigate(
            this.translateRouterService.translateRouteLink(['/search']) as any[],
        )
    }

    public async onLoading(type: 'products' | 'other', value: boolean): Promise<void> {
        if (type === 'products') {
            this.productsLoading.next(value)
        } else {
            this.otherLoading.next(value)
        }
    }
}
