import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Input,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core'
import { LinkFragment, LinkTargetEnum, ProjectSlideFragment } from '@app-graphql/schema'
import { SwiperService } from '@app-services'
import Swiper from 'swiper'
import SwiperCore, { Autoplay, Controller, Navigation, Pagination, SwiperOptions } from 'swiper'
import { SwiperLib } from '@app-lib/swiper.lib'
import { Nil } from '@app-types/common.types'

SwiperCore.use([
    Autoplay,
    Controller,
    Pagination,
    Navigation,
])

@Component({
    selector: 'app-project-showcase',
    templateUrl: './project-showcase.component.html',
    styleUrls: ['./project-showcase.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class ProjectShowcaseComponent implements OnInit {

    @ViewChild('pagination', { static: true })
    public pagination: ElementRef<HTMLDivElement>

    @Input()
    public title: string

    @Input()
    public preHeading: string | Nil = null

    @Input()
    public text: string | Nil = null

    @Input()
    public link: LinkFragment | Nil = null

    @Input()
    public projectsSlides: ProjectSlideFragment[]

    @Input()
    public lazyLoad: boolean = true

    public swiperOptions: SwiperOptions

    public swiper: SwiperLib

    public linkTargetEnum = LinkTargetEnum

    constructor(
        private cdr: ChangeDetectorRef,
        private swiperService: SwiperService,
    ) {
    }

    public ngOnInit(): void {
        this.swiperOptions = {
            slidesPerView: 'auto',
            spaceBetween: 24,
            loop: true,
            direction: 'horizontal',
            pagination: {
                type: 'progressbar',
                el: this.pagination.nativeElement,
            },
            on: {
                progress: () => {
                    if (this.swiper) {
                        this.swiper.onProgress()
                    }
                },
            },
        }
    }

    public onSwiper(swiper: Swiper): void {
        this.swiper = this.swiperService.create(swiper)
        this.swiper.updateTotalIndex()
    }

    public onSlideChange(): void {
        if (this.swiper) {
            this.swiper.onSlideChange()
            this.cdr.detectChanges()
        }
    }

    @HostListener('window:resize')
    onResize() {
        this.swiper.updateTotalIndex()
    }
}
