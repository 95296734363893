import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'includes',
})
export class IncludesPipe implements PipeTransform {
    transform<T>(search: Array<T> | Set<T> | string | null | undefined, value: T | string): boolean {
        if (typeof search === 'string') {
            return search.includes(value as string)
        }

        if (search instanceof Set) {
            return search.has(value as T)
        }

        if (Array.isArray(search)) {
            return search.includes(value as T)
        }

        return false
    }
}
