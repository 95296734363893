import { merge } from 'rxjs'
import { debounceTime } from 'rxjs/operators'

import { Component, Input, OnInit } from '@angular/core'
import { AbstractControl, UntypedFormGroup } from '@angular/forms'

import { FormValidationError } from '@app-types/form'

@Component({
    selector: 'app-validation-errors',
    templateUrl: './validation-errors.component.html',
    styleUrls: ['./validation-errors.component.scss'],
})
export class ValidationErrorsComponent implements OnInit {

    public control: AbstractControl | null

    public errors: Array<string>

    @Input()
    public field?: string

    @Input()
    public form: UntypedFormGroup

    @Input()
    public validationErrors: FormValidationError

    public ngOnInit(): void {
        if (this.field) {
            this.control = this.form.controls[this.field]
        } else {
            this.control = this.form
        }
        if (! this.validationErrors) {
            this.control.statusChanges.subscribe(() => {
                this.errors = Object.values(this.control!.errors ?? {})
            })
        } else {
            merge(this.control.valueChanges.pipe(
                debounceTime(500),
            ), this.control.statusChanges).pipe(
                debounceTime(500),
            ).subscribe(() => {
                this.errors = []
                if (this.validationErrors) {
                    if (this.control!.status === 'INVALID' && this.control!.errors) {
                        this.errors = Object.keys(this.control!.errors).map((value) => {
                            if (this.validationErrors[this.field ?? 'form']) {
                                return this.validationErrors[this.field ?? 'form'][value] ?? ''
                            }
                            return ''
                        })
                    }
                }
            })
        }

    }
}
