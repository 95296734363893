import { firstValueFrom } from 'rxjs'
import { ReCaptchaV3Service } from 'ng-recaptcha-2'
import { TranslateService } from '@ngx-translate/core'

import { Component, Input } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'

import { connectForm } from '@app-modules/graphql/graphql.module.lib'
import { InputPublic } from '@app-domains/ui/components/input/input.component.types'
import { TranslationKey } from '@app-pipes/typed-translate/typed-translate.pipe'
import { checkEmail } from '../../../../../validators'
import { ContactService } from '@app-services'
import { LocalizationService } from '@app-domains/localization/service/localization.service'
import { SnackbarService } from '@app-domains/ui/services/snackbar/snackbar.service'

@Component({
    selector: 'app-contact-form',
    templateUrl: './contact-form.component.html',
    styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent {

    @Input()
    public title: TranslationKey = 'contact.interested'

    @Input()
    public subTitle: TranslationKey = 'contact.are-you-curious'

    public submitting: boolean = false

    public contactForm = new FormGroup({
        name: new FormControl('', [
            Validators.required,
        ]),
        companyName: new FormControl('', [
            Validators.required,
        ]),
        email: new FormControl('', [
            Validators.required,
            checkEmail,
        ]),
        phoneNumber: new FormControl('', [
            Validators.required,
            Validators.pattern('[- +()0-9]+'),
        ]),
        message: new FormControl('', [
            Validators.required,
        ]),
    })

    public contactFormMessages: InputPublic.ErrorMessageRecord = {
        required: this.translate.instant('validation.messages.required'),
        emailNotValid: this.translate.instant('validation.check-input'),
    }

    constructor(
        private readonly recaptchaV3Service: ReCaptchaV3Service,
        private readonly contactService: ContactService,
        private readonly translate: TranslateService,
        private readonly snackbarService: SnackbarService,
        private readonly localizationService: LocalizationService,
    ) {
    }

    public async submitContactForm(): Promise<void> {
        this.submitting = true

        if (this.contactForm.invalid) {
            Object.values(this.contactForm.controls).forEach((c) => c.markAsDirty())
            this.contactForm.markAllAsTouched()
            return
        }

        const formValues = this.contactForm.value

        this.recaptchaV3Service
            .execute('submit')
            .subscribe(async (token: string) => {
                const result = await firstValueFrom(
                    connectForm(this.contactService.sendContactForm({
                        input: {
                            name: formValues.name!,
                            companyName: formValues.companyName!,
                            email: formValues.email!.toLowerCase(),
                            phoneNumber: formValues.phoneNumber!,
                            message: formValues.message!,
                            isNotARobot: token,
                            locale: this.localizationService.getCurrentLocale(),
                        },
                    }), this.contactForm),
                )

                this.submitting = false

                if (result.data) {
                    this.snackbarService.create({
                        content: 'contact.contact-success',
                        dismissible: true,
                    }, 'bottom')
                }
            })
    }
}
