<app-grid [colGap]="true">
    <app-cell mobile="0:12" laptop="1:11">
        <div class="video-content">
            <app-video-player [video]="video"></app-video-player>

            <div *ngIf="caption"
                 class="video-content--caption"
            >
                {{ caption }}
            </div>
        </div>
    </app-cell>
</app-grid>

