import { Component, Input } from '@angular/core'

@Component({
    selector: 'app-tab[label]',
    templateUrl: './tab.component.html',
})
export class TabComponent {

    @Input()
    public value: any

    @Input()
    public label: string = ''

    public active: boolean = false

}
