import { Injectable } from '@angular/core'
import { HealthCheckQueryService } from '@app-graphql/schema'
import { firstValueFrom, map } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class ApiHealthCheckService {
    constructor(
        protected readonly healthCheckQueryService: HealthCheckQueryService,
    ) {
    }

    public isAvailable(): Promise<boolean> {
        return firstValueFrom(
            this.healthCheckQueryService.fetch().pipe(map(({ data }) => data.healthCheck)),
        ).catch(() => false)
    }
}
