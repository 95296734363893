<ng-container *ngIf="state?.results?.hits as hitsUnordered">
    <ng-container *ngIf="objectIDOrder ? (hitsUnordered | orderByObjectIDs : objectIDOrder) : hitsUnordered as hits">
        <ng-container *ngFor="let product of hits | transformProductHit | enhanceProducts | async; let i = index; let last = last">
            <app-other-products-card *ngIf="i | otherProductsGrid : last : hits.length | async as className"
                                     [product]="product"
                                     [className]="className"
                                     [index]="i + 1"
                                     [queryID]="state?.results?.queryID"
            ></app-other-products-card>
        </ng-container>
    </ng-container>
</ng-container>
