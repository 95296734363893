<div class="with-text"
     [class.text-align--left]="textAlign === 'left'"
>
    <div class="component">
        <ng-content></ng-content>
    </div>

    <div class="text-container">
        <div class="text"
             [class.transparent-background]="transparentBackground"
        >
            <h3 *ngIf="preHeader"
                class="serif text--pre-header"
            >
                {{ preHeader }}
            </h3>

            <h2 class="text--header">
                {{ header }}
            </h2>

            <p class="text--content"
               [innerHTML]="content | safe:'html'"
            ></p>

            <div *ngIf="buttons"
                 class="buttons-container"
            >
                <app-button *ngFor="let button of buttons"
                            [href]="button.link.link"
                            [type]="button.link.link ? 'link' : 'button' "
                            [cmsTheme]="button.type"
                            [cmsIcon]="button.icon ?? undefined"
                            [layout]="(button?.icon && button.link.label === ' ') ? 'icon-only'
                                : (button?.icon && button.link.label) ? 'icon-label'
                                : 'label-only'
                            "
                            [cmsTarget]="button.link.target"
                >
                    {{ button.link.label }}
                </app-button>
            </div>
        </div>
    </div>
</div>
