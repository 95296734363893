import { Component, EventEmitter, Input, OnChanges, Output, ViewEncapsulation } from '@angular/core'
import { SafeUrl } from '@angular/platform-browser'
import type { RouterLink } from '@angular/router'

import { Button, ButtonPrivate } from './button.component.types'
import { IconNameUnion } from '@app-domains/ui/directives/icon/icon.directive.types'
import { ButtonThemeEnum, IconEnum, LinkTargetEnum } from '@app-graphql/schema'

/**
 * # Button component
 *
 * Renders a button or a link styled as a button.
 *
 * ## Input bindings
 *
 * | Input binding                                   | Default        |
 * | ----------------------------------------------- | -------------- |
 * | **Generic bindings**                            |                |
 * | {@link ButtonComponent.type [type]}             | `'button'`     |
 * | {@link ButtonComponent.size [size]}             | `'normal'`     |
 * | {@link ButtonComponent.theme [theme]}           | `'dark'`       |
 * | {@link ButtonComponent.layout [layout]}         | `'label-only'` |
 * | {@link ButtonComponent.state [state]}           | `'idle'`       |
 * | {@link ButtonComponent.icon [icon]}             | `undefined`    |
 * | {@link ButtonComponent.color [color]}           | `'dark'`       |
 * | **`<button>` bindings**                         |                |
 * | {@link ButtonComponent.disabled [disabled]}     | `false`        |
 * | **`<a>` bindings**                              |                |
 * | {@link ButtonComponent.link [routerLink]}       | `undefined`    |
 * | {@link ButtonComponent.rel [rel]}               | `''`           |
 * | {@link ButtonComponent.target [target]}         | `'_self'`      |
 *
 * ## Overriding label styles
 *
 * You may override and/or extend the label CSS styling by selecting the label shadow part:
 *
 * ```scss
 * app-button::part(label) {
 *     // Style overrides here...
 * }
 * ```
 *
 * ## Overriding icon styles
 *
 * You may override and/or extend the icon CSS styling by selecting the label shadow part:
 *
 * ```scss
 * app-button::part(icon) {
 *     // Style overrides here...
 * }
 * ```
 */
@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: [
        './button.component.scss',
        '../../../../../assets/scss/_icons.scss',
    ],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class ButtonComponent implements OnChanges {

    // ------------------------------------------------------------------------------
    //      Input bindings
    // ------------------------------------------------------------------------------

    /**
     * Specify the {@link Button.Type button type}. If the type is `'button'` or `'submit'`,
     * a `<button>` element will be rendered. If the type is `'link'` an `<a>` element will be rendered.
     * @default 'button'
     */
    @Input()
    public type: Button.Type = 'button'

    /**
     * Specify the {@link Button.Size button's size}.
     * @default 'normal'
     */
    @Input()
    public size: Button.Size = 'normal'

    /**
     * Specify the {@link Button.Theme button's theme}.
     * @default 'dark'
     */
    @Input()
    public theme: Button.Theme = 'dark'

    @Input()
    public cmsTheme: ButtonThemeEnum

    /**
     * Specify the {@link Button.Layout button's layout}.
     * @default 'label-only'
     */
    @Input()
    public layout: Button.Layout = 'label-only'

    /**
     * Specify the {@link Button.State button's state}.
     * @default 'idle'
     */
    @Input()
    public state: Button.State = 'idle'

    /**
     * Specify the {@link Button.Color button's theme}.
     * @default 'dark'
     */
    @Input()
    public color?: Button.Color

    /**
     * Specify the button's icon by name. Required if the {@link ButtonComponent.layout button's layout}
     * is anything other than `'label-only'`.
     *
     * @default undefined
     */
    @Input()
    public icon?: IconNameUnion

    @Input()
    public cmsIcon?: IconEnum

    @Input()
    public iconStyle: 'default' | 'circle' = 'default'

    // <button> only bindings -------------------------------------------------------

    /**
     * Optionally specify if the button should be disabled. Applies when the
     * {@link ButtonComponent.type button's type} is either `'button'`
     * or `'submit`'. Unused when the type is `'link'`
     *
     * @default false
     */
    @Input()
    public disabled: boolean = false

    // <a> only bindings ------------------------------------------------------------

    /**
     * specify the link's routerLink destination. Applies when the
     * {@link ButtonComponent.type button's type} is `'link'`, remains unused if
     * the type is either `'button'` or `'submit`'.
     */
    @Input()
    public link: RouterLink['routerLink']

    /**
     * specify the link's href destination if it is external instead of a routerLink. Applies when the
     * {@link ButtonComponent.type button's type} is `'link'`, remains unused if
     * the type is either `'button'` or `'submit`' or {@link ButtonComponent.link} is set.
     */
    @Input()
    public href: SafeUrl

    /**
     * Optionally specify {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Link_types the link's rel values}.
     * Applies when the {@link ButtonComponent.type button's type} is `'link'`, remains unused if
     * the type is either `'button'` or `'submit`'.
     */
    @Input()
    public rel: string = ''

    /**
     * Optionally specify
     * {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#attr-target the link's target}.
     * Applies when the {@link ButtonComponent.type button's type} is `'link'`, remains unused if
     * the type is either `'button'` or `'submit`'.
     *
     * @default '_self'
     */
    @Input()
    public target: LinkTargetEnum | string = LinkTargetEnum.Self

    @Input()
    public cmsTarget: LinkTargetEnum = LinkTargetEnum.Self

    // ------------------------------------------------------------------------------
    //      Output bindings
    // ------------------------------------------------------------------------------

    @Output()
    public clicked = new EventEmitter<MouseEvent>()

    // ------------------------------------------------------------------------------
    //      Config
    // ------------------------------------------------------------------------------

    /**
     * Map that for each button size defines appropriate
     * square sizes for contained spinner icons.
     */
    public readonly spinnerSizes: { readonly [K in Button.Size]: number } = {
        smaller: 16,
        normal: 24,
        larger: 32,
    }

    // ------------------------------------------------------------------------------
    //      Derived properties
    // ------------------------------------------------------------------------------

    public classBindings: ButtonPrivate.ClassBindings

    // ------------------------------------------------------------------------------
    //      Lifecycle hooks
    // ------------------------------------------------------------------------------

    public ngOnChanges(): void {
        if (this.cmsIcon) {
            this.icon = this.CMSIconToLocal(this.cmsIcon)
        }

        if (this.cmsTarget) {
            this.target = this.CMSTargetToLocal(this.cmsTarget)
        }

        if (this.cmsTheme) {
            this.theme = this.CMSThemeToLocal(this.cmsTheme)
        }

        this.updateClassBindings()
    }

    private CMSIconToLocal(icon: IconEnum): IconNameUnion {
        return icon.toLowerCase().replace(/_/ig, '-') as IconNameUnion
    }

    private CMSTargetToLocal(target: LinkTargetEnum): any {
        return '_' + target.toLowerCase()
    }

    private CMSThemeToLocal(theme: ButtonThemeEnum): Button.Theme {
        return theme.toString().toLowerCase() as Button.Theme
    }

    // ------------------------------------------------------------------------------
    //      Derived property updates
    // ------------------------------------------------------------------------------

    private updateClassBindings(): this {
        this.classBindings = {
            'theme-dark': this.theme === 'dark',
            'theme-grey': this.theme === 'grey',
            'theme-line': this.theme === 'line',
            'theme-link': this.theme === 'link',
            'size-normal': this.size === 'normal',
            'size-larger': this.size === 'larger',
            'size-smaller': this.size === 'smaller',
            'layout-label-only': this.layout === 'label-only',
            'layout-icon-label': this.layout === 'icon-label',
            'layout-label-icon': this.layout === 'label-icon',
            'layout-icon-only': this.layout === 'icon-only',
            disabled: this.disabled,
            'style-icon-circle': this.iconStyle === 'circle',
            'text-dark': this.color === 'dark',
            'text-light': this.color === 'light',
        }

        return this
    }
}
