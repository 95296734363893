<div class="badge-container">
    <div class="badge stretch"
         *ngIf="count > 0"
         [class.grey-4]="color === 'grey-4'"
         [class.negative]="color === 'negative'"
    >
        {{ count }}
    </div>
    <ng-content></ng-content>
</div>
