<div class="header">
    <div class="content-container">
        <div class="textual-content-container">
            <div class="textual-content">
                <h2 *ngIf="subtitle"
                    class="subheading serif">
                    {{ subtitle }}
                </h2>

                <h1 class="text-white">
                    {{ title }}
                </h1>

                <div class="description-container">
                    <p *ngIf="description"
                       class="text-white"
                    >
                        {{ description  | truncateText: 300 }}
                    </p>
                </div>
            </div>

            <div *ngIf="brandImage"
                 class="brand"
            >
                <img [src]="brandImage.url"
                     alt="{{ title }}"
                     [attr.loading]="lazyLoad ? 'lazy' : 'eager'"
                >
            </div>
        </div>

        <div class="image">
            <app-responsive-image *ngIf="headerImage"
                                  [image]="headerImage"
                                  [lazyLoad]="lazyLoad"
                                  class="category-image"
            ></app-responsive-image>
        </div>
    </div>

    <div class="bg-pattern"></div>
</div>
