import { Inject, Injectable } from '@angular/core'
import { WINDOW } from '@ng-web-apis/common'
import { Subject } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class GeolocationService {
    public location$ = new Subject<GeolocationPosition>()

    constructor(
        @Inject(WINDOW)
        private window: Window,
    ) {
    }

    public getLocation(): Promise<GeolocationPosition> {
        return new Promise<GeolocationPosition>((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position: GeolocationPosition) => {
                        this.location$.next(position)
                        resolve(position)
                    },
                    () => reject('No Geolocation position available.'))
            } else {
                alert('Geolocation is not supported by this browser.')
                reject()
            }
        })
    }
}
