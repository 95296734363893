import { firstValueFrom, Observable, of, ReplaySubject, startWith, switchMap, withLatestFrom } from 'rxjs'
import { MutationResult } from 'apollo-angular'
import { catchError, map } from 'rxjs/operators'
import { Injectable } from '@angular/core'

import {
    AddToWishlistMutation,
    AddToWishlistMutationService,
    GetWishlistQueryService,
    RemoveFromWishlistMutation,
    RemoveFromWishlistMutationService,
    SimpleProductFragment,
} from '@app-graphql/schema'
import { AuthService } from '@app-services'

@Injectable({
    providedIn: 'root',
})
export class WishlistService {
    private refreshWishlistSubject = new ReplaySubject<SimpleProductFragment[]>()

    public wishlist$: Observable<SimpleProductFragment[]> = this.refreshWishlistSubject.asObservable().pipe(
        startWith(undefined),
        withLatestFrom(this.authService.user$),
        switchMap(([, user]) => user ? this.getWishlist() : of([])),
    )

    constructor(
        private addToWishlistMutationService: AddToWishlistMutationService,
        private removeFromWishlistMutationService: RemoveFromWishlistMutationService,
        private getWishlistQueryService: GetWishlistQueryService,
        private authService: AuthService,
    ) {
    }

    public addToWishlist(eanCodes: string[]): Observable<MutationResult<AddToWishlistMutation>> {
        return this.addToWishlistMutationService.mutate({
            eanCodes,
        })
    }

    public removeFromWishlist(eanCodes: string[]): Observable<MutationResult<RemoveFromWishlistMutation>> {
        return this.removeFromWishlistMutationService.mutate({
            eanCodes,
        })
    }

    public async refreshWishlist(): Promise<void> {
        const i = await firstValueFrom(this.getWishlist())
        this.refreshWishlistSubject.next(i)
    }

    private getWishlist(): Observable<SimpleProductFragment[]> {
        return this.getWishlistQueryService.fetch(undefined, {
            fetchPolicy: 'no-cache',
        }).pipe(
            catchError(() => []),
            map((result) => {
                return result.data?.me.wishListItems.map((x) => x.product) as SimpleProductFragment[]
            }),
        )
    }
}
