import { Injectable } from '@angular/core'

import { firstValueFrom } from 'rxjs'
import { AuthService } from '@app-services'
import { map, tap } from 'rxjs/operators'

@Injectable({
    providedIn: 'root',
})
export class DebtorCodeGuard {

    constructor(
        private auth: AuthService,
    ) {}

    canActivate(): Promise<boolean> {
        return firstValueFrom(
            this.auth.user$.pipe(
                tap((result) => {
                    if (result && ! result.debtorCode) {
                        this.auth.openNoDebtorDialog()
                    }
                }),
                map((user) => !! (user && user.debtorCode)),
            ),
        )
    }
}
