import { APOLLO_OPTIONS, ApolloModule, NamedOptions, APOLLO_NAMED_OPTIONS } from 'apollo-angular'
import { HttpLink } from 'apollo-angular/http'
import { onError } from 'apollo-link-error'
import { ApolloClientOptions, ApolloLink, DocumentNode, InMemoryCache, split } from '@apollo/client/core'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { createClient } from 'graphql-ws'
import { print } from 'graphql/language/printer'
import { TranslateService } from '@ngx-translate/core'
import { any } from 'ramda'

import { Dialog } from '@angular/cdk/dialog'
import { isPlatformBrowser } from '@angular/common'
import { Injector, NgModule, NgZone, PLATFORM_ID, makeStateKey, TransferState } from '@angular/core'
import { Router } from '@angular/router'


import { DialogComponent, DialogData } from '@app-domains/ui/components/dialog/dialog.component'
import { environment } from '@app-environments/environment'
import { devConsole } from '@app-lib/common.lib'
import { APOLLO_CACHE } from '@app-modules/graphql/graphql.tokens'
import { AuthStateStore, CookieAuthStorageService } from '@app-services'
import {
    createApiAuthLink,
    createApiHttpLink,
    createSubscriptionsHttpLink,
    createValidationError,
    cacheConfig,
    ValidationError,
} from '@app-modules/graphql/graphql.module.lib'

const STATE_KEY = makeStateKey<any>('apollo.state')

function createApolloSubscription(
    cache: InMemoryCache,
    httpLink: HttpLink,
    injector: Injector,
): NamedOptions {
    const platformId = injector.get(PLATFORM_ID)

    const apiLink = createSubscriptionsHttpLink(httpLink, environment)

    if (isPlatformBrowser(platformId)) {
        const wsLink = new GraphQLWsLink(createClient({
            url: `${environment.subscriptionGraphql.schema}://${environment.subscriptionGraphql.hostname}/graphql`,
        }))

        const splitLink = split(
            ({ query }) => {
                const definition = getMainDefinition(query)
                return (
                    definition.kind === 'OperationDefinition' &&
                    definition.operation === 'subscription'
                )
            },
            wsLink,
            apiLink,
        )

        return {
            subscription: {
                cache: new InMemoryCache(),
                link: splitLink,
            },
        }
    }

    return {
        subscription: {
            cache: new InMemoryCache(),
            link: apiLink,
        },
    }
}

function createApollo(
    authStorageService: AuthStateStore,
    router: Router,
    injector: Injector,
    zone: NgZone,
    transferState: TransferState,
    dialog: Dialog,
    translate: TranslateService,
    cache: InMemoryCache,
    httpLink: HttpLink,
): ApolloClientOptions<any> {
    const platformId = injector.get(PLATFORM_ID)

    const errorHandler = onError(({ response, graphQLErrors, networkError, operation }) => {
        devConsole.groupCollapsed(`%c${operation.operationName}`, 'background-color: red; color: white;')
        if (operation) {
            devConsole.error(print(operation.query as DocumentNode))
            devConsole.error(operation.variables)
        }

        if (graphQLErrors) {
            graphQLErrors.forEach((
                {
                    message,
                    locations,
                    path,
                },
            ) => {
                devConsole.error(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                )
            })
            if (response && response.errors) {
                if (! response.extensions) {
                    response.extensions = {}
                }

                if (any((e) => e.message === 'Unauthenticated.', graphQLErrors)) {
                    authStorageService.clearAuthState()
                }

                response.extensions.validationErrors = response?.errors?.reduce((acc, err) => {
                    if (err.extensions?.category === 'validation') {
                        acc.push(...createValidationError(err.extensions.validation))
                        devConsole.log('error', err.extensions.validation)
                    }
                    return acc
                }, [] as Array<ValidationError>)

                const hasServerErrors = response?.errors?.reduce((acc, err) => {
                    if (err.extensions?.category === 'internal') {
                        return true
                    }
                    return acc
                }, false)

                const currentDialog = dialog.getDialogById('server-error')

                if (! currentDialog && hasServerErrors && isPlatformBrowser(platformId)) {
                    dialog.open(DialogComponent, {
                        id: 'server-error',
                        data: {
                            title: translate.instant('alerts.no-network-title'),
                            message: translate.instant('alerts.no-network-message'),
                        } as DialogData,
                    })
                }
            }
        }

        if (networkError && isPlatformBrowser(platformId)) {
            devConsole.error(`[Network error]: ${networkError}`)
        }

        devConsole.groupEnd()
    })

    const isBrowser = isPlatformBrowser(platformId)

    if (isBrowser) {
        const state = transferState.get<any>(STATE_KEY, null)
        console.groupCollapsed('Apollo State')
        console.debug(state)
        console.groupEnd()
        cache.restore(state)
    } else {
        transferState.onSerialize(STATE_KEY, () => {
            return cache.extract()
        })
        transferState.set(STATE_KEY, cache.extract())
    }

    return {
        cache,
        connectToDevTools: true,
        link: ApolloLink.from([
            (errorHandler as unknown as ApolloLink),
            createApiAuthLink(authStorageService),
            createApiHttpLink(httpLink, environment),
        ]),
        ssrMode: true,
        defaultOptions: {
            mutate: {
                errorPolicy: 'all',
            },
            watchQuery: {
                errorPolicy: 'all',
            },
            query: {
                fetchPolicy: isPlatformBrowser(platformId) ? 'cache-first' : 'cache-only',
                errorPolicy: 'all',
            },
        },
    }
}

@NgModule({
    imports: [
        ApolloModule,
    ],
    providers: [
        {
            provide: APOLLO_CACHE,
            useValue: new InMemoryCache(cacheConfig),
        },
        {
            provide: APOLLO_NAMED_OPTIONS,
            useFactory: createApolloSubscription,
            deps: [
                APOLLO_CACHE,
                HttpLink,
                Injector,
            ],
        },
        {
            provide: APOLLO_OPTIONS,
            useFactory: createApollo,
            deps: [
                CookieAuthStorageService,
                Router,
                Injector,
                NgZone,
                TransferState,
                Dialog,
                TranslateService,
                APOLLO_CACHE,
                HttpLink,
            ],
        },
    ],
})
export class GraphQLModule { }
