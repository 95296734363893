import { Pipe, PipeTransform } from '@angular/core'
import { LocalizationFragment } from '@app-graphql/schema'

@Pipe({
    name: 'ppLocale',
})
export class PpLocalePipe implements PipeTransform {

    transform(value: LocalizationFragment[], locale: 'en' | 'de' | 'fr' | 'nl'): LocalizationFragment | undefined {
        return value.find((loc) => loc.locale === locale)
    }

}
