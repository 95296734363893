import { TranslateService } from '@ngx-translate/core'
import { shareReplay, take } from 'rxjs'
import { map } from 'rxjs/operators'
import { mergeDeepRight } from 'ramda'

TranslateService.prototype.getTranslation = function getTranslation(lang: string) {
    // @ts-ignore
    this.pending = true
    const loadingTranslations = this.currentLoader.getTranslation(lang).pipe(
        shareReplay(1),
        take(1),
    )
    // @ts-ignore
    this.loadingTranslations = loadingTranslations.pipe(
        map((res: Object) => this.compiler.compileTranslations(res, lang)),
        shareReplay(1),
        take(1),
    )
    // @ts-ignore
    this.loadingTranslations
        .subscribe({
            next: (res: Object) => {
                // @ts-ignore
                this.translations[lang] = this.extend && this.translations[lang]
                    ? mergeDeepRight(this.translations[lang], res)
                    : res
                // @ts-ignore
                this.updateLangs()
                // @ts-ignore
                this.pending = false
            },
            error: () => {
                // @ts-ignore
                this.pending = false
            },
        })

    // @ts-ignore
    return this.loadingTranslations
}
