import { Injectable } from '@angular/core'
import { SidebarQuery, SidebarQueryService } from '@app-graphql/schema'
import { Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client'

@Injectable({
    providedIn: 'root',
})
export class SidebarService {

    constructor(
        private sidebarQueryService: SidebarQueryService,
    ) {
    }

    public getSidebar(locale: string): Observable<ApolloQueryResult<SidebarQuery>> {
        return this.sidebarQueryService.fetch({ locale })
    }
}
