import { Inject, Injectable } from '@angular/core'
import { Product } from '@app-domains/content-blocks/components/product-card/product-card.types'
import { LOCAL_STORAGE } from '@ng-web-apis/common'
import { BehaviorSubject } from 'rxjs'
import { shareReplayOne, distinctUntilChangedEquals } from '@app-lib/rxjs.lib'
import { debounceTime } from 'rxjs/operators'

export interface RecentSearch {
    name: string
    ean: string
    slug: string
    queryID: string
}

@Injectable({
    providedIn: 'root',
})
export class SearchService {
    public querySubject = new BehaviorSubject<string>('')
    public query$ = this.querySubject.pipe(
        distinctUntilChangedEquals(),
        debounceTime(250),
        shareReplayOne(),
    )
    public onSearchPage$ = new BehaviorSubject<boolean>(false)

    public productResultsCount = new BehaviorSubject(0)
    public otherResultsCount = new BehaviorSubject(0)

    constructor(
        @Inject(LOCAL_STORAGE)
        private storage: Storage,
    ) {
    }

    public addRecentSearch(product: Product, queryID: string): void {
        const recent = [
            { name: product.name, ean: product.ean, slug: product.slug, queryID },
            ...JSON.parse(this.storage.getItem('recent_searches') ?? '[]'),
        ]

        this.storage.setItem('recent_searches', JSON.stringify(recent.slice(0, 3)))
    }

    public getRecentSearches(): RecentSearch[] {
        const recent = this.storage.getItem('recent_searches')
        return JSON.parse(recent ?? '[]') as RecentSearch[]
    }
}
