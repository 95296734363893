<div class="video-header"
     [class.full-size]="layout === headerLayoutEnum.FullSize"
     [class.compact]="layout === headerLayoutEnum.Compact"
     [style]="{ '--video-aspect-ratio': aspectRatio, '--video-fit': fit }"
>
    <div class="play-button">
        <app-button type="button"
                    layout="icon-only"
                    [icon]="isPlaying ? 'pause' : 'play'"
                    theme="line"
                    size="normal"
                    (click)="togglePlayback()"
                    (touchstart)="$event.stopPropagation()"
                    (pointerdown)="$event.stopPropagation()"
        ></app-button>
    </div>

    <video [attr.autoplay]="autoplay ? 'autoplay' : null"
           [attr.playsinline]="autoplay ? 'playsinline' : null"
           loop
           #videoElement
    >
        <source [src]="video.url">
        <p>
            {{ video.alt ?? video.caption }}
        </p>
    </video>
</div>
