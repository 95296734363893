import { Component } from '@angular/core'
import { AuthService } from '@app-services'

@Component({
    selector: 'app-forgot-password-success',
    templateUrl: './forgot-password-success.component.html',
    styleUrls: ['./forgot-password-success.component.scss'],
})
export class ForgotPasswordSuccessComponent {

    constructor(
        private readonly authService: AuthService,
    ) { }

    public goToLogin(): void {
        window.location.href = this.authService.getLoginUrl()
    }

}
