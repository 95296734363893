<app-grid>
    <app-cell mobile="full:0"
              [responsiveStyle$]="{
                    base: {
                        display: 'block'
                    },
                    mobile: {
                        display: 'none'
                    }
              } | responsiveStyle$"
    >
        <app-scroll-indicator></app-scroll-indicator>
    </app-cell>

    <app-cell mobile="full:full" tablet="0:12">
        <div class="cutoff">
            <app-video-player [video]="video" [layout]="layout"></app-video-player>
        </div>
    </app-cell>
</app-grid>
