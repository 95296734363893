import { Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client'
import { map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import {
    EventFragment,
    EventQuery,
    EventQueryService,
    EventQueryVariables,
    EventsQuery,
    EventsQueryService,
    EventsQueryVariables, LocalesEnum,
    Maybe,
} from '@app-graphql/schema'

@Injectable({
    providedIn: 'root',
})
export class EventsService {

    constructor(
        private eventQueryService: EventQueryService,
        private eventsQueryService: EventsQueryService,
    ) {
    }

    public getEvent(input: EventQueryVariables): Observable<ApolloQueryResult<EventQuery>> {
        return this.eventQueryService.fetch(input)
    }

    public getEvents(input: EventsQueryVariables): Observable<ApolloQueryResult<EventsQuery>> {
        return this.eventsQueryService.fetch(input)
    }

    public getEventBySlug(slug: string, locale: string): Observable<Maybe<EventFragment> | undefined> {
        return this.getEvent({ slug: slug, locale: locale as LocalesEnum }).pipe(
            map((event) => event.data.event),
        )
    }

}
