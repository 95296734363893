import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'orderByObjectIDs',
})
export class OrderByObjectIDsPipe implements PipeTransform {
    public mapOrder(array: any[], order: string[], key: string) {
        return array.sort((a, b) => {
            const objectIDA = a[key]
            const objectIDB = b[key]
            if (order.indexOf(objectIDA) > order.indexOf(objectIDB)) {
                return 1
            } else {
                return -1
            }
        })
    }

    transform(value: any[], order: string[]): any[] {
        return this.mapOrder(value, order, 'objectID')
    }

}
