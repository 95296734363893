/* eslint-disable @angular-eslint/directive-selector */

import { ContentChild, Directive, Input } from '@angular/core'
import { ExpansionPanelContentDirective } from '@app-directives/expansion-panel/expansion-panel-content.directive'
import { ExpansionPanelHeaderDirective } from '@app-directives/expansion-panel/expansion-panel-header.directive'
import { ExpansionPanelTitleDirective } from '@app-directives/expansion-panel/expansion-panel-title.directive'

@Directive({
    selector: 'app-expansion-item',
})
export class ExpansionPanelItemDirective {
    @Input() title = ''
    @Input() disabled = false
    @Input() isExpanded = false
    @ContentChild(ExpansionPanelTitleDirective) customTitle: ExpansionPanelTitleDirective
    @ContentChild(ExpansionPanelHeaderDirective) customHeader: ExpansionPanelHeaderDirective
    @ContentChild(ExpansionPanelContentDirective) content: ExpansionPanelContentDirective
}
