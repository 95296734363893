import type { Observable } from 'rxjs'
import { firstValueFrom, ReplaySubject, Subscription } from 'rxjs'
import { map } from 'rxjs/operators'
import { Locale } from 'date-fns'
import { de, enGB, fr, nl } from 'date-fns/locale'
import { TranslateService } from '@ngx-translate/core'
import { TranslateRouterService } from '@endeavour/ngx-translate-router'

import { Injectable, Injector, OnDestroy } from '@angular/core'

import { distinctUntilChangedEquals } from '@app-lib/rxjs.lib'
import { Localization, locales } from '@aa-app-localization'
import { LocalesEnum } from '@app-graphql/schema'

@Injectable()
export class LocalizationService implements OnDestroy {

    private readonly currentLangSubject = new ReplaySubject<Localization.Locale>()
    public readonly currentLocale$ = this.currentLangSubject.asObservable().pipe(
        distinctUntilChangedEquals(),
    )
    public readonly langList: Localization.LocaleList = locales
    public locale: Locale

    private translate: TranslateService
    private translateRouter: TranslateRouterService
    private onLangChangeSubscription: Subscription

    constructor(
        private readonly injector: Injector,
    ) {}

    public initialize(): void {
        this.translate = this.injector.get(TranslateService)
        this.translateRouter = this.injector.get(TranslateRouterService)

        this.onLangChangeSubscription = this.translate.onLangChange.subscribe(({ lang }) => {
            this.currentLangSubject.next(lang as Localization.Locale)
            this.locale = this.getDateLocale(lang)
        })
    }

    public ngOnDestroy(): void {
        if (this.onLangChangeSubscription) {
            this.onLangChangeSubscription.unsubscribe()
        }
    }

    private getDateLocale(lang: string): Locale {
        switch (lang) {
            case 'en':
                return enGB
            case 'nl':
                return nl
            case 'de':
                return de
            case 'fr':
                return fr
            default:
                return nl
        }
    }

    // ------------------------------------------------------------------------------
    //      Language get and set
    // ------------------------------------------------------------------------------

    public getCurrentLocale(): Localization.Locale {
        return this.translate.currentLang as Localization.Locale
    }

    public async setLocale(locale: Localization.Locale): Promise<void> {
        await this.translateRouter.changeLanguage(locale)
    }

    // ------------------------------------------------------------------------------
    //      Translations registration
    // ------------------------------------------------------------------------------

    public patchTranslations(lang: Localization.Locale, translations: object): void {
        this.translate.setTranslation(lang, translations, true)
    }

    // ------------------------------------------------------------------------------
    //      Transform data on lang change
    // ------------------------------------------------------------------------------

    public localizeStreamFactory<T, U>(transformer: (x: T, lang: Localization.Locale) => U): (x: T) => Observable<U> {
        return (x) => this.streamLocalizedData((lang) => transformer(x, lang))
    }

    public streamLocalizedData<T>(transformer: (lang: Localization.Locale) => T): Observable<T> {
        return this.currentLocale$.pipe(map(transformer))
    }

    // ------------------------------------------------------------------------------
    //      Curried translation functions
    // ------------------------------------------------------------------------------

    /**
     * A curried equivalent of {@link LocalizationService.get `get()`} that returns a new
     * function which takes the params object. When called it will return a promise for
     * the translations.
     */
    public getCurried(path: string | string[]): (params: object) => Promise<any> {
        return (params) => firstValueFrom(
            this.translate.get(path, params),
        )
    }

    /**
     * A curried equivalent of {@link LocalizationService.stream `stream()`} that returns a new
     * function which takes the params object. When called it will return an observable for
     * the translations.
     */
    public streamCurried(path: string | string[]): (params: object) => Observable<any> {
        return (params) => this.translate.stream(path, params)
    }

    /**
     * A curried equivalent of {@link LocalizationService.instant `instant()`} that returns a new
     * function which takes the params object. When called it will return the translations.
     */
    public instantCurried(path: string | string[]): (params: object) => any {
        return (params) => this.translate.instant(path, params)
    }

    // ------------------------------------------------------------------------------
    //      Translation functions
    // ------------------------------------------------------------------------------

    public get(path: string | string[], params?: object): Promise<any> {
        return firstValueFrom(
            this.translate.get(path, params),
        )
    }

    public stream(path: string | string[], params?: object): Observable<string> {
        return this.translate.stream(path, params)
    }

    public streamWithLocale(path: string | readonly string[], params?: object): Observable<{
        locale: Localization.Locale
        translations: any
    }> {
        return this.translate.stream([...path], params).pipe(
            map((translations) => ({ translations, locale: this.getCurrentLocale() })),
        )
    }

    public instant(path: string | string[], params?: object): any {
        return this.translate.instant(path, params)
    }

    public getEnumByString(value: string): LocalesEnum | undefined {
        return (Object.values(LocalesEnum) as string[]).includes(value) ? value as LocalesEnum : undefined
    }

}
