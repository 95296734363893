/* eslint-disable @angular-eslint/directive-selector */

import { Directive, TemplateRef } from '@angular/core'

@Directive({
    selector: '[expansionPanelContent]',
})
export class ExpansionPanelContentDirective {
    constructor(public templateRef: TemplateRef<any>) {}
}
