<button *ngIf="type === 'button' || type === 'submit'"
        class="button"
        [class]="classBindings"
        [disabled]="disabled || state === 'loading'"
        [type]="type"
        (click)="clicked.emit($event)"
>
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
</button>

<a *ngIf="type === 'link' && link"
   class="button"
   [class]="classBindings"
   [rel]="rel"
   [target]="target"
   (click)="clicked.emit($event)"
   [routerLink]="disabled ? undefined : link"
>
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
</a>

<a *ngIf="type === 'link' && href"
   class="button"
   [class]="classBindings"
   [rel]="rel"
   [target]="target"
   (click)="clicked.emit($event)"
   [href]="disabled ? 'javascript:void(0);' : href"
>
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
</a>

<ng-template #buttonContent>
    <span *ngIf="layout !== 'icon-only'" part="label">
        <ng-content></ng-content>
    </span>
    <span *ngIf="layout !== 'label-only'" part="icon" [appIcon]="icon"></span>

    <span class="loading-mask" *ngIf="state === 'loading'">
        <app-spinner [size]="spinnerSizes[size]" fillColor="var(--content-color)"></app-spinner>
    </span>
</ng-template>
