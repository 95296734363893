import { Injectable } from '@angular/core'

@Injectable({
    providedIn: 'root',
})
export class PageStructureDisplayService {

    public showHeader = true
    public showFooter = true

    public changeHeaderDisplay(): void {
        this.showHeader = ! this.showHeader
    }

    public changeFooterDisplay(): void {
        this.showFooter = ! this.showFooter
    }
}
