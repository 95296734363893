import { TranslateRouterModule } from '@endeavour/ngx-translate-router'
import { TranslateModule } from '@ngx-translate/core'
import { SwiperModule } from 'swiper/angular'

import { PlatformModule } from '@angular/cdk/platform'
import { PortalModule } from '@angular/cdk/portal'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'

import {
    AccordionComponent,
    AssetsComponent,
    CarouselComponent,
    ComponentWithTextComponent,
    ContactFormComponent,
    CtaLinkContentComponent,
    FeatureHighlightComponent,
    HighlightedProductsComponent,
    ImageContentComponent,
    ImageContentLinkComponent,
    ImageTextHeaderComponent,
    ImageWithTextComponent,
    OtherProductsCardComponent,
    PagesLinksComponent,
    ParagraphsComponent,
    ProductCategoriesComponent,
    ProjectShowcaseComponent,
    NewsLetterComponent,
    QuotesComponent,
    StatsComponent,
    SubfooterLinksComponent,
    TextHeaderComponent,
    UspsComponent,
    VideoContentComponent,
    VideoHeaderComponent,
    VideoPlayerComponent,
    VideoWithTextComponent,
    SubfooterAccountManagerComponent,
    ParagraphCarouselComponent,
    ImageHeaderComponent,
    ImageMosaicComponent,
    OtherProductsHitsComponent,
    OtherProductsComponent,
    ProductCardComponent,
    DataRequestFormComponent,
} from '@app-content-blocks'
import { PriceSplitPipe } from '@app-domains/content-blocks/pipes/price-split/price-split.pipe'
import { AppFormsModule } from '@app-domains/forms/app-forms.module'
import { UIModule } from '@app-domains/ui/ui.module'
import { DirectivesModule } from '@app-directives/directives.module'
import { AlgoliaModule } from '@app-domains/algolia/algolia.module'
import { PipesModule } from '@app-pipes/pipes.module'
import { OrderByObjectIDsPipe } from './pipes/order-by-object-IDs/order-by-object-ids.pipe'
import { OtherProductsGridPipe } from './pipes/other-products-grid/other-products-grid.pipe'
import { PpLocalePipe } from './pipes/pp-locale/pp-locale.pipe'
import { ComponentsModule } from '@app-domains/components/components.module'

@NgModule({
    imports: [
        AlgoliaModule,
        AppFormsModule,
        CommonModule,
        ComponentsModule,
        DirectivesModule,
        PipesModule,
        PlatformModule,
        PortalModule,
        ReactiveFormsModule,
        RouterModule,
        SwiperModule,
        TranslateModule,
        TranslateRouterModule,
        UIModule,
    ],
    declarations: [
        AccordionComponent,
        AssetsComponent,
        CarouselComponent,
        ComponentWithTextComponent,
        ContactFormComponent,
        CtaLinkContentComponent,
        DataRequestFormComponent,
        FeatureHighlightComponent,
        HighlightedProductsComponent,
        ImageContentComponent,
        ImageContentLinkComponent,
        ImageHeaderComponent,
        ImageMosaicComponent,
        ImageTextHeaderComponent,
        ImageWithTextComponent,
        OrderByObjectIDsPipe,
        OtherProductsCardComponent,
        OtherProductsComponent,
        OtherProductsGridPipe,
        OtherProductsHitsComponent,
        PagesLinksComponent,
        ParagraphCarouselComponent,
        ParagraphsComponent,
        PpLocalePipe,
        PriceSplitPipe,
        ProductCardComponent,
        ProductCategoriesComponent,
        ProjectShowcaseComponent,
        QuotesComponent,
        StatsComponent,
        SubfooterAccountManagerComponent,
        SubfooterLinksComponent,
        TextHeaderComponent,
        UspsComponent,
        VideoContentComponent,
        VideoHeaderComponent,
        VideoPlayerComponent,
        VideoWithTextComponent,
        NewsLetterComponent,
    ],
    exports: [
        AccordionComponent,
        AssetsComponent,
        CarouselComponent,
        ComponentWithTextComponent,
        ContactFormComponent,
        CtaLinkContentComponent,
        DataRequestFormComponent,
        FeatureHighlightComponent,
        HighlightedProductsComponent,
        ImageContentComponent,
        ImageContentLinkComponent,
        ImageHeaderComponent,
        ImageMosaicComponent,
        ImageTextHeaderComponent,
        ImageWithTextComponent,
        OtherProductsComponent,
        PagesLinksComponent,
        ParagraphCarouselComponent,
        ParagraphsComponent,
        PriceSplitPipe,
        ProductCardComponent,
        ProductCategoriesComponent,
        ProjectShowcaseComponent,
        QuotesComponent,
        StatsComponent,
        SubfooterAccountManagerComponent,
        SubfooterLinksComponent,
        TextHeaderComponent,
        UspsComponent,
        VideoContentComponent,
        VideoHeaderComponent,
        VideoPlayerComponent,
        VideoWithTextComponent,
        NewsLetterComponent,
    ],
})
export class ContentBlocksModule {

}
