import { always, cond, isNil, mergeLeft, prop } from 'ramda'
import { combineLatest, fromEvent, Observable, of, shareReplay, startWith } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import Bugsnag from '@bugsnag/js'

import { DOCUMENT, isPlatformBrowser } from '@angular/common'
import { Inject, Injectable, PLATFORM_ID } from '@angular/core'
import {
    Bounds,
    Breakpoint,
    MatchMediaState,
    ResponsiveStyle,
} from '@app-domains/ui/services/breakpoints/breakpoints.service.types'
import { Responsive } from '@app-types/responsive.types'

export const breakpoints: Responsive<number, true> = {
    mobile: 0,
    tablet: 768,
    laptop: 1225,
    desktop: 1610,
}

export const gutters: Responsive<number, true> = {
    mobile: 16,
    tablet: 16,
    laptop: 100,
    desktop: 100,
}

@Injectable()
export class BreakpointsService {
    public currentBreakpoint$: Observable<Breakpoint>
    public state$: Observable<MatchMediaState>

    constructor(
        @Inject(DOCUMENT) protected readonly document: Document,
        @Inject(PLATFORM_ID) protected readonly platformId: string,
    ) {
        this.state$ = combineLatest(isPlatformBrowser(this.platformId)
            ? [
                this.watchBreakpointMatch('mobile'),
                this.watchBreakpointMatch('tablet'),
                this.watchBreakpointMatch('laptop'),
                this.watchBreakpointMatch('desktop'),
            ]
            : [
                of(false), of(false), of(false), of(true),
            ]).pipe(
            filter((matches) => {
                return (matches.filter(f => f).length === 1)
            }),
            map(([mobile, tablet, laptop, desktop]): MatchMediaState => ({
                currentRange: cond<[], Breakpoint>([
                    [always(mobile), always('mobile')],
                    [always(tablet), always('tablet')],
                    [always(laptop), always('laptop')],
                    [always(desktop), always('desktop')],
                ])(),
                matches: {
                    mobile, tablet, laptop, desktop,
                },
            })),
            shareReplay({ refCount: true, bufferSize: 1 }),
        )

        this.currentBreakpoint$ = this.state$.pipe(
            map(prop('currentRange')),
        )
    }

    public getColumnWidth(number: number | Responsive<number, true>): Observable<string> {
        let values: Responsive<number, true>
        if (typeof number === 'number') {
            values = {
                mobile: number,
                laptop: number,
                tablet: number,
                desktop: number,
            }
        } else {
            values = number
        }
        return this.currentBreakpoint$.pipe(
            map((bp) => {
                switch (bp) {
                    case 'desktop':
                        const content = breakpoints.desktop - (2 * gutters.desktop)
                        return `${(content / 12) * values.desktop}px`
                    case 'laptop':
                        return `calc((100vw / 12 * ${values.laptop}) - ${2 * gutters.laptop}px)`
                    case 'mobile':
                        return `calc((100vw / 12 * ${values.mobile}) - ${2 * gutters.mobile}px)`
                    case 'tablet':
                        return `calc((100vw / 12 * ${values.tablet}) - ${2 * gutters.tablet}px)`
                }
            }),
        )
    }

    public buildResponsiveStyle(styles: ResponsiveStyle): Observable<Partial<CSSStyleDeclaration>> {
        return this.currentBreakpoint$.pipe(
            map((breakpoint) => this.getStyleForBreakpoint(styles, breakpoint)),
            map((style) => mergeLeft(style ?? {}, styles.base ?? {})),
        )
    }

    protected watchBreakpointMatch(breakpoint: Breakpoint): Observable<boolean> {
        const window = this.document.defaultView

        if (isNil(window)) {
            return of(false)
        }

        const mediaList = window.matchMedia(
            this.getCssMediaRange(breakpoint),
        )

        return fromEvent<MediaQueryList>(mediaList, 'change').pipe(
            startWith(mediaList),
            map((mediaQueryList) => mediaQueryList.matches),
        )
    }

    protected getStyleForBreakpoint(
        styles: ResponsiveStyle,
        breakpoint: Breakpoint | null,
    ): Partial<CSSStyleDeclaration> | null {
        return breakpoint
            ? styles[breakpoint] ?? null
            : null
    }

    protected getCssMediaRange(breakpoint: Breakpoint): string {
        const bounds = this.getBounds(breakpoint)

        switch (bounds.TAG) {
            case 'min-only':
                return `(min-width: ${bounds.minWidth}px)`
            case 'max-only':
                return `(max-width: ${bounds.maxWidth}px)`
            case 'min-max':
                return `(min-width: ${bounds.minWidth}px) and (max-width: ${bounds.maxWidth}px)`
        }
    }

    public nextBreakpoint(breakpoint: Breakpoint): Breakpoint | null {
        switch (breakpoint) {
            case 'mobile':
                return 'tablet'
            case 'tablet':
                return 'laptop'
            case 'laptop':
                return 'desktop'
            case 'desktop':
                return null
        }
    }

    public prevBreakpoint(breakpoint: Breakpoint): Breakpoint | null {
        switch (breakpoint) {
            case 'mobile':
                return null
            case 'tablet':
                return 'mobile'
            case 'laptop':
                return 'tablet'
            case 'desktop':
                return 'laptop'
        }
    }

    protected getBounds(breakpoint: Breakpoint): Bounds {
        const minWidth = breakpoints[breakpoint]
        const nextBreakpoint = this.nextBreakpoint(breakpoint)

        if (minWidth === 0 && nextBreakpoint === null) {
            Bugsnag.notify('Invalid configuration for MatchMedia API.')
            return { TAG: 'min-only', minWidth: 0 }
        }

        if (minWidth === 0) {
            return nextBreakpoint === null
                ? { TAG: 'min-only', minWidth: 0 }
                : { TAG: 'max-only', maxWidth: breakpoints[nextBreakpoint] - 1 }
        }

        return nextBreakpoint === null
            ? { TAG: 'min-only', minWidth: breakpoints[breakpoint] }
            : { TAG: 'min-max', minWidth: breakpoints[breakpoint], maxWidth: breakpoints[nextBreakpoint] - 1 }
    }
}
