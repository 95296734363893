<ng-container *ngIf="state && state.indices && state.indices[0] as index; else loading">
    <div *ngFor="let index of state?.indices" class="hits-other">

        <div class="title">
            <h4>
                {{ 'register.other' | translate }}
            </h4>

            <div class="hits stretch">{{ index.results.nbHits >= 100 ? '99+' : index.results.nbHits }}</div>
        </div>
        <div class="results-container">
            <ng-container *ngFor="let hit of index.hits; let i = index">
                <app-search-result
                        *ngIf="hit | asPageHit | transformPageHit as page"
                        [otherResult]="page"
                        (resultClicked)="resultClicked.emit()"
                ></app-search-result>
            </ng-container>
        </div>
    </div>
</ng-container>
<ng-template #loading>
    <app-page-spinner></app-page-spinner>
</ng-template>
