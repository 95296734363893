import { ReCaptchaV3Service } from 'ng-recaptcha-2'
import { firstValueFrom } from 'rxjs'
import Swiper, { SwiperOptions } from 'swiper'

import { Component, HostListener, Input, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'

import { SwiperLib } from '@app-lib/swiper.lib'
import { checkEmail } from '../../../../validators'
import { NewsLetterService, SwiperService } from '@app-services'
import { LocalizationService } from '@app-domains/localization/service/localization.service'
import { SnackbarService } from '@app-domains/ui/services/snackbar/snackbar.service'
import { LocalesEnum, NewsLetter } from '@app-graphql/schema'

interface NewsLetterForm {
    email: FormControl<string | null>
    interests?: FormControl<string[] | null>
}

@Component({
    selector: 'app-news-letter',
    templateUrl: './news-letter.component.html',
    styleUrls: ['./news-letter.component.scss'],
})
export class NewsLetterComponent implements OnInit {

    public formSwiper: SwiperLib
    public swiperOptions: SwiperOptions

    @Input()
    public newsLetter: NewsLetter

    @Input()
    public showInterestsSlider: boolean = false

    @Input()
    public theme: 'block' | 'no-block' = 'block'

    public form: FormGroup<NewsLetterForm> = this.formBuilder.group({
        email: this.formBuilder.control<string>('', [
            Validators.required,
            checkEmail,
        ]),
    })

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly swiperService: SwiperService,
        private readonly newsLetterService: NewsLetterService,
        private readonly snackbarService: SnackbarService,
        private readonly recaptchaV3Service: ReCaptchaV3Service,
        private readonly localizationService: LocalizationService,
    ) {
    }

    public ngOnInit(): void {
        this.swiperOptions = {
            slidesPerView: 'auto',
            spaceBetween: 8,
            loop: false,
            direction: 'horizontal',
        }
    }

    public async onSubmit(): Promise<void> {
        if (this.form.invalid) {
            this.form.markAsDirty()
            return
        }

        const locale = await firstValueFrom(this.localizationService.currentLocale$)
        const localeEnum = this.localizationService.getEnumByString(locale) as LocalesEnum

        this.recaptchaV3Service
            .execute('submit')
            .subscribe(async (token: string) => {
                const result = await firstValueFrom(this.newsLetterService.registerForNewsLetter({
                    input: {
                        email: this.form.controls.email.value!,
                        type: this.newsLetter.category,
                        locale: localeEnum,
                        isNotARobot: token,
                    },
                }))

                if (result.errors) {
                    console.error(result.errors)

                    this.snackbarService.create({
                        content: 'common.error',
                        dismissible: true,
                    }, 'bottom')

                    return
                }

                if (result.data) {
                    this.snackbarService.create({
                        content: 'news-letter.sent-success',
                        dismissible: true,
                    }, 'bottom')
                }
            })
    }

    public onSwiper(swiper: Swiper): void {
        this.formSwiper = this.swiperService.create(swiper)
    }

    @HostListener('window:resize')
    public onResize(): void {
        if (this.showInterestsSlider) {
            this.formSwiper.updateTotalIndex()
        }
    }
}
