import SwiperCore, { Autoplay, Controller, SwiperOptions } from 'swiper'

import { Component, Input, ViewEncapsulation } from '@angular/core'

import { HeaderLayoutEnum, ImageComponentFragment } from '@app-graphql/schema'

SwiperCore.use([
    Autoplay,
    Controller,
])

@Component({
    selector: 'app-image-header',
    templateUrl: './image-header.component.html',
    styleUrls: ['./image-header.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class ImageHeaderComponent {
    @Input()
    public images: ImageComponentFragment[] = []

    @Input()
    public title: string

    @Input()
    public subtitle: string | null | undefined

    @Input()
    public layout: HeaderLayoutEnum | null | undefined = HeaderLayoutEnum.Compact

    @Input()
    public lazyLoad: boolean = true

    public swiperConfig: SwiperOptions = {
        slidesPerView: 1,
        autoplay: {
            delay: 4000,
        },
        centeredSlides: true,
        autoHeight: true,
        loop: true,
    }

    public headerLayoutEnum = HeaderLayoutEnum
}
