<app-grid>
    <app-cell mobile="full:full" tablet="6:full">
        <div class="form-container"
             [ngClass]="theme === 'block' ? 'block' : 'no-block'"
        >
            <div class="form">
                <div class="left-pattern"></div>

                <div class="content">
                    <h2 class="text-color title">
                        {{ newsLetter.title }}
                    </h2>

                    <label *ngIf="newsLetter.subtitle"
                           class="text-color"
                    >
                        {{ newsLetter.subtitle }}
                    </label>

                    <div *ngIf="showInterestsSlider && form.controls.interests"
                         class="filters"
                    >
                        <swiper [config]="swiperOptions"
                                (swiper)="onSwiper($event)"
                        >
                            <ng-template swiperSlide>
                                <app-multi-select [control]="form.controls.interests"
                                                  value="products"
                                >
                                    <span appIcon="products" class="text-color"></span>
                                    <span class="text-color stretch">{{ 'common.products' | translate }}</span>
                                </app-multi-select>
                            </ng-template>

                            <ng-template swiperSlide>
                                <app-multi-select [control]="form.controls.interests"
                                                  value="projects"
                                >
                                    <span appIcon="projects" class="text-color"></span>
                                    <span class="text-color stretch">{{ 'common.projects' | translate }}</span>
                                </app-multi-select>
                            </ng-template>

                            <ng-template swiperSlide>
                                <app-multi-select [control]="form.controls.interests"
                                                  value="events"
                                >
                                    <span appIcon="events" class="text-color"></span>
                                    <span class="text-color stretch">{{ 'common.events' | translate }}</span>
                                </app-multi-select>
                            </ng-template>

                            <ng-template swiperSlide>
                                <app-multi-select [control]="form.controls.interests"
                                                  value="news"
                                >
                                    <span appIcon="user" class="text-color"></span>
                                    <span class="text-color stretch">{{ 'common.news' | translate }}</span>
                                </app-multi-select>
                            </ng-template>
                        </swiper>
                    </div>

                    <div class="field">
                        <app-input
                            [control]="form.controls.email"
                            type="email"
                            placeholder="{{ 'register.email'|translate }}"
                        ></app-input>

                        <app-button (click)="onSubmit()"
                                    [theme]="theme === 'block' ? 'grey' : 'dark'"
                        >
                            {{ 'common.submit' | translate }}
                        </app-button>
                    </div>
                </div>
            </div>
        </div>
    </app-cell>
</app-grid>
