import { isPlatformBrowser } from '@angular/common'
import {
    Directive,
    ElementRef,
    Inject,
    Input,
    OnChanges,
    PLATFORM_ID,
    Renderer2,
    SimpleChanges,
} from '@angular/core'

import { IconEnum } from '@app-graphql/schema'
import { IconNameUnion } from './icon.directive.types'

@Directive({
    selector: '[appIcon], [appIconEnum]',
})
export class IconDirective implements OnChanges {

    @Input()
    public appIcon?: IconNameUnion

    @Input()
    public appIconEnum?: IconEnum

    constructor(
        private readonly element: ElementRef<HTMLElement>,
        private readonly renderer: Renderer2,
        @Inject(PLATFORM_ID) protected platformId: string,
    ) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (isPlatformBrowser(this.platformId)) {
            if (changes.appIconEnum && changes.appIconEnum.previousValue !== changes.appIconEnum.currentValue) {
                changes.appIcon = {
                    previousValue: this.appIcon,
                    currentValue: this.CMSIconToLocal(changes.appIconEnum.currentValue),
                    firstChange: !! this.appIcon,
                    isFirstChange: () => {
                        return !! this.appIcon
                    },
                }

                this.appIcon = this.CMSIconToLocal(changes.appIconEnum.currentValue)
            }
            if (changes.appIcon && changes.appIcon.previousValue !== changes.appIcon.currentValue) {
                this.renderer.addClass(this.element.nativeElement, 'icon')
                this.renderer.removeClass(this.element.nativeElement, `icon-${changes.appIcon.previousValue}`)
                this.renderer.addClass(this.element.nativeElement, `icon-${changes.appIcon.currentValue}`)
            }
        } else {
            this.element.nativeElement.style.opacity = '0'
        }
    }

    private CMSIconToLocal(icon: IconEnum): IconNameUnion {
        return icon.toLowerCase().replace(/_/ig, '-') as IconNameUnion
    }

}
