import { Component, EventEmitter, Input, Output } from '@angular/core'
import { CategoryDropdown } from '@app-domains/navigation/category-dropdown/category-dropdown'
import { MenuDropdown } from '@app-domains/navigation/menu-dropdown/menu-dropdown'
import { UpdateProfileService } from '@app-services'
import { LocalesEnum } from '@app-graphql/schema'
import { LocalizationService } from '@app-domains/localization/service/localization.service'

@Component({
    selector: 'app-subcategory-header',
    templateUrl: './subcategory-header.component.html',
    styleUrls: ['./subcategory-header.component.scss'],
})
export class SubcategoryHeaderComponent {

    @Input()
    public categoryWithSubcategory: CategoryDropdown

    @Input()
    public sectionWithSubcategory: MenuDropdown

    @Input()
    public includeMainPageUrl: boolean = true

    @Input()
    public categoryUppercase: boolean = false

    @Output()
    public linkClicked = new EventEmitter()

    public readonly LocalesEnum = LocalesEnum

    constructor(
        public updateProfileService: UpdateProfileService,
        public readonly localization: LocalizationService,
    ) {
    }

    public onLinkClick(): void {
        this.linkClicked.emit()
    }

    @Input()
    public set mobileMenuIsOpen(isOpen: boolean) {
        this.internalMobileMenuIsOpen = isOpen
        if (! isOpen) {
            this.subcategoryIsOpen = false
        }
    }

    public get mobileMenuIsOpen(): boolean {
        return this.internalMobileMenuIsOpen
    }

    public internalMobileMenuIsOpen: boolean
    public subcategoryIsOpen: boolean = false

    public toggleSubcategory(): void {
        this.subcategoryIsOpen = ! this.subcategoryIsOpen
    }
}
