import { Component, Input } from '@angular/core'

import { AuthService, DownloadService, DownloadTrackerService } from '@app-services'

@Component({
    selector: 'app-download-manager',
    templateUrl: './download-manager.component.html',
    styleUrls: ['./download-manager.component.scss'],
})
export class DownloadManagerComponent {

    @Input()
    public layout: 'mobile' | 'desktop' = 'desktop'

    constructor(
        public readonly authService: AuthService,
        public readonly downloadService: DownloadService,
        public readonly downloadTrackerService: DownloadTrackerService,
    ) {
    }
}
