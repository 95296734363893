import { Injectable } from '@angular/core'

@Injectable({
    providedIn: 'root',
})
export class ScrollService {

    public scrollToTop(): void {
        window.scrollTo({
            behavior: 'smooth',
            top: 0,
            left: 0,
        })
    }

    public scrollToElementId(id: string): void {
        document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' })
    }
}
