import { filter, map, tap } from 'rxjs/operators'
import { firstValueFrom, Observable, takeWhile, startWith } from 'rxjs'
import { forwardRef, Inject, Injectable } from '@angular/core'
import { Dialog } from '@angular/cdk/dialog'
import { AuthService, DownloadTrackerService } from '@app-services'
import { ProductDownloadConfigModalComponent } from '@app-domains/product/components/product-download-config-modal/product-download-config-modal.component'
import {
    DownloadInput,
    DownloadProgress,
    DownloadProgressSubscriptionService,
    DownloadRequestedMutationService,
} from '@app-graphql/schema'

@Injectable({
    providedIn: 'root',
})
export class DownloadService {
    public download: DownloadProgress

    constructor(
        private downloadMutationService: DownloadRequestedMutationService,
        private downloadSubscriptionService: DownloadProgressSubscriptionService,
        private dialog: Dialog,
        private authService: AuthService,
        @Inject(forwardRef(() => DownloadTrackerService))
        private readonly downloadTrackerService: DownloadTrackerService,
    ) {
    }

    public async initialize(): Promise<void> {
        const pendingDownloads = this.downloadTrackerService.getPendingDownloads()

        if (pendingDownloads.length === 0) {
            return
        }

        const debtorCode = await firstValueFrom(this.authService.user$).then((user) => user?.debtorCode)

        if (! debtorCode) {
            return
        }

        pendingDownloads.forEach((pendingDownloadId: string) => {
            const subscription = this.subscribeToDownload(
                pendingDownloadId,
                debtorCode,
            )

            this.addDownload(subscription)
        })

        return
    }

    public requestDownload(downloadInput: DownloadInput): Observable<string | undefined> {
        return this.downloadMutationService.mutate({
            input: downloadInput,
        }).pipe(
            map((res) => res.data?.downloadRequested),
        )
    }

    public subscribeToDownload(downloadId: string, debtorId: string): Observable<DownloadProgress> {
        return this.downloadSubscriptionService.subscribe({
            downloadId,
            debtorId,
        }).pipe(
            startWith({ data: { downloadProgressed: { percentage: 0 } } }),
            tap(() => this.downloadTrackerService.registerDownload(downloadId)),
            map((result) => result.data?.downloadProgressed),
            filter((progress): progress is DownloadProgress => !! progress),
            takeWhile((progress) => {
                if (progress.url) {
                    this.downloadTrackerService.unRegisterDownload(downloadId)

                    window.location.href = progress.url
                }

                return ! progress.url
            }, true),
        )
    }

    public addDownload(dl: Observable<DownloadProgress>): void {
        dl.subscribe((downloadProgress) => {
            this.download = downloadProgress
        })
    }

    public async openProductDownloadConfigModal(identifiers: string[]): Promise<void> {
        const dialog = this.dialog.open<DownloadInput | undefined>(
            ProductDownloadConfigModalComponent,
        )

        const result = await firstValueFrom(dialog.closed)

        if (result) {
            const user = await firstValueFrom(this.authService.user$)
            const downloadId = await firstValueFrom(this.requestDownload({
                ...result,
                identifiers,
            }))

            if (! downloadId || ! user?.debtorCode) {
                return
            }

            const subscription = this.subscribeToDownload(
                downloadId,
                user.debtorCode,
            )

            this.addDownload(subscription)
        }
    }
}
