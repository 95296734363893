import { Observable } from 'rxjs'

import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core'

import { Breakpoint } from '@app-domains/ui/services/breakpoints/breakpoints.service.types'
import { TupleToUnion } from '@app-types/common.types'

type Bounds = ['full', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', 'full']
type Bound = TupleToUnion<Bounds>

export type BreakpointConfig<T extends string[] = Bounds, TAcc extends string = never> =
  T extends [infer THead, ...infer TTail]
      ? THead extends string
          ? TTail extends string[]
              ? BreakpointConfig<TTail, TAcc | BreakpointConfigString<THead, TTail>>
              : never
          : never
      : TAcc

type BreakpointConfigString<TStart extends string, TEnds extends string[], TAcc extends string = never> =
    TEnds extends [infer THead, ...infer TTail]
        ? THead extends string
            ? TTail extends string[]
                ? BreakpointConfigString<TStart, TTail, TAcc | `${TStart}:${THead}`>
                : never
            : never
        : TAcc

@Component({
    selector: 'app-cell',
    templateUrl: './cell.component.html',
    styleUrls: ['./cell.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class CellComponent implements OnChanges {
    @Input()
    public mobile?: BreakpointConfig

    @Input()
    public tablet?: BreakpointConfig

    @Input()
    public laptop?: BreakpointConfig

    @Input()
    public desktop?: BreakpointConfig

    public classList: string

    @Input()
    public responsiveStyle$: Observable<{ [k: string]: any }>

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.mobile || changes.tablet || changes.laptop || changes.desktop) {
            const classes = []
            if (this.mobile) {
                classes.push(...CellComponent.processConfig(this.mobile, 'mobile'))
            }
            if (this.tablet) {
                classes.push(...CellComponent.processConfig(this.tablet, 'tablet'))
            }
            if (this.laptop) {
                classes.push(...CellComponent.processConfig(this.laptop, 'laptop'))
            }
            if (this.desktop) {
                classes.push(...CellComponent.processConfig(this.desktop, 'desktop'))
            }

            this.classList = classes.join(' ')
        }
    }

    public static processConfig(config: BreakpointConfig, breakpoint: Breakpoint) {
        const [start, end] = CellComponent.parseBreakpointString(config)
        return [
            CellComponent.generateClass('start', start, breakpoint),
            CellComponent.generateClass('end', end, breakpoint),
        ]
    }

    public static generateClass(
        type: 'start' | 'end',
        value: Bound,
        breakpoint?: Breakpoint,
    ): string {
        return `col-${value}-${type}${breakpoint
            ? `-${breakpoint}`
            : ''}`
    }

    public static parseBreakpointString(
        s: BreakpointConfig,
    ): [Bound, Bound] {
        return s.split(':') as unknown as [Bound, Bound]
    }
}
