import { filter, map } from 'rxjs/operators'
import { firstValueFrom, from, Observable, withLatestFrom } from 'rxjs'

import { Injectable } from '@angular/core'

import { LocalizationService } from '@app-domains/localization/service/localization.service'
import { LocalizedRouting } from '@app-domains/navigation/types/navigation.types'
import {
    BrandFragment,
    BrandQueryService,
    BrandsQueryService,
    CompleteBrandFragment,
} from '@app-graphql/schema'
import { locales, Localization } from '@aa-app-localization'
import { translateBrands } from '@app-lib/brand.lib'

@Injectable({
    providedIn: 'root',
})
export class BrandsService {
    private brands: readonly BrandFragment[]

    constructor(
        public readonly localization: LocalizationService,
        public readonly brandsQueryService: BrandsQueryService,
        public readonly brandQueryService: BrandQueryService,
    ) {
    }

    public all(): readonly BrandFragment[] {
        return this.brands
    }

    public async initialize(): Promise<void> {
        this.brands = await this.fetchBrands()

        type T = Record<Localization.Locale, Record<string, string>>

        const xs = this.brands.reduce<T>((acc, cat) => {
            for ( const locale of this.localization.langList ) {
                acc[locale][cat.id] = cat.name
            }
            return acc
        }, { en: {}, nl: {}, de: {}, fr: {} })

        for (const locale of locales) {
            this.localization.patchTranslations(locale, {
                ROUTES: xs[locale],
            })
        }
    }

    public findBySlug(slug: string): BrandFragment | null {
        const brands = this.all()
        return brands.find((x: BrandFragment) => slug.toLowerCase() === x.name.toLowerCase()) ?? null
    }

    private async fetchBrands(): Promise<BrandFragment[]> {
        const brands = await firstValueFrom(this.brandsQueryService
            .fetch()
            .pipe(
                map(({ data }) => data?.brands),
            ))

        return brands?.length ? brands as BrandFragment[] : []
    }

    public fetchBrand(slug: string): Observable<CompleteBrandFragment> {
        return this.brandQueryService.fetch({ slug })
            .pipe(
                map(({ data }) => data.brand),
                filter((brand): brand is CompleteBrandFragment => !! brand),
            )
    }

    public getTranslatedBrands(): Observable<LocalizedRouting[]> {
        return from(this.fetchBrands()).pipe(
            withLatestFrom(this.localization.currentLocale$),
            map(([brands, lang]) => brands.map(
                (brand: BrandFragment) => translateBrands(brand, lang)),
            ),
        )
    }
}
