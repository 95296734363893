import { Pipe, PipeTransform } from '@angular/core'
import { Observable } from 'rxjs'
import { BreakpointsService } from '@app-domains/ui/services/breakpoints/breakpoints.service'
import { ResponsiveStyle } from '@app-domains/ui/services/breakpoints/breakpoints.service.types'

@Pipe({
    name: 'responsiveStyle$',
})
export class ResponsiveStylePipe implements PipeTransform {
    constructor(
        private matchMediaService: BreakpointsService,
    ) {
    }

    transform(style: ResponsiveStyle): Observable<{ [k: string]: any }> {
        return this.matchMediaService.buildResponsiveStyle(style)
    }

}
