import { map } from 'rxjs/operators'
import { Component, Inject } from '@angular/core'
import { firstValueFrom, withLatestFrom } from 'rxjs'

import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog'

import { ShoppingCartLineFragment, ShoppingCartProductFragment } from '@app-graphql/schema'
import {
    AuthService,
    CheckoutService,
    WishlistService,
} from '@app-services'
import { DialogData } from '@app-domains/ui/components/dialog/dialog.component'
import { ExposeEnums } from '../../../../helpers/expose-enums.component'
import { LocalizationService } from '@app-domains/localization/service/localization.service'
import { TranslationKey } from '@app-pipes/typed-translate/typed-translate.pipe'
import { SnackbarService } from '@app-domains/ui/services/snackbar/snackbar.service'

@Component({
    selector: 'app-added-to-cart-dialog',
    templateUrl: './added-to-cart-dialog.component.html',
    styleUrls: ['./added-to-cart-dialog.component.scss'],
})
export class AddedToCartDialogComponent extends ExposeEnums {

    public shoppingCartWithAddedEansFiltered$ = this.checkoutService.shoppingCart$.pipe(
        withLatestFrom(this.checkoutService.addedToShoppingCart$),
        map(([shoppingCart, shoppingCartLinesAdded]) => {
            return shoppingCartLinesAdded.map((line) => {
                const matchingProduct = shoppingCart.cartLine.filter((r) => {
                    return r.product!.ean === line.ean
                })[0] as ShoppingCartLineFragment

                if (! matchingProduct) {
                    return
                }

                return {
                    product: matchingProduct.product,
                    price: matchingProduct.price,
                    addedQuantity: line.quantity,
                }
            }) as {
                product: ShoppingCartProductFragment
                price: number | null | undefined
                addedQuantity: number | null | undefined
            }[]
        }),
    )

    constructor(
        public checkoutService: CheckoutService,
        public dialogRef: DialogRef<string>,
        @Inject(DIALOG_DATA)
        public data: DialogData,
        public readonly authService: AuthService,
        public localizationService: LocalizationService,
        private wishListService: WishlistService,
        private snackbarService: SnackbarService,
    ) {
        super()
    }

    public closeDialog(): void {
        this.checkoutService.outOfStockProductsPopup$.next([])
        this.dialogRef.close()
    }

    public async addToWishlist(): Promise<void> {
        const products = await firstValueFrom(this.checkoutService.outOfStockProductsPopup$)
        const eans = products.map((item) => item.ean) as string[]
        const result = await firstValueFrom(this.wishListService.addToWishlist(eans))

        if (result.errors) {
            this.createSnackbar('common.error')
            return
        }

        this.createSnackbar('products.added-to-wishlist')
        await this.wishListService.refreshWishlist()
        this.closeDialog()
    }

    private createSnackbar(translation: TranslationKey) {
        this.snackbarService.create({
            content: translation,
            autoHideDelay: 3000,
        }, 'bottom')
    }
}
