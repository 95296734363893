import { Subscription } from 'rxjs'
import { filter } from 'rxjs/operators'
import { WINDOW } from '@ng-web-apis/common'
import { DOCUMENT } from '@angular/common'
import { Inject, Injectable, OnDestroy } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { Maybe, SeoFragment, SocialFragment, SocialNetworkEnum } from '@app-graphql/schema'
import { Meta, MetaDefinition, Title } from '@angular/platform-browser'
import { environment } from '@app-environments/environment'
import { seoDataStaticPages } from './seo.types'
import { LocalizationService } from '@app-domains/localization/service/localization.service'

@Injectable({
    providedIn: 'root',
})
export class SeoService implements OnDestroy {
    private routerSubscription: Subscription
    private seoDataOfStaticPages = seoDataStaticPages

    constructor(
        private title: Title,
        private meta: Meta,
        @Inject(DOCUMENT) private document: Document,
        @Inject(WINDOW) private window: Window,
        private router: Router,
        private readonly localizationService: LocalizationService,
    ) {
        this.routerSubscription = this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
        ).subscribe(() => {
            this.setSeoData()
        })
    }

    public ngOnDestroy() {
        this.routerSubscription.unsubscribe()
    }

    public setSeoData(seoSet?: SeoFragment) {
        this.setTitle(seoSet?.title)
        this.setDescription(seoSet?.description?.slice(0, 155))
        this.buildSocials(seoSet)
        if (seoSet?.keywords) {
            this.setKeywords(seoSet?.keywords)
        }
        this.setCanonicalUrl(seoSet?.canonicalUrl)
        this.setRobots(seoSet?.robots)
        this.setImage(seoSet?.image?.url)
    }

    public getSeoDataPage(page: string): SeoFragment {
        const seoData = this.seoDataOfStaticPages.find(data => page === data.id)
        return seoData!.data[this.localizationService.getCurrentLocale()]
    }

    public setSeoDataPage(page: string) {
        const seoData = this.getSeoDataPage(page)
        this.setSeoData(seoData)
    }

    private buildSocials(seoData?: SeoFragment) {
        const twitterData = seoData
            ? {
                ...this.buildSocialsDefault(seoData, SocialNetworkEnum.Twitter),
                ...seoData.socials.find((s) => s.socialNetwork === SocialNetworkEnum.Twitter),
            }
            : undefined
        const facebookData = seoData
            ? {
                ...this.buildSocialsDefault(seoData, SocialNetworkEnum.Facebook),
                ...seoData.socials.find((s) => s.socialNetwork === SocialNetworkEnum.Facebook),
            }
            : undefined

        this.setTwitterData(twitterData)
        this.setFacebookData(facebookData)
    }

    private setTwitterData(seoData?: Maybe<SocialFragment>) {
        this.setMetaField('twitter:title', seoData?.title + ' | De Eekhoorn')
        this.setMetaField('twitter:image:alt', seoData?.image?.alt)
        this.setMetaField('twitter:image', seoData?.image?.url)
        this.setMetaField('twitter:description', seoData?.description?.slice(0, 155))
        this.setMetaField('twitter:site', '@DeEekhoornWoood')
        this.setMetaField('twitter:creator', '@DeEekhoornWoood')
        this.setMetaField('twitter:card', 'summary')
    }

    private setFacebookData(seoData?: Maybe<SocialFragment>) {
        this.setMetaField('og:title', seoData?.title + ' | De Eekhoorn')
        this.setMetaField('og:url', this.window.location.href, ['property'])

        this.setMetaField('og:image:alt', seoData?.image?.alt ?? seoData?.title)
        this.setMetaField('og:image:url', seoData?.image?.url)
        this.setMetaField('og:image:secure_url', seoData?.image?.url)
        this.setMetaField('og:image:width', seoData?.image?.width.toString())
        this.setMetaField('og:image:height', seoData?.image?.height.toString())
        this.setMetaField('og:image', seoData?.image?.url)

        this.setMetaField('og:description', seoData?.description?.slice(0, 155))
    }

    private buildSocialsDefault(seoData: SeoFragment, network: SocialNetworkEnum): SocialFragment {
        return {
            title: seoData.title,
            description: seoData.description?.slice(0, 155),
            image: seoData.image,
            socialNetwork: network,
        }
    }

    public setTitle(title?: Maybe<string>): void {
        const shortTitle = `${environment.title}${title ? ` | ${title}` : ''}`.slice(0, 155)
        this.title.setTitle(shortTitle)

        this.setMetaField('title', shortTitle)
        this.setMetaField('name', shortTitle, ['itemprop'])
    }

    private setKeywords(keywords: string): void {
        this.setMetaField('keywords', keywords)
    }

    private setRobots(robots?: Maybe<string>): void {
        this.setMetaField('robots', robots)
    }

    private setCanonicalUrl(url?: string | null): void {
        const selector = 'link[rel=\'canonical\']'
        const canonicalElement = this.document.head.querySelector(selector)

        if (canonicalElement) {
            this.document.head.removeChild(canonicalElement)
        }

        if (url && url.length) {
            const link: HTMLLinkElement = this.document.createElement('link')
            link.setAttribute('rel', 'canonical')
            link.setAttribute('href', url)
            this.document.head.appendChild(link)
        }
    }

    private setImage(image?: Maybe<string>): void {
        this.setMetaField('image', image, ['itemprop'])
    }

    private setDescription(description?: Maybe<string>) {
        this.setMetaField('description', description, ['name', 'itemprop'])
    }

    public setMetaField(
        field: string,
        value?: Maybe<string>,
        tagNames: ('name' | 'itemprop' | 'property')[] = ['name'],
    ) {
        if (value) {
            tagNames.forEach((tag) => {
                const update: MetaDefinition = {
                    [tag]: field,
                    content: value,
                }
                this.meta.updateTag(update, tag === 'itemprop' ? `itemprop='${field}'` : undefined)
            })
        } else {
            tagNames.forEach((tag) => {
                const selector = `${tag}='${field}'`
                this.meta.removeTag(selector)
            })
        }
    }
}
