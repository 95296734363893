<div *ngFor="let error of errors"
     class="error"
>
    <ng-container *ngIf="error.toString() !== 'true'">
        <ng-container *ngIf="'graphql-errors.' + (error | lowercase) | translate as translated">
            <ng-container *ngIf="translated !== 'graphql-errors.' + error.toLowerCase()">
                {{ translated }}
            </ng-container>
        </ng-container>
    </ng-container>
</div>


