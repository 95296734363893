import { reverse } from 'ramda'
import { Observable, scan, startWith, Subject, switchMap } from 'rxjs'
import { filter, map, tap } from 'rxjs/operators'

import { Injectable } from '@angular/core'
import { NavigationStart, Router } from '@angular/router'

import { SnackbarHost } from '@app-domains/ui/components/snackbar-host/snackbar-host.component'
import { DownloadProgress } from '@app-graphql/schema'
import { TranslationKey } from '@app-pipes/typed-translate/typed-translate.pipe'

export type SnackbarConfig = DismissibleSnackbarConfig | AutoHideSnackbarConfig | DownloadSnackbarConfig

interface DismissibleSnackbarConfig {
    content: TranslationKey
    dismissible: true
}

interface AutoHideSnackbarConfig {
    content: TranslationKey
    autoHideDelay: number
}

interface DownloadSnackbarConfig {
    download$: Observable<DownloadProgress>
    type: 'orders' | 'products'
}

export interface InternalSnackbarConfig {
    userConfig: SnackbarConfig
    host: string
    close: Subject<boolean>
    closed: Observable<boolean>
}

@Injectable({
    providedIn: 'root',
})
export class SnackbarService {

    public snackbarSubject = new Subject<SnackbarRef>()

    constructor(
        private router: Router,
    ) {
    }

    public listen(name: string): Observable<SnackbarRef[]> {
        return this.snackbarSubject.pipe(
            scan((acc, val) => {
                if (val.host === name) {
                    acc.push(val)
                }
                return acc
            }, [] as SnackbarRef[] ),
            map((snackbars) => reverse(snackbars)),
            switchMap((snackbars) => this.router.events.pipe(
                filter((e) => e instanceof NavigationStart),
                startWith(undefined),
                tap((event) => {
                    if (event) {
                        snackbars.forEach((s) => s.close())
                    }
                }),
                map(() => snackbars),
            )),
        )
    }

    public create(config: SnackbarConfig, name: SnackbarHost): SnackbarRef {
        const close = new Subject<boolean>()
        const sbRef = new SnackbarRef({
            userConfig: config,
            host: name,
            close,
            closed: close.pipe(
                startWith(false),
            ),
        }, close)

        this.snackbarSubject.next(sbRef)

        return sbRef
    }
}

const isDismissible = (
    config: unknown,
): config is DismissibleSnackbarConfig => (config as DismissibleSnackbarConfig).dismissible !== undefined

export class SnackbarRef {
    public readonly content: TranslationKey
    public readonly autoHideDelay?: number
    public readonly dismissible?: boolean
    public readonly host: string
    public readonly closed: Observable<boolean>
    public readonly type: 'orders' | 'products'
    public readonly typeLabel: TranslationKey

    constructor(
        private config: InternalSnackbarConfig,
        private closeSubject: Subject<boolean>,
    ) {
        if ('content' in config.userConfig) {
            this.content = config.userConfig.content
        }

        if ('autoHideDelay' in config.userConfig) {
            this.autoHideDelay = isDismissible(config.userConfig)
                ? undefined
                : config.userConfig.autoHideDelay
        }

        if ('type' in config.userConfig) {
            this.type = config.userConfig.type
            this.typeLabel = `common.${this.type}`
        }

        this.dismissible = isDismissible(config.userConfig) ? config.userConfig.dismissible : undefined
        this.host = config.host
        this.closed = config.closed
    }

    public close(): void {
        this.closeSubject.next(true)
    }

}
