import {
    AbstractControl,
    ValidationErrors,
} from '@angular/forms'

export const checkEmail = (control: AbstractControl): ValidationErrors | null => {
    const { value } = control
    if (value) {
        const valid = (/^([A-Za-z0-9_\-.])+((\+([A-Za-z0-9_\-.])+)?)@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/).test(value)
        if (! valid) {
            return { emailNotValid: 'emailNotValid' }
        }
        return null
    }
    return null
}
