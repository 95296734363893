import { animate, style, transition, trigger } from '@angular/animations'
import { Component, Input, OnInit } from '@angular/core'
import { SnackbarRef, SnackbarService } from '@app-domains/ui/services/snackbar/snackbar.service'
import { Observable } from 'rxjs'

export type SnackbarHost = 'bottom'

@Component({
    selector: 'app-snackbar-host',
    templateUrl: './snackbar-host.component.html',
    styleUrls: ['./snackbar-host.component.scss'],
    animations: [
        trigger('fadeIn', [
            transition(':enter', [
                style({
                    opacity: 0,
                    transform: 'translateY(100%)',
                }),
                animate('250ms cubic-bezier(0.25, 1, 0.5, 1)', style({
                    opacity: 1,
                    transform: 'translateY(0)',
                })),
            ]),
            transition(':leave', [
                animate('250ms cubic-bezier(0.25, 1, 0.5, 1)', style({
                    opacity: 0,
                    transform: 'translateY(100%)',
                })),
            ]),
        ]),
    ],
})
export class SnackbarHostComponent implements OnInit {

    @Input()
    public name: SnackbarHost

    public snackBars$: Observable<SnackbarRef[]>

    constructor(
        private snackBar: SnackbarService,
    ) { }

    public ngOnInit(): void {
        this.snackBars$ = this.snackBar.listen(this.name)
    }

}
