<div>
    <ng-container *ngIf="openCloseVariant">
        <div class="open-close-container">
            <div class="search"
                 [ngClass]="{
                        'open'   : searchIsOpen,
                        'closed' : navigationIsCollapsed
                    }">

                <button (click)="openCloseSearch()" class="search-icon">
                    <span appIcon="search"></span>
                </button>

                <div [cdkMenuTriggerFor]="(searchService.onSearchPage$ | async) ? noSearch : searchResults"
                     class="search-input"
                >
                    <input [formControl]="search"
                           (blur)="onBlur()"
                           (keydown.space)="$event.stopPropagation();"
                           (keydown.enter)="navigateToSearch()"
                           type="search"
                           placeholder="{{'navigation.looking-for' | translate}}"
                           #openCloseInput
                    />
                </div>

                <button (click)="openCloseSearch()" class="close-icon">
                    <span appIcon="close"></span>
                </button>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="! openCloseVariant">
        <app-input [control]="search"
                   [cdkMenuTriggerFor]="(searchService.onSearchPage$ | async) ? undefined : searchResults"
                   (blur)="onBlur()"
                   (keydown.enter)="navigateToSearch()"
                   placeholder="{{'navigation.looking-for' | translate}}"
                   icon="search"
                   theme="search"
                   size="smaller"
                   class="block-page-scroll-on-focus"
        ></app-input>
    </ng-container>

    <ng-template #searchResults>
        <div *ngIf="inputStopped && search.value"
             cdkMenu
             class="search-container"
        >
            <div class="results" [ngClass]="search.value.length < 3 ? 'recent' : ''">
                <ng-container *ngIf="search.value.length >= 3; else recentSearchesTemplate">
                    <ng-container *ngIf="loading$ | async" [ngTemplateOutlet]="loading"></ng-container>

                    <ng-container *ngIf="instantSearchConfig$ | async as isConfig">
                        <ng-container *ngIf="productSearchParameters$ | async as searchParams">
                            <app-algolia-instantsearch [config]="isConfig">
                                <app-algolia-configure *ngIf="searchParams"
                                                      [searchParameters]="searchParams"
                                ></app-algolia-configure>

                                <app-insights></app-insights>

                                <app-search-hits-products (resultClicked)="resultClicked()"
                                                          (loading)="onLoading('products', $event)"
                                ></app-search-hits-products>
                            </app-algolia-instantsearch>
                        </ng-container>

                        <ng-container *ngIf="otherSearchParameters as searchParams">
                            <app-algolia-instantsearch [config]="isConfig">
                                <app-algolia-configure *ngIf="searchParams"
                                                      [searchParameters]="searchParams"
                                ></app-algolia-configure>

                                <app-insights></app-insights>

                                <app-search-hits-other (resultClicked)="resultClicked()"
                                                       (loading)="onLoading('other', $event)"
                                ></app-search-hits-other>
                            </app-algolia-instantsearch>
                        </ng-container>

                        <div class="search-results-footer">
                            <a [routerLink]="'/search' | translateRoute"
                               (click)="onBlur()"
                               class="search-results-footer-link"
                            >
                                {{ 'search.show-all-results' | translate }}
                            </a>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </ng-template>
</div>

<ng-template #recentSearchesTemplate>
    <ng-container *ngIf="recentSearches?.length">
        <div class="title">
            <h4>{{ 'search.recently-searched' | translate }}</h4>
        </div>

        <div class="results-container">
            <div *ngFor="let recent of recentSearches"
                 class="recent-search"
                 [routerLink]="'/products/' + recent.slug | translateRoute"
                 (click)="onBlur(true)"
            >
                {{ recent.name | lowercase }}
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #loading>
    <app-page-spinner></app-page-spinner>
</ng-template>

<ng-template #noSearch>
</ng-template>
