import { firstValueFrom } from 'rxjs'
import { Injectable } from '@angular/core'
import { UrlTree, Router } from '@angular/router'
import { AuthService } from '@app-services'
import { map } from 'rxjs/operators'

@Injectable({
    providedIn: 'root',
})
export class IsNotEnhancedGuard {

    constructor(
        private auth: AuthService,
        private router: Router,
    ) {}

    canActivate(): Promise<boolean | UrlTree> {
        return firstValueFrom(
            this.auth.user$.pipe(
                map((user) => user && ! user.isEnhanced ? !! user : this.router.parseUrl('/dashboard')),
            ),
        )
    }
}
