<ng-container *ngIf="authService.user$ | async as user">

    <ng-container *ngIf="layout === 'desktop'">
        <app-tooltip *ngIf="downloadService.download"
                     alignment="bottom"
                     [instantOpen]="true"
        >
            <div class="download-manager" label>
                <ng-container *ngIf="downloadService.download.percentage !== 100">
                    <span class="download-manager__badge">
                         {{ downloadTrackerService.getPendingDownloads().length }}
                    </span>

                    <app-spinner></app-spinner>
                </ng-container>

                <span *ngIf="downloadService.download.percentage === 100"
                      appIcon="check-circle"
                ></span>

                <span class="download-manager__label">
                    {{ 'products.downloads' | translate }}
                </span>
            </div>

            <div class="download-manager__spinner-container">
                <ng-container *ngIf="downloadService.download.percentage !== 100">
                    <div class="download-manager__spinner"></div>

                    <p>
                        <span class="download-manager__percentage-text">{{ downloadService.download.percentage}}%</span>
                    </p>
                </ng-container>

                <ng-container *ngIf="downloadService.download.percentage === 100">
                    <span appIcon="download"
                          class="download-manager__check-icon"
                    ></span>
                </ng-container>
            </div>

            <div class="text-center download-manager__tooltip-text-container">
                <ng-container *ngIf="downloadTrackerService.getPendingDownloads().length > 1">
                    <p class="download-manager__pending-downloads">
                        {{ 'download-service.pending-downloads' | translate : {
                                downloads: downloadTrackerService.getPendingDownloads().length - 1
                            }
                        }}
                    </p>
                </ng-container>

                <hr class="download-manager__divider" />

                <ng-container *ngIf="downloadService.download.percentage !== 100">
                    <p class="text-white">
                        {{ 'download-service.processing-your-download' | translate }}
                    </p>

                    {{ 'download-service.processing-your-download-explanation' | translate: { email: user.email} }}
                </ng-container>

                <ng-container *ngIf="downloadService.download.percentage === 100">
                    <p class="text-white">
                        {{ 'download-service.download-succeeded' | translate }}
                    </p>

                    {{ 'download-service.download-succeeded-explanation' | translate: { email: user.email} }}
                </ng-container>
            </div>
        </app-tooltip>
    </ng-container>

    <ng-container *ngIf="layout === 'mobile' && downloadService.download">
        <div class="download-manager">
            <span class="download-manager__label">{{ 'products.downloads' | translate }}</span>

            <div *ngIf="downloadService.download.percentage !== 100"
                 class="download-manager__mobile"
            >
                <p>
                    {{ downloadService.download.percentage}}%
                </p>

                <app-spinner></app-spinner>

                <span class="download-manager__badge">
                     {{ downloadTrackerService.getPendingDownloads().length }}
                </span>
            </div>

            <span *ngIf="downloadService.download.percentage === 100"
                  appIcon="check-circle"
            ></span>
        </div>

        <div class="download-manager__spinner-container">
            <ng-container *ngIf="downloadService.download.percentage !== 100">
                <p>
                    <span class="download-manager__percentage-text">{{ downloadService.download.percentage}}%</span>
                </p>
            </ng-container>
        </div>
    </ng-container>

</ng-container>
