import { TranslateModule } from '@ngx-translate/core'

import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { SelectionFooterComponent } from './selection-footer/selection-footer.component'
import { ResponsiveImageComponent } from './responsive-image/responsive-image.component'
import { ProductAvailabilityComponent } from '@app-domains/components/product-availability/product-availability.component'
import { UIModule } from '@app-domains/ui/ui.module'
import { PipesModule } from '@app-pipes/pipes.module'

@NgModule({
    declarations: [
        SelectionFooterComponent,
        ResponsiveImageComponent,
        ProductAvailabilityComponent,
    ],
    exports: [
        SelectionFooterComponent,
        ResponsiveImageComponent,
        ProductAvailabilityComponent,
    ],
    imports: [
        CommonModule,
        UIModule,
        TranslateModule,
        PipesModule,
    ],
})
export class ComponentsModule {
}
