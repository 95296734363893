<ng-container *ngIf="categoryWithSubcategory">
    <button (click)="toggleSubcategory()" class="link icon" [ngClass]="{'uppercase' : categoryUppercase}">
        {{ categoryWithSubcategory.mainCategoryLabel ? (categoryWithSubcategory.mainCategoryLabel | translate) : categoryWithSubcategory.mainCategory }}
        <span appIcon="chevron-right"></span>
    </button>

    <div class="subcategory-container"
         [style.display]="internalMobileMenuIsOpen ? 'flex' : 'none'"
         [ngClass]="{'subcategory-toggle' : subcategoryIsOpen}"
    >
        <div class="subcategory-content">
            <button (click)="toggleSubcategory()" class="close icon">
                <span appIcon="arrow-left"></span>
                {{ 'common.back' | translate }}
            </button>

            <div class="category-title" *ngIf="categoryWithSubcategory.mainCategory">
                <h2>
                    {{ categoryWithSubcategory.mainCategoryLabel ? (categoryWithSubcategory.mainCategoryLabel | translate) : categoryWithSubcategory.mainCategory }}
                </h2>
            </div>

            <div class="subcategory-list">
                <ng-container *ngIf="categoryWithSubcategory.mainPageUrl && includeMainPageUrl">
                    <a [routerLink]="categoryWithSubcategory.mainPageUrl | translateRoute"
                       routerLinkActive="active"
                       class="link main-link"
                       (click)="onLinkClick()"
                    >
                        {{'common.all' | translate}} {{ categoryWithSubcategory.mainCategoryLabel ? (categoryWithSubcategory.mainCategoryLabel | translate) : categoryWithSubcategory.mainCategory }}
                    </a>
                </ng-container>

                <div class="menu-section">
                    <ng-container *ngFor="let subcategory of categoryWithSubcategory.categories">
                        <ng-container *ngIf="subcategory.ppName;else link">
                            <a [routerLink]="subcategory.ppName | positionedPageLink | async"
                               (click)="onLinkClick()"
                               class="link"
                            >
                                {{ subcategory.label ? (subcategory.label | translate) : subcategory.name }}
                            </a>
                        </ng-container>
                        <ng-template #link>
                            <ng-container *ngIf="subcategory.link;else externalLink">
                                <a *ngIf="subcategory.link"
                                   [routerLink]="subcategory.link | translateRoute"
                                   (click)="onLinkClick()"
                                   class="link"
                                >
                                    {{ subcategory.label ? (subcategory.label | translate) : subcategory.name }}
                                </a>
                            </ng-container>
                        </ng-template>
                        <ng-template #externalLink>
                            <ng-container *ngIf="localization.currentLocale$ | async as locale">
                                <a *ngIf="subcategory.externalLink"
                                   [href]="subcategory.externalLink + (locale !== LocalesEnum.Nl ? LocalesEnum.En : LocalesEnum.Nl)"
                                   (click)="onLinkClick()"
                                   class="link"
                                >
                                    {{ subcategory.label ? (subcategory.label | translate) : subcategory.name }}
                                </a>
                            </ng-container>
                        </ng-template>
                    </ng-container>
                </div>
            </div>

            <div class="highlight-group">
                <ng-container *ngFor="let highlight of categoryWithSubcategory.highlights | slice:0:3">
                    <a *ngIf="highlight.image | imageSize : 330 as highlightImage"
                       [routerLink]="highlight.link | translateRoute"
                       class="highlight-item"
                    >
                        <div class="highlight-image">
                            <img [src]="highlightImage"
                                 [alt]="highlight.title"
                                 loading="lazy"
                            />
                        </div>

                        <label *ngIf="highlight"
                               class="highlight-label"
                        >
                            {{ 'navigation.highlighted' | translate }}
                        </label>

                        <div class="highlight-title">
                            <h2 class="text-white">
                                {{ highlight.title }}
                            </h2>
                        </div>
                    </a>
                </ng-container>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="sectionWithSubcategory">
    <div class="dealer-account">
        <h2 class="subheading">
            {{ 'template.site-header.dealer-account' | translate }}
        </h2>

        <button (click)="toggleSubcategory()" class="link icon" [ngClass]="{'uppercase' : categoryUppercase}">
            <div class="user">
                <span appIcon="user"></span>
                {{ sectionWithSubcategory.dropdownButtonLabel ? (sectionWithSubcategory.dropdownButtonLabel | translate) : sectionWithSubcategory.dropdownButtonText }}
            </div>

            <span appIcon="chevron-right"></span>
        </button>
    </div>

    <div class="subcategory-container"
         [ngClass]="{'subcategory-toggle' : subcategoryIsOpen}"
    >
        <div class="subcategory-content">
            <button (click)="toggleSubcategory()" class="close icon">
                <span appIcon="arrow-left"></span>
                {{ 'common.back' | translate }}
            </button>

            <div class="category-title">
                <h2>
                    {{ 'template.site-header.dealer-account' | translate }}
                </h2>
            </div>

            <div class="subcategory-list">
                <div class="menu-section no-border no-padding">
                    <ng-container *ngFor="let subcategory of sectionWithSubcategory.dropdownItems">
                        <a *ngIf="subcategory.clickEvent"
                           class="link"
                           (click)="subcategory.clickEvent($event)"
                        >
                            {{ subcategory.itemLabel ? (subcategory.itemLabel | translate) : subcategory.itemName }}
                        </a>

                        <a *ngIf="subcategory.routerLink"
                           [routerLink]="subcategory.routerLink | translateRoute"
                           class="link"
                        >
                            {{ subcategory.itemLabel ? (subcategory.itemLabel | translate) : subcategory.itemName }}
                        </a>

                        <ng-container *ngIf="subcategory.ppName">
                            <a *ngIf="subcategory.ppName | positionedPageLink | async as link"
                               [routerLink]="link"
                               class="link"
                            >
                                {{ subcategory.ppName | positionedPageTitle | async }}
                            </a>
                        </ng-container>

                        <hr *ngIf="subcategory.underLine" class="split-section" />
                    </ng-container>

                    <hr class="underline" />

                    <app-checkbox [control]="updateProfileService.hidePurchasePrice"
                                  [nativeMode]="true"
                                  class="dropdown-item"
                                  [smallLabel]="true"
                    >
                        {{ 'my-data.hide-purchase-price' | translate }}
                    </app-checkbox>
                </div>
            </div>
        </div>
    </div>
</ng-container>
