import { animate, AnimationMetadataType, query, stagger, style, transition } from '@angular/animations'

export const slideChildrenInBottom = transition(':enter', [
    query(':enter', [
        style({ opacity: 0, transform: 'translateY(10px)' }),
        stagger('75ms', [
            animate('150ms ease-out', style({ opacity: 1, transform: 'translateY(0)' })),
        ]),
    ], { optional: true }),
])

export const fadeIn = (durationMs: number = 300, easing: string = 'ease-in-out') => transition(':enter', [
    style({ opacity: 0 }),
    animate(`${durationMs}ms ${easing}`, style({ opacity: 1 })),
])

export const fadeOut = (durationMs: number = 300, easing: string = 'ease-in-out') => transition(':leave', [
    style({ opacity: 1 }),
    animate(`${durationMs}ms ${easing}`, style({ opacity: 0 })),
])

export const slideInBottom = ({
    durationMs = 300,
    distancePx = 10,
} = {}) => transition(':enter', [
    style({ transform: `translateY(${distancePx}px)` }),
    animate(`${durationMs}ms ease-out`, style({ transform: 'none' })),
])

export const slideOutBottom = ({
    durationMs = 300,
    distancePx = 10,
} = {}) => transition(':leave', [
    animate(`${durationMs}ms ease-out`, style({ transform: `translateY(${distancePx}px)` })),
])

export const shrinkOut = ({ durationMs = 300 } = {}) => transition(':leave', [
    animate(`${durationMs}ms ease-out`, {
        type: AnimationMetadataType.Keyframes,
        steps: [
            style({ opacity: 1 }),
            style({ opacity: 0 }),
            style({ height: 0 }),
        ],
    }),
])
