import { AsyncValidatorFn, FormBuilder, FormControl, FormControlOptions, ValidatorFn } from '@angular/forms'
import { environment } from '@app-environments/environment'
import { Await, UnaryFunction } from '@app-types/common.types'
import { Environment } from '@app-types/environment.types'
import { getISOWeek, getYear, nextMonday, setWeek } from 'date-fns'
import { andThen, fromPairs, map, pipe, toPairs } from 'ramda'
import { InjectionToken } from '@angular/core'
import { PrefetchStorageRedis } from '../../../server/prefetch/storage/storage.redis'

/**
 * A function that always returns true. This can be used as a final catch-all
 * predicate function in conditional-flow function compositions.
 */
export function otherwise(): true {
    return true
}


export function noop(): void {
    // does nothing...
}

export function isString(x: unknown): x is string {
    return typeof x === 'string'
}

export function isNumber(x: unknown): x is number {
    return typeof x === 'number'
}

export function isBoolean(x: unknown): x is boolean {
    return typeof x === 'boolean'
}

export function isFunction(x: unknown): x is (...args: any[]) => any {
    return typeof x === 'function'
}

export function isHTMLElement(x: unknown): x is HTMLElement {
    return x instanceof HTMLElement
}

/**
 * Normalizes an unknown error type to a string for error reporting.
 */
export function errorMessage(error: unknown): string {
    return error instanceof Error ? error.message : String(error)
}

export function capitalize(input: string): string {
    return input[0].toUpperCase() + input.slice(1).toLowerCase()
}

export function delay<T>(ms: number, value: T): Promise<T>
export function delay(ms: number): Promise<void>
export function delay(ms: number, value = undefined): Promise<any> {
    return new Promise((resolve) => setTimeout(() => resolve(value), ms))
}

/**
 * Takes a transformer function `T => U` and returns a new function that takes a `Record<K, T>`.
 * When called, the function returns a promise of a record with the **awaited** values of `U`.
 * This means that the transformer may return promises, and they will all be awaited so that one
 * single promise for a `Record<K, Await<U>>` can be returned.
 */
export function transformRecord<
    T,
    U,
>(transformer: UnaryFunction<T, U>): <K extends string = string>(record: Record<K, T>) => Promise<Record<K, Await<U>>> {
    /* eslint-disable @typescript-eslint/indent */
    return pipe<
        [Record<string, T>],
        [string, T][],
        Promise<[string, Await<U>]>[],
        Promise<[string, Await<U>][]>,
        Promise<Record<string, Await<U>>>
    >(
        toPairs,
        map(async ([key, value]) => [key, await transformer(value)] as [string, Await<U>]),
        Promise.all,
        andThen(fromPairs),
    )
    /* eslint-enable @typescript-eslint/indent */
}

export function *idGenerator(prefix: string): IterableIterator<string> {
    let index = 0
    while (true) {
        yield `${prefix}-${index++}`
    }
}

export function indexWithinParent(el: HTMLElement): number {
    const children = el.parentNode?.childNodes ?? []
    return Array.from(children).reduce((acc, val, index) => {
        if (acc !== -1) {
            return acc
        }
        if (val === el) {
            return index
        }
        return -1
    }, -1)
}

export type StrictFormControlFactory = <T>(
    formState: T,
    validatorOrOpts?: ValidatorFn[] | FormControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
) => FormControl<T>

export const strictFormControl = (fb: FormBuilder): StrictFormControlFactory => <T>(
    formState: T,
    validatorOrOpts?: ValidatorFn[] | FormControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
) => fb.control<T>(formState, {
    ...(validatorOrOpts && ! Array.isArray(validatorOrOpts) ? validatorOrOpts : {}),
    validators: Array.isArray(validatorOrOpts) ? validatorOrOpts : [],
    asyncValidators: asyncValidator,
    nonNullable: true,
})

export const devConsole: Console = new Proxy(console, {
    get(target: Console, property: keyof Console) {
        if (environment.name === Environment.Staging) {
            return target[property]
        }

        return noop
    },
})

export function weekNumberToDate(weekNumber: number) {
    const currentWeek = getISOWeek(new Date())
    const year = weekNumber < currentWeek ? getYear(new Date()) + 1 : getYear(new Date())
    return setWeek(nextMonday(new Date(year, 0, 4)), weekNumber, {
        weekStartsOn: 1,
        firstWeekContainsDate: 4,
    })
}

export const REDIS_PREFETCH_STORAGE = new InjectionToken<PrefetchStorageRedis>('REDIS_PREFETCH_STORAGE')
