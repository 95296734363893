import { Injectable } from '@angular/core'
import { SwiperLib } from '@app-lib/swiper.lib'
import Swiper from 'swiper'

@Injectable({
    providedIn: 'root',
})
export class SwiperService {
    public create(swiper: Swiper): SwiperLib {
        return new SwiperLib(
            swiper,
        )
    }
}
