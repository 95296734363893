<div class="main-container">
    <app-site-header></app-site-header>
    <app-grid>
        <app-cell mobile="full:full">
            <app-grid>
                <app-cell mobile="0:12">
                </app-cell>
            </app-grid>
            <router-outlet></router-outlet>
        </app-cell>
    </app-grid>

    <app-site-footer></app-site-footer>
    <app-snackbar></app-snackbar>
</div>
