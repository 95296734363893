import { TranslateRouterModule } from '@endeavour/ngx-translate-router'
import { TranslateModule } from '@ngx-translate/core'

import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { ReactiveFormsModule } from '@angular/forms'
import { CdkMenuModule } from '@angular/cdk/menu'
import { OverlayModule } from '@angular/cdk/overlay'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AppRoutingModule } from '../../app-routing.module'

import { AlgoliaModule } from '@app-domains/algolia/algolia.module'
import { LoadingModule } from '@app-domains/loading/loading.module'
import { UIModule } from '@app-domains/ui/ui.module'
import { PipesModule } from '@app-pipes/pipes.module'

import { LangSwitchModule } from '@app-domains/localization/lang-switch/lang-switch.module'
import { PageBuilderModule } from '@app-domains/page-builder/page-builder.module'
import { DirectivesModule } from '@app-directives/directives.module'

import { SiteFooterComponent } from '@app-domains/navigation/site-footer/site-footer.component'
import { SiteHeaderComponent } from '@app-domains/navigation/site-header/site-header.component'
import { AuthHeaderComponent } from '@app-domains/navigation/auth-header/auth-header.component'
import { SearchComponent } from './search/search.component'
import { SearchResultComponent } from './search-result/search-result.component'
import { SnackbarComponent } from './snackbar/snackbar.component'
import { ImpersonationBarComponent } from './impersonation-bar/impersonation-bar.component'
import { SubcategoryHeaderComponent } from './subcategory-header/subcategory-header.component'
import { SearchHitsProductsComponent } from './search-hits-products/search-hits-products.component'
import { SearchHitsOtherComponent } from './search-hits-other/search-hits-other.component'
import { DownloadManagerComponent } from '@app-domains/navigation/download-manager/download-manager.component'
import { MenuDropdownComponent } from './menu-dropdown/menu-dropdown.component'
import { CategoryDropdownComponent } from './category-dropdown/category-dropdown.component'

@NgModule({
    declarations: [
        SiteFooterComponent,
        SiteHeaderComponent,
        MenuDropdownComponent,
        CategoryDropdownComponent,
        AuthHeaderComponent,
        SnackbarComponent,
        SubcategoryHeaderComponent,
        ImpersonationBarComponent,
        SearchComponent,
        SearchResultComponent,
        SearchHitsProductsComponent,
        SearchHitsOtherComponent,
        DownloadManagerComponent,
    ],
    exports: [
        SiteFooterComponent,
        SiteHeaderComponent,
        MenuDropdownComponent,
        AuthHeaderComponent,
        SnackbarComponent,
        SearchComponent,
        ImpersonationBarComponent,
    ],
    imports: [
        RouterModule,
        AppRoutingModule,
        AlgoliaModule,
        BrowserAnimationsModule,
        TranslateModule,
        TranslateRouterModule,
        PageBuilderModule,
        UIModule,
        CdkMenuModule,
        LangSwitchModule,
        CommonModule,
        DirectivesModule,
        PipesModule,
        LoadingModule,
        OverlayModule,
        ReactiveFormsModule,
    ],
})
export class NavigationModule {
}
