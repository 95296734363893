import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { EmptyStateComponent } from './components/empty-state/empty-state.component'
import { TranslateModule } from '@ngx-translate/core'
import { UIModule } from '@app-domains/ui/ui.module'

@NgModule({
    declarations: [
        EmptyStateComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        UIModule,
    ],
    exports: [
        EmptyStateComponent,
    ],
})
export class EmptyStateModule {
}
