import { Component, Input } from '@angular/core'
import { SubFooterLinkFragment } from '@app-graphql/schema'

@Component({
    selector: 'app-subfooter-links',
    templateUrl: './subfooter-links.component.html',
    styleUrls: ['./subfooter-links.component.scss'],
})
export class SubfooterLinksComponent {

    @Input()
    public subfooterLinks: SubFooterLinkFragment
}
