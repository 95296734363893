import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'isExternalLink',
})
export class IsExternalLinkPipe implements PipeTransform {

    transform(value: string): boolean {
        if (! value) {
            return false
        }

        if (value.includes('https')
            || value.includes('http')
            || value.includes('www')
        ) {
            return true
        }

        return false
    }
}
