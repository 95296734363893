import { animationFrameScheduler, Observable, share, Subject, Subscription, tap, throttleTime } from 'rxjs'
import { combineLatestWith, map } from 'rxjs/operators'
import Swiper from 'swiper'
import SwiperCore, { Autoplay, Controller, Navigation, Pagination, SwiperOptions } from 'swiper'
import { SwiperComponent } from 'swiper_angular'
import Bugsnag from '@bugsnag/js'

import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core'

import {
    FeatureHighlight,
    ResponsiveImage,
} from '@app-domains/content-blocks/components/feature-highlight/feature-highlight.types'
import { BreakpointsService } from '@app-domains/ui/services/breakpoints/breakpoints.service'
import { Breakpoint } from '@app-domains/ui/services/breakpoints/breakpoints.service.types'
import { Responsive } from '@app-types/responsive.types'
import { clampNumber } from '../../../../helpers/clamp-number'

SwiperCore.use([
    Autoplay,
    Controller,
    Pagination,
    Navigation,
])

@Component({
    selector: 'app-feature-highlight',
    templateUrl: './feature-highlight.component.html',
    styleUrls: ['./feature-highlight.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class FeatureHighlightComponent implements AfterViewInit, OnDestroy {
    @Input()
    public image: ResponsiveImage

    @Input()
    public locations: FeatureHighlight[]

    @ViewChild('swiperComponent', {
        static: true,
    })
    public swiperComponent: SwiperComponent

    public swiper: Swiper

    public loaded = false
    public imageDimensions: Responsive<{ width: number; height: number }> = {}
    public adjustedLocations: [number, number][]
    public swiperConfig: SwiperOptions = {
        slidesPerView: 'auto',
        cssMode: true,
        allowTouchMove: false,
        direction: 'horizontal',
    }

    public sizeSubject = new Subject<{ width: number; height: number }>()
    public resizeObserver = new ResizeObserver(([entry]) => {
        const {
            width,
            height,
        } = entry.contentRect
        this.sizeSubject.next({
            width,
            height,
        })
    })
    public adjustedLocations$: Observable<string[]> = this.sizeSubject.pipe(
        combineLatestWith(this.breakpointsService.currentBreakpoint$),
        throttleTime(0, animationFrameScheduler),
        map(([{ width, height }, currentBreakpoint]) => {
            return this.adjustLocations(width, height, currentBreakpoint)
        }),
        share(),
    )
    public responsiveImage$: Observable<{
        breakpoint: Breakpoint | null
        image: string
    }>

    // Easier to manage these within the component. Using the values directly from this.swiper makes the change
    // detection unhappy
    public index: number = 0
    public isEnd: boolean = false
    public isBeginning: boolean = false
    private adjustedLocationsSubscription: Subscription

    constructor(
        private cdr: ChangeDetectorRef,
        private breakpointsService: BreakpointsService,
    ) {
        this.adjustedLocationsSubscription = this.adjustedLocations$.pipe(
            tap(() => requestAnimationFrame(() => this.cdr.detectChanges())),
        ).subscribe()
        this.responsiveImage$ = this.breakpointsService.currentBreakpoint$.pipe(
            map((breakpoint) => {
                let bp: Breakpoint | null = breakpoint
                let image = this.image[bp]
                while (image === undefined && bp !== null) {
                    bp = this.breakpointsService.prevBreakpoint(bp)
                    image = this.image[bp ?? 'mobile']
                }
                if (! image) {
                    const errorMessage = 'Responsive image not correctly setup'
                    Bugsnag.notify(errorMessage)
                    throw new Error(errorMessage)
                }
                return {
                    breakpoint: bp,
                    image,
                }
            }),
        )
    }

    public ngOnDestroy(): void {
        this.adjustedLocationsSubscription.unsubscribe()
    }

    public ngAfterViewInit(): void {
        if (! this.swiper) {
            this.swiper = this.swiperComponent.swiperRef
            this.cdr.detectChanges()
        }
    }

    public imageLoaded(event: Event, bp: Breakpoint | null): boolean | undefined {
        if (! bp) {
            return undefined
        }
        const imageEl = event.currentTarget as HTMLImageElement
        this.imageDimensions[bp] = {
            width: imageEl.width,
            height: imageEl.height,
        }
        if (! this.loaded && imageEl.previousElementSibling) {
            this.resizeObserver.observe(imageEl.previousElementSibling)
            this.loaded = true
        }
        return true
    }

    public highlightClicked(index: number): void {
        this.swiperComponent.swiperRef.slideTo(index)
        this.updateSwiperValues(index)
        this.cdr.detectChanges()
    }

    public slidePrev(): void {
        this.swiper.slidePrev()
        this.updateSwiperValues(this.index - 1)
    }

    public slideNext(): void {
        this.swiper.slideNext()
        this.updateSwiperValues(this.index + 1)
    }

    public updateSwiperValues(index: number): void {
        this.index = index
        this.isBeginning = index === 0
        this.isEnd = index === this.locations.length - 1
    }

    public adjustLocation(
        locationPosition: number,
        realDimension: number,
        ratio: number,
        dimensionSize: number,
        clampFn: (n: number) => number,
    ): number {
        const realPosition = locationPosition * realDimension
        const position = realPosition * ratio
        const adjustment = ((ratio * realDimension) - dimensionSize) / 2
        const newPosition = position - adjustment
        return clampFn(newPosition)
    }

    public adjustLocations(width: number, height: number, currentBreakpoint?: Breakpoint): string[] {
        if (! currentBreakpoint) {
            return ['']
        }

        let bp: Breakpoint | null = currentBreakpoint
        let dimensions = this.imageDimensions[bp]
        while (dimensions === undefined && bp !== null) {
            bp = this.breakpointsService.prevBreakpoint(bp)
            dimensions = this.imageDimensions[bp ?? 'mobile']
        }
        if (dimensions === undefined) {
            return ['']
        }

        const ratioX = width / dimensions.width
        const ratioY = height / dimensions.height
        const axisToAdjust = ratioY > ratioX ? 'x' : 'y'
        const clampWidth = clampNumber(12, width)
        const clampHeight = clampNumber(12, height)
        return this.locations.map((l) => {
            let pos: { x: number; y: number } | undefined = undefined
            let breakpoint: Breakpoint | null = currentBreakpoint
            while (pos === undefined && breakpoint) {
                pos = l.position[breakpoint]
                breakpoint = this.breakpointsService.prevBreakpoint(breakpoint)
            }
            if (! pos) {
                const errorMessage = 'No positions found!'
                Bugsnag.notify(errorMessage)
                throw new Error(errorMessage)
            }
            const locationX = pos.x * width
            const locationY = pos.y * height

            const x = axisToAdjust === 'y'
                ? locationX
                : this.adjustLocation(
                    pos.x,
                    dimensions!.width,
                    ratioY,
                    width,
                    clampWidth,
                )
            const y = axisToAdjust === 'x'
                ? locationY
                : this.adjustLocation(
                    pos.y,
                    dimensions!.height,
                    ratioX,
                    height,
                    clampHeight,
                )

            return `translate(calc(${x}px - 50%), calc(${y}px - 50%))`
        })
    }
}
