import { CdkMenuModule } from '@angular/cdk/menu'
import { NgModule } from '@angular/core'
import { LangSwitchComponent } from '@app-domains/localization/lang-switch/lang-switch.component'
import { CommonModule } from '@angular/common'
import { UIModule } from '@app-domains/ui/ui.module'
import { TranslateModule } from '@ngx-translate/core'
import { DirectivesModule } from '@app-directives/directives.module'

@NgModule({
    declarations: [
        LangSwitchComponent,
    ],
    exports: [
        LangSwitchComponent,
    ],
    imports: [
        CommonModule,
        UIModule,
        TranslateModule,
        CdkMenuModule,
        DirectivesModule,
    ],
})
export class LangSwitchModule {
}
