import { Injectable } from '@angular/core'
import { SaleStatisticsQuery, SaleStatisticsQueryService, SaleStatisticsQueryVariables } from '@app-graphql/schema'
import { Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client'

@Injectable({
    providedIn: 'root',
})
export class SaleStatisticsService {

    constructor(
        private saleStatisticsQueryService: SaleStatisticsQueryService,
    ) {
    }

    public getSaleStatistics(input: SaleStatisticsQueryVariables): Observable<ApolloQueryResult<SaleStatisticsQuery>> {
        return this.saleStatisticsQueryService.fetch(input)
    }
}
