import { TranslateService } from '@ngx-translate/core'
import { MutationResult } from 'apollo-angular'
import { Observable } from 'rxjs'
import { Injectable } from '@angular/core'
import { FormArray, FormBuilder, FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms'
import { InputPublic } from '@app-domains/ui/components/input/input.component.types'
import {
    AddressInput,
    EnhanceDebtorMutation,
    EnhanceDebtorMutationService,
    EnhanceDebtorMutationVariables,
    Extra,
    PurchasingAssociation,
} from '@app-graphql/schema'
import { strictFormControl } from '@app-lib/common.lib'
import { FormService } from '@app-services'
import { GraphQLInputForm } from '@app-types/common.types'
import { checkEmail } from '../../validators'

interface TimeWindowForm {
    opening: FormControl<string>
    closing: FormControl<string>
}

export interface BusinessHoursForm {
    monday: FormGroup<TimeWindowForm>
    tuesday: FormGroup<TimeWindowForm>
    wednesday: FormGroup<TimeWindowForm>
    thursday: FormGroup<TimeWindowForm>
    friday: FormGroup<TimeWindowForm>
    saturday: FormGroup<TimeWindowForm>
    sunday: FormGroup<TimeWindowForm>
}

export interface ContactPeople {
    commercial: boolean
    delivery: boolean
    email: string
    financial: boolean
    name: string
    order: boolean
    phone: string
}

export interface ShowOn {
    showOnBePureHome: boolean
    showOnWooodnl: boolean
}

type DaysOfTheWeek = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday'

@Injectable({
    providedIn: 'root',
})
export class EnhanceDebtorService {

    public currentEnhanceDebtorStep = 1

    public visitAddress: FormGroup<GraphQLInputForm<AddressInput>> = this.createAddressForm('visitAddress', true)
    public deliveryAddress: FormGroup<GraphQLInputForm<AddressInput>> = this.createAddressForm('deliveryAddress', true)
    public postAddress: FormGroup<GraphQLInputForm<AddressInput>> = this.createAddressForm('postAddress')
    public deliveryTimeWindow: FormGroup<BusinessHoursForm> = this.createTimeWindowForm()
    public visitTimeWindow: FormGroup<BusinessHoursForm> = this.createTimeWindowForm()
    public purchasingAssociation: FormGroup<GraphQLInputForm<PurchasingAssociation> & GraphQLInputForm<Extra>>
        = this.createPurchasingAssociationForm()
    private contactPersonsForm: UntypedFormGroup = this.formBuilder.group({
        contacts: this.formBuilder.array<FormGroup<GraphQLInputForm<ContactPeople>>>([]),
    })
    public agreedToTerms: FormControl<boolean | null> = new FormControl<boolean>(false, [Validators.requiredTrue])
    public showOnForm: FormGroup<GraphQLInputForm<ShowOn>> = this.createShowOnForm()
    public kvk: FormControl<string | null> = new FormControl<string | null>(null, [Validators.minLength(8)])
    public vat: FormControl = new FormControl<string>('', [Validators.required, Validators.minLength(6)])

    public contactsFormArray = this.contactPersonsForm.get('contacts') as
        FormArray<FormGroup<GraphQLInputForm<ContactPeople>>>

    public formErrors: InputPublic.ErrorMessageRecord = {
        required: () => this.translate.get('validation.messages.required'),
        emailNotValid: () => this.translate.get('validation.messages.invalid-email'),
        requiredTrue: () => this.translate.get('validation.messages.you-have-to-agree'),
    }

    constructor(
        private enhanceDebtorMutationService: EnhanceDebtorMutationService,
        private readonly formBuilder: FormBuilder,
        private readonly formService: FormService,
        private readonly translate: TranslateService,
    ) {
    }

    public enhanceDebtor(input: EnhanceDebtorMutationVariables): Observable<MutationResult<EnhanceDebtorMutation>> {
        const enhanceDebtorMutationVariables: EnhanceDebtorMutationVariables = {
            input: {
                deliveryAddress: input.input.deliveryAddress,
                visitAddress: input.input.visitAddress,
                postAddress: input.input.postAddress,
                visitTimeWindow: input.input.visitTimeWindow,
                contactPersons: input.input.contactPersons,
                showOnBePureHome: input.input.showOnBePureHome,
                showOnWooodnl: input.input.showOnWooodnl,
                deliveryTimeWindow: input.input.deliveryTimeWindow,
                kvk: input.input.kvk,
                vat: input.input.vat,
                extra: input.input.extra,
            },
        }

        if (input.input.purchasingAssociation?.name) {
            if (! enhanceDebtorMutationVariables.input.purchasingAssociation) {
                enhanceDebtorMutationVariables.input.purchasingAssociation = {}
            }

            enhanceDebtorMutationVariables.input.purchasingAssociation.name = input.input.purchasingAssociation.name
        }

        if (input.input.purchasingAssociation?.number) {
            if (! enhanceDebtorMutationVariables.input.purchasingAssociation) {
                enhanceDebtorMutationVariables.input.purchasingAssociation = {}
            }

            enhanceDebtorMutationVariables.input.purchasingAssociation.number = input.input.purchasingAssociation.number
        }

        return this.enhanceDebtorMutationService.mutate(enhanceDebtorMutationVariables)
    }

    public updateCurrentEnhanceDebtorStep(event: number): void {
        this.currentEnhanceDebtorStep = event
    }

    private createShowOnForm(): FormGroup<GraphQLInputForm<ShowOn>> {
        const strictControl = strictFormControl(this.formBuilder)
        return this.formBuilder.group<GraphQLInputForm<ShowOn>>({
            showOnBePureHome: strictControl(false),
            showOnWooodnl: strictControl(false),
        })
    }

    private createAddressForm(alias: string, withRequired?: boolean): FormGroup<GraphQLInputForm<AddressInput>> {
        const form = this.formBuilder.group<GraphQLInputForm<AddressInput>>({
            name: this.formBuilder.control(alias),
            street: this.formBuilder.control(''),
            number: this.formBuilder.control(''),
            zipcode: this.formBuilder.control(''),
            city: this.formBuilder.control(''),
            country: this.formBuilder.control(''),
            phone: this.formBuilder.control('', [
                Validators.pattern('[- +()0-9]+'),
            ]),
        })

        if (withRequired) {
            this.formService.addRequiredToForm(form)
        }

        return form
    }

    public copyAddressFormData(): void {
        if (! this.deliveryAddress.touched) {
            this.deliveryAddress.patchValue(this.visitAddress.value)
        }

        if (! this.postAddress.touched) {
            this.postAddress.patchValue(this.visitAddress.value)
        }
    }

    private createTimeWindowForm(): FormGroup<BusinessHoursForm> {
        const strictControl = strictFormControl(this.formBuilder)
        return this.formBuilder.group<BusinessHoursForm>({
            monday: this.formBuilder.group<TimeWindowForm>({
                opening: strictControl(''),
                closing: strictControl(''),
            }),
            tuesday: this.formBuilder.group<TimeWindowForm>({
                opening: strictControl(''),
                closing: strictControl(''),
            }),
            wednesday: this.formBuilder.group<TimeWindowForm>({
                opening: strictControl(''),
                closing: strictControl(''),
            }),
            thursday: this.formBuilder.group<TimeWindowForm>({
                opening: strictControl(''),
                closing: strictControl(''),
            }),
            friday: this.formBuilder.group<TimeWindowForm>({
                opening: strictControl(''),
                closing: strictControl(''),
            }),
            saturday: this.formBuilder.group<TimeWindowForm>({
                opening: strictControl(''),
                closing: strictControl(''),
            }),
            sunday: this.formBuilder.group<TimeWindowForm>({
                opening: strictControl(''),
                closing: strictControl(''),
            }),
        })
    }

    public copyTimeFromPreviousDay(
        dayToCopyFrom: DaysOfTheWeek,
        dayToCopyTo: DaysOfTheWeek,
        form: FormGroup<BusinessHoursForm>,
    ): void {
        form.controls[dayToCopyTo].controls.opening.setValue(form.controls[dayToCopyFrom].controls.opening.value)
        form.controls[dayToCopyTo].controls.closing.setValue(form.controls[dayToCopyFrom].controls.closing.value)
        form.controls[dayToCopyTo].markAllAsTouched()
    }

    private createPurchasingAssociationForm(): FormGroup<GraphQLInputForm<PurchasingAssociation & Extra>> {
        const strictControl = strictFormControl(this.formBuilder)
        return this.formBuilder.group<GraphQLInputForm<PurchasingAssociation & Extra>>({
            name: this.formBuilder.control(null),
            number: this.formBuilder.control(null),
            forkLiftAvailable: strictControl(false),
            standardTrailer: strictControl(false),
            extraRemarks: strictControl(''),
        })
    }

    public addNewContactPersonForm(): void {
        this.contactsFormArray.push(this.generateNewContactPersonForm())
    }

    public deleteContactForm(index: number): void {
        this.contactsFormArray.removeAt(index)
    }

    private generateNewContactPersonForm(): FormGroup<GraphQLInputForm<ContactPeople>> {
        return this.formBuilder.group({
            name: this.formBuilder.control('', [
                Validators.required,
            ]),
            phone: this.formBuilder.control('', [
                Validators.required,
                Validators.pattern('[- +()0-9]+'),
            ]),
            email: this.formBuilder.control('', [
                Validators.required,
                checkEmail,
            ]),
            commercial: this.formBuilder.control(false),
            order: this.formBuilder.control(false),
            delivery: this.formBuilder.control(false),
            financial: this.formBuilder.control(false),
        })
    }
}
