import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TranslateRouterModule } from '@endeavour/ngx-translate-router'

import { PipesModule } from '@app-pipes/pipes.module'
import { UIModule } from '@app-domains/ui/ui.module'
import { PositionedPageLinkPipe } from '../../pages/page/pipes/positioned-page-link/positioned-page-link.pipe'
import { PositionedPageTitlePipe } from '../../pages/page/pipes/positioned-page-title/positioned-page-title.pipe'
import { PageComponentIdentifierComponent } from '@app-components/page-component-identifier/page-component-identifier.component'
import { ContentBlocksModule } from '@app-domains/content-blocks/content-blocks.module'
import { GoogleMapsComponentModule } from '@app-domains/google-maps/google-maps.module'
import { ContentBlocksComponent } from './components/content-blocks/content-blocks.component'
import { LoadingModule } from '@app-domains/loading/loading.module'

@NgModule({
    declarations: [
        PageComponentIdentifierComponent,
        PositionedPageLinkPipe,
        PositionedPageTitlePipe,
        ContentBlocksComponent,
    ],
    imports: [
        CommonModule,
        UIModule,
        ContentBlocksModule,
        PipesModule,
        GoogleMapsComponentModule,
        TranslateRouterModule,
        LoadingModule,
    ],
    exports: [
        PageComponentIdentifierComponent,
        PositionedPageLinkPipe,
        PositionedPageTitlePipe,
        ContentBlocksComponent,
    ],
})

export class PageBuilderModule { }
