<div class="fuzzy-container">
    <div #container
         tabindex="0"
         role="combobox"
         class="cont"
    >
        <app-input [control]="control"
                   [placeholder]="placeholder | translate"
                   [noValidate]="true"
                   (blur)="close()"
                   (keydown.enter)="setMatch()"
                   (focus)="applyFilter()"
                   cdkOverlayOrigin
                   aria-hidden="true"
        ></app-input>

        <div class="options-container"
             [style.width]="container.clientWidth + 'px'"
        >
            <ng-container *ngIf="matches.length">
                <div class="options" cdkTrapFocus>
                    <app-select-option *ngFor="let option of matches"
                                       [value]="option.value"
                                       [label]="option.value.label"
                                       [control]="control"
                                       (valueUpdated)="updated($event)"
                    ></app-select-option>
                </div>
            </ng-container>
        </div>
    </div>
</div>
