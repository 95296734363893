import { Component, Input } from '@angular/core'
import { BreakpointConfig } from '@app-domains/ui/components/grid/cell/cell.component'
import { Breakpoint } from '@app-domains/ui/services/breakpoints/breakpoints.service.types'

type ContentColumnBreakpoints = {
    [k in Breakpoint]?: BreakpointConfig
}

type OtherContentColumnBreakpoints = {
    [k in Breakpoint]?: BreakpointConfig
}

@Component({
    selector: 'app-text-header',
    templateUrl: './text-header.component.html',
    styleUrls: ['./text-header.component.scss'],
})
export class TextHeaderComponent {
    @Input()
    public firstLine: string

    @Input()
    public secondLine: string

    @Input()
    public smallText: string

    @Input()
    public extended: boolean = false

    @Input()
    public height: number = 200

    @Input()
    public textColumn?: ContentColumnBreakpoints = {
            mobile: '0:12',
            tablet: '0:12',
            laptop: '0:4',
            desktop: '0:4',
        }

    @Input()
    public otherContentColumn?: OtherContentColumnBreakpoints = {
            mobile: '0:12',
            tablet: '0:12',
        }
}
