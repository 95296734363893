import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { IfSsrDirective } from '@app-directives/if-ssr/if-ssr.directive'
import { ExpansionPanelContentDirective } from '@app-directives/expansion-panel/expansion-panel-content.directive'
import { ExpansionPanelHeaderDirective } from '@app-directives/expansion-panel/expansion-panel-header.directive'
import { ExpansionPanelTitleDirective } from '@app-directives/expansion-panel/expansion-panel-title.directive'
import { ExpansionPanelItemDirective } from '@app-directives/expansion-panel/expansion-panel-item.directive'
import { ObserveElementDirective } from './intersection-observer/intersection-observer.directive'
import { RecreateViewDirective } from './recreate-view/recreate-view.directive'
import { SsrHrefDirective } from './ssr-href/ssr-href.directive'

@NgModule({
    declarations: [
        IfSsrDirective,
        ExpansionPanelContentDirective,
        ExpansionPanelHeaderDirective,
        ExpansionPanelTitleDirective,
        ExpansionPanelItemDirective,
        ObserveElementDirective,
        RecreateViewDirective,
        SsrHrefDirective,
    ],
    exports: [
        IfSsrDirective,
        ExpansionPanelContentDirective,
        ExpansionPanelHeaderDirective,
        ExpansionPanelTitleDirective,
        ExpansionPanelItemDirective,
        ObserveElementDirective,
        RecreateViewDirective,
        SsrHrefDirective,
    ],
    imports: [
        CommonModule,
    ],
})
export class DirectivesModule {
}
