export namespace Analytics {
    export type ListType =
        | `category.${string}`
        | `theme.${string}`
        | `brand.${string}`
        | 'search'
        | 'products'
        | 'products.new'
        | 'products.sale'

    export class Config {
        trackingId: string
    }

    export type EventNames =
        | 'login'
        | 'signup'
        | 'page_load'
        | 'view_item_list'
        | 'select_item'
        | 'view_item'
        | 'add_to_wishlist'
        | 'remove_from_wishlist'
        | 'view_promotion'
        | 'select_promotion'
        | 'add_to_cart'
        | 'remove_from_cart'
        | 'view_cart'
        | 'filter_on'
        | 'filter_off'
        | 'sort_change'
        | 'begin_checkout'
        | 'add_shipping_info'
        | 'add_payment_info'
        | 'purchase'


    export type FilterTypes =
        | 'checkbox'
        | 'slider'

    export interface EcommerceReset {
        ecommerce: null
    }

    export interface DataLayerItem {
        event: EventNames
        /* page loads */
        user_id?: string // used when logged in for login and pageload
        page_type?: string
        blog_title?: string
        /* ecommerce parts */
        transaction_id?: string
        value?: number
        tax?: number
        shipping?: number
        ecommerce?: EcommerceItem
        /* checkout parts */
        shipping_tier?: string // Always: Delivery
        checkout_step?: string
        payment_type?: string
        /* sorting */
        sort_value?: string
        /* filtering */
        filter_type?: FilterTypes
        filter_name?: string
        filter_value?: string
        filter_value_low?: string
        filter_value_high?: string
    }

    export interface EcommerceItem {
        currency: string
        transaction_id?: string
        value?: number
        tax?: number
        shipping?: number
        items?: Array<PItem>
    }

    export interface CheckoutStep {
        event: EventNames
        checkout_step: number
        shipping_tier: 'delivery' | 'dropshipment'
        ecommerce: EcommerceCheckout
    }

    export interface EcommerceCheckout {
        currency: string
        items: PItem[]
    }

    export type PItem = {
        [key: string]: string | number | null | undefined | { name: string }
    }
}
