import { isNil, values } from 'ramda'
import Bugsnag from '@bugsnag/js'

import { Localization } from '@aa-app-localization'
import { getLocaleRegexPattern } from '@app-domains/localization/localization.lib'

// ------------------------------------------------------------------------------
//      Types
// ------------------------------------------------------------------------------

export enum AlgoliaSortingProperty {
    Price = 'price',
    ProductCode = 'code',
    Range = 'range',
    Name = 'productName',
    CreationDate = 'created',
}

export enum AlgoliaSortingDirection {
    Ascending = 'asc',
    Descending = 'desc',
}

export type ProductIndexName<TLocale extends Localization.Locale = Localization.Locale> =
    | `deeekhoorn_${TLocale}`
    | `deeekhoorn_${TLocale}_${AlgoliaSortingProperty}_${AlgoliaSortingDirection}`


export interface AlgoliaSorting {
    property: AlgoliaSortingProperty
    direction: AlgoliaSortingDirection
}

export interface AlgoliaIndexSelectorParams {
    locale: Localization.Locale
    sorting: null | AlgoliaSorting
}

// ------------------------------------------------------------------------------
//      Functions
// ------------------------------------------------------------------------------

export function getIndexName({ locale, sorting }: AlgoliaIndexSelectorParams): ProductIndexName {
    return sorting === null
        ? `deeekhoorn_${locale}`
        : `deeekhoorn_${locale}_${sorting.property}_${sorting.direction}`
}

/**
 * See {@link https://regex101.com/r/foiRJB/1 regex details} for an explanation.
 */
export function getIndexNameRegex(): RegExp {
    const localePattern = getLocaleRegexPattern()
    const sortingPropertyPattern = values(AlgoliaSortingProperty).join('|')
    const sortingDirectionPattern = values(AlgoliaSortingDirection).join('|')

    return new RegExp(
        `^deeekhoorn_(${localePattern})(?:_(${sortingPropertyPattern})_(${sortingDirectionPattern}))?$`,
    )
}

/**
 * Parses {@link AlgoliaIndexSelectorParams index-selector parameters} from the given index name.
 */
export function parseIndexSelectorParams(indexName: string): AlgoliaIndexSelectorParams {
    const matches = indexName.match(getIndexNameRegex())

    if (isNil(matches)) {
        const errorMessage = `Failed to parse AlgoliaIndexSelectorParams: invalid index name '${indexName}'.`
        Bugsnag.notify(errorMessage)
        throw new Error(errorMessage)
    }

    const [, locale, sortingProperty, sortingDirection] = <
        | [string, Localization.Locale, AlgoliaSortingProperty, AlgoliaSortingDirection]
        | [string, Localization.Locale, undefined, undefined]
        >matches

    return isSortingProperty(sortingProperty) && isSortingDirection(sortingDirection)
        ? { locale, sorting: { property: sortingProperty, direction: sortingDirection } }
        : { locale, sorting: null }
}

/**
 * Tells if the given value is a valid AlgoliaSortingProperty.
 */
export function isSortingProperty(x: any): x is AlgoliaSortingProperty {
    return values(AlgoliaSortingProperty).includes(x)
}

/**
 * Tells if the given value is a valid AlgoliaSortingDirection.
 */
export function isSortingDirection(x: any): x is AlgoliaSortingDirection {
    return values(AlgoliaSortingDirection).includes(x)
}

/**
 * Takes an algolia product-index name and a target locale, and derives the equivalent index
 * representing the target locale from it.
 */
export function translateIndexName(indexName: string, targetLocale: Localization.Locale): string {
    const { sorting } = parseIndexSelectorParams(indexName)
    return getIndexName({ locale: targetLocale, sorting })
}
