<app-grid *ngIf="usps.length">
    <app-cell mobile="0:12">
        <div class="pattern"></div>

        <div class="swiper-container">
            <swiper [config]="swiperOptions"
                    (swiper)="onSwiper($event)"
            >
                <ng-container *ngFor="let usp of usps; let i = index">
                    <ng-template swiperSlide>
                        <div class="usp-container">
                            <ng-template [ngTemplateOutlet]="uspEl"
                                         [ngTemplateOutletContext]="{ $implicit: usp }"
                            ></ng-template>

                            <div class="middle-point">
                                <div class="background-image"></div>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>
            </swiper>

            <div class="buttons-container">
                <app-button theme="line"
                            layout="icon-only"
                            icon="arrow-fat-left"
                            (click)="previousSlide()"
                ></app-button>

                <app-button
                            class="next-button"
                            theme="line"
                            layout="icon-only"
                            icon="arrow-fat-right"
                            (click)="nextSlide()"
                ></app-button>
            </div>
        </div>

        <div class="pattern"></div>
    </app-cell>
</app-grid>

<ng-template let-usp #uspEl>
    <ng-container *ngIf="usp | asUspFragment as uspFragment">
        <div class="usp">
            <div class="icon-block">
                <span class="usp-icon" [appIconEnum]="uspFragment.icon"></span>
            </div>

            <div class="flex-col">
                <label class="bold">
                    {{ uspFragment.title }}
                </label>

                <label class="soft-p">
                    {{ uspFragment.subtitle }}
                </label>
            </div>
        </div>
    </ng-container>
</ng-template>
