<div class="product-availability"
     [class.row]="layout === 'row'"
     [class.column]="layout === 'column'"
>
    <div *ngIf="deliveryTimeInWeeks !== null"
         [ngSwitch]="deliveryTimeInWeeks"
         class="deliverability"
    >
        <div class="oval green"
             *ngSwitchCase="0"
        ></div>

        <div class="oval yellow"
             *ngSwitchCase="1"
        ></div>

        <div class="oval orange"
             *ngSwitchCase="2"
        ></div>

        <div class="oval orange"
             *ngSwitchCase="3"
        ></div>

        <div class="oval grey"
             *ngSwitchDefault
        ></div>

        <label class="ellipsis"
               [class.small]="! largeText"
        >
            {{
                deliveryTimeInWeeks === 0 ? ('products.delivery-from-stock' | translate)
                : ('products.available-from' | translate: { availableIn: deliveryTimeInWeeks })
            }}
        </label>
    </div>

    <label [class.small]="! largeText">
        <ng-container *ngIf="showTooltip else noTooltip">
            <app-tooltip>
                <span label>{{ statusTranslation }}</span>

                {{
                status === statusEnum.Collection ? ('products.collection-explanation' | translate)
                : status === statusEnum.MadeToOrder ? ('products.made-to-order-explanation' | translate)
                : status === statusEnum.Sale ? ('products.sale-explanation' | translate)
                : status === statusEnum.Limited ? ('products.limited-explanation' | translate)
                : ('common.not-available' | translate)
                }}
            </app-tooltip>
        </ng-container>

        <ng-template #noTooltip>
            {{ statusTranslation }}
        </ng-template>
    </label>
</div>
