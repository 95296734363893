import { Component, Input } from '@angular/core'
import { PageLinksFragment } from '@app-graphql/schema'
import { LocalizationService } from '@app-domains/localization/service/localization.service'
import { Router } from '@angular/router'

@Component({
    selector: 'app-pages-links',
    templateUrl: './pages-links.component.html',
    styleUrls: ['./pages-links.component.scss'],
})
export class PagesLinksComponent {

    @Input()
    public pageLinks: PageLinksFragment

    constructor(
        public localization: LocalizationService,
        private router: Router,
    ) {
    }

    public async routeToPage(locale: string, slug: string): Promise<void> {
        await this.router.navigate([`/${ locale }/p/${ slug }`])
    }
}
