import { Injectable } from '@angular/core'
import {
    RegisterNewDebtorMutation,
    RegisterNewDebtorMutationService,
    RegisterNewDebtorMutationVariables,
} from '@app-graphql/schema'
import { Observable } from 'rxjs'
import { MutationResult } from 'apollo-angular'

@Injectable({
    providedIn: 'root',
})
export class RegisterService {

    public currentRegistrationStep = 1

    constructor(
        private readonly registerNewDebtorMutationService: RegisterNewDebtorMutationService,
    ) {
    }

    public registerNewDebtor(input: RegisterNewDebtorMutationVariables)
        : Observable<MutationResult<RegisterNewDebtorMutation>> {
        return this.registerNewDebtorMutationService.mutate(input, {
            errorPolicy: 'all',
        })
    }
}
