import { Component, Input } from '@angular/core'
import { ImageComponentFragment } from '@app-graphql/schema'
import { ComponentWithTextComponent } from '@app-domains/content-blocks/components/component-with-text/component-with-text.component'

@Component({
    selector: 'app-image-with-text',
    templateUrl: './image-with-text.component.html',
})
export class ImageWithTextComponent extends ComponentWithTextComponent {
    @Input()
    public image: ImageComponentFragment

    @Input()
    public lazyLoad: boolean = true
}
