import Swiper from 'swiper'

export class SwiperLib {

    constructor(
        public swiper: Swiper,
    ) {
    }

    public swiperIndex: number = 1
    public totalIndex: number = 1

    public updateTotalIndex(): void {
        if (this.swiper?.slides) {
            this.totalIndex = this.swiper.params.loop
                ? Math.ceil(
                    (this.swiper.slides.length - (this.swiper.loopedSlides ?? 1) * 2)
                    / (this.swiper.params.slidesPerGroup ?? 1),
                )
                : this.swiper.snapGrid.length
        }
    }

    public onProgress(): void {
        if (this.swiper) {
            this.swiperIndex = this.swiper.isEnd
                ? this.totalIndex
                : this.swiperIndex === this.swiper.realIndex + 1
                    ? this.swiperIndex
                    : this.swiper.realIndex + 1
        }
    }

    public onSlideChange(): void {
        if (this.swiper) {
            this.swiperIndex = this.swiper.realIndex + 1
        }
    }

    public slideNext(): void {
        this.swiper?.slideNext()
    }

    public slidePrev(): void {
        this.swiper?.slidePrev()
    }
}
