import { Injectable } from '@angular/core'
import { FormGroup, Validators } from '@angular/forms'

@Injectable({
    providedIn: 'root',
})
export class FormService {

    public markFormDirty(form: FormGroup): void {
        Object.values(form.controls).forEach((c) => c.markAsDirty())
        form.markAllAsTouched()
    }

    public addRequiredToForm(form: FormGroup): void {
        Object.values(form.controls).forEach((c) => c.addValidators([Validators.required]))
    }
}
