<picture>
    <source *ngFor="let bp of breakpointsArray"
            [media]="breakpointsMedia[bp]"
            [srcset]="image[bp].image.url"
            [class]="image[bp].imagePosition"
    />

    <img *ngIf="positionedImage$ | async as positionedImage"
         [src]="positionedImage.image.url"
         [class]="positionedImage.imagePosition"
         [alt]="positionedImage.image.alt?.length
                    ? positionedImage.image.alt
                    : ('common.image-of' | translate: { name: 'De Eekhoorn' })
                "
         [attr.loading]="lazyLoad ? 'lazy' : 'eager'"
    />
</picture>
