import { Injectable } from '@angular/core'
import { UrlTree } from '@angular/router'
import { Observable } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class UserIsLoggedInGuard {

    canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return true
    }
}
