import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { firstValueFrom } from 'rxjs'
import { DebtorService } from '@app-services'

@Injectable({
    providedIn: 'root',
})
export class HasDatafeedRightGuard {

    constructor(
        private readonly debtorService: DebtorService,
        private readonly router: Router,
    ) {
    }

    async canActivate(): Promise<boolean> {
        const debtor = await firstValueFrom(this.debtorService.debtor$)

        if (debtor?.debtorRights.allowedModules.datafeed) {
            return true
        }

        return await this.router.navigateByUrl('/')
    }
}
