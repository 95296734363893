import { Inject, Injectable, OnDestroy } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { without, not } from 'ramda'
import { DOCUMENT } from '@angular/common'
import { Subscription } from 'rxjs'
import { filter } from 'rxjs/operators'

@Injectable({
    providedIn: 'root',
})
export class ScrollBlockerService implements OnDestroy {
    public names: string[] = []
    private subscription: Subscription

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private router: Router,
    ) {
        this.subscription = this.router.events.pipe(
            filter((e) => e instanceof NavigationEnd),
        ).subscribe(() => {
            this.names = []
        })
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe()
    }

    public has(name: string): boolean {
        return this.names.includes(name)
    }

    /**
     * Adds given scroll-blocker to the list of blockers, if it's not already
     * on it.
     * Returned boolean indicates whether the set transitioned from the empty state
     */
    public add(name: string): boolean {
        if (this.has(name)) return false
        const names0 = this.names
        this.names = [name, ...this.names]
        this.apply()
        return names0.length === 0
    }

    /**
     * Adds given scroll-blocker to the list of blockers, if it's not already
     * on it.
     * Returned boolean indicates whether the set transitioned to the empty state
     */
    public remove(name: string): boolean {
        if (! this.has(name)) return false
        const names0 = this.names
        this.names = without([name], names0)
        this.apply()
        return this.names.length === 0
    }

    public toggle(name: string, force?: boolean): boolean {
        if (force ?? not(this.has(name))) {
            return this.add(name)
        }

        return this.remove(name)
    }

    public count(): number {
        return this.names.length
    }

    public reset(): void {
        this.names = []
    }

    public isEmpty(): boolean {
        return this.names.length === 0
    }

    private apply(): void {
        this.document.body.style.overflow = this.isEmpty() ? '' : 'hidden'
    }
}
