import { Injectable, OnDestroy } from '@angular/core'
import {
    LocalesEnum,
    Maybe,
    PageFragment,
    PageQueryService,
    PositionedPageEnum,
    PositionedPageFragment,
    PositionedPagesQueryService,
} from '@app-graphql/schema'
import { shareReplayOne } from '@app-lib/rxjs.lib'
import { Localization } from '@aa-app-localization'
import { path } from 'ramda'
import { Observable, Subscription } from 'rxjs'
import { map } from 'rxjs/operators'
import Locale = Localization.Locale

export type PositionedPageSlugs = { [K in Localization.Locale]: { slug: string; title: string } }
export type PositionedPageSlugsTable = Record<PositionedPageEnum, PositionedPageSlugs>
export type PositionedPageIdentifierLookup = Record<string, PositionedPageEnum>

@Injectable({
    providedIn: 'root',
})
export class PageService implements OnDestroy {
    public readonly slugsTable$: Observable<PositionedPageSlugsTable>
    public slugsTable?: PositionedPageSlugsTable
    private identifierLookup: PositionedPageIdentifierLookup

    private slugsTableSubscription: Subscription
    private identifierLookupSubscription: Subscription

    constructor(
        private readonly pageQueryService: PageQueryService,
        private readonly positionedPagesQueryService: PositionedPagesQueryService,
    ) {
        const requestSlugs$ = this.positionedPagesQueryService.fetch({
            positionedIdentifier: Object.values(PositionedPageEnum),
        }).pipe(
            map(path<PositionedPageFragment[]>(['data', 'positionedPages'])),
        )
        this.slugsTable$ = requestSlugs$.pipe(
            map((slugsList) => {
                return (slugsList ?? []).reduce((acc, val) => {
                    acc[val.identifier as PositionedPageEnum] = val.localizations.reduce((s, l) => {
                        s[l.locale as Locale] = {
                            slug: l.slug,
                            title: l.title,
                        }
                        return s
                    }, {} as PositionedPageSlugs)
                    return acc
                }, {} as PositionedPageSlugsTable)
            }),
            shareReplayOne(),
        )

        this.identifierLookupSubscription = requestSlugs$.pipe(
            map((slugsList) => {
                return (slugsList ?? []).reduce((acc, val) => {
                    const slugs = val.localizations.reduce((s, l) => {
                        s[l.locale as Locale] = {
                            slug: l.slug,
                            title: l.title,
                        }
                        return s
                    }, {} as PositionedPageSlugs)
                    acc[slugs.en?.slug] = val.identifier as PositionedPageEnum
                    acc[slugs.nl?.slug] = val.identifier as PositionedPageEnum
                    // acc[slugs.de?.slug] = val.identifier as PositionedPageEnum
                    // acc[slugs.fr?.slug] = val.identifier as PositionedPageEnum
                    return acc
                }, {} as PositionedPageIdentifierLookup)
            }),
        ).subscribe((lookup) => {
            this.identifierLookup = lookup
        })

        this.slugsTableSubscription = this.slugsTable$.subscribe((table) => {
            this.slugsTable = table
        })
    }

    public ngOnDestroy(): void {
        this.slugsTableSubscription?.unsubscribe()
        this.identifierLookupSubscription?.unsubscribe()
    }

    public getPageBySlug(slug: string, locale: LocalesEnum): Observable<Maybe<PageFragment> | undefined> {
        return this.pageQueryService.fetch({ slug, locale }, {
            errorPolicy: 'none',
        }).pipe(
            map((page) => page.data.page),
        )
    }

    public getIdentifierFromSlug(slug: string): PositionedPageEnum {
        return this.identifierLookup[slug]
    }
}
