import { Component, Input } from '@angular/core'
import { FormControl } from '@angular/forms'

import { CheckboxComponent } from '@app-domains/ui/components/checkbox/checkbox.component'

@Component({
    selector: 'app-multi-select',
    templateUrl: './multi-select.component.html',
    styleUrls: ['./multi-select.component.scss'],
})
export class MultiSelectComponent<T = string> extends CheckboxComponent<T> {

    @Input()
    public override control: FormControl<T[] | null>

}
