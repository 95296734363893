<app-grid>
    <app-cell mobile="0:12">
        <div class="top-content">
            <app-button
                    *ngIf="accordion.button as button"
                    [href]="button.link.link"
                    [type]="button.link.link ? 'link' : 'button' "
                    [cmsTheme]="button.type"
                    [cmsIcon]="button.icon ?? undefined"
                    [layout]="(button?.icon && button.link.label === ' ') ? 'icon-only'
                                : (button?.icon && button.link.label) ? 'icon-label'
                                : 'label-only'
                            "
                    [cmsTarget]="button.link.target"
            >
               {{ button.link.label }}
            </app-button>

            <div class="subject-title">
                <div class="icon-bubble">
                    <span *ngIf="accordion.icon" [appIconEnum]="accordion.icon" class="icon"></span>
                </div>

                <h2>
                    {{ accordion.title }}
                </h2>
            </div>
        </div>

        <div class="mid-content">
            <div *ngFor="let item of accordion.accordionItems">
                <app-expansion-panel [allowMultiOpen]="false"
                                     height="small"
                >
                    <app-expansion-item>
                        <ng-template expansionPanelTitle>
                            <label class="bold">
                                {{ item.title }}
                            </label>
                        </ng-template>

                        <ng-template expansionPanelContent>
                            <p [innerHTML]="item.description | safe: 'html'"></p>
                        </ng-template>
                    </app-expansion-item>
                </app-expansion-panel>
            </div>
        </div>

        <hr class="hr">
    </app-cell>
</app-grid>
