import { Component, EventEmitter, Input, Output } from '@angular/core'
import { RouterLink } from '@angular/router'

import { Button } from '@app-domains/ui/components/button/button.component.types'
import { LinkTargetEnum } from '@app-graphql/schema'

@Component({
    selector: 'app-chevron-link',
    templateUrl: './chevron-link.component.html',
    styleUrls: [ './chevron-link.component.scss' ],
})
export class ChevronLinkComponent {

    @Input()
    public iconColor: 'light' | 'dark' = 'light'

    @Input()
    public linkColor: 'light' | 'dark' = 'dark'

    @Input()
    public link: string | RouterLink['routerLink']

    @Input()
    public notificationCount: number

    @Input()
    public size: Button.Size = 'normal'

    @Input()
    public target: LinkTargetEnum = LinkTargetEnum.Self

    @Output()
    public linkClicked = new EventEmitter<void>()

    public clicked(): void {
        this.linkClicked.emit()
    }
}
