import { Injectable } from '@angular/core'
import {
    ForgotPasswordMutation,
    ForgotPasswordMutationService,
    ForgotPasswordMutationVariables,
    UpdateForgottenPasswordMutation,
    UpdateForgottenPasswordMutationService,
    UpdateForgottenPasswordMutationVariables,
    UpdatePasswordMutation,
    UpdatePasswordMutationService,
    UpdatePasswordMutationVariables,
} from '@app-graphql/schema'
import { InputPublic } from '@app-domains/ui/components/input/input.component.types'
import { Observable } from 'rxjs'
import { MutationResult } from 'apollo-angular'

@Injectable({
    providedIn: 'root',
})
export class PasswordService {

    public passwordFormType: InputPublic.Type = 'password'

    public passwordErrorMessages: InputPublic.ErrorMessageRecord = {
        required: 'validation.messages.required',
        minlength: 'validation.messages.min-length-8',
        noNumber: 'validation.messages.no-number',
        noUppercase: 'validation.messages.no-uppercase',
    }

    constructor(
        private forgotPasswordMutationService: ForgotPasswordMutationService,
        private updatePasswordMutationService: UpdatePasswordMutationService,
        private updateForgottenPasswordMutation: UpdateForgottenPasswordMutationService,
    ) {
    }

    public forgotPassword(input: ForgotPasswordMutationVariables): Observable<MutationResult<ForgotPasswordMutation>> {
        return this.forgotPasswordMutationService.mutate(input)
    }

    public updatePassword(input: UpdatePasswordMutationVariables): Observable<MutationResult<UpdatePasswordMutation>> {
        return this.updatePasswordMutationService.mutate(input)
    }

    public updateForgottenPassword(input: UpdateForgottenPasswordMutationVariables)
        : Observable<MutationResult<UpdateForgottenPasswordMutation>> {
        return this.updateForgottenPasswordMutation.mutate(input)
    }

    public changeFormType(): void {
        this.passwordFormType = this.passwordFormType === 'password' ? 'text' : 'password'
    }

}
