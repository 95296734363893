import { AbstractTranslationStore, NgxTranslateStore, TranslateRouterModule } from '@endeavour/ngx-translate-router'

import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { locales } from '@aa-app-localization'
import { ApiAvailableGuard } from '@app-guards/api-available/api-available.guard'
import { LoggedInGuard } from '@app-guards/logged-in/logged-in.guard'
import { NotLoggedInGuard } from '@app-guards/not-logged-in/not-logged-in.guard'
import { UserIsLoggedInGuard } from '@app-guards/user-is-logged-in/user-is-logged-in.guard'
import { DebtorCodeGuard } from '@app-guards/debtor-code/debtor-code.guard'
import { HasDatafeedRightGuard } from '@app-guards/has-datafeed-right/has-datafeed-right.guard'
import { IsNotEnhancedGuard } from '@app-guards/is-not-enhanced/is-not-enhanced.guard'

const routes: Routes = [
    {
        path: '',
        canActivate: [
            ApiAvailableGuard, // Todo: re-enable once the API endpoint is fixed.
        ],
        children: [
            {
                path: '',
                pathMatch: 'full',
                loadChildren: () => import('./pages/home/home.module').then((m) => m.HomeModule),
            },
            {
                path: 'register',
                pathMatch: 'full',
                canActivate: [
                    NotLoggedInGuard,
                ],
                loadChildren: () => import('./pages/register/register.module').then((m) => m.RegisterModule),
            },
            {
                path: 'category',
                loadChildren: () => import('./pages/category/category.module').then((m) => m.CategoryModule),
            },
            {
                path: 'brand',
                loadChildren: () => import('./pages/brand/brand.module').then((m) => m.BrandModule),
            },
            {
                path: 'theme',
                loadChildren: () => import('./pages/theme/theme.module').then((m) => m.ThemeModule),
            },
            {
                path: 'dashboard',
                canActivate: [
                    LoggedInGuard,
                ],
                loadChildren: () => import('./pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
            },
            {
                path: 'authentication',
                loadChildren: () => import('./pages/auth/auth.module').then((m) => m.AuthModule),
            },
            {
                path: 'dealers',
                loadChildren: () => import('./pages/dealers/dealers.module').then((m) => m.DealersModule),
            },
            {
                path: 'news',
                loadChildren: () => import('./pages/news/news.module').then((m) => m.NewsModule),
            },
            {
                path: 'wishlist',
                canActivate: [
                    LoggedInGuard,
                ],
                loadChildren: () => import('./pages/wishlist/wishlist.module').then((m) => m.WishlistModule),
            },
            {
                path: 'orders',
                canActivate: [
                    LoggedInGuard,
                    DebtorCodeGuard,
                ],
                loadChildren: () => import('./pages/orders/orders.module').then((m) => m.OrdersModule),
            },
            {
                path: 'enhance-debtor',
                canActivate: [
                    LoggedInGuard,
                    IsNotEnhancedGuard,
                ],
                loadChildren: () => import('./pages/enhance-debtor/enhance-debtor.module')
                    .then((m) => m.EnhanceDebtorModule),
            },
            {
                path: 'p',
                loadChildren: () => import('./pages/page/page.module').then((m) => m.PageModule),
            },
            {
                path: 'products',
                loadChildren: () => import('./pages/product/product.module').then((m) => m.ProductModule),
            },
            {
                path: 'assortment',
                canActivate: [
                    LoggedInGuard,
                    DebtorCodeGuard,
                ],
                loadChildren: () => import('./pages/assortment/assortment.module').then((m) => m.AssortmentModule),
            },
            {
                path: 'experience-center',
                loadChildren: () => import('./pages/experience-center-overview/experience-center-overview.module')
                    .then((m) => m.ExperienceCenterOverviewModule),
            },
            {
                path: 'events',
                loadChildren: () => import('./pages/events/events.module').then((m) => m.EventsModule),
            },
            {
                path: 'my-data',
                canActivate: [
                    LoggedInGuard,
                    DebtorCodeGuard,
                ],
                loadChildren: () => import('./pages/my-data/my-data.module').then((m) => m.MyDataModule),
            },
            {
                path: 'shopping-cart',
                canActivate: [
                    UserIsLoggedInGuard,
                    DebtorCodeGuard,
                ],
                loadChildren: () => import('./pages/shopping-cart/shopping-cart.module').then((m) =>
                    m.ShoppingCartModule),
            },
            {
                path: 'checkout',
                canActivate: [
                    LoggedInGuard,
                ],
                loadChildren: () => import('./pages/checkout/checkout.module').then((m) => m.CheckoutModule),
            },
            {
                path: 'search',
                loadChildren: () => import('./pages/search/search.module').then((m) => m.SearchModule),
            },
            {
                path: 'data-feeds',
                canActivate: [
                    UserIsLoggedInGuard,
                    DebtorCodeGuard,
                    HasDatafeedRightGuard,
                ],
                loadChildren: () => import('./pages/data-feeds/data-feeds.module').then((m) => m.DataFeedsModule),
            },
        ],
    },
    {
        path: '**',
        redirectTo: '/',
    },
]

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            initialNavigation: 'disabled',
            anchorScrolling: 'disabled',
            scrollPositionRestoration: 'enabled',
        }),
        TranslateRouterModule.forRoot(routes, {
            cache: TranslateRouterModule.defaultCookieCacheConfig,
            supportedLocales: locales,
            usePrefixForDefaultLocale: true,
            initialNavigation: true,
            translationStore: {
                provide: AbstractTranslationStore,
                useClass: NgxTranslateStore,
            },
        }),
    ],
    exports: [
        RouterModule,
        TranslateRouterModule,
    ],
})
export class AppRoutingModule {
}
