import { animate, style, transition, trigger } from '@angular/animations'
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Input,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core'
import { CarouselSlideFragment, CarouselThemeEnum } from '@app-graphql/schema'
import { SwiperLib } from '@app-lib/swiper.lib'
import { SwiperService } from '@app-services'
import Swiper, { SwiperOptions } from 'swiper'

@Component({
    selector: 'app-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
    animations: [
        trigger('fade', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate(250, style({ opacity: 1 })),
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate(250, style({ opacity: 0 })),
            ]),
        ]),
    ],
})
export class CarouselComponent implements OnInit {

    @ViewChild('pagination', { static: true })
    public pagination: ElementRef<HTMLDivElement>

    @Input()
    public theme: CarouselThemeEnum = CarouselThemeEnum.Dark

    @Input()
    public slides: CarouselSlideFragment[] = []

    @Input()
    public title: string

    @Input()
    public subtitle: string | null | undefined

    @Input()
    public showHeader: boolean = true

    @Input()
    public showScrollBar: boolean = false

    @Input()
    public lazyLoad: boolean = true

    public swiper: SwiperLib
    public swiperOptions: SwiperOptions

    constructor(
        private cdr: ChangeDetectorRef,
        private swiperService: SwiperService,
    ) {
    }

    public ngOnInit(): void {
        this.swiperOptions = {
            slidesPerView: 'auto',
            spaceBetween: 17,
            loop: true,
            pagination: {
                type: 'progressbar',
                el: this.pagination.nativeElement,
            },
            on: {
                progress: () => {
                    if (this.swiper) {
                        this.swiper.onProgress()
                    }
                },
            },
            direction: 'horizontal',
            breakpoints: {
                1224: {
                    spaceBetween: 32,
                },
            },
        }
    }

    public onSwiper(swiper: Swiper): void {
        this.swiper = this.swiperService.create(swiper)
        this.swiper.updateTotalIndex()
    }

    public onSlideChange(): void {
        if (this.swiper) {
            this.swiper.onSlideChange()
            this.cdr.detectChanges()
        }
    }

    @HostListener('window:resize')
    onResize() {
        this.swiper.updateTotalIndex()
    }
}
