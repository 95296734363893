<div class="top">
    <h3 class="header">
        {{ 'other-products.other-products' | translate }}
    </h3>

    <app-tab-group tabJustify="end"
                   class="no-margin"
                   containerSize="large"
    >
        <app-tab *ngIf="(instantSearchConfig$ | async) && (relatedProductsHit$ | async)"
                 [label]="'other-products.related' | translate"
                 [value]="Categories.RELATED"
        >
            <ng-container *ngIf="instantSearchConfig$ | async as instantSearchConfig">
                <ng-container *ngIf="configs$ | async as configs">
                    <ng-container *ngIf="relatedProductsHit$ | async as hit">
                        <app-algolia-instantsearch [config]="instantSearchConfig">
                            <app-algolia-configure [searchParameters]="configs.related"></app-algolia-configure>

                            <app-algolia-experimental-configure-related-items [hit]="hit"
                                                                      [matchingPatterns]="{
                                                                            group: { score: 1 },
                                                                            range: { score: 1 },
                                                                            brand: { score: 1 }
                                                                      }"
                            ></app-algolia-experimental-configure-related-items>

                            <div class="grid-container">
                                <app-other-products-hits></app-other-products-hits>
                            </div>
                        </app-algolia-instantsearch>

                        <div class="bottom">
                            <app-button theme="line"
                                        (click)="loadMoreProducts(OtherProductsCategories.RELATED)"
                            >
                                {{ 'products.show-more-products' | translate }}
                            </app-button>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </app-tab>

        <app-tab *ngIf="(instantSearchConfig$ | async) && (lastViewedProductsConfig$ | async)"
                 [label]="'other-products.last-viewed' | translate"
                 [value]="Categories.RECENT"
        >
            <ng-container *ngIf="instantSearchConfig$ | async as instantSearchConfig">
                <ng-container *ngIf="lastViewedProductsConfig$ | async as lpConfig">
                    <app-algolia-instantsearch [config]="instantSearchConfig">
                        <app-algolia-configure [searchParameters]="lpConfig"></app-algolia-configure>

                        <div class="grid-container">
                            <app-other-products-hits [objectIDOrder]="productHistoryService.history$ | async"></app-other-products-hits>
                        </div>
                    </app-algolia-instantsearch>

                    <div class="bottom">
                        <app-button theme="line"
                                    (click)="loadMoreProducts(OtherProductsCategories.RECENT)"
                        >
                            {{ 'products.show-more-products' | translate }}
                        </app-button>
                    </div>
                </ng-container>
            </ng-container>
        </app-tab>

        <app-tab *ngIf="(instantSearchConfig$ | async) && (sameSeriesHit$ | async)"
                 [label]="'other-products.same-series' | translate"
                 [value]="Categories.SAME_SERIES"
        >
            <ng-container *ngIf="instantSearchConfig$ | async as instantSearchConfig">
                <ng-container *ngIf="configs$ | async as configs">
                    <app-algolia-instantsearch [config]="instantSearchConfig">
                        <app-algolia-configure [searchParameters]="configs.same_series"></app-algolia-configure>

                        <app-algolia-experimental-configure-related-items *ngIf="sameSeriesHit$ | async as hit"
                                                                     [hit]="hit"
                                                                     [matchingPatterns]="{ range: { score: 1 } }"
                        ></app-algolia-experimental-configure-related-items>

                        <div class="grid-container">
                            <app-other-products-hits></app-other-products-hits>
                        </div>
                    </app-algolia-instantsearch>

                    <div class="bottom">
                        <app-button theme="line"
                                    (click)="loadMoreProducts(OtherProductsCategories.SAME_SERIES)"
                        >
                            {{ 'products.show-more-products' | translate }}
                        </app-button>
                    </div>
                </ng-container>
            </ng-container>
        </app-tab>

        <app-tab *ngIf="(instantSearchConfig$ | async) && (sameBrandHit$ | async)"
                 [label]="'other-products.same-brand' | translate"
                 [value]="Categories.SAME_BRAND"
        >
            <ng-container *ngIf="instantSearchConfig$ | async as instantSearchConfig">
                <ng-container *ngIf="configs$ | async as configs">
                    <app-algolia-instantsearch [config]="instantSearchConfig">
                        <app-algolia-configure [searchParameters]="configs.same_brand"></app-algolia-configure>

                        <app-algolia-experimental-configure-related-items *ngIf="sameBrandHit$ | async as hit"
                                                                  [hit]="hit"
                                                                  [matchingPatterns]="{ brand: { score: 1 } }"
                        ></app-algolia-experimental-configure-related-items>
                        <div class="grid-container">
                            <app-other-products-hits></app-other-products-hits>
                        </div>
                    </app-algolia-instantsearch>

                    <div class="bottom">
                        <app-button theme="line"
                                    (click)="loadMoreProducts(OtherProductsCategories.SAME_BRAND)"
                        >
                            {{ 'products.show-more-products' | translate }}
                        </app-button>
                    </div>
                </ng-container>
            </ng-container>
        </app-tab>
    </app-tab-group>
</div>
