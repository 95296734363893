import { equals, isNil } from 'ramda'
import {
    combineLatestWith,
    delay,
    first,
    firstValueFrom,
    merge,
    Observable,
    of,
    pipe,
    startWith,
    Subject,
    switchMap,
} from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { TranslateRouterService } from '@endeavour/ngx-translate-router'

import { Component, forwardRef, Inject, Input, OnInit, Optional } from '@angular/core'
import { FormControl } from '@angular/forms'
import { Router } from '@angular/router'

import { Product, ProductCard } from '@app-domains/content-blocks/components/product-card/product-card.types'
import { Analytics } from '@app-domains/analytics/types/analytics.types'
import { Breakpoint } from '@app-domains/ui/services/breakpoints/breakpoints.service.types'
import { StatusEnum } from '@app-domains/components/product-availability/product-availability.component'
import { BreakpointsService } from '@app-domains/ui/services/breakpoints/breakpoints.service'
import { LocalizationService } from '@app-domains/localization/service/localization.service'
import { AnalyticsService } from '@app-domains/analytics/analytics.service'
import { AlgoliaInstantsearchComponent } from '@app-domains/algolia/components/algolia-instantsearch.component'
import {
    AuthService,
    CheckoutService,
    DebtorService,
    ProductSelectionService,
    ProductEnhanceService,
    WishlistService,
} from '@app-services'

@Component({
    selector: 'app-product-card',
    templateUrl: './product-card.component.html',
    styleUrls: ['./product-card.component.scss'],
    animations: [ ProductCard.hoverAnimation ],
})

export class ProductCardComponent implements OnInit {

    @Input()
    public product: Product

    @Input()
    public highlightItem: boolean

    @Input()
    public forceHover = false

    @Input()
    public theme: 'product' | 'wishlist' = 'product'

    @Input()
    public XLarge: boolean = false

    public quantityControl = new FormControl(1)

    public hoverContainerSubject = new Subject<boolean>()

    public isInSelectMode$ = this.productSelectionService.isInSelectMode$

    public isLoading$ = this.checkoutService.isLoading$

    public removingFromWishlist: boolean = false

    public statusEnum = StatusEnum

    public debtorProfileIsIncomplete: boolean

    constructor(
        public readonly authService: AuthService,
        public readonly productSelectionService: ProductSelectionService,
        public readonly productEnhanceService: ProductEnhanceService,
        public readonly debtorService: DebtorService,
        @Optional()
        @Inject(forwardRef(() => AlgoliaInstantsearchComponent))
        private readonly search: AlgoliaInstantsearchComponent,
        private readonly translateRouterService: TranslateRouterService,
        private readonly localization: LocalizationService,
        private readonly analytics: AnalyticsService,
        private readonly checkoutService: CheckoutService,
        private readonly router: Router,
        private readonly breakpointsService: BreakpointsService,
        private readonly wishlistService: WishlistService,
    ) {
    }

    public ngOnInit(): void {
        firstValueFrom(this.authService.user$).then((user) => {
            if (isNil(user)) {
                this.debtorProfileIsIncomplete = false
                return
            }

            if (! user!.debtorCode) {
                this.debtorProfileIsIncomplete = true
            }
        })
    }

    public async onCardClick(): Promise<void> {
        if (this.isInSelectMode$.getValue()) {
            this.changeSelectedState()
            return
        } else if (this.search) {
            this.sendGTMEvent()
        }

        const urlCommands = this.translateRouterService.translateRouteLink([
            '/products',
            this.product.slug,
        ]) as string[]

        await this.router.navigate(urlCommands)
    }

    public async addToCart(): Promise<void> {
        await this.checkoutService.addToShoppingCart([
            {
                ean: this.product.ean,
                quantity: Number(this.quantityControl.value),
            },
        ])
    }

    public async addProductToWishlist(event: MouseEvent): Promise<void> {
        event.stopPropagation()

        if (this.product.inFavourites) {
            this.product.inFavourites = ! this.product.inFavourites
            await this.removeFromWishlist()
            return
        }

        this.analytics.logWishlistAdd(this.setupGTag())

        this.product.inFavourites = ! this.product.inFavourites

        await firstValueFrom(
            this.wishlistService.addToWishlist([this.product.ean]),
        )

        await this.wishlistService.refreshWishlist()
    }

    public async removeFromWishlist(event?: MouseEvent): Promise<void> {
        this.removingFromWishlist = true

        event?.stopPropagation()

        this.analytics.logWishlistRemove(this.setupGTag())

        await firstValueFrom(
            this.wishlistService.removeFromWishlist([this.product.ean]),
        )

        await this.wishlistService
            .refreshWishlist()
            .then(() => this.removingFromWishlist = false)
    }

    public changeSelectedState(): void {
        if (this.productSelectionService.EANCodes.has(this.product.ean)) {
            this.productSelectionService.removeSelectedProduct(this.product.ean)
        } else {
            this.productSelectionService.addSelectedProduct(this.product.ean)
        }
    }

    private sendGTMEvent(): void {
        this.search.instantSearchInstance.sendEventToInsights({
            insightsMethod: 'clickedObjectIDs',
            payload: {
                index: this.search.instantSearchInstance.indexName,
                eventName: 'Click item',
                queryID: this.search.instantSearchInstance.helper?.lastResults?.queryID ?? '',
                objectIDs: [this.product.ean],
                positions: [1],
            },
            eventType: 'click',
            widgetType: 'product-card',
        })

        this.analytics.logProductClick(this.setupGTag())
    }

    private setupGTag(): Analytics.PItem[] {
        const props = {
            quantity: 1,
        }

        const locale = this.localization.getCurrentLocale()
        const productData = { ...this.product, ...props }
        const pItem: Analytics.PItem = this.analytics.transformProductToPItem(locale, productData)

        return [pItem]
    }

    public buildAnimationParams = (currentBreakpoint$: Observable<Breakpoint>) => pipe(
        switchMap((isHovered) => {
            return merge(
                of(isHovered).pipe(delay(20)),
                this.hoverContainerSubject.pipe(filter(equals(! isHovered))),
            ).pipe(
                first(),
            )
        }),
        combineLatestWith(currentBreakpoint$),
        map(([shouldExpand, breakpoint]) => {
            const bpUpLaptop = (breakpoint === 'laptop' || breakpoint === 'desktop')
            const expand = bpUpLaptop ? shouldExpand : false
            return {
                value: expand ? 'expand' : 'none',
            } as ProductCard.AnimationParams
        }),
        startWith({ value: 'none' } as ProductCard.AnimationParams),
    )

    public animation$: Observable<ProductCard.AnimationParams> = this.hoverContainerSubject.pipe(
        this.buildAnimationParams(this.breakpointsService.currentBreakpoint$),
    )

}
