import { Injectable } from '@angular/core'
import { DeleteAccountMutation, DeleteAccountMutationService } from '@app-graphql/schema'
import { Observable } from 'rxjs'
import { MutationResult } from 'apollo-angular'

@Injectable({
    providedIn: 'root',
})
export class DeleteAccountService {

    constructor(
        private deleteAccountMutationService: DeleteAccountMutationService,
    ) {
    }

    public deleteAccount(): Observable<MutationResult<DeleteAccountMutation>> {
        return this.deleteAccountMutationService.mutate()
    }
}
