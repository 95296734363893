<div *ngIf="data"
     class="cutoff"
     [class.transparent-background]="transparentBackground"
>
    <app-grid>
        <app-cell laptop="3:full"
                  desktop="3:full"
                  [responsiveStyle$]="{
            mobile: {
                display: 'none'
            },
            tablet: {
                display: 'none'
            }
        } | responsiveStyle$">
            <div class="bar"></div>
        </app-cell>

        <app-cell mobile="full:0"
                  tablet="full:0"
                  [responsiveStyle$]="{
            laptop: {
                display: 'none'
            },
            desktop: {
                display: 'none'
            }
        } | responsiveStyle$">
            <div class="gutter"></div>
        </app-cell>

        <app-cell desktop="0:5" laptop="0:5" tablet="0:12" mobile="0:12">
            <div class="big-image">
                <app-responsive-image *ngIf="data.mainImage"
                                      [image]="data.mainImage"
                                      [lazyLoad]="lazyLoad"
                ></app-responsive-image>
            </div>
        </app-cell>

        <app-cell mobile="12:full"
                  [responsiveStyle$]="{
            laptop: {
                display: 'none'
            },
            desktop: {
                display: 'none'
            }
        } | responsiveStyle$">
            <div class="gutter"></div>
        </app-cell>

        <app-cell mobile="full:0"
                  tablet="full:0"
                  [responsiveStyle$]="{
            laptop: {
                display: 'none'
            },
            desktop: {
                display: 'none'
            }
        } | responsiveStyle$">
            <div class="gutter"></div>
        </app-cell>

        <app-cell desktop="5:12" laptop="5:12" tablet="0:12" mobile="0:12">
            <div class="content-container">
                <div>
                    <h3 class="subheading serif">
                        {{ data.subtitle }}
                    </h3>

                    <h1>
                        {{ data.title }}
                    </h1>

                    <div class="body">
                        <p class="summary">
                            {{ data.text }}
                        </p>

                        <div class="buttons">
                            <div *ngFor="let link of data.pageLinks">
                                <app-chevron-link [link]="link.link"
                                                  [target]="link.target"
                                >
                                    {{ link.label }}
                                </app-chevron-link>
                            </div>
                        </div>
                    </div>

                    <div class="images">
                        <ng-container *ngIf="data.contentImage?.length; else emptyImage">
                            <div *ngFor="let contentImage of data.contentImage"
                                  class="image"
                            >
                                <app-responsive-image [image]="contentImage"
                                                      [lazyLoad]="lazyLoad"
                                ></app-responsive-image>
                            </div>
                        </ng-container>

                        <ng-template #emptyImage>
                            <div class="image empty"></div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </app-cell>

        <app-cell mobile="12:full">
            <div class="gutter"></div>
        </app-cell>

        <app-cell laptop="3:full"
                  desktop="3:full"
                  [responsiveStyle$]="{
            mobile: {
                display: 'none'
            },
            tablet: {
                display: 'none'
            }
        } | responsiveStyle$">
            <div class="bar bottom"></div>
        </app-cell>
    </app-grid>
</div>
