import { Component, Input } from '@angular/core'

@Component({
    selector: 'app-notification-badge',
    templateUrl: './notification-badge.component.html',
    styleUrls: ['./notification-badge.component.scss'],
})
export class NotificationBadgeComponent {

    @Input()
    public count: number

    @Input()
    public color: 'grey-4' | 'negative' = 'grey-4'

}
