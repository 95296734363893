import { EventEmitter, Injectable } from '@angular/core'
import { FormControl } from '@angular/forms'
import {
    CustomOption,
    Feed,
    Field,
    InputMaybe, Maybe,
    UpdateFeedOptionsMutation,
    UpdateFeedOptionsMutationService,
} from '@app-graphql/schema'
import { DataFeeds } from '../../pages/data-feeds/types/data-feeds.types'
import { Observable } from 'rxjs'
import { MutationResult } from 'apollo-angular'
import FeedType = DataFeeds.FeedType
import FileType = DataFeeds.FileType

@Injectable({
    providedIn: 'root',
})
export class DataFeedService {

    public feedType = new FormControl<FeedType>('products', {
        nonNullable: true,
    })
    public fileType = new FormControl<FileType>('csv', {
        nonNullable: true,
    })
    public selectedFields: number = 0

    public selectAllUpdated = new EventEmitter<boolean>()

    constructor(
        private updateFeedOptionsMutationService: UpdateFeedOptionsMutationService,
    ) {
    }

    public cleanFields(fields: InputMaybe<Field>[]): { name: string; child: Maybe<string> | undefined }[] {
        return fields.map((field) => {
            return {
                name: field!.name,
                child: field!.child,
            }
        })
    }

    public cleanOptions(customFields: InputMaybe<CustomOption>[]): { key: string; alias: string; selected: boolean }[] {
        return customFields.map((field) => {
            return {
                key: field!.key,
                alias: field!.alias,
                selected: field!.selected,
            }
        })
    }


    public updateFeedOptions(productFeed: Feed, stockFeed: Feed)
        : Observable<MutationResult<UpdateFeedOptionsMutation>> {
        return this.updateFeedOptionsMutationService.mutate({
            products: {
                fields: this.cleanFields(productFeed.fields),
                options: this.cleanOptions(productFeed.options),
            },
            stocks: {
                fields: this.cleanFields(stockFeed.fields),
                options: this.cleanOptions(stockFeed.options),
            },
        })
    }
}
