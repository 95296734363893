<app-grid>
    <app-cell mobile="0:12">
        <app-component-with-text [preHeader]="preHeader"
                                 [header]="header"
                                 [content]="content"
        >
            <app-video-player [video]="video"></app-video-player>
        </app-component-with-text>
    </app-cell>
</app-grid>

