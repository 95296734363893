<app-grid>
    <app-cell mobile="full:0"
              [responsiveStyle$]="{
                    base: {
                        display: 'block'
                    },
                    mobile: {
                        display: 'none'
                    }
              } | responsiveStyle$"
    >
        <app-scroll-indicator *ngIf="images.length > 1"></app-scroll-indicator>
    </app-cell>

    <app-cell mobile="full:full" tablet="0:12">
        <div class="image-header">
            <swiper *ngIf="images.length > 1; else singleImage" [config]="swiperConfig">
                <ng-template *ngFor="let image of images"
                             swiperSlide
                >
                    <div class="slide"
                         [class.full-size]="layout === headerLayoutEnum.FullSize"
                    >
                        <app-responsive-image [image]="image"
                                              [lazyLoad]="lazyLoad"
                        ></app-responsive-image>
                    </div>
                </ng-template>
            </swiper>
        </div>
    </app-cell>
</app-grid>

<ng-template #singleImage>
    <div class="slide"
         [class.full-size]="layout === headerLayoutEnum.FullSize"
    >
        <app-responsive-image
            [image]="images[0]"
            [lazyLoad]="lazyLoad"
        ></app-responsive-image>
    </div>

    <div *ngIf="title || subtitle"
         class="text-content"
    >
        <h1 *ngIf="title">{{ title }}</h1>
        <p *ngIf="subtitle">{{ subtitle }}</p>
    </div>
</ng-template>
