<ng-container *ngIf="localization.currentLocale$ | async as currentLang">
    <button type="button"
            [cdkMenuTriggerFor]="dropdown"
            (cdkMenuClosed)="showDropdown = false"
            (cdkMenuOpened)="showDropdown = true"
            class="main-link"
    >
        <span>{{ currentLang | uppercase }}</span>

        <span appIcon="chevron-down"
              class="chevron"
              [class.inverted]="showDropdown"
        ></span>
    </button>

    <ng-template #dropdown>
        <div cdkMenu class="dropdown">
            <p class="heading">
                {{ 'common.language' | translate }}
            </p>

            <div class="options">
                <a *ngFor="let lang of langList"
                   [ssrHref]="'/' + lang"
                >
                    <button (click)="setLanguage(lang)"
                            type="button"
                            cdkMenuItem
                    >
                        <span *ngIf="currentLang === lang"
                              appIcon="checkmark"
                              class="checkmark"
                        ></span>

                        {{ langLabels[lang] }}
                    </button>
                </a>
            </div>

            <ng-container *ngIf="authService.user$ | async else countrySelect"></ng-container>

            <ng-template #countrySelect>
                <hr class="underline">

                <p class="heading">
                    {{ 'register.country' | translate }}
                </p>

                <div class="options">
                    <button [disabled]="currentCountry === country.value"
                            *ngFor="let country of countries"
                            cdkMenuItem
                            type="button"
                            (click)="setCountry(country.value)"
                    >
                        <span *ngIf="currentCountry === country.value"
                              appIcon="checkmark"
                              class="checkmark"
                        ></span>

                        {{ country.label | translate }}
                    </button>
                </div>
            </ng-template>
        </div>
    </ng-template>
</ng-container>
