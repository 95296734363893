import { Subscription } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'

import { DialogRef } from '@angular/cdk/dialog'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, NonNullableFormBuilder } from '@angular/forms'

import { LocalizationService } from '@app-domains/localization/service/localization.service'
import { DownloadInput } from '@app-graphql/schema'
import { DebtorService, Download } from '@app-services'

export type Config = null | 'productSheetsForm' | 'dataSheetsForm' | 'priceListForm'

@Component({
    selector: 'app-product-download-config-modal',
    templateUrl: './product-download-config-modal.component.html',
    styleUrls: ['./product-download-config-modal.component.scss' ],
})
export class ProductDownloadConfigModalComponent implements OnInit, OnDestroy {
    private fb: NonNullableFormBuilder = this.formBuilder.nonNullable

    public selectedTypes = this.fb.control<string[]>([
        'productsheet',
        'manuals',
        'pricelist',
        'datasheet',
    ])

    public selectAllControls = this.fb.group({
        pricelist: true,
        productsheet: true,
    })

    public pricelistConfig = Download.pricelistConfig
    public pricelistFields = this.fb.control<Download.PricelistFields[]>(
        Object.values(this.pricelistConfig).map((c) => c.control),
    )
    public pricelistOptions = this.fb.group<Download.PricelistOptions>({
        price: this.debtorService.isNL.getValue() ? 'regular' : 'ISE',
    })

    public productsheetConfig = Download.productsheetConfig
    public productsheetFields = this.fb.control<Download.ProductsheetFields[]>(
        Object.values(this.productsheetConfig).map((c) => c.control),
    )

    public dataSheetConfig = Download.datasheetConfig
    public dataSheetOptions = this.fb.control<Download.DataSheetFields>(
        this.translate.instant(this.dataSheetConfig[0].label),
    )

    public currentConfig: Config = null

    private formControlSubscriptions: Subscription[] = []

    constructor(
        public dialogRef: DialogRef,
        private readonly formBuilder: FormBuilder,
        private localization: LocalizationService,
        private debtorService: DebtorService,
        private translate: TranslateService,
    ) {
    }

    public ngOnInit(): void {
        this.formControlSubscriptions.push(
            this.selectAllControls.controls.pricelist.valueChanges.subscribe(
                (selectAll) => {
                    this.pricelistFields.patchValue(
                        selectAll
                            ? this.pricelistConfig.map((c) => c.control)
                            : [],
                    )
                },
            ),
        )

        this.formControlSubscriptions.push(
            this.selectAllControls.controls.productsheet.valueChanges.subscribe(
                (selectAll) => {
                    this.productsheetFields.patchValue(
                        selectAll
                            ? this.productsheetConfig.map((c) => c.control)
                            : [],
                    )
                },
            ),
        )
    }

    public closeDialog(): void {
        const type = this.selectedTypes.value
        const options: { [k: string]: any } = {}
        const fields: { [k: string]: any } = {}

        if (type.includes('pricelist')) {
            options.pricelist = this.pricelistOptions.value
            fields.pricelist = this.pricelistFields.value
        }

        if (type.includes('datasheet')) {
            options.datasheet = this.dataSheetOptions.value
        }

        if (type.includes('productsheet')) {
            fields.productsheet = this.productsheetFields.value
        }

        const optionsStr = JSON.stringify(options)
        const fieldsStr = JSON.stringify(fields)

        const result: DownloadInput = {
            language: this.localization.getCurrentLocale(),
            type,
            identifiers: [],
            options: optionsStr,
            fields: fieldsStr,
        }

        this.dialogRef.close(result)
    }

    public updateCurrentConfig(config: Config): void {
        this.currentConfig = config
    }

    public ngOnDestroy(): void {
        this.formControlSubscriptions.forEach(s => s.unsubscribe())
    }

}
