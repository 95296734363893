import {
    createInsightsMiddleware,
    InsightsEvent,
} from 'instantsearch.js/es/middlewares'

import { Component, Inject, forwardRef, OnInit } from '@angular/core'

import { AlgoliaInstantsearchComponent } from '@app-domains/algolia/components/algolia-instantsearch.component'
import { ALGOLIA_INSIGHTS_CLIENT, InsightsData } from '@app-domains/algolia/algolia.module'

@Component({
    selector: 'app-insights',
    template: '',
})
export class InsightsComponent implements OnInit {
    constructor(
        @Inject(forwardRef(() => AlgoliaInstantsearchComponent))
        public search: AlgoliaInstantsearchComponent,
        @Inject(ALGOLIA_INSIGHTS_CLIENT)
        private insights: InsightsData,
    ) {}

    public ngOnInit() {
        this.search.instantSearchInstance.use(
            createInsightsMiddleware({
                insightsClient: this.insights.client,
                insightsInitParams: {
                    useCookie: true,
                },
                onEvent: this.sendEvent.bind(this),
            }),
        )
    }

    private getQueryID(): string | undefined {
        return this.search.instantSearchInstance.helper?.lastResults?.queryID
    }

    private sendEvent(event: InsightsEvent): void {
        const { insightsMethod, payload } = event

        if (insightsMethod) {
            // @ts-ignore
            payload.queryID = this.getQueryID()
            // @ts-ignore
            this.insights.client(insightsMethod, payload)
        }
    }
}
