<ng-container *ngIf="product">
    <ng-container *ngIf="'/products/' + product.slug | translateRoute as route">
        <div class="search-result">
            <div class="inner-result"
                 (click)="onClick(route)"
            >
                <img [src]="product.primaryImage ? (product.primaryImage | imageSize : 48)
                        : product.ambianceImage ? (product.ambianceImage | imageSize : 48)
                        : 'assets/images/missing-image-icon.svg'
                        "
                     [alt]="product.primaryImage ? ('common.image-of' | translate: { name: product.name }) : 'Missing image of ' + product.name"
                     class="result-image"
                     loading="lazy"
                     fetchpriority="auto"
                />

                <div class="product-name">
                    <span [innerHTML]="firstWord" class="first"></span>
                    <span [innerHTML]="remainingWords" class="remaining"></span>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>

<ng-container *ngIf="otherResult">
    <div class="search-result">
        <div class="inner-result"
             *ngIf="otherResult.link | translateRoute as route"
             (click)="onClick(route)"
        >
            <div class="other-result-details">
                <div [innerHTML]="otherResult.title" class="title"></div>
                <div [innerHTML]="otherResult.description" class="description"></div>
            </div>
        </div>
    </div>
</ng-container>
