import { path } from 'ramda'
import { combineLatestWith, Observable, startWith } from 'rxjs'
import { map } from 'rxjs/operators'

import { Pipe, PipeTransform } from '@angular/core'

import { LocalizationService } from '@app-domains/localization/service/localization.service'
import { PositionedPageEnum } from '@app-graphql/schema'
import { Localization } from '@aa-app-localization'
import { PageService, PositionedPageSlugsTable } from '@app-services'

@Pipe({
    name: 'positionedPageLink',
})
export class PositionedPageLinkPipe implements PipeTransform {
    constructor(
        private localization: LocalizationService,
        private positionedPageService: PageService,
    ) {
    }

    transform(identifier: PositionedPageEnum): Observable<string | null> {
        return this.positionedPageService.slugsTable$.pipe(
            combineLatestWith(this.localization.currentLocale$),
            startWith<[PositionedPageSlugsTable, Localization.Locale]>(
                [
                    this.positionedPageService.slugsTable!,
                    this.localization.getCurrentLocale(),
                ],
            ),
            map(([table, locale]) => this.createUrl(identifier, table, locale)),
        )
    }

    private createUrl(
        identifier: PositionedPageEnum,
        table: PositionedPageSlugsTable,
        locale: Localization.Locale,
    ): string | null {
        const slug = path([identifier, locale, 'slug'], table)
        return slug
            ? `/${locale}/p/${slug}`
            : null
    }
}
