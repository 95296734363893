import { Component, Inject } from '@angular/core'
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog'
import { Button } from '@app-domains/ui/components/button/button.component.types'
import { TranslateService } from '@ngx-translate/core'
import { noop } from '@app-lib/common.lib'

export interface DialogData {
    title: string
    message: string
    buttons?: Button.InputConfig[]
}

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {
    public buttons: Button.InputConfig[]
    public noop = noop

    constructor(
        @Inject(DIALOG_DATA) public data: DialogData,
        public dialogRef: DialogRef<void>,
        private translate: TranslateService,
    ) {
        this.buttons = this.data.buttons ?? [
            {
                label: this.translate.instant('common.dismiss'),
                theme: 'dark',
                layout: 'label-only',
                clicked: this.close,
            },
        ]
    }

    public close(): void {
        this.dialogRef.close()
    }
}
