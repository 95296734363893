import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormChildPipe } from './pipes/form-child/form-child.pipe'

@NgModule({
    declarations: [
        FormChildPipe,
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        FormChildPipe,
    ],
})
export class AppFormsModule {
}
