import { Injectable, Inject } from '@angular/core'
import { UserFragment } from '@app-graphql/schema'
import { AuthService } from '@app-services'
import { CookieService } from 'ngx-cookie-service'
import { firstValueFrom, Subject } from 'rxjs'
import { CookieAuthStorageService } from '@app-services'
import { WINDOW } from '@ng-web-apis/common'

@Injectable({
    providedIn: 'root',
})
export class ImpersonationService {
    public impersonating$ = new Subject<UserFragment | null>()

    constructor(
        private cookiesService: CookieService,
        private authStorageService: CookieAuthStorageService,
        private authService: AuthService,
        @Inject(WINDOW) private window: Window,
    ) {
        const accessToken = this.cookiesService.get('IMPERSONATION')

        if (accessToken) {
            this.authStorageService.submitAuthState({
                accessToken,
                expiresAt: new Date(),
                tokenType: '',
                refreshToken: '',
            })
            this.authService.refreshUser().then(async () => {
                const user = await firstValueFrom(this.authService.user$)
                if (user) {
                    this.impersonating$.next(user)
                } else {
                    this.stopImpersonation()
                }
            }).catch(() => {
                this.stopImpersonation()
            })
        }
    }

    public stopImpersonation(): void {
        this.impersonating$.next(null)
        void this.authService.logout()
    }
}
