import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { AuthService } from '@app-services'
import { firstValueFrom } from 'rxjs'
import { map, tap } from 'rxjs/operators'

@Injectable({
    providedIn: 'root',
})
export class LoggedInGuard {

    constructor(
        private auth: AuthService,
        private router: Router,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        return firstValueFrom(
            this.auth.user$.pipe(
                map((user) => user ? !! user : this.router.parseUrl('/')),
                tap((result) => {
                    if (result !== true) {
                        this.auth.openLoginDialog(state.url)
                    }
                }),
            ),
        )
    }
}
