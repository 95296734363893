import { Component, Input } from '@angular/core'
import { Button, ImageComponentFragment } from '@app-graphql/schema'

@Component({
    selector: 'app-cta-link-content',
    templateUrl: './cta-link-content.component.html',
    styleUrls: ['./cta-link-content.component.scss'],
})
export class CtaLinkContentComponent {
    @Input()
    public preHeader: string

    @Input()
    public header: string

    @Input()
    public text: string

    @Input()
    public image: ImageComponentFragment

    @Input()
    public button: Button | undefined | null

    @Input()
    public lazyLoad: boolean = true
}
