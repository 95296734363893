import { Injectable } from '@angular/core'
import {
    UpdateProfileMutation,
    UpdateProfileMutationService,
    UpdateProfileMutationVariables,
} from '@app-graphql/schema'
import { FormControl } from '@angular/forms'
import { AuthService } from '@app-services'
import { firstValueFrom, Observable } from 'rxjs'
import { MutationResult } from 'apollo-angular'

@Injectable({
    providedIn: 'root',
})
export class UpdateProfileService {
    public hidePurchasePrice = new FormControl<boolean>(false, { nonNullable: true })

    constructor(
        private updateProfileMutationService: UpdateProfileMutationService,
        public authService: AuthService,

    ) {
        this.hidePurchasePrice.valueChanges.subscribe(async () => {
            await this.updateHidePurchaseSetting()
        })
    }

    public async updateHidePurchaseSetting(): Promise<void> {
        const user = await firstValueFrom(this.authService.user$)

        if (! user) {
            return
        }

        await firstValueFrom(this.updateProfile({
            preferredLanguage: user.preferredLanguage,
            hidePurchasePrice: this.hidePurchasePrice.value,
            splitOrderByDefault: user.splitOrderByDefault,
        }))

        await this.authService.refreshUser()
    }

    public updateProfile(input: UpdateProfileMutationVariables): Observable<MutationResult<UpdateProfileMutation>> {
        return this.updateProfileMutationService.mutate(input)
    }
}
