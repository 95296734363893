import {
    AbstractControl,
    ValidationErrors,
} from '@angular/forms'

export const containsNumber = (control: AbstractControl): ValidationErrors | null => {
    const { value } = control
    if (value) {
        const valid = (/[0-9]/).test(value)
        if (! valid) {
            return { noNumber: 'noNumber' }
        }
        return null
    }
    return null
}
