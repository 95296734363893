<div class="cutoff">
    <app-grid>
        <app-cell *ngIf="showHeader"
                  laptop="1:5"
                  mobile="1:12"
        >
            <div class="carousel-container row-1">
                <div class="head-text">
                    <h3 class="subheading serif">
                        {{ subtitle }}
                    </h3>
                    <h1>
                        {{ title }}
                    </h1>
                </div>
            </div>
        </app-cell>

        <app-cell mobile="5:full"
                  [responsiveStyle$]="{
                base: {
                    display: 'block'
                },
                tablet: {
                    display: 'none'
                },
                mobile: {
                    display: 'none'
                }
          } | responsiveStyle$"
        >
            <div class="carousel-container row-1 enter">
                <div class="carousel-container row-1-background theme-{{theme | lowercase}}"></div>
            </div>
        </app-cell>

        <app-cell mobile="full:0"
                  [responsiveStyle$]="{
                base: {
                    display: 'block'
                },
                tablet: {
                    display: 'none'
                },
                mobile: {
                    display: 'none'
                }
          } | responsiveStyle$"
        >
            <app-scroll-indicator *ngIf="showScrollBar"
            ></app-scroll-indicator>
        </app-cell>

        <app-cell laptop="0:6"
                  mobile="full:full"
        >
            <div class="carousel-container">
                <div class="main-image">
                    <ng-container *ngFor="let card of slides; let i = index">
                        <app-responsive-image *ngIf="i === swiper?.swiper?.realIndex && card.backgroundImage"
                                              [image]="card.backgroundImage"
                                              @fade
                                              [lazyLoad]="lazyLoad"
                        ></app-responsive-image>
                    </ng-container>
                </div>
            </div>
        </app-cell>

        <app-cell mobile="full:0"
                  [responsiveStyle$]="{
                base: {
                    display: 'none'
                },
                tablet: {
                    display: 'block'
                },
                mobile: {
                    display: 'block'
                }
          } | responsiveStyle$"
        >
            <div class="carousel-container row-2 theme-{{theme | lowercase}}"></div>
        </app-cell>

        <app-cell laptop="6:full"
                  mobile="0:full"
        >
            <div class="carousel-container row-2 theme-{{theme | lowercase}} center-end">
                <div class="swiper-container">
                    <swiper [config]="swiperOptions"
                            (swiper)="onSwiper($event)"
                            (slideChange)="onSlideChange()"
                    >
                        <ng-container *ngFor="let card of slides">
                            <ng-template swiperSlide>
                                <div class="card">
                                    <div class="card-inner">
                                        <div class="card-front">
                                            <app-responsive-image *ngIf="card.backgroundImage"
                                                                  [image]="card.backgroundImage"
                                                                  @fade
                                                                  [lazyLoad]="lazyLoad"
                                            ></app-responsive-image>

                                            <div *ngIf="card.mainImage"
                                                 class="right"
                                            >
                                                <app-responsive-image [image]="card.mainImage"
                                                                      [lazyLoad]="lazyLoad"
                                                ></app-responsive-image>
                                            </div>
                                        </div>

                                        <div class="card-back">
                                            <div class="left">
                                                <h3 class="subheading--soft">
                                                    {{ card.subtitle }}
                                                </h3>

                                                <h2 class="serif">
                                                    {{ card.title }}
                                                </h2>

                                                <label *ngIf="card.text"
                                                       class="small"
                                                       [innerHTML]="card.text | truncateText: 250 | safe: 'html'"
                                                ></label>

                                                <div *ngIf="card.link"
                                                     class="link"
                                                >
                                                    <app-chevron-link
                                                        size="normal"
                                                        iconColor="dark"
                                                        [link]="card.link.link"
                                                        [target]="card.link.target"
                                                    >  {{ card.link.label }}
                                                    </app-chevron-link>
                                                </div>
                                            </div>

                                            <div *ngIf="card.mainImage"
                                                 class="right"
                                            >
                                                <app-responsive-image [image]="card.mainImage"
                                                                      [lazyLoad]="lazyLoad"
                                                ></app-responsive-image>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-container>
                    </swiper>
                </div>

                <div class="pagination-container theme-{{theme.toLowerCase()}}">
                    <p class="index"
                       *ngIf="swiper && swiper.totalIndex && swiper.totalIndex > 1"
                    >
                        {{ swiper.swiperIndex }}/{{ swiper.totalIndex }}
                    </p>

                    <div #pagination></div>

                    <div *ngIf="swiper && swiper.totalIndex && swiper.totalIndex > 1"
                         class="arrow-container"
                    >
                        <span appIcon="arrow-left"
                              class="navigation-icon"
                              (click)="swiper.slidePrev()"
                        ></span>
                        <span appIcon="arrow-right"
                              class="navigation-icon "
                              (click)="swiper.slideNext()"
                        ></span>
                    </div>
                </div>
            </div>
        </app-cell>

        <app-cell mobile="5:full"
                  [responsiveStyle$]="{
                base: {
                    display: 'block'
                },
                tablet: {
                    display: 'none'
                },
                mobile: {
                    display: 'none'
                }
          } | responsiveStyle$"
        >
            <div class="carousel-container row-1 theme-{{theme | lowercase}} cutoff bottom-right"></div>
        </app-cell>
    </app-grid>
</div>
