import { Pipe, PipeTransform } from '@angular/core'
import { any, equals } from 'ramda'

@Pipe({
    name: 'isSelected',
})
export class IsSelectedPipe<T> implements PipeTransform {

    transform(formValue: T[] | null, value: T): boolean {
        return any(equals(value), formValue ?? [])
    }

}
