import { Component, Inject, OnInit } from '@angular/core'
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog'
import { ShoppingCartLineFragment } from '@app-graphql/schema'
import { BehaviorSubject } from 'rxjs'

@Component({
    selector: 'app-out-of-stock-modal',
    templateUrl: './out-of-stock-modal.component.html',
    styleUrls: ['./out-of-stock-modal.component.scss'],
})
export class OutOfStockModalComponent implements OnInit {

    public tableItems = new BehaviorSubject<ShoppingCartLineFragment[]>([])
    public categories: BehaviorSubject<ShoppingCartLineFragment[]>[]

    public displayedColumns: string[] = [
        'image',
        'product',
        'quantity-old',
        'subTotal',
    ]

    constructor(
        public dialogRef: DialogRef,
        @Inject(DIALOG_DATA) public data: ShoppingCartLineFragment[],
    ) {
    }

    public ngOnInit(): void {
        this.tableItems.next(this.data)
    }

    public closeDialog(): void {
        this.dialogRef.close()
    }

    public orderWithoutProducts(): void {
        this.dialogRef.close('delete-out-of-stock')
    }
}
