<app-grid>
    <app-cell mobile="full:full">
        <!-- TODO: Filters (SHOULD) -->
        <!--        <div class="filters"> -->
        <!--            <app-select [control]="brands"-->
        <!--                        placeholder="merken"-->
        <!--            >-->
        <!--                <option value="all">{{'media.all-media' | translate}}</option>-->
        <!--                <option value="other">{{ 'media.other-brands' | translate }}</option>-->
        <!--            </app-select>-->

        <!--            <app-select [control]="mediaType"-->
        <!--                        placeholder="Mediatype"-->
        <!--            >-->
        <!--                <option value="all">{{ 'media.all-brands' | translate }}</option>-->
        <!--                <option value="other">{{ 'media.other-media' | translate }}</option>-->
        <!--            </app-select>-->
        <!--        </div>-->
    </app-cell>

    <app-cell mobile="0:12">
        <div class="assets-grid">
            <ng-container *ngFor="let asset of assets">
                <div class="asset-card">
                    <div class="asset-content">
                        <img *ngIf="asset.image"
                             [src]="asset.image.url"
                             [alt]="asset.image.alt ? asset.image.alt : asset.title"
                        >

                        <div class="bottom">
                            <h3 class="subheading">
                                {{ asset.title }}
                            </h3>

                            <span appIcon="arrow-fat-right" class="icon"></span>
                        </div>

                        <a *ngIf="asset.media"
                           [href]="asset.media!.url"
                           target="_blank"
                        ></a>
                    </div>
                </div>
            </ng-container>
        </div>
    </app-cell>
</app-grid>
