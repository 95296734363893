<app-grid>
    <app-cell mobile="0:12" tablet="0:6" [responsiveStyle$]="{
            mobile: {
                gridRow: '2'
            },
            tablet: {
                gridRow: '1'
            }
        } | responsiveStyle$">
        <div class="cta-link__content">
            <h4 class="subheading serif">{{ preHeader }}</h4>

            <h2>{{ header }}</h2>

            <p class="cta-link__txt" [innerHTML]="text | safe: 'html'"></p>

            <ng-container *ngIf="button">
                <app-button
                    *ngIf="button.link.link"
                    [type]="button.link.link ? 'link' : 'button' "
                    [cmsTheme]="button.type"
                    [cmsIcon]="button.icon ? button.icon : undefined"
                    [layout]="
                        (button.icon && button.link.label === ' ') ? 'icon-only'
                        : (button.icon && button.link.label) ? 'icon-label'
                        : 'label-only'
                    "
                    [cmsTarget]="button.link.target"
                    [href]="button.link.link"
                >
                    {{ button.link.label }}
                </app-button>
            </ng-container>

        </div>
    </app-cell>
    <app-cell mobile="0:12" tablet="6:12">
        <div class="cta-link__image">
            <app-responsive-image [image]="image"
                                  [lazyLoad]="lazyLoad"
            ></app-responsive-image>
        </div>
    </app-cell>
</app-grid>
