import {
    Component,
    Input,
    Inject,
    forwardRef,
    KeyValueDiffer,
    KeyValueDiffers,
    DoCheck,
    OnInit,
} from '@angular/core'

import { noop } from '@app-lib/common.lib'
import { connectConfigure } from 'instantsearch.js/es/connectors'
import {
    ConfigureWidgetDescription,
    ConfigureRenderState,
    ConfigureConnectorParams,
} from 'instantsearch.js/es/connectors/configure/connectConfigure'
import { AbstractInstantsearchWidgetComponent } from '@app-domains/algolia/abstract-instantsearch-widget.component'
import { AlgoliaInstantsearchComponent } from '@app-domains/algolia/components/algolia-instantsearch.component'

@Component({
    selector: 'app-algolia-configure',
    template: '',
})
export class AlgoliaConfigureComponent extends AbstractInstantsearchWidgetComponent<
ConfigureWidgetDescription,
ConfigureConnectorParams
> implements OnInit, DoCheck {
    public widgetName: string

    private internalSearchParameters: ConfigureConnectorParams['searchParameters']

    private differ: KeyValueDiffer<string, any>

    public override state: ConfigureRenderState = {
        refine: noop,
    }

    constructor(
        private differs: KeyValueDiffers,

        @Inject(forwardRef(() => AlgoliaInstantsearchComponent))
        public instantSearchInstance: AlgoliaInstantsearchComponent,
    ) {
        super()
    }

    @Input()
    set searchParameters(values: ConfigureConnectorParams['searchParameters']) {
        this.internalSearchParameters = values

        if (! this.differ && values) {
            this.differ = this.differs.find(values).create()
        }
    }

    public override ngOnInit() {
        this.createWidget(
            connectConfigure,
            {
                searchParameters: this.internalSearchParameters,
            },
            {
                $$widgetType: 'ais.configure',
            },
        )
        super.ngOnInit()
    }

    public ngDoCheck() {
        if (this.differ) {
            const changes = this.differ.diff(this.internalSearchParameters)
            if (changes) {
                this.state.refine(this.internalSearchParameters)
            }
        }
    }
}
