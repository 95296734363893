import { BehaviorSubject } from 'rxjs'
import { SearchClient } from 'algoliasearch/lite'
import aa, { InsightsClient } from 'search-insights'

import { CommonModule, DOCUMENT } from '@angular/common'
import { InjectionToken, NgModule, makeStateKey, TransferState } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'

import { environment } from '@app-environments/environment'
import { InsightsComponent } from './components/insights/insights.component'
import { createAlgoliaSearchClient } from '@app-domains/algolia/createAlgoliaSearchClient'
import { AlgoliaConfigureComponent } from '@app-domains/algolia/components/algolia-configure.component'
import { AlgoliaStatsComponent } from '@app-domains/algolia/components/algolia-stats.component'
import { AlgoliaExperimentalConfigureRelatedItemsComponent } from '@app-domains/algolia/components/algolia-experimental-configure-related-items.component'
import { AlgoliaInstantsearchComponent } from '@app-domains/algolia/components/algolia-instantsearch.component'

export interface InsightsData {
    client: InsightsClient
    token: BehaviorSubject<string | undefined>
}

export const ALGOLIA_SEARCH_CLIENT = new InjectionToken<SearchClient>('ALGOLIA_SEARCH_CLIENT')
export const ALGOLIA_INSIGHTS_CLIENT = new InjectionToken<InsightsData>('ALGOLIA_INSIGHTS_CLIENT')

export function algoliaInsightsClientFactory(): InsightsData {
    const tokenSubject = new BehaviorSubject<string | undefined>(undefined)
    aa('onUserTokenChange', (token) => {
        tokenSubject.next(token?.toString())
    }, {
        immediate: true,
    })
    aa('init', {
        appId: environment.algolia.appId,
        apiKey: environment.algolia.apiKey,
        useCookie: true,
    })
    return {
        client: aa,
        token: tokenSubject,
    }
}
export function algoliaSearchClientFactory(
    doc: Document,
    httpClient: HttpClient,
    transferState: TransferState,
) {
    const preconnect = doc.createElement('link')

    preconnect.crossOrigin = 'crossorigin'
    preconnect.href = `https://${environment.algolia.appId}-dsn.algolia.net`
    preconnect.rel = 'preconnect'

    doc.head.appendChild(preconnect)

    return createAlgoliaSearchClient({
        appId: environment.algolia.appId,
        apiKey: environment.algolia.apiKey,
        makeStateKey,
        HttpHeaders,
        transferState: transferState,
        httpClient: httpClient,
    })
}

@NgModule({
    declarations: [
        InsightsComponent,
        AlgoliaConfigureComponent,
        AlgoliaStatsComponent,
        AlgoliaExperimentalConfigureRelatedItemsComponent,
        AlgoliaInstantsearchComponent,
    ],
    imports: [
        CommonModule,
    ],
    providers: [
        {
            provide: ALGOLIA_INSIGHTS_CLIENT,
            useFactory: algoliaInsightsClientFactory,
        },
        {
            provide: ALGOLIA_SEARCH_CLIENT,
            useFactory: algoliaSearchClientFactory,
            deps: [
                DOCUMENT,
                HttpClient,
                TransferState,
            ],
        },
    ],
    exports: [
        InsightsComponent,
        AlgoliaConfigureComponent,
        AlgoliaStatsComponent,
        AlgoliaExperimentalConfigureRelatedItemsComponent,
        AlgoliaInstantsearchComponent,
    ],
})
export class AlgoliaModule {
}
