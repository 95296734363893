import { firstValueFrom, Subscription } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'

import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { TranslateRouterService } from '@endeavour/ngx-translate-router'

import { InputPublic } from '@app-domains/ui/components/input/input.component.types'
import { checkEmail } from '../../../validators'
import { SnackbarService } from '@app-domains/ui/services/snackbar/snackbar.service'
import { AuthService, PasswordService } from '@app-services'
import { LocalizationService } from '@app-domains/localization/service/localization.service'


@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

    public email = new FormControl<string>('', [
        Validators.required,
        checkEmail,
    ])

    public emailErrorMessages: InputPublic.ErrorMessageRecord = {
        required: this.translate.instant('validation.messages.required'),
        emailNotValid: this.translate.instant('validation.messages.invalid-email'),
    }

    private localeSub: Subscription

    constructor(
        private readonly authService: AuthService,
        private readonly snackbarService: SnackbarService,
        private readonly translate: TranslateService,
        private readonly passwordService: PasswordService,
        private readonly router: Router,
        private readonly localization: LocalizationService,
        private translateRouterService: TranslateRouterService,
    ) {
    }

    public ngOnInit() {
        this.localeSub = this.localization.currentLocale$.subscribe(() => {
            this.emailErrorMessages = {
                required: this.translate.instant('validation.messages.required'),
                emailNotValid: this.translate.instant('validation.messages.invalid-email'),
            }
        })
    }

    public ngOnDestroy() {
        if (this.localeSub) {
            this.localeSub.unsubscribe()
        }
    }

    public goToLogin(): void {
        window.location.href = this.authService.getLoginUrl()
    }

    public async submit(): Promise<void> {
        if (this.email.invalid) {
            this.email.markAsDirty()
            this.email.markAllAsTouched()
            return
        }

        const result = await firstValueFrom(this.passwordService.forgotPassword({
            input: { email: this.email.value! },
        }))

        if (result.errors) {
            this.snackbarService.create({
                content: 'common.error',
                dismissible: true,
            }, 'bottom')
            return
        }

        await this.router.navigateByUrl(
            this.translateRouterService.translateRouteLink('/authentication/forgot-password-success') as string,
        )
    }
}
