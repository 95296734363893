import { firstValueFrom } from 'rxjs'
import { map } from 'rxjs/operators'

import { Injectable } from '@angular/core'
import { FormControl } from '@angular/forms'

import { FixedSelectionModel } from '@app-lib/fixed-selection-model'
import { ShoppingCartLineFragment } from '@app-graphql/schema'
import { CheckoutService } from '@app-services'

@Injectable({
    providedIn: 'root',
})
export class CartSelectionService {

    public selectedProductsControl = new FormControl<ShoppingCartLineFragment[]>([])

    public selectAllControl = new FormControl(false, { nonNullable: true })

    public selectedRowsSelectionModel = new FixedSelectionModel<ShoppingCartLineFragment>(
        true,
        [],
        true,
        (p1, p2) => p1.id === p2.id,
    )

    constructor(
        private checkoutService: CheckoutService,
    ) {
    }

    public toggleRowInSelection(elements: ShoppingCartLineFragment[]): void {
        this.selectedRowsSelectionModel.clear()
        elements.forEach((element) => {
            this.selectedRowsSelectionModel.toggle(element)
        })

        this.setSelectedItemsSubject()
    }

    public handleDeselectAll(): void {
        this.selectedRowsSelectionModel.clear()
        this.selectAllControl.setValue(false, { emitEvent: false })
        this.selectedProductsControl.setValue([])
        this.setSelectedItemsSubject()
    }

    public async handleSelectAll(): Promise<void> {
        const cartlines = await firstValueFrom(
            this.checkoutService.shoppingCart$.pipe(
                map((r) => r.cartLine),
            ),
        )

        this.selectedRowsSelectionModel.setSelection(...cartlines)
        this.selectedProductsControl.setValue(cartlines)
        this.selectAllControl.setValue(true, { emitEvent: false })
        this.setSelectedItemsSubject()
    }

    public setSelectedItemsSubject(): void {
        this.checkoutService.selectedItemsInShoppingCart$.next(this.selectedRowsSelectionModel.selected)
    }

}
