import { createScriptElement } from '../../helpers/create-script-element'
import { environment } from '@app-environments/environment'

export const initializeGoogleMaps = async () => {
    const googleMapsApiUrl = `https://maps.googleapis.com/maps/api/js?libraries=places&key=${environment.google.maps}&loading=async&callback=Function.prototype`
    const googleMapsMarkerClustererPlusUrl = 'https://unpkg.com/@googlemaps/markerclustererplus@1.2.10/dist/index.min.js'

    await createScriptElement(googleMapsApiUrl)
    await createScriptElement(googleMapsMarkerClustererPlusUrl)
}
