import { Pipe, PipeTransform } from '@angular/core'
import { ProductImageFragment, ProductImageTypeEnum } from '@app-graphql/schema'
import { getProductImageOfType } from '@app-lib/product.lib'

@Pipe({
    name: 'getProductImageOfType',
})
export class GetProductImageOfTypePipe implements PipeTransform {
    transform(
        images: ProductImageFragment[],
        type: ProductImageTypeEnum,
    ): ProductImageFragment {
        return getProductImageOfType(images, type)
    }

}
