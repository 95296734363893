/* eslint-disable max-len */
import { SeoFragment, SocialNetworkEnum } from '@app-graphql/schema'

interface SeoData {
    id: string
    data: {
        [key: string]: SeoFragment
    }
}

export enum Pages {
    ExperienceCenterOverview = 'experience-center-overview',
    AllProducts = 'all-products',
    NewProducts = 'new-products',
    SaleProducts = 'sale-products',
    SearchResultPage = 'search-result-page',
    EventsOverview = 'events-overview',
    NewsOverview = 'news-overview',
    Dealers = 'dealers',
    Register = 'register',
}

// TODO/REFACTOR: Export this file to another place that watches the route and then decides which data has to be fetched

/**
 *
 * Array contains objects with SEO for all static pages
 *
 * */

export const seoDataStaticPages: SeoData[] = [
    {
        id: Pages.ExperienceCenterOverview,
        data: {
            nl: {
                title: 'Experience Center - Voor zakelijke klanten & consumenten',
                description: 'Ontdek onze collecties in het Experience Center en laat je inspireren door de nieuwste designs en trends. ✓ Snelle levering  ✓ Eigen fabriek ✓ Hoge product kwaliteit',
                canonicalUrl: '',
                image: {
                    __typename: 'Image',
                    url: '/assets/images/experience-center-business.webp',
                    previewUrl: '/assets/images/experience-center-business.webp',
                    provider: '/assets/images/experience-center-business.webp',
                    alt: 'Experience Center - Voor zakelijke klanten & consumenten',
                    name: 'Experience Center - Voor zakelijke klanten & consumenten',
                    width: 1080,
                    height: 1920,
                },
                socials: [
                    {
                        socialNetwork: SocialNetworkEnum.Facebook,
                        title: 'Experience Center - Voor zakelijke klanten & consumenten',
                    },
                    {
                        socialNetwork: SocialNetworkEnum.Twitter,
                        title: 'Experience Center - Voor zakelijke klanten & consumenten',
                    },
                ],
            },
            en: {
                title: 'Experience Center - For business customers & consumers',
                description: 'Explore our collections in the Experience Centre and get inspired by the latest designs and trends. ✓ Fast delivery ✓ Own factory ✓ High product quality',
                canonicalUrl: '',
                image: {
                    __typename: 'Image',
                    url: '/assets/images/experience-center-business.webp',
                    previewUrl: '/assets/images/experience-center-business.webp',
                    provider: '/assets/images/experience-center-business.webp',
                    alt: 'Experience Center - For business customers & consumers',
                    name: 'Experience Center - For business customers & consumers',
                    width: 1080,
                    height: 1920,
                },
                socials: [
                    {
                        socialNetwork: SocialNetworkEnum.Facebook,
                        title: 'Experience Center - For business customers & consumers',
                    },
                    {
                        socialNetwork: SocialNetworkEnum.Twitter,
                        title: 'Experience Center - For business customers & consumers',
                    },
                ],
            },
            de: {
                title: 'Experience Center - Für Geschäftskunden und Verbraucher',
                description: 'Entdecken Sie unsere Kollektionen im Experience Center und lassen Sie sich von den neuesten Designs und Trends inspirieren. Schnelle Lieferung ✓ Eigene Fabrik ✓ Hohe Produktqualität',
                canonicalUrl: '',
                image: {
                    __typename: 'Image',
                    url: '/assets/images/experience-center-business.webp',
                    previewUrl: '/assets/images/experience-center-business.webp',
                    provider: '/assets/images/experience-center-business.webp',
                    alt: 'Experience Center - Für Geschäftskunden und Verbraucher',
                    name: 'Experience Center - Für Geschäftskunden und Verbraucher',
                    width: 1080,
                    height: 1920,
                },
                socials: [
                    {
                        socialNetwork: SocialNetworkEnum.Facebook,
                        title: 'Experience Center - Für Geschäftskunden und Verbraucher',
                    },
                    {
                        socialNetwork: SocialNetworkEnum.Twitter,
                        title: 'Experience Center - Für Geschäftskunden und Verbraucher',
                    },
                ],
            },
            fr: {
                title: 'Experience Center - Pour les clients professionnels et les consommateurs',
                description: 'Explorez nos collections dans le centre d\'expérience et laissez-vous inspirer par les derniers designs et tendances. ✓ Livraison rapide ✓ Propre usine ✓ Haute qualité des produits',
                canonicalUrl: '',
                image: {
                    __typename: 'Image',
                    url: '/assets/images/experience-center-business.webp',
                    previewUrl: '/assets/images/experience-center-business.webp',
                    provider: '/assets/images/experience-center-business.webp',
                    alt: 'Experience Center - Pour les clients professionnels et les consommateurs',
                    name: 'Experience Center - Pour les clients professionnels et les consommateurs',
                    width: 1080,
                    height: 1920,
                },
                socials: [
                    {
                        socialNetwork: SocialNetworkEnum.Facebook,
                        title: 'Experience Center - Pour les clients professionnels et les consommateurs',
                    },
                    {
                        socialNetwork: SocialNetworkEnum.Twitter,
                        title: 'Experience Center - Pour les clients professionnels et les consommateurs',
                    },
                ],
            },
        },
    },
    {
        id: Pages.AllProducts,
        data: {
            nl: {
                title: 'Alle producten - Ontdek ons productaanbod',
                description: 'Bekijk ons uitgebreide productaanbod van de merken WOOOD, BePureHome, vtwonen en Exotan. ✓ Snelle levering  ✓ Eigen fabriek ✓ Hoge product kwaliteit',
                canonicalUrl: '',
                image: {
                    __typename: 'Image',
                    url: '/assets/images/experience-center-business.webp',
                    previewUrl: '/assets/images/experience-center-business.webp',
                    provider: '/assets/images/experience-center-business.webp',
                    alt: 'Alle producten - Ontdek ons productaanbod',
                    name: 'Alle producten - Ontdek ons productaanbod',
                    width: 1080,
                    height: 1920,
                },
                socials: [
                    {
                        socialNetwork: SocialNetworkEnum.Facebook,
                        title: 'Alle producten - Ontdek ons productaanbod',
                    },
                    {
                        socialNetwork: SocialNetworkEnum.Twitter,
                        title: 'Alle producten - Ontdek ons productaanbod',
                    },
                ],
            },
            en: {
                title: 'All products - Explore our product range',
                description: 'Check out our extensive product range of the brands WOOOD, BePureHome, vtwonen and Exotan. Fast delivery ✓ Own factory ✓ High product quality',
                canonicalUrl: '',
                image: {
                    __typename: 'Image',
                    url: '/assets/images/experience-center-business.webp',
                    previewUrl: '/assets/images/experience-center-business.webp',
                    provider: '/assets/images/experience-center-business.webp',
                    alt: 'All products - Explore our product range',
                    name: 'All products - Explore our product range',
                    width: 1080,
                    height: 1920,
                },
                socials: [
                    {
                        socialNetwork: SocialNetworkEnum.Facebook,
                        title: 'All products - Explore our product range',
                    },
                    {
                        socialNetwork: SocialNetworkEnum.Twitter,
                        title: 'All products - Explore our product range',
                    },
                ],
            },
            de: {
                title: 'Alle Produkte - Entdecken Sie unsere Produktpalette',
                description: 'Stöbern Sie in unserem umfangreichen Produktsortiment der Marken WOOOD, BePureHome, vtwonen und Exotan. Schnelle Lieferung ✓ Eigene Fabrik ✓ Hohe Produktqualität',
                canonicalUrl: '',
                image: {
                    __typename: 'Image',
                    url: '/assets/images/experience-center-business.webp',
                    previewUrl: '/assets/images/experience-center-business.webp',
                    provider: '/assets/images/experience-center-business.webp',
                    alt: 'Alle Produkte - Entdecken Sie unsere Produktpalette',
                    name: 'Alle Produkte - Entdecken Sie unsere Produktpalette',
                    width: 1080,
                    height: 1920,
                },
                socials: [
                    {
                        socialNetwork: SocialNetworkEnum.Facebook,
                        title: 'Alle Produkte - Entdecken Sie unsere Produktpalette',
                    },
                    {
                        socialNetwork: SocialNetworkEnum.Twitter,
                        title: 'Alle Produkte - Entdecken Sie unsere Produktpalette',
                    },
                ],
            },
            fr: {
                title: 'Tous les produits - Découvrez notre gamme de produits',
                description: 'Découvrez notre vaste gamme de produits des marques WOOOD, BePureHome, vtwonen et Exotan. Livraison rapide ✓ Propre usine ✓ Haute qualité des produits',
                canonicalUrl: '',
                image: {
                    __typename: 'Image',
                    url: '/assets/images/experience-center-business.webp',
                    previewUrl: '/assets/images/experience-center-business.webp',
                    provider: '/assets/images/experience-center-business.webp',
                    alt: 'Tous les produits - Découvrez notre gamme de produits',
                    name: 'Tous les produits - Découvrez notre gamme de produits',
                    width: 1080,
                    height: 1920,
                },
                socials: [
                    {
                        socialNetwork: SocialNetworkEnum.Facebook,
                        title: 'Tous les produits - Découvrez notre gamme de produits',
                    },
                    {
                        socialNetwork: SocialNetworkEnum.Twitter,
                        title: 'Tous les produits - Découvrez notre gamme de produits',
                    },
                ],
            },
        },
    },
    {
        id: Pages.NewProducts,
        data: {
            nl: {
                title: 'Nieuwe producten - Bekijk de nieuwste meubels',
                description: 'Ontdek de nieuwste meubels van de merken WOOOD, BePureHome, vtwonen en Exotan. ✓ Snelle levering  ✓ Eigen fabriek ✓ Hoge product kwaliteit',
                canonicalUrl: '',
                image: {
                    __typename: 'Image',
                    url: '/assets/images/experience-center-business.webp',
                    previewUrl: '/assets/images/experience-center-business.webp',
                    provider: '/assets/images/experience-center-business.webp',
                    alt: 'Nieuwe producten - Bekijk de nieuwste meubels',
                    name: 'Nieuwe producten - Bekijk de nieuwste meubels',
                    width: 1080,
                    height: 1920,
                },
                socials: [
                    {
                        socialNetwork: SocialNetworkEnum.Facebook,
                        title: 'Nieuwe producten - Bekijk de nieuwste meubels',
                    },
                    {
                        socialNetwork: SocialNetworkEnum.Twitter,
                        title: 'Nieuwe producten - Bekijk de nieuwste meubels',
                    },
                ],
            },
            en: {
                title: 'New products - View the latest furniture',
                description: 'Discover the latest furniture from the brands WOOOD, BePureHome, vtwonen and Exotan. Fast delivery ✓ Own factory ✓ High product quality',
                canonicalUrl: '',
                image: {
                    __typename: 'Image',
                    url: '/assets/images/experience-center-business.webp',
                    previewUrl: '/assets/images/experience-center-business.webp',
                    provider: '/assets/images/experience-center-business.webp',
                    alt: 'New products - View the latest furniture',
                    name: 'New products - View the latest furniture',
                    width: 1080,
                    height: 1920,
                },
                socials: [
                    {
                        socialNetwork: SocialNetworkEnum.Facebook,
                        title: 'New products - View the latest furniture',
                    },
                    {
                        socialNetwork: SocialNetworkEnum.Twitter,
                        title: 'New products - View the latest furniture',
                    },
                ],
            },
            de: {
                title: 'Neue Produkte - Sehen Sie die neuesten Möbel',
                description: 'Entdecken Sie die neuesten Möbel von den Marken WOOOD, BePureHome, vtwonen und Exotan. ✓ Schnelle Lieferung ✓ Eigene Fabrik ✓ Hohe Produktqualität',
                canonicalUrl: '',
                image: {
                    __typename: 'Image',
                    url: '/assets/images/experience-center-business.webp',
                    previewUrl: '/assets/images/experience-center-business.webp',
                    provider: '/assets/images/experience-center-business.webp',
                    alt: 'Neue Produkte - Sehen Sie die neuesten Möbel',
                    name: 'Neue Produkte - Sehen Sie die neuesten Möbel',
                    width: 1080,
                    height: 1920,
                },
                socials: [
                    {
                        socialNetwork: SocialNetworkEnum.Facebook,
                        title: 'Neue Produkte - Sehen Sie die neuesten Möbel',
                    },
                    {
                        socialNetwork: SocialNetworkEnum.Twitter,
                        title: 'Neue Produkte - Sehen Sie die neuesten Möbel',
                    },
                ],
            },
            fr: {
                title: 'Nouveaux produits - Découvrez les derniers meubles',
                description: 'Découvrez les derniers meubles des marques WOOOD, BePureHome, vtwonen et Exotan. Livraison rapide ✓ Propre usine ✓ Haute qualité des produits',
                canonicalUrl: '',
                image: {
                    __typename: 'Image',
                    url: '/assets/images/experience-center-business.webp',
                    previewUrl: '/assets/images/experience-center-business.webp',
                    provider: '/assets/images/experience-center-business.webp',
                    alt: 'Nouveaux produits - Découvrez les derniers meubles',
                    name: 'Nouveaux produits - Découvrez les derniers meubles',
                    width: 1080,
                    height: 1920,
                },
                socials: [
                    {
                        socialNetwork: SocialNetworkEnum.Facebook,
                        title: 'Nouveaux produits - Découvrez les derniers meubles',
                    },
                    {
                        socialNetwork: SocialNetworkEnum.Twitter,
                        title: 'Nouveaux produits - Découvrez les derniers meubles',
                    },
                ],
            },
        },
    },
    {
        id: Pages.SaleProducts,
        data: {
            nl: {
                title: 'SALE producten - Bekijk de meubels in de SALE',
                description: 'Bekijk onze SALE producten van de merken WOOOD, BePureHome, vtwonen en Exotan. Bestel direct online in onze portal. ✓ Snelle levering  ✓ Eigen fabriek ✓ Hoge product kwaliteit',
                canonicalUrl: '',
                image: {
                    __typename: 'Image',
                    url: '/assets/images/experience-center-business.webp',
                    previewUrl: '/assets/images/experience-center-business.webp',
                    provider: '/assets/images/experience-center-business.webp',
                    alt: 'SALE producten - Bekijk de meubels in de SALE',
                    name: 'SALE producten - Bekijk de meubels in de SALE',
                    width: 1080,
                    height: 1920,
                },
                socials: [
                    {
                        socialNetwork: SocialNetworkEnum.Facebook,
                        title: 'SALE producten - Bekijk de meubels in de SALE',
                    },
                    {
                        socialNetwork: SocialNetworkEnum.Twitter,
                        title: 'SALE producten - Bekijk de meubels in de SALE',
                    },
                ],
            },
            en: {
                title: 'SALE products - View the furniture on sale',
                description: 'Check out our SALE products from the brands WOOOD, BePureHome, vtwonen and Exotan. Order directly online in our portal. Fast delivery ✓ Own factory ✓ High product quality',
                canonicalUrl: '',
                image: {
                    __typename: 'Image',
                    url: '/assets/images/experience-center-business.webp',
                    previewUrl: '/assets/images/experience-center-business.webp',
                    provider: '/assets/images/experience-center-business.webp',
                    alt: 'SALE products - View the furniture on sale',
                    name: 'SALE products - View the furniture on sale',
                    width: 1080,
                    height: 1920,
                },
                socials: [
                    {
                        socialNetwork: SocialNetworkEnum.Facebook,
                        title: 'SALE products - View the furniture on sale',
                    },
                    {
                        socialNetwork: SocialNetworkEnum.Twitter,
                        title: 'SALE products - View the furniture on sale',
                    },
                ],
            },
            de: {
                title: 'SALE Produkte - Sehen Sie die Möbel im Verkauf',
                description: 'Entdecken Sie unsere SALE-Produkte der Marken WOOOD, BePureHome, vtwonen und Exotan. Bestellen Sie direkt online in unserem Portal. Schnelle Lieferung ✓ Eigene Fabrik ✓ Hohe Produktqualität',
                canonicalUrl: '',
                image: {
                    __typename: 'Image',
                    url: '/assets/images/experience-center-business.webp',
                    previewUrl: '/assets/images/experience-center-business.webp',
                    provider: '/assets/images/experience-center-business.webp',
                    alt: 'SALE Produkte - Sehen Sie die Möbel im Verkauf',
                    name: 'SALE Produkte - Sehen Sie die Möbel im Verkauf',
                    width: 1080,
                    height: 1920,
                },
                socials: [
                    {
                        socialNetwork: SocialNetworkEnum.Facebook,
                        title: 'SALE Produkte - Sehen Sie die Möbel im Verkauf',
                    },
                    {
                        socialNetwork: SocialNetworkEnum.Twitter,
                        title: 'SALE Produkte - Sehen Sie die Möbel im Verkauf',
                    },
                ],
            },
            fr: {
                title: 'Produits en vente - Voir les meubles en vente',
                description: 'Découvrez nos produits en SOLDE des marques WOOOD, BePureHome, vtwonen et Exotan. Commandez directement en ligne sur notre portail. Livraison rapide ✓ Propre usine ✓ Haute qualité des produits',
                canonicalUrl: '',
                image: {
                    __typename: 'Image',
                    url: '/assets/images/experience-center-business.webp',
                    previewUrl: '/assets/images/experience-center-business.webp',
                    provider: '/assets/images/experience-center-business.webp',
                    alt: 'Produits en vente - Voir les meubles en vente',
                    name: 'Produits en vente - Voir les meubles en vente',
                    width: 1080,
                    height: 1920,
                },
                socials: [
                    {
                        socialNetwork: SocialNetworkEnum.Facebook,
                        title: 'Produits en vente - Voir les meubles en vente',
                    },
                    {
                        socialNetwork: SocialNetworkEnum.Twitter,
                        title: 'Produits en vente - Voir les meubles en vente',
                    },
                ],
            },
        },
    },
    {
        id: Pages.SearchResultPage,
        data: {
            nl: {
                title: 'Ontdek meer dan 2800 producten',
                description: 'Ontdek meer dan 2800 producten in onze portal! Bekijk onze unieke collecties van WOOOD, BePureHome, Exotan en vtwonen.  ✓ Snelle levering  ✓ Eigen fabriek ✓ Hoge product kwaliteit',
                canonicalUrl: '',
                image: {
                    __typename: 'Image',
                    url: '/assets/images/experience-center-business.webp',
                    previewUrl: '/assets/images/experience-center-business.webp',
                    provider: '/assets/images/experience-center-business.webp',
                    alt: 'Ontdek meer dan 2800 producten',
                    name: 'Ontdek meer dan 2800 producten',
                    width: 1080,
                    height: 1920,
                },
                socials: [
                    {
                        socialNetwork: SocialNetworkEnum.Facebook,
                        title: 'Ontdek meer dan 2800 producten',
                    },
                    {
                        socialNetwork: SocialNetworkEnum.Twitter,
                        title: 'Ontdek meer dan 2800 producten',
                    },
                ],
            },
            en: {
                title: 'Discover over 2800 products',
                description: 'Discover more than 2800 products in our portal! Check out our unique collections by WOOOD, BePureHome, Exotan and vtwonen.  Fast delivery ✓ Own factory ✓ High product quality',
                canonicalUrl: '',
                image: {
                    __typename: 'Image',
                    url: '/assets/images/experience-center-business.webp',
                    previewUrl: '/assets/images/experience-center-business.webp',
                    provider: '/assets/images/experience-center-business.webp',
                    alt: 'Discover over 2800 products',
                    name: 'Discover over 2800 products',
                    width: 1080,
                    height: 1920,
                },
                socials: [
                    {
                        socialNetwork: SocialNetworkEnum.Facebook,
                        title: 'Discover over 2800 products',
                    },
                    {
                        socialNetwork: SocialNetworkEnum.Twitter,
                        title: 'Discover over 2800 products',
                    },
                ],
            },
            de: {
                title: 'Entdecken Sie mehr als 2.800 Produkte',
                description: 'Entdecken Sie mehr als 2.800 Produkte in unserem Portal! Entdecken Sie unsere einzigartigen Kollektionen von WOOOD, BePureHome, Exotan und vtwonen.  ✓ Schnelle Lieferung ✓ Eigene Fabrik ✓ Hohe Produktqualität',
                canonicalUrl: '',
                image: {
                    __typename: 'Image',
                    url: '/assets/images/experience-center-business.webp',
                    previewUrl: '/assets/images/experience-center-business.webp',
                    provider: '/assets/images/experience-center-business.webp',
                    alt: 'Entdecken Sie mehr als 2.800 Produkte',
                    name: 'Entdecken Sie mehr als 2.800 Produkte',
                    width: 1080,
                    height: 1920,
                },
                socials: [
                    {
                        socialNetwork: SocialNetworkEnum.Facebook,
                        title: 'Entdecken Sie mehr als 2.800 Produkte',
                    },
                    {
                        socialNetwork: SocialNetworkEnum.Twitter,
                        title: 'Entdecken Sie mehr als 2.800 Produkte',
                    },
                ],
            },
            fr: {
                title: 'Découvrez plus de 2800 produits',
                description: 'Découvrez plus de 2800 produits sur notre portail ! Consultez nos collections uniques de WOOOD, BePureHome, Exotan et vtwonen.  Livraison rapide ✓ Propre usine ✓ Haute qualité des produits',
                canonicalUrl: '',
                image: {
                    __typename: 'Image',
                    url: '/assets/images/experience-center-business.webp',
                    previewUrl: '/assets/images/experience-center-business.webp',
                    provider: '/assets/images/experience-center-business.webp',
                    alt: 'Découvrez plus de 2800 produits',
                    name: 'Découvrez plus de 2800 produits',
                    width: 1080,
                    height: 1920,
                },
                socials: [
                    {
                        socialNetwork: SocialNetworkEnum.Facebook,
                        title: 'Découvrez plus de 2800 produits',
                    },
                    {
                        socialNetwork: SocialNetworkEnum.Twitter,
                        title: 'Découvrez plus de 2800 produits',
                    },
                ],
            },
        },
    },
    {
        id: Pages.EventsOverview,
        data: {
            nl: {
                title: 'Inspirerende  evenementen',
                description: 'Laat je inspireren door onze jaarlijkse Huisshow en andere evenementen. Bekijk hier wat er binnenkort op de planning staat! ✓ Snelle levering  ✓ Eigen fabriek ✓ Hoge product kwaliteit',
                canonicalUrl: '',
                image: {
                    __typename: 'Image',
                    url: '/assets/images/experience-center-business.webp',
                    previewUrl: '/assets/images/experience-center-business.webp',
                    provider: '/assets/images/experience-center-business.webp',
                    alt: 'Inspirerende  evenementen',
                    name: 'Inspirerende  evenementen',
                    width: 1080,
                    height: 1920,
                },
                socials: [
                    {
                        socialNetwork: SocialNetworkEnum.Facebook,
                        title: 'Inspirerende  evenementen',
                    },
                    {
                        socialNetwork: SocialNetworkEnum.Twitter,
                        title: 'Inspirerende  evenementen',
                    },
                ],
            },
            en: {
                title: 'Inspirational events',
                description: 'Get inspired by our annual preview and other events. See what\'s coming up soon here! Fast delivery ✓ Own factory ✓ High product quality',
                canonicalUrl: '',
                image: {
                    __typename: 'Image',
                    url: '/assets/images/experience-center-business.webp',
                    previewUrl: '/assets/images/experience-center-business.webp',
                    provider: '/assets/images/experience-center-business.webp',
                    alt: 'Inspirational events',
                    name: 'Inspirational events',
                    width: 1080,
                    height: 1920,
                },
                socials: [
                    {
                        socialNetwork: SocialNetworkEnum.Facebook,
                        title: 'Inspirational events',
                    },
                    {
                        socialNetwork: SocialNetworkEnum.Twitter,
                        title: 'Inspirational events',
                    },
                ],
            },
            de: {
                title: 'Inspirierende Veranstaltungen',
                description: 'Lassen Sie sich von unserer jährlichen Hausmesse und anderen Veranstaltungen inspirieren. Sehen Sie hier, was demnächst ansteht! ✓ Schnelle Lieferung ✓ Eigene Fabrik ✓ Hohe Produktqualität',
                canonicalUrl: '',
                image: {
                    __typename: 'Image',
                    url: '/assets/images/experience-center-business.webp',
                    previewUrl: '/assets/images/experience-center-business.webp',
                    provider: '/assets/images/experience-center-business.webp',
                    alt: 'Inspirierende Veranstaltungen',
                    name: 'Inspirierende Veranstaltungen',
                    width: 1080,
                    height: 1920,
                },
                socials: [
                    {
                        socialNetwork: SocialNetworkEnum.Facebook,
                        title: 'Inspirierende Veranstaltungen',
                    },
                    {
                        socialNetwork: SocialNetworkEnum.Twitter,
                        title: 'Inspirierende Veranstaltungen',
                    },
                ],
            },
            fr: {
                title: 'Événements inspirants',
                description: 'Laissez-vous inspirer par notre avant-première annuelle et d\'autres événements. Voyez ce qui se passera bientôt ici! Livraison rapide ✓ Propre usine ✓ Haute qualité des produits',
                canonicalUrl: '',
                image: {
                    __typename: 'Image',
                    url: '/assets/images/experience-center-business.webp',
                    previewUrl: '/assets/images/experience-center-business.webp',
                    provider: '/assets/images/experience-center-business.webp',
                    alt: 'Événements inspirants',
                    name: 'Événements inspirants',
                    width: 1080,
                    height: 1920,
                },
                socials: [
                    {
                        socialNetwork: SocialNetworkEnum.Facebook,
                        title: 'Événements inspirants',
                    },
                    {
                        socialNetwork: SocialNetworkEnum.Twitter,
                        title: 'Événements inspirants',
                    },
                ],
            },
        },
    },
    {
        id: Pages.NewsOverview,
        data: {
            nl: {
                title: 'Nieuws - Ontdek de laatste updates',
                description: 'Blijf op de hoogte van de laatste updates en collecties van de merken WOOOD, BePureHome, Exotan en vtwonen.  ✓ Snelle levering  ✓ Eigen fabriek ✓ Hoge product kwaliteit',
                canonicalUrl: '',
                image: {
                    __typename: 'Image',
                    url: '/assets/images/experience-center-business.webp',
                    previewUrl: '/assets/images/experience-center-business.webp',
                    provider: '/assets/images/experience-center-business.webp',
                    alt: 'Nieuws - Ontdek de laatste updates',
                    name: 'Nieuws - Ontdek de laatste updates',
                    width: 1080,
                    height: 1920,
                },
                socials: [
                    {
                        socialNetwork: SocialNetworkEnum.Facebook,
                        title: 'Nieuws - Ontdek de laatste updates',
                    },
                    {
                        socialNetwork: SocialNetworkEnum.Twitter,
                        title: 'Nieuws - Ontdek de laatste updates',
                    },
                ],
            },
            en: {
                title: 'News - Discover the latest updates',
                description: 'Stay up to date with the latest updates and collections from the WOOOD, BePureHome, Exotan and vtwonen brands.  Fast delivery ✓ Own factory ✓ High product quality',
                canonicalUrl: '',
                image: {
                    __typename: 'Image',
                    url: '/assets/images/experience-center-business.webp',
                    previewUrl: '/assets/images/experience-center-business.webp',
                    provider: '/assets/images/experience-center-business.webp',
                    alt: 'News - Discover the latest updates',
                    name: 'News - Discover the latest updates',
                    width: 1080,
                    height: 1920,
                },
                socials: [
                    {
                        socialNetwork: SocialNetworkEnum.Facebook,
                        title: 'News - Discover the latest updates',
                    },
                    {
                        socialNetwork: SocialNetworkEnum.Twitter,
                        title: 'News - Discover the latest updates',
                    },
                ],
            },
            de: {
                title: 'Nachrichten - Entdecken Sie die letzten Neuigkeiten',
                description: 'Bleiben Sie auf dem Laufenden über die neuesten Updates und Kollektionen der Marken WOOOD, BePureHome, Exotan und vtwonen.  ✓ Schnelle Lieferung ✓ Eigene Fabrik ✓ Hohe Produktqualität',
                canonicalUrl: '',
                image: {
                    __typename: 'Image',
                    url: '/assets/images/experience-center-business.webp',
                    previewUrl: '/assets/images/experience-center-business.webp',
                    provider: '/assets/images/experience-center-business.webp',
                    alt: 'Nachrichten - Entdecken Sie die letzten Neuigkeiten',
                    name: 'Nachrichten - Entdecken Sie die letzten Neuigkeiten',
                    width: 1080,
                    height: 1920,
                },
                socials: [
                    {
                        socialNetwork: SocialNetworkEnum.Facebook,
                        title: 'Nachrichten - Entdecken Sie die letzten Neuigkeiten',
                    },
                    {
                        socialNetwork: SocialNetworkEnum.Twitter,
                        title: 'Nachrichten - Entdecken Sie die letzten Neuigkeiten',
                    },
                ],
            },
            fr: {
                title: 'Nouvelles - Découvrez les dernières mises à jour',
                description: 'Restez au courant des dernières mises à jour et collections des marques WOOOD, BePureHome, Exotan et vtwonen.  Livraison rapide ✓ Propre usine ✓ Haute qualité des produits',
                canonicalUrl: '',
                image: {
                    __typename: 'Image',
                    url: '/assets/images/experience-center-business.webp',
                    previewUrl: '/assets/images/experience-center-business.webp',
                    provider: '/assets/images/experience-center-business.webp',
                    alt: 'Nouvelles - Découvrez les dernières mises à jour',
                    name: 'Nouvelles - Découvrez les dernières mises à jour',
                    width: 1080,
                    height: 1920,
                },
                socials: [
                    {
                        socialNetwork: SocialNetworkEnum.Facebook,
                        title: 'Nouvelles - Découvrez les dernières mises à jour',
                    },
                    {
                        socialNetwork: SocialNetworkEnum.Twitter,
                        title: 'Nouvelles - Découvrez les dernières mises à jour',
                    },
                ],
            },
        },
    },
    {
        id: Pages.Dealers,
        data: {
            nl: {
                title: 'Dealers - Bekijk onze verkooppunten',
                description: 'Bekijk onze officiële dealers van de merken WOOOD, BePureHome, Exotan en vtwonen. ✓ Snelle levering  ✓ Eigen fabriek ✓ Hoge product kwaliteit',
                canonicalUrl: '',
                image: {
                    __typename: 'Image',
                    url: '/assets/images/experience-center-business.webp',
                    previewUrl: '/assets/images/experience-center-business.webp',
                    provider: '/assets/images/experience-center-business.webp',
                    alt: 'Dealers - Bekijk onze verkooppunten',
                    name: 'Dealers - Bekijk onze verkooppunten',
                    width: 1080,
                    height: 1920,
                },
                socials: [
                    {
                        socialNetwork: SocialNetworkEnum.Facebook,
                        title: 'Dealers - Bekijk onze verkooppunten',
                    },
                    {
                        socialNetwork: SocialNetworkEnum.Twitter,
                        title: 'Dealers - Bekijk onze verkooppunten',
                    },
                ],
            },
            en: {
                title: 'Dealers - Discover our points of sale',
                description: 'Check out our official dealers of the brands WOOOD, BePureHome, Exotan and vtwonen. Fast delivery ✓ Own factory ✓ High product quality',
                canonicalUrl: '',
                image: {
                    __typename: 'Image',
                    url: '/assets/images/experience-center-business.webp',
                    previewUrl: '/assets/images/experience-center-business.webp',
                    provider: '/assets/images/experience-center-business.webp',
                    alt: 'Dealers - Discover our points of sale',
                    name: 'Dealers - Discover our points of sale',
                    width: 1080,
                    height: 1920,
                },
                socials: [
                    {
                        socialNetwork: SocialNetworkEnum.Facebook,
                        title: 'Dealers - Discover our points of sale',
                    },
                    {
                        socialNetwork: SocialNetworkEnum.Twitter,
                        title: 'Dealers - Discover our points of sale',
                    },
                ],
            },
            de: {
                title: 'Händler - Sehen Sie unsere Verkaufsstellen',
                description: 'Besuchen Sie unsere offiziellen Händler der Marken WOOOD, BePureHome, Exotan und vtwonen. ✓ Schnelle Lieferung ✓ Eigene Fabrik ✓ Hohe Produktqualität',
                canonicalUrl: '',
                image: {
                    __typename: 'Image',
                    url: '/assets/images/experience-center-business.webp',
                    previewUrl: '/assets/images/experience-center-business.webp',
                    provider: '/assets/images/experience-center-business.webp',
                    alt: 'Händler - Sehen Sie unsere Verkaufsstellen',
                    name: 'Händler - Sehen Sie unsere Verkaufsstellen',
                    width: 1080,
                    height: 1920,
                },
                socials: [
                    {
                        socialNetwork: SocialNetworkEnum.Facebook,
                        title: 'Händler - Sehen Sie unsere Verkaufsstellen',
                    },
                    {
                        socialNetwork: SocialNetworkEnum.Twitter,
                        title: 'Händler - Sehen Sie unsere Verkaufsstellen',
                    },
                ],
            },
            fr: {
                title: 'Distributeurs - Découvrez nos points de vente',
                description: 'Découvrez nos revendeurs officiels des marques WOOOD, BePureHome, Exotan et vtwonen. Livraison rapide ✓ Propre usine ✓ Haute qualité des produits',
                canonicalUrl: '',
                image: {
                    __typename: 'Image',
                    url: '/assets/images/experience-center-business.webp',
                    previewUrl: '/assets/images/experience-center-business.webp',
                    provider: '/assets/images/experience-center-business.webp',
                    alt: 'Distributeurs - Découvrez nos points de vente',
                    name: 'Distributeurs - Découvrez nos points de vente',
                    width: 1080,
                    height: 1920,
                },
                socials: [
                    {
                        socialNetwork: SocialNetworkEnum.Facebook,
                        title: 'Distributeurs - Découvrez nos points de vente',
                    },
                    {
                        socialNetwork: SocialNetworkEnum.Twitter,
                        title: 'Distributeurs - Découvrez nos points de vente',
                    },
                ],
            },
        },
    },
    {
        id: Pages.Register,
        data: {
            nl: {
                title: 'Registreren - Maak een account aan',
                description: 'Registreer je als klant en maak een account aan om bestellingen te kunnen plaatsen in de portal. ✓ Snelle levering  ✓ Eigen fabriek ✓ Hoge product kwaliteit',
                canonicalUrl: '',
                image: {
                    __typename: 'Image',
                    url: '/assets/images/experience-center-business.webp',
                    previewUrl: '/assets/images/experience-center-business.webp',
                    provider: '/assets/images/experience-center-business.webp',
                    alt: 'Registreren - Maak een account aan',
                    name: 'Registreren - Maak een account aan',
                    width: 1080,
                    height: 1920,
                },
                socials: [
                    {
                        socialNetwork: SocialNetworkEnum.Facebook,
                        title: 'Registreren - Maak een account aan',
                    },
                    {
                        socialNetwork: SocialNetworkEnum.Twitter,
                        title: 'Registreren - Maak een account aan',
                    },
                ],
            },
            en: {
                title: 'Register - Create an account',
                description: 'Register as a customer and create an account to place orders in the portal. Fast delivery ✓ Own factory ✓ High product quality',
                canonicalUrl: '',
                image: {
                    __typename: 'Image',
                    url: '/assets/images/experience-center-business.webp',
                    previewUrl: '/assets/images/experience-center-business.webp',
                    provider: '/assets/images/experience-center-business.webp',
                    alt: 'Register - Create an account',
                    name: 'Register - Create an account',
                    width: 1080,
                    height: 1920,
                },
                socials: [
                    {
                        socialNetwork: SocialNetworkEnum.Facebook,
                        title: 'Register - Create an account',
                    },
                    {
                        socialNetwork: SocialNetworkEnum.Twitter,
                        title: 'Register - Create an account',
                    },
                ],
            },
            de: {
                title: 'Registrieren - Ein Konto erstellen',
                description: 'Registrieren Sie sich als Kunde und erstellen Sie ein Konto, um Bestellungen im Portal aufzugeben. ✓ Schnelle Lieferung ✓ Eigene Fabrik ✓ Hohe Produktqualität',
                canonicalUrl: '',
                image: {
                    __typename: 'Image',
                    url: '/assets/images/experience-center-business.webp',
                    previewUrl: '/assets/images/experience-center-business.webp',
                    provider: '/assets/images/experience-center-business.webp',
                    alt: 'Registrieren - Ein Konto erstellen',
                    name: 'Registrieren - Ein Konto erstellen',
                    width: 1080,
                    height: 1920,
                },
                socials: [
                    {
                        socialNetwork: SocialNetworkEnum.Facebook,
                        title: 'Registrieren - Ein Konto erstellen',
                    },
                    {
                        socialNetwork: SocialNetworkEnum.Twitter,
                        title: 'Registrieren - Ein Konto erstellen',
                    },
                ],
            },
            fr: {
                title: 'S\'inscrire - Créer un compte',
                description: 'S\'inscrire en tant que client et créer un compte pour passer des commandes sur le portail. Livraison rapide ✓ Propre usine ✓ Haute qualité des produits',
                canonicalUrl: '',
                image: {
                    __typename: 'Image',
                    url: '/assets/images/experience-center-business.webp',
                    previewUrl: '/assets/images/experience-center-business.webp',
                    provider: '/assets/images/experience-center-business.webp',
                    alt: 'S\'inscrire - Créer un compte',
                    name: 'S\'inscrire - Créer un compte',
                    width: 1080,
                    height: 1920,
                },
                socials: [
                    {
                        socialNetwork: SocialNetworkEnum.Facebook,
                        title: 'S\'inscrire - Créer un compte',
                    },
                    {
                        socialNetwork: SocialNetworkEnum.Twitter,
                        title: 'S\'inscrire - Créer un compte',
                    },
                ],
            },
        },
    },
]
