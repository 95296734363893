import { firstValueFrom } from 'rxjs'
import { ReCaptchaV3Service } from 'ng-recaptcha-2'
import { TranslateService } from '@ngx-translate/core'

import { Component } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'

import { InputPublic } from '@app-domains/ui/components/input/input.component.types'
import { SnackbarService } from '@app-domains/ui/services/snackbar/snackbar.service'
import { LocalizationService } from '@app-domains/localization/service/localization.service'
import { FuzzyData } from '@app-domains/ui/components/fuzzy-search/types/fuzzy-search.types'
import { DataRequestService } from '@app-services'
import { connectForm } from '@app-modules/graphql/graphql.module.lib'
import { CountriesEnum } from '@app-types/common.types'
import { TranslationKey } from '@app-pipes/typed-translate/typed-translate.pipe'

@Component({
    selector: 'app-data-request-form',
    templateUrl: './data-request-form.component.html',
    styleUrls: [
        '../contact-form/contact-form.component.scss',
        './data-request-form.component.scss',
    ],
})
export class DataRequestFormComponent {

    public countries: { label: TranslationKey; code: string }[] = Object.values(CountriesEnum).map(
        (code): { label: TranslationKey; code: string } => {
            return {
                label: this.translate.instant(`countries.${ code }`),
                code,
            }
        },
    )

    public dataRequestForm = new FormGroup({
        salutation: new FormControl('', [
            Validators.required,
        ]),
        firstName: new FormControl('', [
            Validators.required,
        ]),
        lastName: new FormControl('', [
            Validators.required,
        ]),
        zipcode: new FormControl('', [
            Validators.required,
        ]),
        houseNumber: new FormControl('', [
            Validators.required,
        ]),
        addition: new FormControl(''),
        street: new FormControl('', [
            Validators.required,
        ]),
        city: new FormControl('', [
            Validators.required,
        ]),
        country: new FormControl<CountriesEnum>(CountriesEnum.nl, {
            nonNullable: true,
            validators: [
                Validators.required,
            ],
        }),
        phoneNumber: new FormControl('', [
            Validators.required,
            Validators.pattern('[- +()0-9]+'),
        ]),
        email: new FormControl('', [
            Validators.required,
            Validators.email,
        ]),
    })

    public dataRequestFormMessages: InputPublic.ErrorMessageRecord = {
        required: this.translate.instant('validation.messages.required'),
        emailNotValid: this.translate.instant('validation.check-input'),
    }

    public submitting: boolean = false

    constructor(
        private readonly recaptchaV3Service: ReCaptchaV3Service,
        private readonly dataRequestService: DataRequestService,
        private readonly translate: TranslateService,
        private readonly snackbarService: SnackbarService,
        private readonly localizationService: LocalizationService,
    ) {
    }

    public async submitDataRequestForm(): Promise<void> {
        this.submitting = true

        if (this.dataRequestForm.invalid) {
            Object.values(this.dataRequestForm.controls).forEach((c) => c.markAsDirty())
            this.dataRequestForm.markAllAsTouched()
            return
        }

        const formValues = this.dataRequestForm.value

        this.recaptchaV3Service.execute('submit')
            .subscribe(async (token: string) => {
                const result = await firstValueFrom(
                    connectForm(this.dataRequestService.sendDataRequestForm({
                        input: {
                            salutation: formValues.salutation!,
                            firstName: formValues.firstName!,
                            lastName: formValues.lastName!,
                            zipcode: formValues.zipcode!,
                            houseNumber: formValues.houseNumber!,
                            addition: formValues.addition,
                            street: formValues.street!,
                            city: formValues.city!,
                            country: formValues.country!,
                            email: formValues.email!.toLowerCase(),
                            phoneNumber: formValues.phoneNumber!,
                            isNotARobot: token,
                            locale: this.localizationService.getCurrentLocale(),
                        },
                    }), this.dataRequestForm),
                )

                this.submitting = false

                if (result.data) {
                    this.snackbarService.create({
                        content: 'data-request.data-request-success',
                        dismissible: true,
                    }, 'bottom')
                }
            })
    }

    public setCountry(event: FuzzyData): void {
        this.dataRequestForm.controls.country.setValue(event.code as CountriesEnum)
    }
}
