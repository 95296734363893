import { Component, Input } from '@angular/core'
import { StatisticLayoutEnum } from '@app-graphql/schema'

@Component({
    selector: 'app-stats',
    templateUrl: './stats.component.html',
    styleUrls: ['./stats.component.scss'],
})
export class StatsComponent {
    @Input()
    public label: string

    @Input()
    public value: string

    @Input()
    public layout?: StatisticLayoutEnum | null = StatisticLayoutEnum.TitleBig

    public LAYOUTS = StatisticLayoutEnum
}
