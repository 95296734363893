import { flatten } from 'ramda'

import { BrandFragment, HighlightedProductFragment, ProductImageTypeEnum } from '@app-graphql/schema'
import { Localization } from '@aa-app-localization'
import { Highlight } from '@app-domains/navigation/category-dropdown/category-dropdown'
import { LocalizedRouting } from '@app-domains/navigation/types/navigation.types'
import { getProductImageOfType, translateProduct } from '@app-lib/product.lib'
import { Translated } from '@app-types/common.types'

export const translateBrands = (
    brand: BrandFragment,
    lang: Localization.Locale,
): LocalizedRouting => {
    const translateProductToLocale = translateProduct(lang)
    return {
        id: brand.id.toString(),
        name: brand.name,
        slug: brand.slug,
        highlightedProducts: brand.highlightedProducts.map(translateProductToLocale),
    }
}

export const randomizeHighlightedProducts = (items: LocalizedRouting[]): Highlight[] => {
    const products = items.map((b) => b.highlightedProducts ?? [])
    const flattened = flatten(products)
    const sorted = flattened.sort(() => Math.random() - 0.5).slice(0, 3)
    return sorted.map((s: Translated<HighlightedProductFragment>) => ({
        title: s.name,
        image: getProductImageOfType(s.images, ProductImageTypeEnum.Group).url,
        highlight: true,
        link: `/products/${s.slug}`,
    }))
}
