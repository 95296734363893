import { Component, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core'
import { FormControl } from '@angular/forms'

import { idGenerator } from '@app-lib/common.lib'

/**
 * # Radio button component
 *
 * Renders a radio button with a label.
 *
 * -
 *
 * ## Input bindings
 *
 * | Input binding                                    | Default        | Required |
 * | ------------------------------------------------ | -------------- | -------- |
 * | {@link RadioButtonComponent.name [name]}         | `''`           | true     |
 * | {@link RadioButtonComponent.value [value]}       | `''`           | true     |
 * | {@link RadioButtonComponent.control [control]}   | `undefined`    | true     |
 * | {@link RadioButtonComponent.disabled [disabled]} | `false`        | false    |
 *
 * -
 *
 * ## Label content
 * The label content is projected in an `<ng-content>` slot:
 *
 * ```html
 * <app-radio-button [control]="..." [value]="...">
 *     <span>Label content here</span>
 * </app-radio-button>
 * ```
 */
@Component({
    selector: 'app-radio-button',
    templateUrl: 'radio-button.component.html',
    styleUrls: ['radio-button.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class RadioButtonComponent {

    private static readonly idGenerator = idGenerator('radio-button')

    /**
     * Provide the name for this radio button that matches it with its sibling options.
     */
    @Input()
    public name: string = ''

    /**
     * Provide the value for this radio button.
     */
    @Input()
    public value: string = ''

    /**
     * Requires a form control to attach to the native input element. This input accepts
     * `undefined` so that the existence check for controls within form-groups can be done
     * internally. If `undefined` is bound then the radio button will simply not render.
     */
    @Input()
    public control: FormControl | undefined = undefined

    @Input()
    public checked: boolean | undefined = undefined

    /**
     * Should this button be disabled? (Default false)
     * Can be either toggled with a one-time binding or with a dynamic boolean binding. Allowed syntax:
     *
     * ```html
     * <app-radio-button disabled></app-radio-button>
     * <app-radio-button disabled="disabled"></app-radio-button>
     * <app-radio-button [disabled]="booleanValue"></app-radio-button>
     * ```
     */
    @Input()
    public set disabled(disabled: '' | 'disabled' | boolean) {
        this.isDisabled = disabled === '' || disabled === 'disabled' || disabled
    }

    @Output()
    public changed = new EventEmitter<boolean>()

    public readonly id = `radio-button-${RadioButtonComponent.idGenerator.next().value}`

    public isDisabled: boolean = false

}
