import { NgModule, ModuleWithProviders } from '@angular/core'
import { Analytics } from '@app-domains/analytics/types/analytics.types'

@NgModule({})
export class AnalyticsModule {
    static forRoot(config: Analytics.Config): ModuleWithProviders<AnalyticsModule> {
        return {
            ngModule: AnalyticsModule,
            providers: [{ provide: 'analyticsConfig', useValue: config }],
        }
    }
}
