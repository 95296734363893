import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'imageSize',
})
export class ImageSizePipe implements PipeTransform {

    transform(url: string, width: number): string {
        if (! url.includes('bfldr.com')) {
            return url
        }
        const baseUrl = url.split('?')[0] ?? url
        return `${baseUrl}?width=${width}&auto=webp`
    }

}
