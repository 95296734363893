import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core'

@Component({
    selector: 'app-grid',
    templateUrl: './grid.component.html',
    styleUrls: ['./grid.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class GridComponent implements OnChanges {
    @Input()
    public gap: boolean

    @Input()
    public rowGap: boolean = false

    @Input()
    public colGap: boolean = false

    public class = 'grid'

    public ngOnChanges(changes: SimpleChanges) {
        const classes: string[] = []
        if (changes.gap || changes.rowGap || changes.colGap) {
            if (this.gap) {
                classes.push('grid-gap')
            } else if (this.rowGap) {
                classes.push('grid-row-gap')
            } else if (this.colGap) {
                classes.push('grid-col-gap')
            }
        }
        if (classes.length === 0) {
            this.class = 'grid'
        } else {
            this.class = classes.join(' ')
        }
    }
}
