import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Input,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core'
import { BreakpointsService } from '@app-domains/ui/services/breakpoints/breakpoints.service'
import { Breakpoint } from '@app-domains/ui/services/breakpoints/breakpoints.service.types'
import { ParagraphFragment } from '@app-graphql/schema'
import { SwiperLib } from '@app-lib/swiper.lib'
import { SwiperService } from '@app-services'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import Swiper, { SwiperOptions } from 'swiper'

@Component({
    selector: 'app-paragraph-carousel',
    templateUrl: './paragraph-carousel.component.html',
    styleUrls: ['./paragraph-carousel.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class ParagraphCarouselComponent implements OnInit {

    @ViewChild('pagination', { static: true })
    public pagination: ElementRef<HTMLDivElement>

    @Input()
    public paragraphCarousels: ParagraphFragment[]

    public swiper: SwiperLib
    public swiperOptions$: Observable<SwiperOptions>

    public shouldSlide$: Observable<boolean> = this.breakpoint.currentBreakpoint$.pipe(
        map((bp) => this.shouldLoop(bp)),
    )

    constructor(
        private cdr: ChangeDetectorRef,
        private swiperService: SwiperService,
        private breakpoint: BreakpointsService,
    ) {
    }

    public ngOnInit(): void {
        this.swiperOptions$ = this.breakpoint.currentBreakpoint$.pipe(
            map((bp) => this.getOptions(bp)),
        )
    }

    public getOptions(bp: Breakpoint): SwiperOptions {
        return {
            slidesPerView: this.shouldLoop(bp) ? 'auto' : 3,
            spaceBetween: 48,
            loop: this.shouldLoop(bp),
            pagination: {
                type: 'progressbar',
                el: this.pagination.nativeElement,
            },
            on: {
                progress: () => {
                    if (this.swiper) {
                        this.swiper.onProgress()
                    }
                },
            },
            direction: 'horizontal',
        }
    }

    private shouldLoop(bp: Breakpoint): boolean {
        switch (bp) {
            case 'desktop':
            case 'laptop':
            case 'tablet':
                return this.paragraphCarousels.length > 3
            case 'mobile':
                return this.paragraphCarousels.length > 1
        }
    }

    public onSwiper(swiper: Swiper): void {
        this.swiper = this.swiperService.create(swiper)
        this.swiper.updateTotalIndex()
    }

    public onSlideChange(): void {
        if (this.swiper) {
            this.swiper.onSlideChange()
            this.cdr.detectChanges()
        }
    }

    @HostListener('window:resize')
    onResize() {
        this.swiper.updateTotalIndex()
    }
}
