import { TranslateLoader } from '@ngx-translate/core'
import { from, Observable } from 'rxjs'
import { Localization } from '@app-domains/localization/localization.types'

export class WebpackTranslateLoader extends TranslateLoader {
    public getTranslation(lang: Localization.Locale): Observable<any> {
        return from(
            import(`../../../../assets/i18n/${lang}.ts`).then((m) => m.translations),
        )
    }
}
