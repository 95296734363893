<section class="expansion-panel"
         [class.active-shadow]="activeShadow"
>
    <div *ngFor="let item of items; index as i"
         [class.disabled]="item.disabled"
         [class.active]="expanded.has(index ? index : i)"
         class="item"
    >
        <ng-container [ngTemplateOutlet]="(item?.customHeader?.templateRef || defaultHeader)"
                      [ngTemplateOutletContext]="{$implicit: item, index: index ? index : i, toggle: getToggleState(index ? index : i)}"
        ></ng-container>
        <div *ngIf="item?.content?.templateRef"
             [class.expanded]="expanded.has(index ? index : i)"
             [@contentExpansion]="expanded.has(index ? index : i) ? 'expanded':'collapsed'"
             class="content"
        >
            <ng-container *ngTemplateOutlet="item.content.templateRef"
            ></ng-container>
        </div>
    </div>
</section>

<ng-template #defaultHeader let-item let-index="index">
    <header class="header"
            (click)="item.disabled ? {} :toggleState(index)"
            [class.header-border]="headerHasBorder && ! expanded.has(index)"
            [class.small-height]="height === 'small'"
    >
        <ng-container *ngTemplateOutlet="item?.customTitle?.templateRef || defaultTitle"
        ></ng-container>
        <button class="toggle-btn"
                [disabled]="item.disabled"
        >
            <span appIcon="chevron-right"
                  class="chevron"
            ></span>
        </button>
    </header>
    <ng-template #defaultTitle>
        <h3>
            {{item?.title}}
        </h3>
    </ng-template>
</ng-template>
