import {
    AfterViewInit,
    Component,
    Input,
    OnInit,
    OnDestroy,
    Inject,
    PLATFORM_ID,
} from '@angular/core'

import instantsearch from 'instantsearch.js/es'

import { InstantSearchOptions, InstantSearch, Widget } from 'instantsearch.js/es/types'
export { SearchClient, Hit } from 'instantsearch.js/es/types'

@Component({
    selector: 'app-algolia-instantsearch',
    template: '<ng-content></ng-content>',
})
export class AlgoliaInstantsearchComponent implements AfterViewInit, OnInit, OnDestroy {

    @Input()
    public config: InstantSearchOptions

    @Input()
    public instanceName: string = 'default'

    public instantSearchInstance: InstantSearch

    constructor(
        @Inject(PLATFORM_ID) public platformId: Object,
    ) {
    }

    public ngOnInit() {
        this.instantSearchInstance = instantsearch(this.config)
    }

    public ngAfterViewInit() {
        this.instantSearchInstance.start()
    }

    public ngOnDestroy() {
        if (this.instantSearchInstance) {
            this.instantSearchInstance.dispose()
        }
    }

    public addWidgets(widgets: Widget[]) {
        this.instantSearchInstance.addWidgets(widgets)
    }

    public removeWidgets(widgets: Widget[]) {
        this.instantSearchInstance.removeWidgets(widgets)
    }

    public refresh() {
        this.instantSearchInstance.refresh()
    }
}
