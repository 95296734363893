import { isPlatformBrowser } from '@angular/common'
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { environment } from '@app-environments/environment'
import { BootService } from '@app-services'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    public isBrowser = isPlatformBrowser(this.platformId)

    public constructor(
        private readonly titleService: Title,
        public readonly bootService: BootService,
        @Inject(PLATFORM_ID) private platformId: string,
    ) {
    }

    public ngOnInit(): void {
        this.titleService.setTitle(environment.title)
    }
}
