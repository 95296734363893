import { Component, Inject } from '@angular/core'
import { environment } from '@app-environments/environment'
import { ImpersonationService } from '@app-services'
import { WINDOW } from '@ng-web-apis/common'

@Component({
    selector: 'app-impersonation-bar',
    templateUrl: './impersonation-bar.component.html',
    styleUrls: ['./impersonation-bar.component.scss'],
})
export class ImpersonationBarComponent {
    public backofficeUrl = `${environment.api.schema}://${environment.api.backoffice}`

    constructor(
        public impersonationService: ImpersonationService,
        @Inject(WINDOW)
        public window: Window,
    ) {}

    public returnToBackOffice(): void {
        this.impersonationService.stopImpersonation()
        this.window.location.href = this.backofficeUrl
    }
}
