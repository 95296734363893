import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'priceSplit',
})
export class PriceSplitPipe implements PipeTransform {

    transform(value: number, priceInCents: boolean = true): [string, string] {
        let price = value

        if (priceInCents) {
            price = price / 100
        }

        return (price.toFixed(2).split('.')) as [string, string]
    }
}
