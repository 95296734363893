import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule, Routes } from '@angular/router'
import { UIModule } from '@app-domains/ui/ui.module'
import { AuthCallbackComponent } from './auth-callback/auth-callback.component'
import { TranslateModule } from '@ngx-translate/core'
import { TranslateRouterModule } from '@endeavour/ngx-translate-router'
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component'
import { ResetPasswordComponent } from './reset-password/reset-password.component'
import { AppFormsModule } from '@app-domains/forms/app-forms.module'
import { ForgotPasswordSuccessComponent } from './forgot-password-success/forgot-password-success.component'

const routes: Routes = [
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
    },
    {
        path: 'forgot-password-success',
        component: ForgotPasswordSuccessComponent,
    },
    {
        path: 'callback',
        pathMatch: 'full',
        component: AuthCallbackComponent,
    },
    {
        path: 'reset-password',
        component: ResetPasswordComponent,
    },
]

@NgModule({
    declarations: [
        AuthCallbackComponent,
        ForgotPasswordComponent,
        ForgotPasswordSuccessComponent,
        ResetPasswordComponent,
    ],
    imports: [
        CommonModule,
        UIModule,
        RouterModule.forChild(routes),
        TranslateRouterModule.forChild(routes),
        TranslateModule,
        AppFormsModule,
    ],
})
export class AuthModule {
}
