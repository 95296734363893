import { Pipe, PipeTransform } from '@angular/core'
import { PageHit } from '@app-types/page.types'
import { OtherResult } from '../../pages/search/types/search.types'

@Pipe({
    name: 'transformPageHit',
})
export class TransformPageHitPipe implements PipeTransform {

    transform(hit: PageHit): OtherResult {
        return {
            title: hit.title,
            image: this.getPageImage(hit),
            description: this.getPageDescription(hit),
            link: this.getPageLink(hit),
        }
    }

    private getPageDescription(hit: PageHit): string {
        return (hit.elements ?? []).reduce((acc, curr) => {
            if (acc) {
                return acc
            }
            if ((curr as any).paragraphs) {
                return (curr as any).paragraphs[0].paragraph
            }
            return acc
        }, undefined) ?? hit.seo?.metaDescription ?? ''
    }

    private getPageImage(hit: PageHit) {
        return (hit.header ?? []).reduce((acc, curr) => {
            if (acc) {
                return acc
            }
            if ((curr as any).image) {
                return (curr as any).image
            }
            return acc
        }, undefined) ?? hit.seo?.metaImage ?? {
            url: 'http://placehold.it/300x300',
        }
    }

    private getPageLink(hit: PageHit): string {
        switch (hit.type) {
            case 'page':
            default:
                return `/p/${hit.slug}`
            case 'news-item':
                return `/news/${hit.slug}`
            case 'event':
                return `/events/${hit.slug}`
        }
    }
}
