import { Component, Input } from '@angular/core'
import { AssetFragment } from '@app-graphql/schema'

@Component({
    selector: 'app-assets',
    templateUrl: './assets.component.html',
    styleUrls: ['./assets.component.scss'],
})
export class AssetsComponent {
    @Input()
    public assets: AssetFragment[]
}
