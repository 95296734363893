<app-grid *ngIf="snackbarRef">
    <app-cell mobile="0:12">
        <ng-container *ngIf="snackbarRef.content" [ngTemplateOutlet]="regular"></ng-container>
    </app-cell>
</app-grid>

<ng-template #regular>
    <div class="snackbar">
        <div class="content">
            <p>{{ snackbarRef.content | translate }}</p>
        </div>

        <app-button *ngIf="! snackbarRef.autoHideDelay && snackbarRef.dismissible"
                    theme="dark"
                    layout="label-only"
                    (clicked)="close()"
        >
            {{ 'common.dismiss' | translate }}
        </app-button>
    </div>
</ng-template>
