import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { Component, Input, OnInit } from '@angular/core'

import { ImageComponentFragment, PositionedImageFragment } from '@app-graphql/schema'
import { breakpoints, BreakpointsService } from '@app-domains/ui/services/breakpoints/breakpoints.service'
import { Breakpoint } from '@app-domains/ui/services/breakpoints/breakpoints.service.types'

@Component({
    selector: 'app-responsive-image',
    templateUrl: './responsive-image.component.html',
    styleUrls: ['./responsive-image.component.scss'],
})
export class ResponsiveImageComponent implements OnInit {
    @Input()
    public image: ImageComponentFragment

    @Input()
    public lazyLoad: boolean = true

    public positionedImage$: Observable<PositionedImageFragment>

    public breakpointsMedia = {
        mobile: `(max-width: ${breakpoints.tablet}px)`,
        tablet: `(max-width: ${breakpoints.laptop}px)`,
        laptop: `(max-width: ${breakpoints.desktop}px)`,
        desktop: `(min-width: ${breakpoints.desktop}px)`,
    }

    public breakpointsArray: Breakpoint[] = [
        'mobile',
        'tablet',
        'laptop',
        'desktop',
    ]

    constructor(
        private breakpointsService: BreakpointsService,
    ) {
    }

    public ngOnInit(): void {
        this.positionedImage$ = this.breakpointsService.currentBreakpoint$.pipe(
            map((bp) => {
                switch (bp) {
                    case 'desktop':
                        return this.image.desktop
                    case 'laptop':
                        return this.image.laptop
                    case 'tablet':
                        return this.image.tablet
                    case 'mobile':
                        return this.image.mobile
                }
            }),
        )
    }
}
