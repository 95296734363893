import { Component, Input, OnDestroy } from '@angular/core'
import { Router } from '@angular/router'
import { BreakpointsService } from '@app-domains/ui/services/breakpoints/breakpoints.service'
import { CategoryDropdown } from '@app-domains/navigation/category-dropdown/category-dropdown'
import { Subscription } from 'rxjs'
import { LocalizationService } from '@app-domains/localization/service/localization.service'

@Component({
    selector: 'app-category-dropdown',
    templateUrl: './category-dropdown.component.html',
    styleUrls: ['./category-dropdown.component.scss'],
})
export class CategoryDropdownComponent implements OnDestroy {

    @Input()
    public categoryDropdown: CategoryDropdown

    @Input()
    public navigationIsCollapsed: boolean = false

    private bpSubscription: Subscription
    private routerSubscription: Subscription
    public hideSubMenu: boolean = false

    constructor(
        private readonly router: Router,
        private breakpointService: BreakpointsService,
        public localizationService: LocalizationService,
    ) {
        this.bpSubscription = this.breakpointService.currentBreakpoint$.subscribe(() => {
            this.linkIsNotClicked()
        })
        this.routerSubscription = this.router.events.subscribe(() => {
            this.linkIsNotClicked()
        })
    }

    public ngOnDestroy(): void {
        this.bpSubscription.unsubscribe()
        this.routerSubscription.unsubscribe()
    }

    public linkHasClicked(): void {
        this.hideSubMenu = false
    }

    public linkIsNotClicked(): void {
        this.hideSubMenu = true
    }

}
