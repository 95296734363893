import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { SkeletonComponent } from '@app-domains/loading/components/skeleton/skeleton.component'
import { PageSpinnerComponent } from '@app-domains/loading/components/page-spinner/page-spinner.component'
import { UIModule } from '@app-domains/ui/ui.module'

@NgModule({
    declarations: [
        SkeletonComponent,
        PageSpinnerComponent,
    ],
    imports: [
        CommonModule,
        UIModule,
    ],
    exports: [
        SkeletonComponent,
        PageSpinnerComponent,
    ],
})
export class LoadingModule {
}
