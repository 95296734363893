import { Environment, EnvironmentVariables } from '@app-types/environment.types'

export const environment: EnvironmentVariables = {
    production: true,
    name: Environment.Production,
    title: 'De Eekhoorn',
    isCrawlAble: true,
    url: 'deeekhoorn.com',
    api: {
        clientId: '965838ed-e9a1-431f-8f86-05f03246bf26',
        schema: 'https',
        hostname: 'api.deeekhoorn.com',
        backoffice: 'backoffice.deeekhoorn.com',
        oauthCallbackUrl: '/authentication/callback',
        oauthLoginUrl: '/authorize',
        oauthTokenUrl: '/oauth/token',
        // Unused yet, but will be used in the future!
        acceptVersionRange: '>=0.0.1 <1.0.0',
    },
    subscriptionGraphql: {
        schema: 'wss',
        hostname: 'deeekhoorn.com',
    },
    colijn: {
        url: 'https://eekhoorn.ionecloud.nl/ione/',
        ajaxUrl: 'https://eekhoorn.ionecloud.nl/ione/',
        upId: '67324',
        version: '252',
        assetPath: 'https://cdn.ione360.com/content43/',
        assetIndex: 'index-white.json',
        threeDAssetPath: 'https://cdn.ione360.com/content380',
    },
    algolia: {
        appId: 'EAWFDZLNSQ',
        apiKey: 'c503699295db9fe0ad7f33ca81394343',
        default_index: 'deeekhoorn_en',
    },
    google: {
        maps: 'AIzaSyDvGcUrmDskBB_IVxU4vbOdPJVLK00JSQY',
        recaptcha: '6LcflXoiAAAAACWN4L_ICjcdaSfHlf6L7OPkC3e1',
        gtm: 'GTM-MMXKQJ',
    },
}
