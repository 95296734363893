import { Localization, locales, defaultLang } from '@aa-app-localization'

export function getLocales(): Localization.LocaleList {
    return locales
}

export function getDefaultLocale(): Localization.Locale {
    return defaultLang
}

export function isValidLocale(x: any): x is Localization.Locale {
    return getLocales().includes(x)
}

export function getLocaleRegexPattern(): string {
    return getLocales().join('|')
}
