import { memoizeWith } from 'ramda'

import { AfterContentInit, Component, ContentChildren, Input, QueryList } from '@angular/core'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { ExpansionPanelItemDirective } from '@app-directives/expansion-panel/expansion-panel-item.directive'

@Component({
    selector: 'app-expansion-panel',
    templateUrl: './expansion-panel.component.html',
    styleUrls: ['./expansion-panel.component.scss'],
    animations: [
        trigger('contentExpansion', [
            state('expanded', style({ height: '*', opacity: 1, visibility: 'visible' })),
            state('collapsed', style({ height: '0', opacity: 0, visibility: 'hidden' })),
            transition('expanded <=> collapsed',
                animate('200ms cubic-bezier(.37,1.04,.68,.98)')),
        ]),
    ],
})
export class ExpansionPanelComponent implements AfterContentInit {
    @Input()
    public expanded = new Set<number>()

    @Input()
    public activeShadow: boolean = false

    @Input()
    public headerHasBorder: boolean = true

    @Input()
    public index?: number

    @Input()
    public height?: 'small' | 'big' = 'big'

    /**
     * Make the toggle function available to be called from
     * outside.
     * Memoize to prevent extra calls
     * @param index - index of the expansion item
     */
    public getToggleState = memoizeWith(String, n => {
        return this.toggleState.bind(this, n)
    })

    /**
     * Decides if the single item will be open at once or not.
     * In collapsing mode, toggling one would collapse others
     */
    @Input() allowMultiOpen = true

    @Input() allowNoneOpen = true

    @ContentChildren(ExpansionPanelItemDirective) items: QueryList<ExpansionPanelItemDirective>

    public toggleState = (index: number) => {
        let currentIndex = this.index ?? index

        if (this.expanded.has(currentIndex) && this.allowNoneOpen) {
            this.expanded.delete(currentIndex)
        } else {
            if (! this.allowMultiOpen) {
                this.expanded.clear()
            }
            this.expanded.add(currentIndex)
        }
    }

    public ngAfterContentInit() {
        const [first] = this.items ?? [undefined]
        if (this.index && first?.isExpanded) {
            this.expanded.add(this.index)
        } else {
            this.items?.forEach((item, index) => {
                if (item.isExpanded) {
                    this.expanded.add(index)
                }
            })
        }
    }

}
