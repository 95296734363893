import { Component, Input } from '@angular/core'
import { ImageComponentFragment } from '@app-graphql/schema'

@Component({
    selector: 'app-image-mosaic',
    templateUrl: './image-mosaic.component.html',
    styleUrls: ['./image-mosaic.component.scss'],
})

export class ImageMosaicComponent {
    @Input()
    public landscape1: ImageComponentFragment

    @Input()
    public landscape2: ImageComponentFragment

    @Input()
    public portrait: ImageComponentFragment

    @Input()
    public lazyLoad: boolean = true
}
