<app-notification-badge [count]="notificationCount"
                        color="grey-4"
>
    <app-button [size]="size"
                type="link"
                theme="link"
                layout="icon-label"
                [href]="link ? link : '' "
                [cmsTarget]="target"
                icon="round-chevron"
                [color]="linkColor === 'light' ? 'light' : 'dark'"
                [ngClass]="iconColor === 'light' ? 'light' : 'dark'"
                (click)="clicked()"
    >
        <ng-content></ng-content>
    </app-button>
</app-notification-badge>
