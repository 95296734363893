<app-grid>
    <app-cell mobile="0:12">
        <div class="mosaic-container">
            <div class="tile">
                <app-responsive-image *ngIf="landscape1; else coloredBackground"
                                      [image]="landscape1"
                                      [lazyLoad]="lazyLoad"
                ></app-responsive-image>
            </div>
            <div class="tile">
                <app-responsive-image *ngIf="portrait; else coloredBackground"
                                      [image]="portrait"
                                      [lazyLoad]="lazyLoad"
                ></app-responsive-image>
            </div>
            <div class="tile">
                <app-responsive-image *ngIf="landscape2; else coloredBackground"
                                      [image]="landscape2"
                                      [lazyLoad]="lazyLoad"
                ></app-responsive-image>
            </div>
        </div>

        <ng-template #coloredBackground>
            <div class="mosaic__bg"></div>
        </ng-template>
    </app-cell>
</app-grid>

