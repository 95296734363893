import { Observable } from 'rxjs'
import { MutationResult } from 'apollo-angular'
import { Injectable } from '@angular/core'
import {
    SendDataRequestFormMutation,
    SendDataRequestFormMutationService,
    SendDataRequestFormMutationVariables,
} from '@app-graphql/schema'

@Injectable({
    providedIn: 'root',
})
export class DataRequestService {

    constructor(
        private readonly sendDataRequestFormMutationService: SendDataRequestFormMutationService,
    ) {
    }

    public sendDataRequestForm(input: SendDataRequestFormMutationVariables)
        : Observable<MutationResult<SendDataRequestFormMutation>> {
        return this.sendDataRequestFormMutationService.mutate(input, {
            errorPolicy: 'all',
        })
    }
}
