import { Pipe, PipeTransform } from '@angular/core'
import { LocalizationService } from '@app-domains/localization/service/localization.service'
import { PositionedPageEnum } from '@app-graphql/schema'
import { PageService } from '@app-services'
import { path } from 'ramda'
import { combineLatestWith, Observable, startWith } from 'rxjs'
import { map } from 'rxjs/operators'

@Pipe({
    name: 'positionedPageTitle',
})
export class PositionedPageTitlePipe implements PipeTransform {
    constructor(
        private localization: LocalizationService,
        private positionedPageService: PageService,
    ) {
    }

    transform(identifier: PositionedPageEnum, defaultValue?: string): Observable<string | null> {
        return this.positionedPageService.slugsTable$.pipe(
            startWith(defaultValue),
            combineLatestWith(this.localization.currentLocale$),
            map(([table, locale]) => path([identifier, locale, 'title'], table) ?? null),
        )
    }
}
