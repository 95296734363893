import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { FocusableOption, FocusKeyManager } from '@angular/cdk/a11y'
import { Injectable, QueryList } from '@angular/core'
import { FormControl } from '@angular/forms'

import { SelectOptionComponent } from '@app-domains/ui/components/select/option/select-option.component'

/**
 * # SelectControlRegistryService
 * Used to manage the state of a select component
 *
 */
@Injectable({
    providedIn: 'root',
})
export class SelectControlRegistryService<T = unknown> {

    private internalIsOpen = false
    private control: FormControl
    private parent: FocusableOption

    public keyManager: FocusKeyManager<SelectOptionComponent<T>>
    public activeItem$: Observable<SelectOptionComponent<T> | null>

    /**
     * Forwards keyboard events to the key manager.
     * @param {KeyboardEvent} e
     */
    public forwardEvent(e: KeyboardEvent) {
        return this.keyManager.onKeydown(e)
    }

    /**
     * Set the parent of the options.
     * @param {T extends FocusableOption} parent
     */
    public initialise<Y extends FocusableOption>(parent: Y) {
        this.parent = parent
    }

    /**
     * Initialise the options with the given control and query list of options.
     * @param {FormControl} control
     * @param {QueryList<SelectOptionComponent>} options
     */
    public initialiseOptions(control: FormControl, options: QueryList<SelectOptionComponent<T>>) {
        this.control = control
        this.keyManager = new FocusKeyManager<SelectOptionComponent<T>>(options).withWrap()
        this.activeItem$ = this.keyManager.change.pipe(
            map(() => {
                return this.keyManager.activeItem
            }),
        )
        if (control.value) {
            const option = options.find((o) => o.value === control.value)
            if (option) {
                this.keyManager.setActiveItem(option)
            }
        } else {
            this.keyManager.setActiveItem(0)
        }
    }

    /**
     * Open the options.
     */
    public open(): void {
        this.internalIsOpen = true
    }

    /**
     * Close the options.
     */
    public close(): void {
        this.internalIsOpen = false
        if (this.parent) {
            this.parent.focus()
        }
    }

    /**
     * Get the state of the options overlay.
     * @returns {boolean}
     */
    public get isOpen(): boolean {
        return this.internalIsOpen
    }

}
