import { TranslateModule } from '@ngx-translate/core'
import { Loader } from '@googlemaps/js-api-loader'

import { ReactiveFormsModule } from '@angular/forms'
import { APP_INITIALIZER, NgModule, PLATFORM_ID } from '@angular/core'
import { CommonModule, isPlatformBrowser } from '@angular/common'
import { GoogleMapsModule } from '@angular/google-maps'
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete'

import { GoogleMapsComponent } from '@app-domains/google-maps/components/google-maps/google-maps.component'
import { initializeGoogleMaps } from '@app-domains/google-maps/google-maps.bootstrap'
import { UIModule } from '@app-domains/ui/ui.module'
import { PipesModule } from '@app-pipes/pipes.module'
import { FilterDialogComponent } from './components/filter-dialog/filter-dialog.component'
import { DirectivesModule } from '@app-directives/directives.module'
import { ProductListDomainModule } from '@app-domains/product-list/product-list-domain.module'
import { environment } from '@app-environments/environment'

@NgModule({
    declarations: [
        GoogleMapsComponent,
        FilterDialogComponent,
    ],
    imports: [
        CommonModule,
        GoogleMapsModule,
        UIModule,
        TranslateModule,
        PipesModule,
        DirectivesModule,
        ProductListDomainModule,
        ReactiveFormsModule,
        NgxGpAutocompleteModule,
    ],
    exports: [
        GoogleMapsComponent,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (platformId: string) => async () => {
                if (isPlatformBrowser(platformId)) {
                    await initializeGoogleMaps()
                }
            },
            deps: [
                PLATFORM_ID,
            ],
            multi: true,
        },
        {
            provide: Loader,
            useValue: new Loader({
                apiKey: environment.google.maps,
                libraries: ['places'],
            }),
        },
    ],
})
export class GoogleMapsComponentModule {}
