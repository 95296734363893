<div class="tab-group" *ngIf="tabs?.length">
    <ng-container *ngIf="indicatorContainer; else indicators">
        <ng-container *ngTemplateOutlet="indicatorContainer, context: { content: indicators }"></ng-container>
    </ng-container>

    <div class="tab-contents">
        <ng-content select="app-tab"></ng-content>
    </div>
</div>

<ng-template #indicators>
    <div class="tabs" [ngClass]="['justify-' + tabJustify, containerSize]">
        <button *ngFor="let tab of tabs"
                class="tab"
                [class.active]="tab.active"
                (click)="setActiveTab(tab)"
        >
            {{ tab.label }}
        </button>
    </div>
</ng-template>
