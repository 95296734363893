import {
    AfterContentInit,
    Component,
    ContentChildren,
    EventEmitter,
    Input,
    Output,
    QueryList,
    TemplateRef,
} from '@angular/core'
import { TabComponent } from '@app-domains/ui/components/tab/tab/tab.component'

@Component({
    selector: 'app-tab-group',
    templateUrl: './tab-group.component.html',
    styleUrls: ['./tab-group.component.scss'],
})
export class TabGroupComponent implements AfterContentInit {

    @Input()
    public tabJustify: 'start' | 'center' | 'end' | 'stretch' = 'stretch'

    /**
     * An optional one-time binding to specify the initially active tab, identified by its label.
     * If not given, the first tab will become active initially.
     */
    @Input()
    public initialTabLabel?: string

    /**
     * An optional one-time binding to specify the initially active tab, identified by its value.
     * If not given, the first tab will become active initially.
     */
    @Input()
    public initialTabValue?: string

    /**
     * Allows you to pass in a template to be used as a container for the tab indicators.
     * The template MUST have `let-content="content"` in the `ng-template` attributes and then use
     * `<ng-container *ngTemplateOutlet="content"></ng-container>` wherever in the container you want
     * to display the indicators
     */
    @Input()
    public indicatorContainer: TemplateRef<any>

    @Input()
    public containerSize: 'small' | 'large' = 'small'

    /**
     * Emits the active tab value or label if value is not present whenever the active tab changes.
     */
    @Output()
    public tabChange = new EventEmitter<any>()

    @ContentChildren(TabComponent)
    public tabs: QueryList<TabComponent>

    // ------------------------------------------------------------------------------
    //      Lifecycle hooks
    // ------------------------------------------------------------------------------

    public ngAfterContentInit(): void {
        if (this.initialTabLabel) {
            this.setActiveTab(this.getTabByLabel(this.initialTabLabel) ?? this.tabs.first)
        } else if (this.initialTabValue) {
            this.setActiveTab(this.getTabByValue(this.initialTabValue) ?? this.tabs.first)
        } else {
            this.setActiveTab(this.tabs.first)
        }
    }

    public setActiveTab(nextActiveTab: TabComponent): void {
        const prevActiveTab = this.getActiveTab()

        if (prevActiveTab === nextActiveTab) {
            return
        }

        if (! this.hasAnyTab()) {
            return
        }

        if (! this.hasTab(nextActiveTab)) {
            if (this.noTabIsActive()) {
                this.setActiveTab(this.tabs.first)
            }
            return
        }

        for (const tab of this.tabs) {
            tab.active = tab === nextActiveTab
        }

        this.tabChange.emit(nextActiveTab.value ?? nextActiveTab.label)
    }

    private getActiveTab(): TabComponent | undefined {
        return this.tabs.find((tab) => tab.active)
    }

    private getTabByLabel(label: string): TabComponent | undefined {
        return this.tabs?.find((tab) => tab.label === label)
    }

    private getTabByValue(value: string): TabComponent | undefined {
        return this.tabs?.find((tab) => tab.value === value)
    }

    private hasAnyTab(): boolean {
        return this.tabs.length > 0
    }

    private hasTab(searchTab: TabComponent): boolean {
        return this.tabs?.some((tab) => tab === searchTab) ?? false
    }

    private noTabIsActive(): boolean {
        return ! this.tabs.some((tab) => tab.active)
    }

}
