import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core'
import { UniqueSellingPointFragment } from '@app-graphql/schema'
import { SwiperService } from '@app-services'
import Swiper from 'swiper'
import { SwiperOptions } from 'swiper'
import { SwiperLib } from '@app-lib/swiper.lib'

@Component({
    selector: 'app-usps',
    templateUrl: './usps.component.html',
    styleUrls: ['./usps.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class UspsComponent implements OnInit {

    @Input()
    public usps: UniqueSellingPointFragment[]

    public swiper: SwiperLib

    public swiperOptions: SwiperOptions

    constructor(
        private swiperService: SwiperService,
    ) {
    }

    public ngOnInit(): void {
        this.swiperOptions = {
            slidesPerView: 1,
            direction: 'horizontal',
            loop: true,
            breakpoints: {
                768: {
                    slidesPerView: 2,
                },
                1225: {
                    slidesPerView: 3,
                },
            },
        }
    }

    public onSwiper(swiper: Swiper): void {
        this.swiper = this.swiperService.create(swiper)
    }

    public previousSlide(): void {
        this.swiper.slidePrev()
    }

    public nextSlide(): void {
        this.swiper.slideNext()
    }
}
