import { CdkMenuTrigger } from '@angular/cdk/menu'
import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { MenuDropdown } from '@app-domains/navigation/menu-dropdown/menu-dropdown'
import { AuthService } from '@app-services'
import { UpdateProfileService } from '@app-services'
import { firstValueFrom } from 'rxjs'
import { LocalizationService } from '@app-domains/localization/service/localization.service'
import { LocalesEnum } from '@app-graphql/schema'

@Component({
    selector: 'app-menu-dropdown',
    templateUrl: './menu-dropdown.component.html',
    styleUrls: ['./menu-dropdown.component.scss'],
})
export class MenuDropdownComponent implements OnInit {

    public showDropdown: boolean = false

    @ViewChild(CdkMenuTrigger, { static: false }) matMenuTrigger: CdkMenuTrigger

    @Input() dropdown: MenuDropdown

    @Input() userDropdown: boolean = false

    public readonly LocalesEnum = LocalesEnum

    constructor(
        public authService: AuthService,
        public updateProfileService: UpdateProfileService,
        public localization: LocalizationService,
    ) {
    }

    public ngOnInit(): void {
        if (this.userDropdown) {
            firstValueFrom(this.authService.user$).then((user) => {
                this.updateProfileService.hidePurchasePrice.setValue(user!.hidePurchasePrice)
            })
        }
    }

    public close(): void {
        this.matMenuTrigger.close()
    }
}
