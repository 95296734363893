<ng-container *ngIf="authService.user$ | async">
    <div *ngIf="accountManager"
        class="subfooter"
    >
        <app-grid>
            <app-cell mobile="0:12">
                <div class="subfooter-2-container">
                    <div class="middle-point">
                        <img src="/assets/images/divider.svg"
                             alt="mid point icon"
                             loading="lazy"
                        />
                    </div>

                    <div class="block left-content">
                        <div class="person-info">
                            <div class="profile">
                                <img [src]="accountManager.photoUrl"
                                     loading="lazy"
                                     [alt]="('common.image-of' | translate: { name: 'account manager ' + accountManager.name})"
                                     height="120"
                                     width="120"
                                     [class.placeholder]="(accountManager.photoUrl | includes: 'user-placeholder-icon.svg')"
                                />
                            </div>

                            <div class="text">
                                <h3 *ngIf="accountManager.name">
                                    {{ accountManager.name }}
                                </h3>

                                <p class="label">
                                    {{ 'contact.any-questions' | translate }}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="horizontal-middle-point">
                        <div class="background-image"></div>
                    </div>

                    <div class="block right-content">
                        <div class="button-grid">
                            <app-button theme="grey"
                                        layout="icon-label"
                                        icon="phone"
                                        class="span-2"
                                        type="link"
                                        href="{{ 'tel:' + (accountManager.phone.length ? accountManager.phone : '+31889666300') }}"
                            >
                                {{ 'contact.call-with' | translate }} {{ accountManager.name.length ? accountManager.name : 'De Eekhoorn' }}
                            </app-button>

                            <app-button *ngIf="localization.currentLocale$ | async as locale"
                                        theme="line"
                                        type="link"
                                        class="span-2"
                                        [href]="locale + '/p/service'"
                            >
                                {{ 'navigation.service' | translate }}
                            </app-button>
                        </div>
                    </div>
                </div>
            </app-cell>
        </app-grid>
    </div>
</ng-container>
