import { Pipe, PipeTransform } from '@angular/core'
import { BreakpointsService } from '@app-domains/ui/services/breakpoints/breakpoints.service'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'

type ClassName = 'single-item' | 'double-item' | 'triple-item' | 'quad-item'

@Pipe({
    name: 'otherProductsGrid',
})
export class OtherProductsGridPipe implements PipeTransform {
    constructor(
        private breakpointsService: BreakpointsService,
    ) {
    }

    /**
     * Calculates the size of a card in the recommended products based on it's index
     **/
    transform(index: number, last?: boolean, count?: number): Observable<ClassName> {
        if (last && count) {
            return this.breakpointsService.currentBreakpoint$.pipe(
                map((bp) => {
                    if (['mobile', 'tablet'].includes(bp)) {
                        const remainder = count % 2
                        switch (remainder) {
                            default:
                            case 0:
                                return 'single-item'
                            case 1:
                                return 'double-item'
                        }
                    } else {
                        const remainder = count % 6
                        switch (remainder) {
                            default:
                            case 0:
                            case 3:
                                return this.getClass(index)
                            case 1:
                            case 4:
                                return 'quad-item'
                            case 2:
                                return 'triple-item'
                            case 5:
                                return 'double-item'
                        }
                    }
                }),
            )
        }
        return of(this.getClass(index))
    }

    private getClass(index: number): 'single-item' | 'double-item' {
        return Math.round(((index % 6) + 1) / 2) % 2 ? 'single-item' : 'double-item'
    }
}
