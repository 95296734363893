<app-grid>
    <app-cell mobile="0:12">
        <app-component-with-text [preHeader]="preHeader"
                                 [header]="header"
                                 [content]="content"
                                 [buttons]="buttons"
                                 [transparentBackground]="transparentBackground"
                                 [textAlign]="textAlign"
        >
            <app-responsive-image [image]="image"
                                  [lazyLoad]="lazyLoad"
            ></app-responsive-image>
        </app-component-with-text>
    </app-cell>
</app-grid>
