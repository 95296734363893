import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'truncateText',
})
export class TruncateTextPipe implements PipeTransform {

    public transform(value: string, limit = 225, completeWords = true, ellipsis = '...') {
        let charLimit = limit

        if (! value) {
            return ''
        }

        if (value.length < charLimit || charLimit === 0) {
            return value
        }

        if (completeWords && value.length > limit) {
            charLimit = value.substring(0, limit).lastIndexOf(' ')
        }

        return value.substring(0, charLimit) + ellipsis
    }
}
