import { Localization } from '@aa-app-localization'
import { LocalizedRouting } from '@app-domains/navigation/types/navigation.types'
import { CategoryFragment, ChildCategoryFragment, SimpleProductFragment } from '@app-graphql/schema'
import { translateProduct } from '@app-lib/product.lib'
import { Translated, Translatable } from '@app-types/common.types'

export const isChildCategory = (
    category: CategoryFragment | ChildCategoryFragment,
): category is ChildCategoryFragment => {
    return !! (category as ChildCategoryFragment).parentId
}

export const translateCategory = (
    category: CategoryFragment | ChildCategoryFragment,
    lang: Localization.Locale,
): LocalizedRouting => {
    const translateProductToLocale = translateProduct(lang)
    return {
        id: category.id,
        name: category.translations[lang].name,
        slug: category.translations[lang].slug,
        highlightedProducts: isChildCategory(category)
            ? [] as Translated<SimpleProductFragment>[]
            : category.highlightedProducts.map(translateProductToLocale),
        children: (category as CategoryFragment).children?.filter(
            (c): c is ChildCategoryFragment => !! c,
        ).map(
            (child) => translateCategory(child, lang),
        ),
    }
}

export function genericTranslate<T extends Translatable>(
    translatable: T,
    lang: Localization.Locale,
): Translated<T> {
    return {
        ...translatable.translations[lang],
        ...translatable,
    }
}
