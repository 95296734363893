import { Injectable } from '@angular/core'
import { CookieService } from 'ngx-cookie-service'

import { AuthState, AuthStateStore } from '@app-services'

@Injectable({
    providedIn: 'root',
})
export class CookieAuthStorageService implements AuthStateStore {

    constructor(
        private readonly cookieService: CookieService,
    ) {
    }

    private authState: AuthState | null = null

    public getAuthState(): AuthState | null {
        return this.authState
    }

    public isAuthenticated(): boolean {
        return this.authState !== null
    }

    /**
     * Submits the given auth-state to local storage and then to the AuthState observable streams.
     */
    public submitAuthState({ accessToken, tokenType, refreshToken, expiresAt }: AuthState): void {
        this.authState = {
            accessToken,
            tokenType,
            refreshToken,
            expiresAt,
        }

        this.cookieService.set('authState', JSON.stringify(this.authState), {
            path: '/',
            domain: location.hostname,
        })
    }

    /**
     * Removes all AuthState information from local storage and pushes `null` into the
     * AuthState observable streams.
     */
    public clearAuthState(): void {
        this.cookieService.delete('authState', '/', location.hostname)
        this.cookieService.delete('authState_temp', '/', location.hostname)
        this.authState = null
    }

    /**
     * Initializes the AuthState for the application: Reads the currently stored state, and
     * if it is valid it will be pushed into the AuthState observable streams. Otherwise, it
     * removes any partial data that is stored locally, and pushes `null` into the streams.
     */
    public initialize(): AuthState | null {
        const initialState = this.readPersistedAuthState()
        if (initialState !== null) {
            this.submitAuthState(initialState)
        } else {
            this.clearAuthState()
        }

        return this.authState
    }

    /**
     * Reads and returns the locally stored AuthState data. Returns null instead if a
     * fully valid state object cannot be constructed from the stored data.
     */
    private readPersistedAuthState(): AuthState | null {
        const state = JSON.parse(this.cookieService.get('authState') || 'null') as AuthState | null

        if (! state) {
            return null
        }
        state.expiresAt = new Date(state.expiresAt)

        return this.isValidAuthState(state) ? state : null
    }

    /**
     * Tests if the given value is the shape of a full authentication state object, as is
     * locally stored on the app device.
     */
    public isValidAuthState(state: any): state is AuthState {
        return typeof state === 'object'
            && typeof state.accessToken === 'string'
            && typeof state.tokenType === 'string'
            && typeof state.refreshToken === 'string'
            && this.isValidDate(state.expiresAt)
    }

    protected isValidDate(dateString: string): boolean {
        const date = new Date(dateString)
        return ! isNaN(date.getTime())
    }
}
