<ng-container *ngIf="checked !== undefined">
    <input type="checkbox"
           [id]="id"
           [checked]="checked"
           (change)="changed.emit($any($event.target).value)"
           [attr.disabled]="isDisabled ? 'disabled' : null"
    >
    <ng-container *ngTemplateOutlet="label"></ng-container>
</ng-container>

<ng-container *ngIf="! nativeMode && renderedFormControl; else native">
    <input type="checkbox"
           [id]="id"
           [formControl]="renderedFormControl"
           [attr.disabled]="isDisabled ? 'disabled' : null"
    >
    <ng-container *ngTemplateOutlet="label"></ng-container>
</ng-container>

<ng-template #native>
    <ng-container *ngIf="nativeMode && internalFormControl">
        <input type="checkbox"
               [id]="id"
               [formControl]="internalFormControl"
               [attr.disabled]="isDisabled ? 'disabled' : null"
        >
        <ng-container *ngTemplateOutlet="label"></ng-container>
    </ng-container>
</ng-template>

<ng-template #label>
    <label [for]="id" class="label">
        <span class="label-content"
              [class.small]="smallLabel"
        >
            <ng-content></ng-content>
        </span>
    </label>
</ng-template>
