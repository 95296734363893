import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { Injectable } from '@angular/core'
import { AccountManagerDataFragment, AccountManagerQueryService } from '@app-graphql/schema'

@Injectable({
    providedIn: 'root',
})
export class AccountManagerService {

    constructor(
        private accountManagerQueryService: AccountManagerQueryService,
    ) {
    }

    public getAccountManager(): Observable<AccountManagerDataFragment> {
        return this.accountManagerQueryService.fetch().pipe(
            map((result) => result.data?.debtor.accountManager),
        )
    }
}
