import { Component, forwardRef, Inject, Input, OnInit } from '@angular/core'

import { EXPERIMENTAL_connectConfigureRelatedItems } from 'instantsearch.js/es/connectors'
import {
    ConfigureRelatedItemsConnectorParams,
    ConfigureRelatedItemsWidgetDescription,
} from 'instantsearch.js/es/connectors/configure-related-items/connectConfigureRelatedItems'
import { AbstractInstantsearchWidgetComponent } from '@app-domains/algolia/abstract-instantsearch-widget.component'
import { AlgoliaInstantsearchComponent } from '@app-domains/algolia/components/algolia-instantsearch.component'

@Component({
    selector: 'app-algolia-experimental-configure-related-items',
    template: '',
})
export class AlgoliaExperimentalConfigureRelatedItemsComponent extends AbstractInstantsearchWidgetComponent<
ConfigureRelatedItemsWidgetDescription,
ConfigureRelatedItemsConnectorParams
> implements OnInit {

    public widgetName: string

    @Input() hit: ConfigureRelatedItemsConnectorParams['hit']
    @Input()
    public matchingPatterns: ConfigureRelatedItemsConnectorParams['matchingPatterns']
    @Input()
    public transformSearchParameters: ConfigureRelatedItemsConnectorParams['transformSearchParameters']

    constructor(
        @Inject(forwardRef(() => AlgoliaInstantsearchComponent))
        public instantSearchInstance: AlgoliaInstantsearchComponent,
    ) {
        super()
    }

    public override ngOnInit() {
        this.createWidget(
            EXPERIMENTAL_connectConfigureRelatedItems,
            {
                hit: this.hit,
                matchingPatterns: this.matchingPatterns,
                transformSearchParameters: this.transformSearchParameters,
            },
            {
                $$widgetType: 'ais.configureRelatedItems',
            },
        )

        super.ngOnInit()
    }
}
