<div class="modal">
    <div class="title-row"
    >
        <h3>
            {{'shopping-cart.not-in-stock' | translate}}
        </h3>

        <span appIcon="close"
              class="close-icon"
              (click)="closeDialog()"
        ></span>
    </div>

    <div class="out-of-stock"
         *ngIf="tableItems"
    >
        <app-shopping-cart-products-table class="table"
                                          [dataSource]="tableItems"
                                          [noSort]="true"
                                          [displayedColumns]="displayedColumns"
                                          [priceFieldCanBeChanged]="false"
                                          [mobileHead]="false"
        ></app-shopping-cart-products-table>
    </div>
    <div class="footer">

        <div>
            <div class="contact-label">
                <label class="bold stretch">
                    {{ 'contact.can-we-help' | translate }}
                </label>
            </div>

            <div class="detail">
                <div class="icon">
                    <span appIcon="phone"></span>
                </div>
                <div>
                    <a href="tel:+31889666300" tabindex="-1">+31 (0)889-666300</a>
                    <p class="subtitle">
                        ({{'contact.days' | translate}}) 08:30 - 17:00
                    </p>
                </div>
            </div>
        </div>

        <app-button theme="dark"
                    (click)="orderWithoutProducts()"
        >
            {{'shopping-cart.order-without' | translate}}
        </app-button>
    </div>
</div>