<div class="project-showcase-container">
    <app-grid>
        <app-cell mobile="full:0" [responsiveStyle$]="{
            laptop: {
                gridRow: '1 / 3'
            },
            desktop: {
                gridRow: '1 / 3'
            },
            mobile: {
                gridRow: '1 / 5'
            },
            tablet: {
                gridRow: '1 / 5'
            }
        } | responsiveStyle$">
            <div class="gutter-flex gutter-left">
                <div class="project-showcase--gutter--outside"></div>
                <div class="project-showcase--gutter--inside"></div>
            </div>
        </app-cell>

        <app-cell laptop="0:3" mobile="0:12">
            <div class="project-showcase-text">
                <div class="text-container">
                    <h3 *ngIf="preHeading" class="subheading--soft serif">{{ preHeading }}</h3>
                    <h1 *ngIf="title" class="text-white">{{ title }}</h1>
                    <p *ngIf="text" class="text-white">{{ text }}</p>

                    <div class="link" *ngIf="link">
                        <app-chevron-link
                                size="normal"
                                linkColor="light"
                                [link]="link.link"
                                [target]="link.target"
                        >  {{ link.label }}
                        </app-chevron-link>
                    </div>
                </div>
            </div>
        </app-cell>

        <app-cell mobile="12:full" [responsiveStyle$]="{
            laptop: {
                display: 'none'
            },
            desktop: {
                display: 'none'
            },
            mobile: {
                gridRow: '1 / 5'
            },
            tablet: {
                gridRow: '1 / 5'
            }
        } | responsiveStyle$">
            <div class="gutter-flex gutter-right">
                <div class="project-showcase--gutter--inside"></div>
            </div>
        </app-cell>

        <app-cell mobile="3:7" [responsiveStyle$]="{
                mobile: {
                    display: 'none'
                },
                tablet: {
                    display: 'none'
                },
                laptop: {
                    gridRow: 'span 2'
                },
                desktop: {
                    gridRow: 'span 2'
                }
            } | responsiveStyle$">
            <div class="project-showcase cutoff">
            </div>
        </app-cell>

        <app-cell laptop="7:full" mobile="0:12" [responsiveStyle$]="{
            laptop: {
                gridRow: 'span 2'
            },
            desktop: {
                gridRow: 'span 2'
            }
        } | responsiveStyle$">

            <div class="swiper-container">
                <swiper [config]="swiperOptions"
                        (swiper)="onSwiper($event)"
                        (slideChange)="onSlideChange()"
                >
                    <ng-container *ngFor="let project of projectsSlides">
                        <ng-template swiperSlide>
                            <div class="container">
                                <div class="card"
                                     [class.pointer]="project.link?.link"
                                >
                                    <app-responsive-image *ngIf="project.image"
                                                          [image]="project.image"
                                                          [lazyLoad]="lazyLoad"
                                    ></app-responsive-image>

                                    <div class="card-content">
                                        <div class="text">
                                            <div>
                                                <h3>
                                                    {{ project.title }}
                                                </h3>
                                                <p>
                                                    {{ project.subtitle }}
                                                </p>
                                            </div>

                                            <div *ngIf="project.link"
                                                 class="icon"
                                            >
                                                <span appIcon="arrow-fat-right"></span>
                                            </div>
                                        </div>
                                    </div>

                                    <a class="link-overlay"
                                       *ngIf="project.link?.link" [href]="project.link?.link"
                                       [target]="project.link ?
                                                  project.link!.target === linkTargetEnum.Blank ? '_blank' : '_self'
                                                  : null
                                                "
                                       ></a>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </swiper>
            </div>
        </app-cell>

        <app-cell mobile="0:12" laptop="0:3">
            <div class="pagination-container"
            >
                <p class="text-white"
                   *ngIf="swiper && swiper.totalIndex && swiper.totalIndex > 1"
                >
                    {{swiper.swiperIndex}}/{{swiper.totalIndex}}
                </p>

                <div #pagination></div>

                <div *ngIf="swiper && swiper.totalIndex && swiper.totalIndex > 1"
                     class="swiper-navigation"
                >
                    <span appIcon="arrow-left"
                          class="navigation-icon"
                          (click)="swiper.slidePrev()"
                    ></span>

                    <span appIcon="arrow-right"
                          class="navigation-icon"
                          (click)="swiper.slideNext()"
                    ></span>
                </div>
            </div>
        </app-cell>

    </app-grid>
</div>
