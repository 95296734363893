import { Directive, Input, HostBinding, Inject, PLATFORM_ID } from '@angular/core'
import { isPlatformBrowser } from '@angular/common'
import { RouterLinkCommands } from '@endeavour/ngx-translate-router'

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[ssrHref]',
})
export class SsrHrefDirective {
    @Input()
    public ssrHref: RouterLinkCommands

    @HostBinding('attr.href')
    public get href() {
        return isPlatformBrowser(this.platformId) ? undefined : this.ssrHref as string
    }

    constructor(
        @Inject(PLATFORM_ID)
        public platformId: string,
    ) { }

}
