import { Inject, Injectable } from '@angular/core'
import { LOCAL_STORAGE } from '@ng-web-apis/common'

@Injectable({
    providedIn: 'root',
})
export class DownloadTrackerService {

    // Future feature: Store downloadIds in Local Storage when Redis logic at back-end has setup
    private readonly pendingDownloads: string[] = []

    constructor(
        @Inject(LOCAL_STORAGE)
        private storage: Storage,
    ) { }

    public getPendingDownloads(): string[] {
        if (! this.pendingDownloads) {
            return []
        }

        return this.pendingDownloads
    }

    public registerDownload(pendingDownloadId: string): void {
        const downloads = this.getPendingDownloads()

        if (! downloads.includes(pendingDownloadId)) {
            downloads.push(pendingDownloadId)
        }
    }

    public unRegisterDownload(pendingDownloadId: string): void {
        const downloads = this.getPendingDownloads()

        const foundIndex = downloads.indexOf(pendingDownloadId)

        if (foundIndex > -1) {
            downloads.splice(foundIndex, 1)
        }
    }
}
