import { Localization } from '@aa-app-localization'
import { Product } from '@app-domains/content-blocks/components/product-card/product-card.types'
import {
    HighlightedProductFragment,
    ProductFragment,
    ProductImageFragment,
    ProductImageTypeEnum,
    SimpleProductFragment,
} from '@app-graphql/schema'
import { AlgoliaHit } from '@app-types/algolia.types'
import { Translated } from '@app-types/common.types'
import { curry } from 'ramda'

export type ProductTypes = HighlightedProductFragment | ProductFragment | SimpleProductFragment

export const translateProduct = curry(<T extends ProductTypes>(
    lang: Localization.Locale,
    productFragment: T,
): Translated<T> => {
    return {
        ...productFragment,
        ...productFragment.translations[lang],
    }
})

export function getProductImageOfType(
    images: ProductImageFragment[],
    type: ProductImageTypeEnum,
): ProductImageFragment {
    return images.find((i) => i.type === type) ?? images[0] ?? {
        type: ProductImageTypeEnum.Default,
        alt: '',
        url: '/assets/images/missing-image-icon.svg',
    }
}

export function getProductImagesOfType(
    images: ProductImageFragment[],
    type: ProductImageTypeEnum,
): ProductImageFragment[] {
    return images.filter((i) => i.type === type) ?? images
}

export function transformHit(hit: AlgoliaHit): Product {
    return {
        ambianceImage: hit.ambianceImage?.url,
        brand: hit.brand,
        categoryNames: hit.categories,
        codeLocal: hit.codeLocal,
        colors: (hit.colors ?? []),
        collectionName: hit.range,
        debtorPrice: hit.suggestedRetailPriceNlInCents,
        deliveryTimeInWeeks: hit.deliveryTimeInWeeks,
        dimensions: {
            height: hit.dimensions.height,
            length: hit.dimensions.length,
            units: 'cm',
            width: hit.dimensions.width,
        },
        ean: hit.ean,
        fsc: false,
        id: hit.ean,
        inFavourites: false,
        inStock: true,
        materials: hit.materials ?? [],
        name: hit.title,
        newDebtorPrice: hit.suggestedStandardRetailPriceNlInCents,
        newProduct: hit.isNew,
        packages: [],  // Because it does not come from Algolia,
        primaryImage: hit.primaryImage?.url,
        productOrderSteps: hit.orderStep,
        slug: hit.slug,
        status: hit.status,
        statusEnum: hit.statusOriginal,
        subColors: (hit.subColors ?? []),
        subMaterials: (hit.subMaterials ?? []),
        suggestedRetailPrice: hit.suggestedRetailPriceNlInCents,
        suggestedRetailPriceISE: hit.suggestedRetailPriceIseInCents,
        suggestedStandardRetailPrice: hit.suggestedStandardRetailPriceNlInCents,
        suggestedStandardRetailPriceISE: hit.suggestedStandardRetailPriceIseInCents,
        totalPackages: '0',
        wideImage: hit.wideImage?.url,
    }
}

