import { Component, Input, OnInit } from '@angular/core'
import { SnackbarRef } from '@app-domains/ui/services/snackbar/snackbar.service'

@Component({
    selector: 'app-snack-bar',
    templateUrl: './snackbar.component.html',
    styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent implements OnInit {

    @Input()
    public snackbarRef: SnackbarRef

    public ngOnInit(): void {
        if (this.snackbarRef?.autoHideDelay) {
            setTimeout(() => {
                this.close()
            }, this.snackbarRef.autoHideDelay)
        }
    }

    public close(): void {
        this.snackbarRef.close()
    }

}
