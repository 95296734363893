<ng-container *ngIf="control; else nonControl">
    <input type="radio"
           [id]="id"
           [value]="value"
           [formControl]="control"
           [attr.disabled]="isDisabled ? 'disabled' : null"
           [name]="name"
    >

    <ng-container *ngTemplateOutlet="label"></ng-container>
</ng-container>

<ng-template #nonControl>
    <input type="radio"
           [id]="id"
           [value]="value"
           [checked]="checked"
           [attr.disabled]="isDisabled ? 'disabled' : null"
           [name]="name"
           (change)="changed.emit($any($event.target).value)"
    >

    <ng-container *ngTemplateOutlet="label"></ng-container>
</ng-template>

<ng-template #label>
    <label [for]="id">
        <span class="label-content">
            <ng-content></ng-content>
        </span>
    </label>
</ng-template>