import { Component, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core'

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.svg',
})
export class SpinnerComponent implements OnChanges {

    @Input()
    public fillColor: string = 'var(--color-primary)'

    @Input()
    public size: number = 24

    constructor(
        private readonly element: ElementRef<HTMLElement>,
        private readonly renderer: Renderer2,
    ) {
    }

    public ngOnChanges(): void {
        this.renderer.setStyle(this.element.nativeElement, 'width', `${this.size}px`)
        this.renderer.setStyle(this.element.nativeElement, 'height', `${this.size}px`)
    }

}
