import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { containsNumber, containsUppercase } from '../../../validators'
import { matchPassword } from '../../../validators/match-password'
import { PasswordService } from '@app-services'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { firstValueFrom } from 'rxjs'
import { SnackbarService } from '@app-domains/ui/services/snackbar/snackbar.service'
import { GraphQLError } from 'graphql/index'
import { TranslationKey } from '@app-pipes/typed-translate/typed-translate.pipe'

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
    private params: Params

    public graphqlErrors: TranslationKey[] = []

    constructor(
        public readonly passwordService: PasswordService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly snackbarService: SnackbarService,
    ) {
    }

    public ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            this.params = params
        })
    }

    public resetPasswordForm = new FormGroup<{
        password: FormControl<string | null>
        passwordConfirmation: FormControl<string | null>
    }>({
        password: new FormControl<string>('', [
            Validators.required,
            Validators.minLength(8),
            containsNumber,
            containsUppercase,
        ]),
        passwordConfirmation: new FormControl<string>('', [
            Validators.required,
            Validators.minLength(8),
            containsNumber,
            containsUppercase,
        ]),
    }, { validators: matchPassword('password', 'passwordConfirmation') })

    public async submit(): Promise<void> {
        if (this.resetPasswordForm.invalid) {
            Object.values(this.resetPasswordForm.controls).forEach((c) => c.markAsDirty())
            this.resetPasswordForm.markAllAsTouched()
            return
        }

        this.graphqlErrors = []

        const result = await firstValueFrom(this.passwordService.updateForgottenPassword({
            input: {
                email: this.params.email,
                token: this.params.token,
                password: this.resetPasswordForm.controls.password.value!,
                password_confirmation: this.resetPasswordForm.controls.passwordConfirmation.value!,
            },
        }))

        if (result.errors) {
            result.errors.map((errors) => {
                this.graphqlErrors = this.getGraphQLValidationErrors(errors)
            })
            return
        }

        this.passwordService.passwordFormType = 'password'
        this.snackbarService.create({
            content: 'my-data.password-changed',
            dismissible: true,
        }, 'bottom')

        await this.router.navigateByUrl('/')
    }

    private getGraphQLValidationErrors(errors: GraphQLError) {
        const DETECT_STRING_PART = 'input.'
        const TRANSLATION_PREFIX = 'graphql-errors.'
        const filteredErrors: TranslationKey[] = []

        if (errors.extensions.errors) {
            const error = errors.extensions.errors! as { token: string }
            if (error.token) {
                filteredErrors.push(TRANSLATION_PREFIX + error.token as TranslationKey)
            }
        }

        if (errors.extensions.validation) {
            const validationErrors = Object.values(<Object>errors.extensions.validation) ?? []

            if (validationErrors.length) {
                validationErrors.map((inputErrors) => {
                    inputErrors.map((err: string) => {
                        if (err.includes(DETECT_STRING_PART)) {
                            const message = err.split(DETECT_STRING_PART)[1]
                            filteredErrors.push(TRANSLATION_PREFIX + message as TranslationKey)
                        } else {
                            filteredErrors.push(TRANSLATION_PREFIX + err as TranslationKey)
                        }
                    })
                })
            }
        }

        return filteredErrors
    }

}
