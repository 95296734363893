import { first, Observable, of, startWith, Subject, SubscriptionLike, switchMap } from 'rxjs'
import { map } from 'rxjs/operators'
import { always } from 'ramda'
import { TranslateService } from '@ngx-translate/core'

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core'
import { FormControl, FormControlStatus, UntypedFormGroup } from '@angular/forms'

import { InputPrivate, InputPublic } from './input.component.types'
import { idGenerator, isFunction } from '@app-lib/common.lib'
import { IconNameUnion } from '@app-domains/ui/directives/icon/icon.directive.types'

const generateId = (() => {
    const gen = idGenerator('app-input')
    return (): string => gen.next().value
})()

/**
 * # Input field
 *
 * ## Label content
 * The label content is projected in an `<ng-content>` slot:
 *
 * ```html
 * <app-input [control]="...">
 *     <span>Label content here</span>
 * </app-input>
 */
@Component({
    selector: 'app-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnChanges {

    // ------------------------------------------------------------------------------
    //      Configuration / data mapping
    // ------------------------------------------------------------------------------

    public readonly id = generateId()

    /**
     * Maps Angular's form-control statuses to their internal counterparts.
     */
    public readonly validationStateMap: { readonly [K in FormControlStatus]: InputPublic.ValidationState } = {
        DISABLED: 'idle',
        VALID: 'valid',
        INVALID: 'error',
        PENDING: 'pending',
    }

    /**
     * Specifies the notification icon to show for each validation-state.
     */
    public readonly validationStateIcons: { readonly [K in InputPublic.ValidationState]: IconNameUnion | undefined } = {
        idle: undefined,
        pending: undefined,
        error: 'error-circle',
        warning: 'warning-circle',
        valid: 'check-circle',
    }

    // ------------------------------------------------------------------------------
    //      Input bindings
    // ------------------------------------------------------------------------------

    @Input()
    public type: InputPublic.Type = 'text'

    @Input()
    public size: InputPublic.Size = 'normal'

    @Input()
    public theme: InputPublic.Theme = 'input'

    /**
     * OPTIONAL: Next two are optional if you want to use graphql errors.
     */
    @Input()
    public form?: UntypedFormGroup

    @Input()
    public fieldName?: string

    /**
     * REQUIRED: A form control to attach to the native input element. This input-binding accepts
     * `undefined` so that the existence check for controls within form-groups can be done
     * internally. If `undefined` is bound then this component will simply not render.
     */
    @Input()
    public control: FormControl | undefined

    /**
     * OPTIONAL: Specify a placeholder value for the native input element.
     */
    @Input()
    public placeholder?: string

    @Input()
    public maxLength?: number

    @Input()
    public counter?: boolean

    @Input()
    public autocapitalize: InputPublic.Autocapitalize = 'on'

    @Input()
    public autocomplete: InputPublic.Autocomplete = 'on'

    /**
     * OPTIONAL: For {@link InputComponent.type [type="number"]} inputs,
     * you may specify the `min` attribute for the native input field.
     */
    @Input()
    public min?: string | number

    /**
     * OPTIONAL: For {@link InputComponent.type [type="number"]} inputs,
     * you may specify the `max` attribute for the native input field.
     */
    @Input()
    public max?: string | number

    /**
     * OPTIONAL: For {@link InputComponent.type [type="number"]} inputs,
     * you may specify the `step` attribute for the native input field.
     */
    @Input()
    public step?: string | number

    @Input()
    public errorMessages: InputPublic.ErrorMessageRecord = {}

    /**
     * Specify the input icon by name. Can be used as a button to change the input type
     *
     * @default undefined
     */
    @Input()
    public icon?: IconNameUnion

    /**
     * Adds an Optional label to the label
     * @default false
     */

    @Input()
    public isOptional?: boolean = false

    @Input()
    public disabled: boolean = false

    @Output()
    public iconAction: EventEmitter<void> = new EventEmitter<void>()

    @Input()
    public noValidate?: boolean = false

    // ------------------------------------------------------------------------------
    //      Derived properties
    // ------------------------------------------------------------------------------

    public classBindings: InputPrivate.ClassBindings

    public controlStatusChangesSubscription: SubscriptionLike | null = null

    public validationState: InputPublic.ValidationState = 'idle'

    public currentErrorMessage$: Observable<string> | null = null
    public fallbackErrorMessage: string = this.translate.instant('validation.check-input')

    public readonly blurEvents$ = new Subject<{ programmatic: boolean }>()

    constructor(
        private translate: TranslateService,
    ) {}

    // ------------------------------------------------------------------------------
    //      Lifecycle hooks
    // ------------------------------------------------------------------------------

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.controlDidChange(changes)) {
            this.handleControlChange(changes.control.currentValue)
        }

        this.currentErrorMessage$ = this.control
            ? this.determineErrorMessage(this.control)
            : null

        this.classBindings = this.determineClassBindings(this.size, this.validationState, this.theme)
    }

    // ------------------------------------------------------------------------------
    //      Imperative data setters
    // ------------------------------------------------------------------------------

    private handleControlChange(control: FormControl | undefined): void {
        this.controlStatusChangesSubscription?.unsubscribe()
        this.controlStatusChangesSubscription = null
        this.validationState = 'idle'
        this.currentErrorMessage$ = null

        if (! this.isFormControl(control)) {
            return
        }

        this.addControlProgrammaticTouchedListener(control)

        const validationStart$: Observable<void> = control.untouched
            ? this.blurEvents$.pipe(first(), map(() => undefined))
            : of(undefined)

        this.controlStatusChangesSubscription = validationStart$.pipe(
            switchMap(() => control.statusChanges.pipe(startWith(control.status))),
            map((status) => this.validationStateMap[status]),
            startWith<InputPublic.ValidationState>('idle'),
        ).subscribe((validationState) => {
            if (! this.noValidate) {
                this.validationState = validationState
                this.currentErrorMessage$ = this.determineErrorMessage(control)
                this.classBindings = this.determineClassBindings(this.size, validationState, this.theme)
            }
        })
    }

    private determineErrorMessage(control: FormControl): InputComponent['currentErrorMessage$'] {
        if (! control.errors || control.untouched) {
            return null
        }

        const firstErrorKey = Object.keys(control.errors!)[0]
        const firstErrorMessageOrFactory = this.errorMessages[firstErrorKey]

        if (! firstErrorMessageOrFactory) {
            return of(this.fallbackErrorMessage)
        }

        const errorMessageFactory = isFunction(firstErrorMessageOrFactory)
            ? firstErrorMessageOrFactory
            : always(firstErrorMessageOrFactory)

        const firstErrorMessage = errorMessageFactory(control.errors[firstErrorKey])

        return this.isObservable(firstErrorMessage)
            ? firstErrorMessage
            : of(firstErrorMessage)
    }

    private determineClassBindings(
        size: InputPublic.Size,
        validationState: InputPublic.ValidationState,
        theme: InputPublic.Theme,
    ): InputPrivate.ClassBindings {
        if (theme === 'search') {
            return {
                'size-smaller': size === 'smaller',
                'size-normal': size === 'normal',
                'state-idle': false,
                'state-pending': false,
                'state-valid': false,
                'state-warning': false,
                'state-error': validationState === 'error',
                'theme-input': false,
                'theme-search': theme === 'search',
            }
        }

        return {
            'size-smaller': size === 'smaller',
            'size-normal': size === 'normal',
            'state-idle': validationState === 'idle',
            'state-pending': validationState === 'pending',
            'state-valid': validationState === 'valid',
            'state-warning': validationState === 'warning',
            'state-error': validationState === 'error',
            'theme-input': theme === 'input',
            'theme-search': false,
        }
    }

    // ------------------------------------------------------------------------------
    //      Checks & assertions
    // ------------------------------------------------------------------------------

    private controlDidChange(changes: SimpleChanges): boolean {
        return changes.control
            && changes.control.previousValue !== changes.control.currentValue
    }

    private isFormControl(control: unknown): control is FormControl {
        return control instanceof FormControl
    }

    private isObservable(x: unknown): x is Observable<any> {
        return x instanceof Observable
    }

    // ------------------------------------------------------------------------------
    //      A necessary hack
    // ------------------------------------------------------------------------------

    /**
     * Whenever a programmatic {@link FormControl.markAsTouched touched} update happens initiated from outside
     * we want to know, so we can have the {@link InputComponent.validationState validation state} update accordingly.
     * Angular currently does not provide an option to listen for such programmatic changes, so we'll have
     * to hack our way through by proxying the original method.
     *
     * @param control
     * @private
     */
    private addControlProgrammaticTouchedListener(control: FormControl): void {
        const originalKey = 'ORIGINAL_markAsTouched'

        if (control.hasOwnProperty(originalKey)) {
            return
        }

        type PatchedFormControl = FormControl & {
            [K in typeof originalKey]: FormControl['markAsTouched']
        }

        Object.defineProperty(control, originalKey, {
            enumerable: true,
            configurable: true,
            writable: true,
            value: control.markAsTouched,
        })

        control.markAsTouched = (opts) => {
            (control as PatchedFormControl)[originalKey](opts)
            this.blurEvents$.next({ programmatic: true })
        }
    }

    public fireEvent(): void {
        this.iconAction.emit()
    }
}
