<app-grid [colGap]="true">
    <app-cell mobile="0:12" laptop="1:11">
        <div class="image-content">
            <app-responsive-image [image]="image"
                                  [lazyLoad]="lazyLoad"
                                  class="image-content--image"
            ></app-responsive-image>

            <div *ngIf="caption"
                 class="image-content--caption"
            >
                {{ caption }}
            </div>
        </div>
    </app-cell>
</app-grid>

