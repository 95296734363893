import { Component, EventEmitter, forwardRef, Inject, Input, OnInit, Output } from '@angular/core'

import connectHits, {
    HitsConnectorParams as CParams,
    HitsWidgetDescription as WDescription,
} from 'instantsearch.js/es/connectors/hits/connectHits'

import { OtherProductsCategories } from '@app-domains/content-blocks/components/other-products/other-products.component'
import { AbstractInstantsearchWidgetComponent } from '@app-domains/algolia/abstract-instantsearch-widget.component'
import { AlgoliaInstantsearchComponent } from '@app-domains/algolia/components/algolia-instantsearch.component'

@Component({
    selector: 'app-other-products-hits',
    templateUrl: './other-products-hits.component.html',
    styleUrls: ['./other-products-hits.component.scss'],
})
export class OtherProductsHitsComponent extends AbstractInstantsearchWidgetComponent<
WDescription,
CParams
> implements OnInit {

    public override readonly widgetName = 'otherProducts'

    @Output()
    public loadMore: EventEmitter<void> = new EventEmitter<void>()

    @Output()
    public loadCategory: EventEmitter<OtherProductsCategories> = new EventEmitter<OtherProductsCategories>()

    @Input()
    public objectIDOrder: Array<string> | null

    constructor(
        @Inject(forwardRef(() => AlgoliaInstantsearchComponent))
        public instantSearchInstance: AlgoliaInstantsearchComponent,
    ) {
        super()
    }

    public override ngOnInit(): void {
        this.createWidget(connectHits, {})
        super.ngOnInit()
    }

}
