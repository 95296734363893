import { Component, Input, ViewEncapsulation } from '@angular/core'
import { ParagraphsFragment, ParagraphsLayoutEnum } from '@app-graphql/schema'

@Component({
    selector: 'app-paragraphs',
    templateUrl: './paragraphs.component.html',
    styleUrls: ['./paragraphs.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ParagraphsComponent {

    @Input()
    public paragraphs: ParagraphsFragment
    protected readonly ParagraphsLayoutEnum = ParagraphsLayoutEnum
}
