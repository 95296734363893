import { AbstractControl, ValidationErrors } from '@angular/forms'

export const containsUppercase = (control: AbstractControl): ValidationErrors | null => {
    const { value } = control
    if (value) {
        const valid = (/[A-Z]/).test(value)
        if (! valid) {
            return { noUppercase: 'noUppercase' }
        }
        return null
    }
    return null
}
