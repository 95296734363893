<ng-container *ngIf="control">
    <div class="label-row">
        <label [for]="id" [class]="classBindings">
            <ng-content></ng-content>
        </label>

        <label *ngIf="isOptional"
               class="small grey-7"
        >
            {{ 'common.optional' | translate }}
        </label>
    </div>

    <div class="input-wrapper"
         [class]="classBindings"
    >
        <span *ngIf="theme === 'search'"
              appIcon="search"
              class="search-icon"
        ></span>

        <input *ngIf="type !== 'textarea'"
               (blur)="blurEvents$.next({ programmatic: false })"
               [formControl]="control"
               [value]="control.value"
               [type]="type"
               [id]="id"
               [attr.min]="min"
               [attr.max]="max"
               [attr.step]="step"
               [attr.placeholder]="placeholder"
               [attr.disabled]="disabled ? 'disabled' : null"
        >

        <div *ngIf="type === 'textarea'"
             class="textarea-wrapper"
        >
            <textarea (blur)="blurEvents$.next({ programmatic: false })"
                      [formControl]="control"
                      [id]="id"
                      [attr.placeholder]="placeholder"
                      [attr.disabled]="disabled ? 'disabled' : null"
                      [attr.maxlength]="maxLength"
            ></textarea>

            <label *ngIf="counter"
                   class="small grey-7"
            >
                <span>
                    {{ control.value.length }} / {{ maxLength }}
                </span>
            </label>
        </div>

        <div *ngIf="!noValidate && !icon && theme === 'input' && (type !== 'textarea') && (type !== 'time') && validationStateIcons[validationState] as icon"
             [appIcon]="icon"
             class="state-icon"
        ></div>

        <span *ngIf="icon && theme === 'input'"
              (click)="fireEvent()"
              [appIcon]="icon"
              class="icon"
        ></span>

        <div *ngIf="validationState === 'pending'"
             class="loader"
        ></div>

        <div *ngIf="currentErrorMessage$ | async as message"
             class="error-message"
        >
            {{ message | translate }}
        </div>

        <app-validation-errors *ngIf="form && fieldName && ! noValidate"
                               [form]="form"
                               [field]="fieldName"
        ></app-validation-errors>
    </div>
</ng-container>
