import { Pipe, PipeTransform } from '@angular/core'
import { Product } from '@app-domains/content-blocks/components/product-card/product-card.types'
import { ProductService } from '@app-services'
import { Observable } from 'rxjs'

@Pipe({
    name: 'enhanceProducts',
})
export class EnhanceProductsPipe implements PipeTransform {
    constructor(
        private productService: ProductService,
    ) {
    }

    transform(products: Array<Product>): Observable<Array<Product>> {
        return this.productService.enhance(products)
    }
}
