<app-grid>
    <app-cell mobile="0:12" tablet="0:8" laptop="0:4" desktop="0:4">
        <div class="reset-password">

            <div class="text">
                <h1>
                    {{'auth.reset-password' | translate}}
                </h1>
            </div>

            <app-input placeholder="{{'my-data.enter-new-password' | translate}}"
                       [control]="resetPasswordForm | formChild: 'password' : 'control'"
                       [errorMessages]="passwordService.passwordErrorMessages"
                       [type]="passwordService.passwordFormType"
                       icon="show"
                       (iconAction)="passwordService.changeFormType()"
            >
                {{'my-data.new-password' | translate}}
            </app-input>

            <div class="bottom-form">
                <app-input placeholder="{{'my-data.confirm-your-password' | translate}}"
                           [control]="resetPasswordForm | formChild: 'passwordConfirmation' : 'control'"
                           [errorMessages]="passwordService.passwordErrorMessages"
                           [type]="passwordService.passwordFormType"
                           icon="show"
                           (iconAction)="passwordService.changeFormType()"
                >
                    {{'my-data.confirm-password' | translate}}
                </app-input>
            </div>

            <label *ngIf="resetPasswordForm.getError('noMatchingPassword')"
                   class="error-message small"
            >
                {{'validation.messages.no-passwords-match' | translate}}
            </label>

            <div *ngIf="graphqlErrors.length">
                <div class="errors">
                    <ul *ngFor="let item of graphqlErrors">
                        <li >
                            {{ item | translate }}
                        </li>
                    </ul>
                </div>
            </div>

            <div class="button">
                <app-button size="normal"
                            theme="dark"
                            layout="label-only"
                            (click)="submit()"
                >
                    {{'common.submit' | translate}}
                </app-button>
            </div>

        </div>
    </app-cell>
</app-grid>
