<app-grid>
    <app-cell mobile="0:12">
        <div class="contact-form">
            <header class="header">
                <h2 class="big">
                    {{ title | translate }}?
                </h2>

                <p>
                    {{ subTitle | translate }}
                </p>
            </header>

            <section class="info-container">
                <h3>
                    {{ 'contact.contact-us-directly' | translate }}
                </h3>

                <div class="detail">
                    <div class="icon">
                        <span appIcon="phone"></span>
                    </div>
                    <div>
                        <a href="tel:+31889666300">+31 (0)889-666300</a>
                        <p class="subtitle">
                            ({{ 'contact.days' | translate }}) 08:30 - 17:00
                        </p>
                    </div>
                </div>

                <div class="detail">
                    <div class="icon">
                        <span appIcon="mail"></span>
                    </div>
                    <div>
                        <a href="mailto:info@deeekhoorn.com">info&#64;deeekhoorn.com</a>
                    </div>
                </div>

                <div class="detail">
                    <div class="icon">
                        <span appIcon="location"></span>
                    </div>
                    <div>
                        De Eekhoorn Dutch Furniture
                        <p class="subtitle address">
                            Jan Tinbergenweg 1 <br>
                            1689 ZV Hoorn
                        </p>

                        <p class="hq">
                            <a [routerLink]="'/experience-center' | translateRoute"
                               target="_blank"
                               class="text-link"
                            >{{ 'contact.click-here'| translate }}</a>

                            {{ 'contact.more-info-experience-center'| translate }}
                        </p>
                    </div>
                </div>
            </section>

            <form [formGroup]="contactForm">
                <h3>
                    {{ 'contact.let-us-contact' | translate }}
                </h3>

                <app-input [control]="contactForm | formChild : 'name':'control'"
                           [errorMessages]="contactFormMessages"
                           [placeholder]="'contact.enter-name'| translate"
                           class="first-input"
                           autocapitalize="words"
                           autocomplete="name"
                >
                    {{ 'contact.name' | translate }}
                </app-input>

                <app-input [control]="contactForm | formChild : 'companyName':'control'"
                           [errorMessages]="contactFormMessages"
                           [placeholder]="'contact.enter-company-name' | translate"
                           autocapitalize="words"
                           autocomplete="organization"
                >
                    {{ 'contact.company-name' | translate }}
                </app-input>

                <app-input [control]="contactForm | formChild : 'email':'control'"
                           [errorMessages]="contactFormMessages"
                           [placeholder]="'contact.enter-email'| translate"
                           [form]="contactForm"
                           type="email"
                           fieldName="email"
                           autocapitalize="off"
                           autocomplete="email"
                >
                    {{ 'contact.email'| translate }}
                </app-input>

                <app-input [control]="contactForm | formChild : 'phoneNumber':'control'"
                           [errorMessages]="contactFormMessages"
                           [placeholder]="'contact.enter-phone-number' | translate"
                           type="tel"
                           autocapitalize="off"
                           autocomplete="tel"
                >
                    {{ 'contact.phone-number' | translate }}
                </app-input>

                <app-input [control]="contactForm | formChild : 'message':'control'"
                           [errorMessages]="contactFormMessages"
                           [placeholder]="'contact.how-can-we-help' | translate"
                           type="textarea"
                           class="textarea"
                           autocapitalize="sentences"
                >
                    {{ 'contact.message' | translate }}
                </app-input>

                <div class="footer">

                    <label class="recaptcha">
                        {{ 'recaptcha.protected-by'| translate }}

                        <a href='https://policies.google.com/privacy'
                           class="text-link"
                           target="_blank"
                        >
                            {{ 'recaptcha.privacy-policy'| translate }}
                        </a>

                        {{ 'recaptcha.and'| translate }}

                        <a href='https://policies.google.com/terms'
                           class="text-link"
                           target="_blank">
                            {{ 'recaptcha.terms-of-service'| translate }}
                        </a>

                        {{ 'recaptcha.apply'| translate }}.
                    </label>

                    <app-button theme="dark"
                                (click)="submitContactForm()"
                                class="submit"
                    >
                        {{ 'common.send' | translate }}
                    </app-button>
                </div>
            </form>
        </div>
    </app-cell>
</app-grid>
