import { SelectionModel } from '@angular/cdk/collections'

export class FixedSelectionModel<T> extends SelectionModel<T> {
    public override deselect(...values: T[]): boolean | void {
        if (this.compareWith) {
            values.forEach((value) => {
                if (this.isSelected(value)) {
                    const found = this.selected.find(
                        (v) => this.compareWith!(value, v),
                    )
                    if (found) {
                        super.deselect(found)
                    }
                }
            })
        } else {
            return super.deselect(...values)
        }
    }
}
