import { Component, Input } from '@angular/core'
import { VideoFragment } from '@app-graphql/schema'

@Component({
    selector: 'app-video-content',
    templateUrl: './video-content.component.html',
    styleUrls: ['./video-content.component.scss'],
})
export class VideoContentComponent {
    @Input()
    public video: VideoFragment

    @Input()
    public caption?: string
}
