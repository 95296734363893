<ng-container *ngIf="localizationService.currentLocale$ | async as locale">
    <div class="add-to-cart-popup">

        <ng-container *ngIf="(checkoutService.outOfStockProductsPopup$ | async) as outOfStock">
            <ng-container *ngIf="outOfStock?.length">
                <div class="header">
                    <h3>
                        {{ 'products.could-not-be-added-cart' | translate }}
                    </h3>

                    <span class="close-icon"
                          appIcon="close"
                          (click)="closeDialog()"
                    ></span>
                </div>

                <div>
                    <div class="product" *ngFor="let line of outOfStock">

                        <img class="product-image"
                             loading="lazy"
                             [src]="line.primaryImage | imageSize : 240"
                             [alt]="line.name"
                        />
                        <div class="product-information">
                            <p class="product-brand stretch">
                                {{ line.brand }}
                            </p>
                            <p class="product-type stretch first-letter-uppercase">
                                {{ line.name }}
                            </p>
                            <div class="line-flex">
                                <ng-container *ngIf="authService.user$ | async as user">
                                    <ng-container *ngIf="! user.hidePurchasePrice">
                                        <p *ngIf="line.debtorPrice"
                                           class="product-price"
                                        >
                                            € {{ line.debtorPrice | priceSplit }}
                                        </p>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="actions">
                    <app-button theme="line"
                                layout="label-only"
                                (clicked)="addToWishlist()"
                    >
                        {{ 'products.add-to-wishlist-instead' | translate }}
                    </app-button>
                </div>

                <hr class="hr">
            </ng-container>
        </ng-container>

        <ng-container *ngIf="(shoppingCartWithAddedEansFiltered$ | async) as shoppingCartWithAddedEansFiltered">
            <ng-container *ngIf="shoppingCartWithAddedEansFiltered.length">
                <div class="header">
                    <h3>
                        {{ 'added-to-shopping-cart.added-to-your-cart' | translate }}
                    </h3>

                    <span class="close-icon"
                          appIcon="close"
                          (click)="closeDialog()"
                    ></span>
                </div>

                <ng-container *ngFor="let line of shoppingCartWithAddedEansFiltered">
                    <div *ngIf="line"
                         class="product"
                    >
                        <ng-container
                                *ngIf="line.product.images | getProductImageOfType: ProductImageTypeEnum.Front as productImage"
                        >
                            <img class="product-image"
                                 loading="lazy"
                                 [src]="productImage.url | imageSize : 240"
                                 [alt]="productImage.alt"
                            />
                        </ng-container>

                        <div class="product-information">
                        <p class="product-brand stretch">
                            {{ line.product.brand?.name ?? '' }}
                        </p>
                        <p class="product-type stretch first-letter-uppercase">
                            {{ line.product.translations[locale].name }}
                        </p>
                        <div class="line-flex">
                            <p class="product-amount">
                                {{ line.addedQuantity}}  x
                            </p>

                            <ng-container *ngIf="authService.user$ | async as user">
                                <ng-container *ngIf="! user.hidePurchasePrice">
                                    <p *ngIf="line.price"
                                       class="product-price"
                                    >
                                        € {{ line.price | priceSplit }}
                                    </p>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                    </div>
                </ng-container>

                <div class="actions">
                    <app-button theme="dark"
                                type="link"
                                [link]="'/shopping-cart' | translateRoute"
                                (clicked)="closeDialog()"
                    >
                        {{ 'added-to-shopping-cart.view-your-cart' | translate }}
                    </app-button>

                    <app-button theme="line"
                                (clicked)="closeDialog()"
                    >
                        {{ 'added-to-shopping-cart.continue-shopping' | translate }}
                    </app-button>
                </div>
            </ng-container>
        </ng-container>
    </div>
</ng-container>
