import { Component, Input } from '@angular/core'
import { AccordionFragment } from '@app-graphql/schema'

@Component({
    selector: 'app-accordion',
    templateUrl: './accordion.component.html',
    styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent {

    @Input()
    public accordion: AccordionFragment

}
