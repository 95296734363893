import { Component, Input } from '@angular/core'

import { MenuDropdown } from '@app-domains/navigation/menu-dropdown/menu-dropdown'
import { AuthService } from '@app-services'
import { LocalizationService } from '@app-domains/localization/service/localization.service'

@Component({
    selector: 'app-auth-header',
    templateUrl: './auth-header.component.html',
    styleUrls: ['./auth-header.component.scss'],
})
export class AuthHeaderComponent {

    @Input()
    public authVersion: 'mobile' | 'desktop' = 'desktop'

    @Input()
    public mobileMenuIsOpen: boolean

    @Input()
    public userDropdown: MenuDropdown

    constructor(
        public readonly authService: AuthService,
        private readonly localization: LocalizationService,
    ) {
    }

    public goToLoginPage(): void {
        this.authService.originUrl = this.localization.getCurrentLocale() + '/dashboard'
        window.location.href = this.authService.getLoginUrl()
    }
}
