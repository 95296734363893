import { Injectable } from '@angular/core'
import { Router, UrlTree } from '@angular/router'
import { AuthService } from '@app-services'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

@Injectable({
    providedIn: 'root',
})
export class NotLoggedInGuard {

    constructor(
        private auth: AuthService,
        private router: Router,
    ) {}

    canActivate(): Observable<boolean | UrlTree> {
        return this.auth.user$.pipe(
            map((user) => ! user ? true : this.router.parseUrl('/')),
        )
    }
}
