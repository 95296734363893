<div class="dialog">
    <div class="heading">
        <h3>
            {{ data.title }}
        </h3>
    
        <div class="close-button">
            <app-button icon="close"
                layout="icon-only"
                theme="link"
                size="smaller"
                (click)="close()"
            ></app-button>
        </div>
    </div>

    <hr />

    <p class="text-margin">
        {{ data.message }}
    </p>

    <div class="dialog__buttons">
        <app-button *ngFor="let button of buttons"
                    [theme]="button.theme ?? 'dark'"
                    [link]="button.link"
                    [layout]="button.layout ?? 'label-only'"
                    [size]="button.size ?? 'normal'"
                    [disabled]="button.disabled ?? false"
                    [icon]="button.icon"
                    [type]="button.type ?? 'button'"
                    [target]="button.target ?? '_self'"
                    [rel]="button.rel ?? ''"
                    (click)="(button.clicked ?? noop).bind(this)($event)"
        >
            {{ button.label }}
        </app-button>
    </div>
</div>


