import { TranslateRouterModule } from '@endeavour/ngx-translate-router'
import { TranslateModule } from '@ngx-translate/core'
import { NouisliderModule } from 'ng2-nouislider'

import { A11yModule } from '@angular/cdk/a11y'
import { OverlayModule } from '@angular/cdk/overlay'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'

import { ButtonComponent } from './components/button/button.component'
import { CellComponent } from './components/grid/cell/cell.component'
import { CheckboxComponent } from './components/checkbox/checkbox.component'
import { ChevronLinkComponent } from './components/chevron-link/chevron-link.component'
import { DialogComponent } from './components/dialog/dialog.component'
import { ExpansionPanelComponent } from './components/expansion-panel/expansion-panel.component'
import { FormErrorsComponent } from './form-errors/form-errors.component'
import { FuzzySearchComponent } from './components/fuzzy-search/fuzzy-search.component'
import { GeneralInfoDialogComponent } from './components/general-info-dialog/general-info-dialog.component'
import { GridComponent } from './components/grid/grid/grid.component'
import { IconMessageHeaderComponent } from './components/icon-message-header/icon-message-header.component'
import { InputComponent } from './components/input/input.component'
import { MultiSelectComponent } from './components/multi-select/multi-select.component'
import { NotificationBadgeComponent } from './components/notification-badge/notification-badge.component'
import { RadioButtonComponent } from './components/radio-button/radio-button.component'
import { RangeSliderComponent } from './components/range-slider/range-slider.component'
import { ScrollIndicatorComponent } from './components/scroll-indicator/scroll-indicator.component'
import { SelectComponent } from './components/select/select/select.component'
import { SelectOptionComponent } from './components/select/option/select-option.component'
import { SelectOptionDirective } from './components/select/option/select-option.directive'
import { SelectOptionsComponent } from './components/select/options/select-options.component'
import { SelectOptGroupDirective } from './components/select/optgroup/select-optgroup.directive'
import { SelectOptgroupComponent } from './components/select/optgroup/select-optgroup.component'
import { SliderPaginationComponent } from './components/slider-pagination/slider-pagination.component'
import { SnackbarComponent } from './components/snack-bar/snackbar.component'
import { SnackbarHostComponent } from './components/snackbar-host/snackbar-host.component'
import { SpinnerComponent } from './components/spinner/spinner.component'
import { TabComponent } from './components/tab/tab/tab.component'
import { TabGroupComponent } from './components/tab/tab-group/tab-group.component'
import { TooltipComponent } from './components/tooltip/tooltip.component'
import { ValidationErrorsComponent } from './components/validation-errors/validation-errors.component'

import { BreakpointsService } from './services/breakpoints/breakpoints.service'
import { SelectControlRegistryService } from './services/select-control-registry/select-control-registry.service'

import { DirectivesModule } from '@app-directives/directives.module'
import { IconDirective } from './directives/icon/icon.directive'

import { PipesModule } from '@app-pipes/pipes.module'
import { IsSelectedPipe } from './pipes/is-selected/is-selected.pipe'
import { IsExternalLinkPipe } from './pipes/is-external-link/is-external-link.pipe'
import { IsSelectOptgroupPipe } from './pipes/is-select-optgroup/is-select-optgroup.pipe'
import { IsSelectOptionPipe } from './pipes/is-select-option/is-select-option.pipe'
import { ResponsiveStylePipe } from './pipes/responsive-style/responsive-style.pipe'

@NgModule({
    declarations: [
        ButtonComponent,
        CellComponent,
        CheckboxComponent,
        ChevronLinkComponent,
        DialogComponent,
        ExpansionPanelComponent,
        FormErrorsComponent,
        FuzzySearchComponent,
        GeneralInfoDialogComponent,
        GridComponent,
        IconDirective,
        IconMessageHeaderComponent,
        InputComponent,
        IsExternalLinkPipe,
        IsSelectOptionPipe,
        IsSelectOptgroupPipe,
        IsSelectedPipe,
        MultiSelectComponent,
        NotificationBadgeComponent,
        RadioButtonComponent,
        RangeSliderComponent,
        ResponsiveStylePipe,
        ScrollIndicatorComponent,
        SelectComponent,
        SelectOptGroupDirective,
        SelectOptionComponent,
        SelectOptionDirective,
        SelectOptgroupComponent,
        SelectOptionsComponent,
        SliderPaginationComponent,
        SnackbarComponent,
        SnackbarHostComponent,
        SpinnerComponent,
        TabComponent,
        TabGroupComponent,
        TooltipComponent,
        ValidationErrorsComponent,
    ],
    providers: [
        BreakpointsService,
        SelectControlRegistryService,
    ],
    imports: [
        A11yModule,
        CommonModule,
        DirectivesModule,
        FormsModule,
        NouisliderModule,
        OverlayModule,
        PipesModule,
        ReactiveFormsModule,
        RouterModule,
        TranslateModule,
        TranslateRouterModule,
    ],
    exports: [
        ButtonComponent,
        CellComponent,
        CheckboxComponent,
        ChevronLinkComponent,
        DialogComponent,
        ExpansionPanelComponent,
        FormErrorsComponent,
        FuzzySearchComponent,
        GridComponent,
        IconDirective,
        IconMessageHeaderComponent,
        InputComponent,
        IsExternalLinkPipe,
        MultiSelectComponent,
        NotificationBadgeComponent,
        RadioButtonComponent,
        RangeSliderComponent,
        ResponsiveStylePipe,
        ScrollIndicatorComponent,
        SelectComponent,
        SelectOptGroupDirective,
        SelectOptionComponent,
        SelectOptionDirective,
        SliderPaginationComponent,
        SnackbarComponent,
        SnackbarHostComponent,
        SpinnerComponent,
        TabComponent,
        TabGroupComponent,
        TooltipComponent,
        ValidationErrorsComponent,
    ],
})
export class UIModule {
}
