import { Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client'
import { map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import {
    LocalesEnum,
    Maybe, NewsFragment,
    NewsItemQuery,
    NewsItemQueryService,
    NewsItemQueryVariables,
    NewsItemsQuery,
    NewsItemsQueryService,
    NewsItemsQueryVariables,
} from '@app-graphql/schema'

@Injectable({
    providedIn: 'root',
})
export class NewsService {

    constructor(
        private newsItemQueryService: NewsItemQueryService,
        private newsItemsQueryService: NewsItemsQueryService,
    ) {
    }

    public getNewsItem(input: NewsItemQueryVariables): Observable<ApolloQueryResult<NewsItemQuery>> {
        return this.newsItemQueryService.fetch(input)
    }

    public getNewsItems(input: NewsItemsQueryVariables): Observable<ApolloQueryResult<NewsItemsQuery>> {
        return this.newsItemsQueryService.fetch(input)
    }

    public getNewsBySlug(slug: string, locale: string): Observable<Maybe<NewsFragment> | undefined> {
        return this.getNewsItem({ slug: slug, locale: locale as LocalesEnum }).pipe(
            map((newsItem) => newsItem.data.newsItem),
        )
    }
}
