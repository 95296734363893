<ng-container *ngIf="categoryDropdown">
    <ng-container *ngIf="localizationService.currentLocale$ | async as locale">
        <a [routerLink]="categoryDropdown.mainCategory ? (locale+categoryDropdown.mainPageUrl | translateRoute) : undefined"
           [class.pointer]="categoryDropdown.mainCategory"
           [class.navigation-collapsed]="navigationIsCollapsed"
           (click)="linkIsNotClicked()"
           (mouseenter)="linkHasClicked()"
           (mouseleave)="linkIsNotClicked()"
           class="dropdown-main"
        >
            {{ categoryDropdown.mainCategoryLabel ? (categoryDropdown.mainCategoryLabel | translate) : categoryDropdown.mainCategory }}
        </a>
    </ng-container>

    <div (mouseenter)="linkHasClicked()"
         (mouseleave)="linkIsNotClicked()"
         [class.navigation-collapsed]="navigationIsCollapsed"
         [class.hide]="hideSubMenu"
         class="menu-container"
    >
        <div class="below-dropdown"></div>

        <div class="dropdown-container">
            <app-grid>
                <app-cell mobile="0:12">
                    <div class="content">
                        <div class="category-container">
                            <div class="category-list">
                                <ng-container *ngIf="categoryDropdown.mainPageUrl">
                                    <h3 (click)="linkIsNotClicked()"
                                        [routerLink]="categoryDropdown.mainPageUrl | translateRoute"
                                        class="subheading no-transform pointer"
                                    >
                                        {{ 'common.all' | translate }} {{ categoryDropdown.mainCategoryLabel ? (categoryDropdown.mainCategoryLabel | translate) : categoryDropdown.mainCategory }}
                                    </h3>

                                    <hr class="hr">
                                </ng-container>

                                <div class="item-container"
                                     [class.scrollable]="categoryDropdown.categories.length >= 6"
                                >
                                    <h3 *ngFor="let category of categoryDropdown.categories"
                                        (click)="linkIsNotClicked()"
                                        [routerLink]="category.link! | translateRoute"
                                        class="category-item subheading no-transform pointer"
                                    >
                                        {{ category.label ? (category.label | translate) : category.name }}
                                    </h3>
                                </div>
                            </div>
                        </div>

                        <div class="highlight-grid">
                            <ng-container *ngFor="let highlight of categoryDropdown.highlights | slice:0:3">
                                <div *ngIf="highlight.image | imageSize : 330 as highlightImage"
                                     (click)="linkIsNotClicked()"
                                     [routerLink]="highlight.link | translateRoute"
                                     class="highlight-category"
                                >
                                    <div class="highlight-label">
                                        <label *ngIf="highlight.highlight"
                                               class="text-white small white-bold stretch text product-title"
                                        >
                                            {{ 'navigation.highlighted' | translate }}
                                        </label>
                                    </div>

                                    <div class="title">
                                        <h3 class="text-white">
                                            {{ highlight.title }}
                                        </h3>
                                    </div>

                                    <img [src]="highlightImage"
                                         alt="Image of {{ highlight.title }}"
                                         loading="lazy"
                                    />
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </app-cell>
            </app-grid>
        </div>
    </div>
</ng-container>