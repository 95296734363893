<ng-container *ngIf="impersonationService.impersonating$ | async as impersonating">
    <div class="impersonation-bar">
        <div class="back-button">

            <app-button theme="line"
                        icon="chevron-left"
                        layout="icon-label"
                        size="smaller"
                        (click)="returnToBackOffice()"
            >
                {{ 'navigation.sign-out' | translate }}
            </app-button>
        </div>
        <small>{{ 'impersonation.you-are-impersonating' | translate : { name: impersonating.name, debtorCode: impersonating.debtorCode ?? '-' } }}</small>
    </div>
</ng-container>
