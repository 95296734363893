import { Component } from '@angular/core'

@Component({
    selector: 'app-scroll-indicator',
    templateUrl: './scroll-indicator.component.html',
    styleUrls: ['./scroll-indicator.component.scss'],
})
export class ScrollIndicatorComponent {

}
