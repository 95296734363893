<div>
    <ng-container *ngIf="renderedFormControl">
        <input type="checkbox"
               [id]="id"
               [formControl]="renderedFormControl"
               [attr.disabled]="isDisabled ? 'disabled' : null"
               class="input"
        >
        <ng-container *ngTemplateOutlet="label"></ng-container>
    </ng-container>

    <ng-template #label>
        <label [for]="id" class="label"
               [ngClass]="(control.value | isSelected : value) ? 'selected': 'unselected'"
               [attr.disabled]="isDisabled ? 'disabled' : null"
        >
        <span class="label-content">
            <ng-content></ng-content>
        </span>
        </label>
    </ng-template>
</div>
