import { ApolloLink, InMemoryCacheConfig, Operation } from '@apollo/client/core'
import { HttpLink } from 'apollo-angular/http'
import { EnvironmentVariables } from '@app-types/environment.types'
import { mergeDeepRight } from 'ramda'
import { AuthStateStore } from '@app-services'
import { GraphQLError, ExecutionResult } from 'graphql'
import { UntypedFormGroup } from '@angular/forms'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import { mergeDeep } from '@apollo/client/utilities'

/**
 * Constructs a GraphQL API URL for the given schema and hostname. Optionally takes a custom path
 * for the URL (default 'graphql')
 */
export const graphQlApiUrl = (env: { schema: string; hostname: string }, path: string = 'graphql') =>
    (operation: Operation): string => `${env.schema}://${env.hostname}/${path}?op=${operation.operationName}`

/**
 * Creates an {@link HttpLink Apollo HTTP link} for the given environment.
 */
export function createApiHttpLink(httpLink: HttpLink, environment: EnvironmentVariables) {
    return httpLink.create({
        uri: graphQlApiUrl(environment.api),
    })
}

export function createSubscriptionsHttpLink(httpLink: HttpLink, environment: EnvironmentVariables) {
    return httpLink.create({
        uri: graphQlApiUrl({
            schema: environment.subscriptionGraphql.schema,
            hostname: `${environment.subscriptionGraphql.hostname}/graphql`,
        }),
    })
}

export function createApiAuthLink(authStorageService: AuthStateStore): ApolloLink {
    return new ApolloLink((operation, forward) => {
        const context = operation.getContext()
        const token = authStorageService.getAuthState()?.accessToken
        const authContext = token
            ? mergeDeepRight(context, {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            })
            : context

        operation.setContext(authContext)
        return forward(operation)
    })
}

export interface ValidationError {
    field: string
    message: Array<string>
}

export const createValidationError = (error: GraphQLError): Array<ValidationError> => {
    const entries = Object.entries(error)
    return entries.map(([field, message]) => ({
        field: field.replace(/^input\./g, ''),
        message,
    } as ValidationError))
}

export const connectForm = (obs: Observable<ExecutionResult>, form: UntypedFormGroup) => obs.pipe(
    tap((response) => {
        const validationErrors = response.extensions?.validationErrors as Array<ValidationError>
        if (validationErrors) {
            validationErrors.forEach((error) => {
                form.get(error.field)?.setErrors({ ...error.message })
            })
        }
        const generalErrors = response.errors?.filter(
            (err) => err.extensions?.category !== 'validation',
        ).map((err) => err.message)
        form.setErrors({ ...generalErrors })
    }),
)

export const cacheConfig: InMemoryCacheConfig = {
    typePolicies: {
        Product: {
            keyFields: ['ean'],
        },
        Debtor: {
            keyFields: ['debtorCode'],
        },
        PositionedPages: {
            keyFields: ['identifier'],
        },
        Page: {
            keyFields: ['slug', 'locale'],
        },
        Category: {
            merge: (existing, incoming) => {
                return mergeDeep(existing, incoming)
            },
        },
        CategoryTranslationsMap: {
            merge: (existing, incoming) => {
                return mergeDeep(existing, incoming)
            },
        },
    },
    possibleTypes: {
        Component: [
            'Accordion',
            'AccountManager',
            'Assets',
            'Carousel',
            'Categories',
            'CtaLink',
            'ImageContentLink',
            'ImageHeader',
            'ImageWithTextHeader',
            'ImageMosaic',
            'ImageText',
            'ImageWithText',
            'Form',
            'Map',
            'NewsLetter',
            'PageLinks',
            'Paragraphs',
            'ParagraphsCarousel',
            'ProjectShowCase',
            'Quotes',
            'Statistics',
            'SubFooterLink',
            'TextHeader',
            'UniqueSellingPoints',
            'VideoHeader',
            'VideoText',
            'VideoWithText',
        ],
    },
}
