import algoliaSearch, { SearchClient } from 'algoliasearch/lite'
import { VERSION as AngularVersion, StateKey, TransferState } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'

export interface AlgoliaSearchClientOptions {
    appId: string
    apiKey: string
    httpClient: HttpClient
    HttpHeaders: typeof HttpHeaders
    transferState: TransferState
    options?: object

    makeStateKey<T = void>(key: string): StateKey<T>
}

/**
 * Creates an Algolia (lite) {@link SearchClient search client} instance for the given options.
 *
 * ---
 *
 * **Note:** This function was copied (and modified thereafter) from the discontinued algolia-instantsearch library's
 * {@link https://github.com/algolia/angular-instantsearch/blob/4.4.1/src/create-ssr-search-client.ts#L37 `createSSRSearchClient`}
 * function.
 */
export function createAlgoliaSearchClient(searchClientOptions: AlgoliaSearchClientOptions): SearchClient {
    const {
        appId,
        apiKey,
        httpClient,
        transferState,
        makeStateKey,
        options = {},
    } = searchClientOptions

    const searchClient = algoliaSearch(appId, apiKey, {
        ...options,
        requester: {
            send({ headers, method, url, data }) {
                const transferStateKey = makeStateKey<string>(`ngais(${data})`)

                if (transferState.hasKey(transferStateKey)) {
                    const response = JSON.parse(
                        transferState.get(transferStateKey, JSON.stringify({})),
                    )

                    return Promise.resolve({
                        status: response.status,
                        content: JSON.stringify(response.body),
                        isTimedOut: false,
                    })
                }

                return new Promise((resolve, reject) => httpClient
                    .request(method, url, {
                        headers,
                        body: data,
                        observe: 'response',
                    })
                    .subscribe({
                        next: (response) => {
                            transferState.set(transferStateKey, JSON.stringify(response))

                            resolve({
                                status: response.status,
                                content: JSON.stringify(response.body),
                                isTimedOut: false,
                            })
                        },
                        error: (response) => reject({
                            status: response.status,
                            body: response.body,
                        }),
                    }))
            },
        },
    })

    searchClient.addAlgoliaAgent(`angular (${AngularVersion.full})`)
    return searchClient
}
