import { AfterContentChecked, ChangeDetectorRef, Component, Input, ViewEncapsulation } from '@angular/core'
import { QuoteFragment } from '@app-graphql/schema'
import Swiper, { SwiperOptions } from 'swiper'

@Component({
    selector: 'app-quotes',
    templateUrl: './quotes.component.html',
    styleUrls: ['./quotes.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class QuotesComponent implements AfterContentChecked {
    @Input()
    public quotes: QuoteFragment[]

    public swiper?: Swiper

    public swiperConfig: SwiperOptions = {
        slidesPerView: 1,
        centeredSlides: true,
        allowTouchMove: false,
        autoHeight: true,
        loop: true,
    }
    public swiperIndex: number

    constructor(
        private cdr: ChangeDetectorRef,
    ) {
    }

    public ngAfterContentChecked(): void {
        this.swiper?.updateAutoHeight()
    }

    public onSwiper(swiper: Swiper): void {
        if (swiper) {
            this.swiper = swiper
            this.swiperIndex = swiper.realIndex
        }
    }

    public goToSlide(i: number): void {
        this.swiper?.slideTo(i + 1)
    }

    public slideChange([swiper]: [swiper: Swiper]): void {
        this.swiperIndex = swiper.realIndex
        this.cdr.detectChanges()
    }
}
