import { Component, Input } from '@angular/core'
import { ImageComponentFragment, ImageFragment } from '@app-graphql/schema'

@Component({
    selector: 'app-image-text-header',
    templateUrl: './image-text-header.component.html',
    styleUrls: ['./image-text-header.component.scss'],
})
export class ImageTextHeaderComponent {

    @Input()
    public title: string

    @Input()
    public subtitle?: string | null

    @Input()
    public description?: string | null

    @Input()
    public brandImage?: ImageFragment | null

    @Input()
    public headerImage?: ImageComponentFragment | null

    @Input()
    public lazyLoad: boolean = true

}
