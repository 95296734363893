import { firstValueFrom, of, switchMap } from 'rxjs'
import { TranslateRouterService } from '@endeavour/ngx-translate-router'

import { Component, OnInit } from '@angular/core'

import { AccountManagerService, AuthService } from '@app-services'
import { AccountManagerDataFragment } from '@app-graphql/schema'
import { LocalizationService } from '@app-domains/localization/service/localization.service'

@Component({
    selector: 'app-subfooter-account-manager',
    templateUrl: './subfooter-account-manager.component.html',
    styleUrls: ['./subfooter-account-manager.component.scss'],
})
export class SubfooterAccountManagerComponent implements OnInit {

    public accountManager?: AccountManagerDataFragment

    constructor(
        private accountManagerService: AccountManagerService,
        public translateRouterService: TranslateRouterService,
        public readonly authService: AuthService,
        public localization: LocalizationService,
    ) {
    }

    public ngOnInit(): void {
        firstValueFrom(
            this.authService.user$.pipe(
                switchMap((user) => user ? this.accountManagerService.getAccountManager() : of(undefined)),
            ),
        ).then((accountManager) => this.accountManager = accountManager)
    }
}
