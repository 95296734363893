<ng-container *ngIf="dropdown">
    <a [cdkMenuTriggerFor]="menu"
       (cdkMenuClosed)="showDropdown = false"
       (cdkMenuOpened)="showDropdown = true"
       class="dropdown-main"
       [ngClass]="dropdown.noUpperCase ? '' : 'uppercase'"
    >
        <span *ngIf="dropdown.icon"
              [appIcon]="dropdown.icon"
              class="icon"
        ></span>

        {{ dropdown.dropdownButtonLabel ? (dropdown.dropdownButtonLabel | translate) : dropdown.dropdownButtonText }}

        <span appIcon="chevron-down"
              class="chevron"
              [class.inverted]="showDropdown"
        ></span>
    </a>

    <ng-template #menu>
        <div *ngIf="dropdown.dropdownItems"
             cdkMenu
             class="dropdown"
        >
            <ng-container *ngFor="let item of dropdown.dropdownItems">
                <a *ngIf="item.clickEvent"
                   cdkMenuItem
                   (click)="item.clickEvent($event)"
                   class="dropdown-item stretch"
                >
                    {{ item.itemLabel ? (item.itemLabel | translate) : item.itemName }}
                </a>

                <a *ngIf="item.routerLink"
                   [routerLink]="item.routerLink | translateRoute"
                   cdkMenuItem
                   (click)="close()"
                   class="dropdown-item stretch"
                >
                    {{ item.itemLabel ? (item.itemLabel | translate) : item.itemName }}
                </a>

                <ng-container *ngIf="localization.currentLocale$ | async as locale">
                    <a *ngIf="item.externalLink"
                       [href]="item.externalLink + (locale !== LocalesEnum.Nl ? LocalesEnum.En : LocalesEnum.Nl)"
                       target="_blank"
                       cdkMenuItem
                       (click)="close()"
                       class="dropdown-item stretch"
                    >
                        {{ item.itemLabel ? (item.itemLabel | translate) : item.itemName }}
                    </a>
                </ng-container>

                <ng-container *ngIf="item.ppName ">
                    <a *ngIf="item.ppName | positionedPageLink | async as link"
                       [routerLink]="link"
                       cdkMenuItem
                       (click)="close()"
                       class="dropdown-item stretch"
                    >
                        {{ item.itemLabel ? (item.itemLabel | translate) : item.itemName }}
                    </a>
                </ng-container>

                <hr *ngIf="item.underLine" class="underline" />
            </ng-container>

            <ng-container *ngIf="userDropdown">
                <hr class="underline" />

                <!-- Wrapping div is needed to prevent menu from closing when checking/unchecking
                 ! TODO: Check if this can be refactored when new major version of Angular is available -->
                <div (click)="updateProfileService.hidePurchasePrice.setValue(! updateProfileService.hidePurchasePrice.value)"
                     class="dropdown-item pointer"
                >
                    <app-checkbox [control]="updateProfileService.hidePurchasePrice"
                                  [nativeMode]="true"
                                  [smallLabel]="true"
                                  class="no-pointer-events"
                    >
                        {{ 'my-data.hide-purchase-price' | translate }}
                    </app-checkbox>
                </div>
            </ng-container>
        </div>
    </ng-template>
</ng-container>
