import { Observable } from 'rxjs'
import { MutationResult } from 'apollo-angular'
import { Injectable } from '@angular/core'
import {
    SendContactFormMutation,
    SendContactFormMutationService,
    SendContactFormMutationVariables,
} from '@app-graphql/schema'

@Injectable({
    providedIn: 'root',
})
export class ContactService {

    constructor(
        private readonly sendContactFormMutationService: SendContactFormMutationService,
    ) {
    }

    public sendContactForm(input: SendContactFormMutationVariables)
        : Observable<MutationResult<SendContactFormMutation>> {
        return this.sendContactFormMutationService.mutate(input, {
            errorPolicy: 'all',
        })
    }
}
