export const createScriptElement = (url: string) => {
    return new Promise((resolve, reject) => {
        const el = document.createElement('script')
        el.src = url
        el.type = 'text/javascript'
        el.addEventListener('load', () => {
            resolve(el)
        })
        el.addEventListener('error', (e) => {
            reject(e)
        })

        document.body.append(el)
    })
}
