import { Pipe, PipeTransform } from '@angular/core'
import {
    AbstractControl,
    FormArray,
    FormControl,
    FormGroup,
    UntypedFormArray,
    UntypedFormControl,
    UntypedFormGroup,
} from '@angular/forms'

@Pipe({
    name: 'formChild',
})
export class FormChildPipe implements PipeTransform {

    /**
     * Takes from the given form-group the control by given name. If the group does not have a control
     * by the given name, undefined is returned.
     *
     * ## expect: 'control'
     *
     * The assertion is made that the found control is an instance of
     * {@link FormControl}. If it is not, undefined is returned in its place.
     */
    public transform(group: UntypedFormGroup, controlName: string, expect: 'control'): UntypedFormControl | undefined
    public transform(group: FormGroup, controlName: string, expect: 'control'): FormControl | undefined
    /**
     * Takes from the given form-group the control by given name. If the group does not have a control
     * by the given name, undefined is returned.
     *
     * ## expect: 'group'
     *
     * The assertion is made that the found control is an instance of
     * {@link FormGroup}. If it is not, undefined is returned in its place.
     */
    public transform(group: UntypedFormGroup, controlName: string, expect: 'group'): UntypedFormGroup | undefined
    public transform(group: FormGroup, controlName: string, expect: 'group'): FormGroup | undefined
    /**
     * Takes from the given form-group the control by given name. If the group does not have a control
     * by the given name, undefined is returned.
     *
     * ## expect: 'array'
     *
     * The assertion is made that the found control is an instance of
     * {@link FormArray}. If it is not, undefined is returned in its place.
     */
    public transform(group: UntypedFormGroup, controlName: string, expect: 'array'): UntypedFormArray | undefined
    public transform(group: FormGroup, controlName: string, expect: 'array'): FormArray | undefined
    /**
     * Takes from the given form-group the control by given name. If the group does not have a control
     * by the given name, undefined is returned.
     */
    public transform(group: UntypedFormGroup, controlName: string): AbstractControl | undefined
    public transform(group: FormGroup, controlName: string): AbstractControl | undefined
    public transform(group: unknown, controlName: unknown, expect?: unknown): AbstractControl | undefined {
        if (! ((group instanceof UntypedFormGroup || group instanceof FormGroup) && typeof controlName === 'string')) {
            return undefined
        }

        const child: AbstractControl | undefined = group.controls[controlName]

        if (expect === undefined) {
            return child
        }

        switch (expect) {
            case 'control':
                return child instanceof UntypedFormControl || child instanceof FormControl ? child : undefined
            case 'group':
                return child instanceof UntypedFormGroup || child instanceof FormGroup ? child : undefined
            case 'array':
                return child instanceof UntypedFormArray || child instanceof FormArray ? child : undefined
            default:
                return undefined
        }
    }
}
