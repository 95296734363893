import { Component, Input } from '@angular/core'
import { ImageContentLinkFragment } from '@app-graphql/schema'

@Component({
    selector: 'app-image-content-link',
    templateUrl: './image-content-link.component.html',
    styleUrls: ['./image-content-link.component.scss'],
})
export class ImageContentLinkComponent {

    @Input()
    public data: ImageContentLinkFragment

    @Input()
    public lazyLoad: boolean = true

    @Input()
    public transparentBackground?: boolean = false
}
