<header id="header"
        class="navigation__container"
        [ngClass]="{'hidden' : !pageStructureDisplayService.showHeader}"
>
    <div *ngIf="localization.currentLocale$ | async as locale"
         class="navigation"
         [ngClass]="{
            'navigation--collapsed' : navigationIsCollapsed,
            'toggle__search' : searchIsOpen
        }"
    >
        <app-impersonation-bar></app-impersonation-bar>

        <div [class.toggle__mobile-menu]="mobileMenuIsOpen"
             class="content-container"
        >
            <div class="content">
                <a [routerLink]="'/' | translateRoute"
                   class="logo"
                >
                    <img src="/assets/images/de-eekhoorn.svg"
                         alt="Logo of De Eekhoorn"
                    >
                </a>

                <div class="top-bar top-bar__primary">
                    <div class="lang-switch">
                        <app-lang-switch></app-lang-switch>
                    </div>
                    <div class="usp usp__left"
                    >
                        {{ 'template.site-header.usp-left' | translate }}
                    </div>
                </div>

                <div class="top-bar top-bar__secondary">
                    <div class="usp usp__right">{{ 'template.site-header.usp-right' | translate }}</div>
                    <div class="user-links">
                        <app-auth-header authVersion="desktop"
                                         [userDropdown]="userDropdown"
                        >
                        </app-auth-header>
                    </div>
                </div>

                <div class="controls">
                    <div class="lang-switch">
                        <app-lang-switch></app-lang-switch>
                    </div>
                    <div class="controls__list">
                        <div class="search search__desktop">
                            <app-search
                                    [openCloseVariant]="true"
                                    [navigationIsCollapsed]=navigationIsCollapsed
                            ></app-search>
                        </div>
                        <button (click)="toggleSearch()"
                                class="controls__button controls__button--search"
                                appIcon="search">
                        </button>
                        <a [routerLink]="'/wishlist' | translateRoute"
                           class="controls__button shopping-cart"
                           [class.has-items]="(wishlistService.wishlist$ | async)?.length"
                           appIcon="heart"
                        >
                            <ng-container *ngIf="authService.user$ | async">
                                <span class="badge grey"
                                      *ngIf="(wishlistService.wishlist$ | async)?.length as wishlist"
                                >
                                    {{ wishlist }}
                                </span>
                            </ng-container>
                        </a>
                        <ng-container *ngIf="authService.user$ | async as user">
                            <ng-container *ngIf="user.debtorCode">
                                <a [routerLink]="'/shopping-cart' | translateRoute"
                                   class="controls__button shopping-cart"
                                   [class.has-items]="(checkoutService.shoppingCart$ | async)?.totalItems"
                                   appIcon="cart"
                                >
                                    <span *ngIf="(checkoutService.shoppingCart$ | async)?.totalItems as shoppingCart"
                                          class="badge"
                                    >
                                        {{ shoppingCart }}
                                    </span>
                                </a>
                            </ng-container>
                        </ng-container>

                        <button (click)="toggleMobileMenu()"
                                class="hamburger__container"
                        >
                            <span class="hamburger">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </span>
                        </button>
                    </div>
                </div>

                <div class="main">
                    <a [routerLink]="PositionedPageEnum.Projects | positionedPageLink | async"
                       class="main__link"
                    >
                        {{ 'navigation.projects' | translate }}
                    </a>

                    <a [routerLink]="((authService.user$ | async) ? '/p/experience-center-business': '/experience-center') | translateRoute"
                       class="main__link"
                    >
                        {{ 'navigation.experience-center' | translate }}
                    </a>

                    <app-menu-dropdown [dropdown]="aboutUsDropdown"></app-menu-dropdown>
                </div>

                <div class="categories">
                    <div class="categories__group"
                         [class.navigation-collapsed]="navigationIsCollapsed"
                    >
                        <a [routerLink]="'/products/new' | translateRoute"
                           class="categories__link new"
                        >
                            {{ 'navigation.new' | translate }}
                        </a>

                        <app-category-dropdown *ngIf="brandsDropdown$ | async as dropdown"
                                               [categoryDropdown]="dropdown"
                                               [navigationIsCollapsed]=navigationIsCollapsed
                        ></app-category-dropdown>

                        <app-category-dropdown *ngIf="themesDropdown$ | async as dropdown"
                                               [categoryDropdown]="dropdown"
                                               [navigationIsCollapsed]=navigationIsCollapsed
                        ></app-category-dropdown>
                    </div>

                    <div class="categories__group"
                         [class.navigation-collapsed]="navigationIsCollapsed"
                    >
                        <a [routerLink]="'/products' | translateRoute"
                           class="categories__link all"
                        >
                            {{ 'navigation.collection' | translate }}
                        </a>

                        <app-category-dropdown *ngFor="let category of dropdownCategories$ | async"
                                               [categoryDropdown]="category"
                                               [navigationIsCollapsed]=navigationIsCollapsed
                        ></app-category-dropdown>
                    </div>

                    <div class="categories__group">
                        <a [routerLink]="'/products/sale' | translateRoute"
                           class="categories__link"
                           [class.navigation-collapsed]="navigationIsCollapsed"
                           [style.color]="'var(--color-negative)'">
                            {{ 'navigation.sale' | translate }}
                        </a>
                    </div>
                </div>

                <div class="search search__mobile">
                    <app-search [mobileMenuIsOpen]="mobileMenuIsOpen"></app-search>
                    <button (click)="toggleSearch()"
                            class="controls__button controls__button--close"
                            appIcon="close">
                    </button>
                </div>

                <div class="mobile__menu">
                    <app-auth-header authVersion="mobile"
                                     [mobileMenuIsOpen]="mobileMenuIsOpen"
                                     [userDropdown]="userDropdown"
                    ></app-auth-header>

                    <div class="mobile__menu-section mobile__menu-section--no-border">
                        <a [routerLink]="'/products/new' | translateRoute"
                           (click)="toggleMobileMenu()"
                           class="mobile__link"
                        >
                            {{ 'navigation.new' | translate }}
                        </a>

                        <app-subcategory-header *ngIf="brandsDropdown$ | async as subcategory"
                                                [categoryWithSubcategory]="subcategory"
                                                [mobileMenuIsOpen]="mobileMenuIsOpen"
                                                (linkClicked)="toggleMobileMenu()"
                        ></app-subcategory-header>

                        <app-subcategory-header *ngIf="themesDropdown$ | async as subcategory"
                                                [categoryWithSubcategory]="subcategory"
                                                [mobileMenuIsOpen]="mobileMenuIsOpen"
                                                (linkClicked)="toggleMobileMenu()"
                        ></app-subcategory-header>
                    </div>

                    <div class="mobile__menu-section">
                        <a [routerLink]="'/products' | translateRoute"
                           class="mobile__link"
                        >
                            {{ 'navigation.collection' | translate }}
                        </a>

                        <app-subcategory-header *ngFor="let subcategory of dropdownCategories$ | async"
                                                [categoryWithSubcategory]="subcategory"
                                                [mobileMenuIsOpen]="mobileMenuIsOpen"
                                                (linkClicked)="toggleMobileMenu()"
                        ></app-subcategory-header>
                    </div>

                    <div class="mobile__menu-section mobile__menu-section--last">
                        <a [routerLink]="'/products/sale' | translateRoute"
                           (click)="toggleMobileMenu()"
                           class="mobile__link mobile__link--sale"
                        >
                            {{ 'navigation.sale' | translate }}
                        </a>

                        <a [routerLink]="PositionedPageEnum.Projects | positionedPageLink | async"
                           class="mobile__link mobile__link--uppercase"
                        >
                            {{ 'navigation.projects' | translate }}
                        </a>

                        <a [routerLink]="((authService.user$ | async) ? '/p/experience-center-business': '/experience-center') | translateRoute"
                           (click)="toggleMobileMenu()"
                           class="mobile__link mobile__link--uppercase"
                        >
                            {{ 'navigation.experience-center' | translate }}
                        </a>

                        <app-subcategory-header *ngIf="aboutUsSubcategory"
                                                [categoryWithSubcategory]="aboutUsSubcategory"
                                                [includeMainPageUrl]="false"
                                                [categoryUppercase]="true"
                                                [mobileMenuIsOpen]="mobileMenuIsOpen"
                                                (linkClicked)="toggleMobileMenu()"
                        ></app-subcategory-header>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>

<div
    class="header-observer"
    appObserveElement
    (isIntersecting)="isIntersecting($event, 'header')"
    [isContinuous]="true"
    [debounceTime]="0"
>
</div>
