import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms'

export const matchPassword = (form1: string, form2: string): ValidatorFn =>
    (group: AbstractControl): ValidationErrors | null => {
        if (! (group instanceof FormGroup)) {
            return null
        }
        if (group && group.controls[form1].value && group.controls[form2].value) {
            if (group.controls[form1].value !== group.controls[form2].value) {
                return { noMatchingPassword: true }
            }
            return null
        }
        return null
    }
