<div class="modal-container">
    <ng-container *ngIf="!currentConfig">
        <div class="fixed">
            <div class="top">
                <h3>
                    {{ 'assortment.what-do-you-download' | translate }}
                </h3>

                <span appIcon="close"
                      class="close-icon"
                      (click)="dialogRef.close()"
                ></span>
            </div>

            <hr/>
        </div>

        <div class="mid">
            <div class="config-row">
                <app-checkbox [value]="'productsheet'"
                              [control]="selectedTypes"
                >
                    {{ 'assortment.product-sheet' | translate }}
                </app-checkbox>

                <app-button theme="link"
                            layout="label-icon"
                            icon="chevron-right"
                            (click)="this.updateCurrentConfig('productSheetsForm')"
                >
                    {{ 'assortment.configure' | translate }}
                </app-button>
            </div>

            <div class="single-row">
                <app-checkbox [value]="'manuals'"
                              [control]="selectedTypes"
                              class="check-mb"
                >
                    {{ 'assortment.manuals' | translate }}
                </app-checkbox>
            </div>

            <div class="single-row">
                <app-checkbox [value]="'images'"
                              [control]="selectedTypes"
                              class="check-mb"
                >
                    {{ 'assortment.images' | translate }}
                </app-checkbox>
            </div>

            <div class="config-row">
                <app-checkbox [value]="'pricelist'"
                              [control]="selectedTypes"
                >
                    {{ 'assortment.price-list' | translate }}
                </app-checkbox>

                <app-button theme="link"
                            layout="label-icon"
                            icon="chevron-right"
                            (click)="this.updateCurrentConfig('priceListForm')"
                >
                    {{ 'assortment.configure' | translate }}
                </app-button>
            </div>

            <div class="config-row">
                <app-checkbox [value]="'datasheet'"
                              [control]="selectedTypes"
                >
                    {{ 'assortment.data-sheet' | translate }}
                </app-checkbox>

                <app-button theme="link"
                            layout="label-icon"
                            icon="chevron-right"
                            (click)="this.updateCurrentConfig('dataSheetsForm')"
                >
                    {{ 'assortment.configure' | translate }}
                </app-button>
            </div>
        </div>

        <p class="small terms">
            {{ 'assortment.download-terms' | translate }}
        </p>

        <app-button theme="dark"
                    layout="icon-label"
                    icon="download"
                    class="full-width-button"
                    (click)="closeDialog()"
        >
            {{ 'assortment.download-selection' | translate }}
        </app-button>
    </ng-container>

    <ng-container *ngIf="currentConfig === 'productSheetsForm'">
        <div class="fixed">

            <div class="top">
                <app-button theme="link"
                            layout="icon-label"
                            icon="chevron-left"
                            (click)="this.updateCurrentConfig(null)"
                >
                    {{ 'common.back' | translate }}
                </app-button>

                <span appIcon="close"
                      class="close-icon"
                      (click)="dialogRef.close()"
                ></span>
            </div>

            <h3 class="sub-title">
                {{ 'assortment.configure-your-product-sheets' | translate }}
            </h3>

            <hr class="hr">
        </div>

        <div class="content">
            <app-checkbox [nativeMode]="true"
                          [control]="selectAllControls.controls.productsheet"
                          class="select-all"
            >
                {{ 'assortment.all' | translate }}
            </app-checkbox>

            <ng-container *ngFor="let control of productsheetConfig">
                <div class="config">
                    <app-checkbox [value]="control.control"
                                  [control]="productsheetFields"
                    >
                        {{ control.label | translate }}
                    </app-checkbox>
                </div>
            </ng-container>
        </div>

        <app-button theme="dark"
                    layout="label-only"
                    class="full-width-button mt"
                    (click)="currentConfig = null"
        >
            {{ 'assortment.save-configuration' | translate }}
        </app-button>
    </ng-container>

    <ng-container *ngIf="currentConfig === 'dataSheetsForm'">
        <div class="fixed">
            <div class="top">
                <app-button theme="link"
                            layout="icon-label"
                            icon="chevron-left"
                            (click)="currentConfig = null"
                >
                    {{ 'common.back' | translate }}
                </app-button>

                <span appIcon="close"
                      class="close-icon"
                      (click)="dialogRef.close()"
                ></span>
            </div>

            <h3 class="sub-title">
                {{ 'assortment.configure-your-data-sheets' | translate }}
            </h3>

            <hr/>

            <div class="content">
                <ng-container *ngFor="let control of dataSheetConfig">
                    <div class="config">
                        <app-radio-button [control]="dataSheetOptions"
                                          [value]="control.label | translate"
                        >
                            {{  control.label | translate  }}
                        </app-radio-button>
                    </div>
                </ng-container>
            </div>
        </div>

        <app-button theme="dark"
                    layout="label-only"
                    class="full-width-button mt"
                    (click)="currentConfig = null"
        >
            {{ 'assortment.save-configuration' | translate }}
        </app-button>
    </ng-container>

    <ng-container *ngIf="currentConfig === 'priceListForm'">
        <div class="fixed">
            <div class="top">
                <app-button theme="link"
                            layout="icon-label"
                            icon="chevron-left"
                            (click)="currentConfig = null"
                >
                    {{ 'common.back' | translate }}
                </app-button>

                <span appIcon="close"
                      class="close-icon"
                      (click)="dialogRef.close()"
                ></span>
            </div>
            <h3 class="sub-title">
                {{ 'assortment.configure-your-price-list' | translate }}
            </h3>
            <hr/>
        </div>

        <div class="content">
            <app-checkbox [nativeMode]="true"
                          [control]="selectAllControls.controls.pricelist"
                          class="select-all"
            >
                {{ 'assortment.all' | translate }}
            </app-checkbox>

            <ng-container *ngFor="let control of pricelistConfig">
                <div class="config">
                    <app-checkbox [control]="pricelistFields"
                                  [value]="control.control"
                    >
                        {{ control.label | translate }}
                    </app-checkbox>
                </div>
            </ng-container>
        </div>

        <app-button theme="dark"
                    layout="label-only"
                    class="full-width-button mt"
                    (click)="currentConfig = null"
        >
            {{ 'assortment.save-configuration' | translate }}
        </app-button>
    </ng-container>
</div>
