<div class="tooltip"
     (mouseover)="openTooltip($any($event.clientX))"
     (mouseleave)="closeTooltip()"
>
    <div (click)="openTooltip($any($event.clientX))">
        <ng-content select="[label]"></ng-content>
    </div>

    <span class="inner-tooltip hide" [class.grid]="gridLayout">
        <ng-content></ng-content>

        <span appIcon="close"
              class="close-icon"
              (click)="closeTooltip()"
        ></span>
    </span>
</div>
