import { Pipe, PipeTransform } from '@angular/core'
import { Product } from '@app-domains/content-blocks/components/product-card/product-card.types'
import {
    ImageFragment,
    ParagraphFragment,
    ProductImageFragment,
    UniqueSellingPointFragment,
    VideoFragment,
} from '@app-graphql/schema'
import { AlgoliaDealer } from '@app-types/dealer.types'
import { PageHit } from '@app-types/page.types'

export class AsTypePipe<T> implements PipeTransform {
    transform(value: unknown, asArray: true): T[]
    transform(value: unknown, asArray: false): T
    transform(value: unknown): T
    transform(value: unknown, asArray?: boolean): T | T[] {
        if (asArray) {
            return value as T[]
        }
        return value as T
    }
}

@Pipe({
    name: 'asProduct',
})
export class AsProductPipe extends AsTypePipe<Product> implements PipeTransform {}

@Pipe({
    name: 'asUspFragment',
})
export class AsUspFragmentPipe extends AsTypePipe<UniqueSellingPointFragment> implements PipeTransform {}

@Pipe({
    name: 'asParagraphFragment',
})
export class AsParagraphFragmentPipe extends AsTypePipe<ParagraphFragment> implements PipeTransform {}

@Pipe({
    name: 'asVideoFragment',
})
export class AsVideoFragmentPipe extends AsTypePipe<VideoFragment> implements PipeTransform {}

@Pipe({
    name: 'asProductImageFragment',
})
export class AsProductImageFragmentPipe extends AsTypePipe<ProductImageFragment> implements PipeTransform {}

@Pipe({
    name: 'asImageFragment',
})
export class AsImageFragmentPipe extends AsTypePipe<ImageFragment> implements PipeTransform {}

@Pipe({
    name: 'asAlgoliaDealer',
})
export class AsAlgoliaDealerPipe extends AsTypePipe<AlgoliaDealer> implements PipeTransform {}

@Pipe({
    name: 'asPageHit',
})
export class AsPageHitPipe extends AsTypePipe<PageHit> implements PipeTransform {}
