import { Pipe, PipeTransform } from '@angular/core'
import { Product } from '@app-domains/content-blocks/components/product-card/product-card.types'
import { ProductService } from '@app-services'

@Pipe({
    name: 'transformProductHit',
})
export class TransformProductHitPipe implements PipeTransform {
    constructor(
        private productService: ProductService,
    ) {
    }

    transform(hit: any[]): Product[] {
        return this.productService.transformHitToProduct(hit)
    }
}
