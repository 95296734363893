import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { HeaderLayoutEnum, VideoFragment } from '@app-graphql/schema'
import { Observable, Subscription } from 'rxjs'

@Component({
    selector: 'app-video-player',
    templateUrl: './video-player.component.html',
    styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnInit, OnDestroy {
    @Input()
    public video: VideoFragment

    @Input()
    public layout: HeaderLayoutEnum

    @Input()
    public aspectRatio?: string = 'auto'

    @Input()
    public fit: 'contain' | 'cover' = 'cover'

    @Input()
    public autoplay: boolean = true

    @Input()
    public togglePlayback$: Observable<boolean>

    @ViewChild('videoElement', {
        static: true,
    })
    public videoElement: ElementRef<HTMLVideoElement>

    public headerLayoutEnum = HeaderLayoutEnum

    public isPlaying: boolean
    private subscription: Subscription

    public ngOnInit(): void {
        if (this.togglePlayback$) {
            this.subscription = this.togglePlayback$.subscribe(
                (shouldPlay) => this.togglePlayback(shouldPlay),
            )
        }
        this.videoElement.nativeElement.addEventListener('loadeddata', async () => {
            try {
                this.videoElement.nativeElement.muted = true

                if (this.autoplay) {
                    await this.videoElement.nativeElement.play()
                }
                this.isPlaying = ! this.videoElement.nativeElement.paused
            } catch (ex) {

            }
        })
    }

    public ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe()
        }
    }

    public async togglePlayback(shouldPlay?: boolean): Promise<void> {
        const video = this.videoElement.nativeElement

        if (shouldPlay ?? video.paused) {
            this.isPlaying = true
            await video.play()
        } else {
            this.isPlaying = false
            video.pause()
        }
    }
}
