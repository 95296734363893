import { Subscription } from 'rxjs'

import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { FormControl } from '@angular/forms'
import { BlockScrollStrategy, ScrollStrategy, ViewportRuler } from '@angular/cdk/overlay'

import { FilterMatch, FuzzyData, FuzzySegment } from '@app-domains/ui/components/fuzzy-search/types/fuzzy-search.types'
import { TranslationKey } from '@app-pipes/typed-translate/typed-translate.pipe'

@Component({
    selector: 'app-fuzzy-search',
    templateUrl: './fuzzy-search.component.html',
    styleUrls: ['./fuzzy-search.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class FuzzySearchComponent implements OnInit, OnDestroy {

    @Input()
    public dataSet: FuzzyData[]

    @Input()
    public placeholder: TranslationKey

    @Output()
    public valueUpdated = new EventEmitter<FuzzyData>

    public matches: FilterMatch[] = []

    public control = new FormControl('', {
        nonNullable: true,
    })

    private controlSub: Subscription

    public scrollStrategy: ScrollStrategy

    constructor(
        private readonly viewportRuler: ViewportRuler,
        @Inject(DOCUMENT) protected readonly document: Document,
    ) {
        this.scrollStrategy = new BlockScrollStrategy(viewportRuler, document)
    }

    public ngOnInit() {
        this.controlSub = this.control.valueChanges.subscribe(() => {
            this.applyFilter()
        })
    }

    public ngOnDestroy() {
        if (this.controlSub) {
            this.controlSub.unsubscribe()
        }
    }

    public applyFilter(): void {
        if (! this.control.value) {
            this.matches = []
            return
        }

        this.matches = this.dataSet.map((primate) => {
            return ({
                value: primate,
                score: this.scoreValue(primate.label, this.control.value),
            })
        }).sort(
            (a, b) => {
                return (
                    ((a.score > b.score) && -1) || // Move item up.
                    ((a.score < b.score) && 1) || // Move item down.
                    0
                )
            }).map((scoredValue) => {
            return ({
                score: scoredValue.score,
                value: scoredValue.value,
                segments: this.parseValue(scoredValue.value.label, this.control.value),
            })
        })
    }

    public parseValue(value: string, input: string): FuzzySegment[] {
        let valueLength = value.length
        const inputLength = input.length
        let valueIndex = 0
        let inputIndex = 0

        const segments: FuzzySegment[] = []
        let segment: FuzzySegment = {
            isMatch: false,
            value: '',
        }

        while (valueIndex < valueLength) {
            const valueChar = value.charAt(valueIndex++).toLowerCase()
            const inputChar = input.charAt(inputIndex).toLowerCase()

            if (valueChar === inputChar) {
                inputIndex++
                if (segment && segment.isMatch) {
                    segment.value += valueChar
                } else {
                    segment = {
                        value: valueChar,
                        isMatch: true,
                    }
                    segments.push(segment)
                }

                if ((inputIndex === inputLength) && (valueIndex < valueLength)) {
                    segments.push({
                        value: value.slice(valueIndex),
                        isMatch: false,
                    })

                    break
                }
            } else {
                if (segment && ! segment.isMatch) {
                    segment.value += valueChar
                } else {
                    segment = {
                        value: valueChar,
                        isMatch: false,
                    }

                    segments.push(segment)
                }
            }
        }
        return (segments)
    }

    public scoreValue(value: string, input: string): number {
        const normalizedValue = value.toLowerCase()
        const normalizedInput = input.toLowerCase()

        const valueLength = normalizedValue.length
        const inputLength = normalizedInput.length
        let valueIndex = 0
        let inputIndex = 0

        let previousIndexMatched = false
        let score = 0

        while (valueIndex < valueLength) {
            const valueChar = normalizedValue.charAt(valueIndex++) // Get and increment.
            const inputChar = normalizedInput.charAt(inputIndex)

            if (valueChar === inputChar) {
                inputIndex++
                score += (previousIndexMatched) ? 3 : 2
                previousIndexMatched = true

                if (inputIndex === inputLength) {
                    return (score -= (valueLength - valueIndex))
                }

            } else {
                score -= 1
                previousIndexMatched = false
            }
        }
        return (score)
    }

    public updated(event: FuzzyData) {
        this.control.setValue(event.label)
        this.valueUpdated.emit(event)
        this.matches = []
    }

    public close() {
        setTimeout(() => {
            this.matches = []
        }, 100)
    }

    public setMatch() {
        const match = this.matches[0]

        if (match) {
            this.control.setValue(match.value.label)
            this.valueUpdated.emit(match.value)
            this.matches = []
        }
    }
}
