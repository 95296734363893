import { Component, Input } from '@angular/core'

import { VideoFragment } from '@app-graphql/schema'
import { ComponentWithTextComponent } from '@app-domains/content-blocks/components/component-with-text/component-with-text.component'

@Component({
    selector: 'app-video-with-text',
    templateUrl: './video-with-text.component.html',
})
export class VideoWithTextComponent extends ComponentWithTextComponent {
    @Input()
    public video: VideoFragment
}
