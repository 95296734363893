import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { ProductGridBlueprintComponent } from './components/product-grid-blueprint/product-grid-blueprint.component'
import { GridNormalComponent } from './components/grid-normal/grid-normal.component'
import { GridLargeComponent } from './components/grid-large/grid-large.component'
import { GridXlargeComponent } from './components/grid-xlarge/grid-xlarge.component'
import { ContentBlocksModule } from '@app-domains/content-blocks/content-blocks.module'

@NgModule({
    declarations: [
        ProductGridBlueprintComponent,
        GridNormalComponent,
        GridLargeComponent,
        GridXlargeComponent,
    ],
    imports: [
        CommonModule,
        ContentBlocksModule,
    ],
    exports: [
        ProductGridBlueprintComponent,
    ],
})
export class ProductGridDomainModule { }
