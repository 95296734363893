import { TranslateModule } from '@ngx-translate/core'
import { APP_INITIALIZER, NgModule } from '@angular/core'

import { LocalizationService } from '@app-domains/localization/service/localization.service'

export function appInitializerFactory(localizationService: LocalizationService): () => Promise<void> {
    return async () => localizationService.initialize()
}

@NgModule({
    imports: [
        TranslateModule,
    ],
    providers: [
        {
            provide: LocalizationService,
            useClass: LocalizationService,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [LocalizationService],
            multi: true,
        },
    ],
})
export class LocalizationModule {
}
