import { Component, forwardRef, Inject, Input } from '@angular/core'
import { Product } from '@app-domains/content-blocks/components/product-card/product-card.types'
import { AlgoliaInstantsearchComponent } from '@app-domains/algolia/components/algolia-instantsearch.component'

@Component({
    selector: 'app-other-products-card',
    templateUrl: './other-products-card.component.html',
    styleUrls: ['./other-products-card.component.scss'],
})
export class OtherProductsCardComponent {
    @Input()
    public product: Product

    @Input()
    public className: string

    @Input()
    public queryID?: string

    @Input()
    public index: number

    constructor(
        @Inject(forwardRef(() => AlgoliaInstantsearchComponent))
        public instantSearchInstance: AlgoliaInstantsearchComponent,
    ) {
    }

    public handleClick(product: Product): void {
        const ic = this.instantSearchInstance.instantSearchInstance.insightsClient
        if (ic && this.queryID) {
            ic('clickedObjectIDsAfterSearch', {
                index: this.instantSearchInstance.instantSearchInstance.indexName,
                eventName: 'Click item',
                queryID: this.queryID,
                objectIDs: [product.ean],
                positions: [this.index],
            })
        }
    }
}
