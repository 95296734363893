import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Product } from '@app-domains/content-blocks/components/product-card/product-card.types'
import { RouterLinkCommands } from '@endeavour/ngx-translate-router'
import { OtherResult } from '../../../pages/search/types/search.types'

@Component({
    selector: 'app-search-result',
    templateUrl: './search-result.component.html',
    styleUrls: ['./search-result.component.scss'],
})
export class SearchResultComponent implements OnInit {
    @Output()
    public resultClicked = new EventEmitter<void>()

    @Input()
    public product: Product

    @Input()
    public otherResult: OtherResult

    public firstWord: string = ''

    public remainingWords: string = ''

    constructor(
        private router: Router,
    ) {
    }

    public ngOnInit(): void {
        this.wrapFirstWord()
    }

    public onClick(route: RouterLinkCommands): void {
        this.resultClicked.emit()
        this.router.navigate([route as string])
    }

    public wrapFirstWord(): void {
        const productName = this.product?.name.toLowerCase()

        if (productName) {
            this.firstWord = productName.split(' ')[0] + ' '
            this.remainingWords = productName.split(' ').slice(1).join(' ')
        }
    }
}
