import { NgModule } from '@angular/core'
import {
    AsAlgoliaDealerPipe,
    AsImageFragmentPipe, AsPageHitPipe,
    AsParagraphFragmentPipe,
    AsProductImageFragmentPipe,
    AsProductPipe,
    AsUspFragmentPipe, AsVideoFragmentPipe,
} from '@app-pipes/as-type/as-type.pipe'
import { CssUrlPipe } from '@app-pipes/css-url/css-url.pipe'
import { EqualsPipe } from '@app-pipes/equals/equals.pipe'
import { IncludesPipe } from '@app-pipes/includes/includes.pipe'
import { IsEmptyPipe } from '@app-pipes/is-empty/is-empty.pipe'
import { IsFuturePipe } from '@app-pipes/is-future/is-future.pipe'
import { IsNonEmptyPipe } from '@app-pipes/is-nonempty/is-nonempty.pipe'
import { IsPastPipe } from '@app-pipes/is-past/is-past.pipe'
import { IsTodayPipe } from '@app-pipes/is-today/is-today.pipe'
import { IsTomorrowPipe } from '@app-pipes/is-tomorrow/is-tomorrow.pipe'
import { KeysPipe } from '@app-pipes/keys/keys.pipe'
import { NegatePipe } from '@app-pipes/negate/negate.pipe'
import { TruncateTextPipe } from '@app-pipes/truncate-text/truncate-text.pipe'
import { TransformProductHitPipe } from '@app-pipes/transform-product-hit/transform-product-hit.pipe'
import { SafePipe } from '@app-pipes/safe/safe.pipe'
import { TypedTranslatePipe } from '@app-pipes/typed-translate/typed-translate.pipe'
import { SlideIsActivePipe } from './slide-is-active/slide-is-active.pipe'
import { LocaleDatePipe } from '@app-pipes/date/locale-date.pipe'
import { SelectablePagesPipe } from './selectable-pages/selectable-pages.pipe'
import { FillArrayPipe } from '@app-pipes/fill-array/fill-array.pipe'
import { EnhanceProductsPipe } from './enhance-product/enhance-product.pipe'
import { GetProductImageOfTypePipe } from './get-product-image-of-type/get-product-image-of-type.pipe'
import { ImageSizePipe } from './image-size/image-size.pipe'
import { GetOfficeOfTypePipe } from './get-office-of-type/get-office-of-type.pipe'
import { SetCurrentVariantPipe } from '@app-pipes/set-current-variant/set-current-variant.pipe'
import { AmountOfProductsInOrderPipe } from './amount-of-products-in-order/amount-of-products-in-order.pipe'
import { CheckProductOrderTypePipe } from './check-product-order-type/check-product-order-type.pipe'
import { GetLastDropshipmentDatePipe } from '@app-pipes/get-last-dropshipment-date/get-last-dropshipment-date.pipe'
import { ExtendPipe } from './extend/extend.pipe'
import { WeekToDatePipe } from './week-to-date/week-to-date.pipe'
import { IsPageReadyPipe } from '@app-pipes/is-page-ready/is-page-ready.pipe'
import { SetCurrentColorVariantPipe } from './set-current-color-variant/set-current-color-variant.pipe'
import { TransformPageHitPipe } from './transform-page-hit/transform-page-hit.pipe'
import { ShipmentProductsPipe } from './shipment-products/shipment-products.pipe'
import { ShipmentDeliveryWeekPipe } from './shipment-delivery-week/shipment-delivery-week.pipe'
import { CartlinesPipe } from './cartlines/cartlines.pipe'
import { ShipmentTotalPipe } from './shipment-total/shipment-total.pipe'
import { ShipmentPriceWithShippingPipe } from './shipment-price-with-shipping/shipment-price-with-shipping.pipe'
import { SegmentMinOrderLimitPipe } from './segment-min-order-limit/segment-min-order-limit.pipe'
import {
    AreShipmentsBelowOrderLimitPipe,
} from '@app-pipes/all-segment-order-limit/are-shipments-below-order-limit.pipe'
import { SearchResultsCountPipe } from './search-results-count/search-results-count.pipe'
import { DropshipmentCostPipe } from './dropshipment-cost/dropshipment-cost.pipe'
import { UseRoutingPipe } from './use-routing/use-routing.pipe'
import { IsOnSalePipe } from '@app-pipes/is-on-sale/is-on-sale.pipe'
import { ProductNotAvailablePipe } from './product-not-available/product-not-available.pipe'
import { CannotBeAddedToCartPipe } from '@app-pipes/cannot-be-added-to-cart/cannot-be-added-to-cart.pipe'

@NgModule({
    declarations: [
        CssUrlPipe,
        EqualsPipe,
        IncludesPipe,
        IsEmptyPipe,
        IsFuturePipe,
        IsNonEmptyPipe,
        IsPastPipe,
        IsTodayPipe,
        IsTomorrowPipe,
        KeysPipe,
        NegatePipe,
        TruncateTextPipe,
        TransformProductHitPipe,
        AsProductPipe,
        AsUspFragmentPipe,
        SafePipe,
        AsParagraphFragmentPipe,
        AsProductImageFragmentPipe,
        AsVideoFragmentPipe,
        SlideIsActivePipe,
        AsImageFragmentPipe,
        LocaleDatePipe,
        SelectablePagesPipe,
        FillArrayPipe,
        EnhanceProductsPipe,
        GetProductImageOfTypePipe,
        ImageSizePipe,
        GetOfficeOfTypePipe,
        SetCurrentVariantPipe,
        ExtendPipe,
        TypedTranslatePipe,
        AmountOfProductsInOrderPipe,
        CheckProductOrderTypePipe,
        GetLastDropshipmentDatePipe,
        AsAlgoliaDealerPipe,
        AsPageHitPipe,
        WeekToDatePipe,
        IsPageReadyPipe,
        SetCurrentColorVariantPipe,
        TransformPageHitPipe,
        ShipmentProductsPipe,
        ShipmentDeliveryWeekPipe,
        CartlinesPipe,
        ShipmentTotalPipe,
        ShipmentPriceWithShippingPipe,
        SegmentMinOrderLimitPipe,
        AreShipmentsBelowOrderLimitPipe,
        SearchResultsCountPipe,
        DropshipmentCostPipe,
        UseRoutingPipe,
        IsOnSalePipe,
        ProductNotAvailablePipe,
        CannotBeAddedToCartPipe,
    ],
    exports: [
        CssUrlPipe,
        EqualsPipe,
        IncludesPipe,
        IsEmptyPipe,
        IsFuturePipe,
        IsNonEmptyPipe,
        IsPastPipe,
        IsTodayPipe,
        IsTomorrowPipe,
        KeysPipe,
        NegatePipe,
        TruncateTextPipe,
        TransformProductHitPipe,
        SafePipe,
        AsProductPipe,
        AsUspFragmentPipe,
        AsParagraphFragmentPipe,
        AsProductImageFragmentPipe,
        AsVideoFragmentPipe,
        SlideIsActivePipe,
        AsImageFragmentPipe,
        LocaleDatePipe,
        SelectablePagesPipe,
        FillArrayPipe,
        EnhanceProductsPipe,
        GetProductImageOfTypePipe,
        GetOfficeOfTypePipe,
        ImageSizePipe,
        SetCurrentVariantPipe,
        TypedTranslatePipe,
        AmountOfProductsInOrderPipe,
        CheckProductOrderTypePipe,
        GetLastDropshipmentDatePipe,
        ExtendPipe,
        AsAlgoliaDealerPipe,
        WeekToDatePipe,
        IsPageReadyPipe,
        SetCurrentColorVariantPipe,
        AsPageHitPipe,
        TransformPageHitPipe,
        ShipmentProductsPipe,
        ShipmentDeliveryWeekPipe,
        CartlinesPipe,
        ShipmentTotalPipe,
        ShipmentPriceWithShippingPipe,
        SegmentMinOrderLimitPipe,
        AreShipmentsBelowOrderLimitPipe,
        DropshipmentCostPipe,
        SearchResultsCountPipe,
        UseRoutingPipe,
        IsOnSalePipe,
        ProductNotAvailablePipe,
        CannotBeAddedToCartPipe,
    ],
})
export class PipesModule {
}
