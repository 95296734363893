import { Inject, Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client'
import { LOCAL_STORAGE } from '@ng-web-apis/common'
import { QueryOptionsAlone } from 'apollo-angular/types'
import { Observable, scan, Subject } from 'rxjs'
import { tap } from 'rxjs/operators'
import { ProductBySlugQuery, ProductBySlugQueryService, ProductBySlugQueryVariables } from '@app-graphql/schema'
import { Localization } from '@aa-app-localization'

@Injectable({
    providedIn: 'root',
})
export class ProductHistoryService {
    private slugSubject = new Subject<string>()
    public history$: Observable<Array<string>> = this.slugSubject.pipe(
        scan((acc, val) => {
            return [val, ...acc].slice(0, 6)
        }, JSON.parse(this.storage.getItem('recentItems') ?? '[]') as Array<string>),
        tap((recentItems) => {
            this.storage.setItem('recentItems', JSON.stringify(recentItems))
        }),
    )

    constructor(
        private productBySlugQueryService: ProductBySlugQueryService,
        @Inject(LOCAL_STORAGE) private storage: Storage,
    ) {
    }

    public fetch(
        variables: ProductBySlugQueryVariables,
        locale: Localization.Locale,
        options?: QueryOptionsAlone<ProductBySlugQueryVariables, ProductBySlugQuery>,
    ): Observable<ApolloQueryResult<ProductBySlugQuery>> {
        return this.productBySlugQueryService.fetch(variables, options).pipe(
            tap((result) => {
                if (result.data.productBySlug?.translations[locale].slug) {
                    this.slugSubject.next(result.data.productBySlug.translations[locale].slug)
                }
            }),
        )
    }
}
