<ng-container *ngIf="authService.user$ | async as user else noAuth">
    <ng-container *ngIf="authVersion === 'mobile'">
        <app-download-manager layout="mobile"></app-download-manager>

        <app-subcategory-header
                [sectionWithSubcategory]="userDropdown"
                [includeMainPageUrl]="false"
                [mobileMenuIsOpen]="mobileMenuIsOpen"
        ></app-subcategory-header>
    </ng-container>

    <ng-container *ngIf="authVersion === 'desktop'">
        <app-download-manager layout="desktop"></app-download-manager>

        <app-menu-dropdown [dropdown]="userDropdown"
                           [userDropdown]="true"
        ></app-menu-dropdown>
    </ng-container>
</ng-container>

<ng-template #noAuth>
    <ng-container *ngIf="authVersion === 'mobile'">
        <div class="mobile__dealer-account">
            <h2 class="subheading">
                {{ 'template.site-header.dealer-account' | translate }}
            </h2>

            <div class="mobile__dealer-section">
                <a (click)="goToLoginPage()" class="mobile__dealer-link user-link">
                    <span appIcon="user"></span>
                    <span>{{ 'navigation.dealer-login' | translate }}</span>
                </a>

                <a [routerLink]="'/register' | translateRoute"
                   class="mobile__dealer-link user-link"
                >
                    {{ 'navigation.register' | translate }}
                </a>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="authVersion === 'desktop'">
        <button class="user-link" (click)="goToLoginPage()">
            <span appIcon="user"></span>
            <span>{{ 'navigation.dealer-login' | translate }}</span>
        </button>

        <a [routerLink]="'/register' | translateRoute"
           class="user-link"
        >
            {{ 'navigation.register' | translate }}
        </a>
    </ng-container>
</ng-template>



