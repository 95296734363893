<ng-container *ngIf="state && state.indices && state.indices[0] as index; else loading">
    <div *ngFor="let index of state?.indices" class="hits-products">
        <div class="title">
            <h4>
                {{ 'common.products' | translate }}
            </h4>

            <div class="hits stretch">{{ index.results.nbHits >= 100 ? '99+' : index.results.nbHits }}</div>
        </div>

        <div class="results-container">
            <app-search-result
                    *ngFor="let product of index.hits | transformProductHit; let i = index"
                    [product]="product"
                    (resultClicked)="resultClick(product, i)"
            ></app-search-result>
        </div>
    </div>
</ng-container>

<ng-template #loading>
    <app-page-spinner></app-page-spinner>
</ng-template>
