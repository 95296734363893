import { Component, ElementRef, Input, OnInit } from '@angular/core'

@Component({
    selector: 'app-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnInit {

    @Input()
    public label: any

    @Input()
    public tooltipContent: any

    @Input()
    public alignment?: 'left' | 'right' | 'bottom' | 'auto' = 'auto'

    @Input()
    public gridLayout: boolean = false

    @Input()
    public instantOpen: boolean = false

    constructor(
        private elRef: ElementRef,
    ) {
    }

    public ngOnInit(): void {
        if (this.instantOpen) {
            const tooltip: HTMLElement = this.elRef.nativeElement.querySelector('.tooltip')
            const mouseoverEvent = new Event('mouseover')
            tooltip.dispatchEvent(mouseoverEvent)

            window.scrollTo(0, 0)
        }
    }

    public openTooltip(pointerPositionX: number): void {
        const tooltip: HTMLElement = this.elRef.nativeElement.querySelector('.inner-tooltip')
        const tooltipWidth = parseInt(getComputedStyle(tooltip).getPropertyValue('--tooltip-width'))

        const alignment = this.alignment === 'auto'
            ? ((pointerPositionX + tooltipWidth < window.innerWidth) ? 'left' : 'right')
            : this.alignment

        tooltip.classList.remove('hide', 'alignment-left', 'alignment-right')
        tooltip.classList.add('show', `alignment-${alignment}`)
    }

    public closeTooltip(): void {
        const tooltip: HTMLElement = this.elRef.nativeElement.querySelector('.inner-tooltip')
        tooltip.classList.add('hide')
        tooltip.classList.remove('show')
    }

}
