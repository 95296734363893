import { Pipe, PipeTransform } from '@angular/core'
import { cannotBeAddedToCart } from '../../helpers/cannot-be-added-to-cart/cannot-be-added-to-cart'

@Pipe({
    name: 'cannotBeAddedToCart',
})
export class CannotBeAddedToCartPipe implements PipeTransform {

    transform(debtorPrice: number, status: string): boolean {
        return cannotBeAddedToCart(debtorPrice, status)
    }

}
